import { iconsTypes } from 'lavaa';
import { useMapping } from '../UseMapping';
import { useModules } from '../UseModules';

export const prepareMappingsAsMenuItems = (mappings: any) => Object.entries(mappings).map(([mappingUID, mapping]: [string, any]) => ({
    id: mappingUID,
    label: mapping.name,
    action: 'editMapping',
    actionData: {
        mappingUID: mappingUID
    },
}))

const useMenuMappings = (): any[] => {
    const { mappings } = useMapping();
    const myMappings = prepareMappingsAsMenuItems(mappings);

    return [
        {
            id: 'my-mapping',
            label: 'My Mapping',
            icon: iconsTypes.plug,
            children: myMappings
        },
    ];
};

export { useMenuMappings };