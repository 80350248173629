import { FC } from 'react';
import { NodeProps } from 'reactflow';
import { Node } from '../Node.component';
import { useNavigate } from "react-router-dom";

export const OpenRulesetNode: FC<NodeProps> = ( props) => {
  let navigate = useNavigate();
  let { id } = props;

  const onDoubleClick = () => {
    navigate( id );
  }

  return <Node {...props} type="openRuleset" label="Ruleset" inputs={ 1 } outputs={ 1 } onDoubleClick={onDoubleClick} />
};