import { FC, useContext } from "react";
import { NodeProps } from 'reactflow';
import { Node } from '../Node.component';
import { ModalsCtx } from "../../../../../Context/Modals.context";

export const DestinationNode: FC<NodeProps> = ( props) => {

  let { setModuleSettingsModalActive, setModuleSettingsUID } = useContext(ModalsCtx);
  let { id } = props;

  const onDoubleClick = () => {
    setModuleSettingsModalActive(true);
    setModuleSettingsUID(id);
  }

  return <Node {...props} type="destination" label="Destination" inputs={ 1 } outputs={ 1 } onDoubleClick={onDoubleClick} />
};