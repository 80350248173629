import { FC, useContext, useState } from "react";
import { Row, Col, Cell } from 'lavaa';
import { Button } from 'lavaa';
import { Title } from 'lavaa';
import { Text } from 'lavaa';
import { DeleteProjectActionButton } from '../../../Custom/Modals/DeleteProjectConfirm/DeleteProjectActionButton.component';
import { classNames } from '../../../../Tools';
import { iconsTypes } from 'lavaa';
import { EditProjectModal } from "../../../Custom/Modals/EditProjectModal/EditProjectModal.component";
import { ModalsCtx } from "../../../../Context/Modals.context";
import css from './ProjectsList.module.scss';

interface IProps {
  data: any[],
  onProjectView: (item: any) => void,
  onProjectPlay: (item: any) => void
}

// ProjectsList
const ProjectsList: FC<IProps> = (props) => {

  let { data, onProjectView, onProjectPlay } = props;
  const [activeEditProjectId, setActiveEditProjectId] = useState(null);
  const { setEditProjectModalActive } = useContext(ModalsCtx);

  // View Project
  function viewProject(e: Event, project: any) {
    e.stopPropagation();
    onProjectView(project);
  }

  // Run Project
  function runProject(e: Event, project: any) {
    e.stopPropagation();
    onProjectPlay(project);
  }

  // Edit Project
  function editProject(e: Event, project: any) {
    e.stopPropagation();
    setActiveEditProjectId(project.uid);
    setEditProjectModalActive(true);
  }

  return (
    <>
      <Col className={css.ProjectsList} grow="1" scroll="true">
        <Row wrap="wrap" style={{ justifyContent: 'center' }}>
          {
            data.map((item) => <Col key={item.uid} className={css.Item} shrink="1" grow="1" onClick={(e) => viewProject(e, item)} marginLeft="1rem" marginRight="1rem" marginBottom="2rem">

              {/* Top */}
              <Row alignitems="center" paddingTop="0.25rem">

                {/* Name */}
                <Cell className={css.Name}>
                  <Title className={css.Text} ellipsis={true} size="x4">{item.name}</Title>
                </Cell>
              </Row>

              {/* Center - Description */}
              <Row grow="1" className={css.Description}>
                <Text className={css.Text} size='x2' text={item.description} ellipsis={true} maxLines={4} />
              </Row>

              {/* Bottom */}
              <Row alignitems="center">

                {/* Run Project */}
                <Row grow="1" justifycontent="center" className={css.Controls}>
                  <Button className={classNames(css.Button, css.Play)} icon={iconsTypes.play} iconSize="x1" grow="1" type="secondary" onClick={(e) => runProject(e, item)} />
                </Row>

                {/* Edit Project */}
                <Row grow="1" justifycontent="center" className={css.Controls}>
                  <Button className={classNames(css.Button, css.View)} icon={item.isEditEnabled ? iconsTypes.pen : iconsTypes.eye} grow="1" type="secondary" onClick={(e) => editProject(e, item)} />
                </Row>

                {/* Delete Project */}
                <Row grow="1" justifycontent="center" className={css.Controls}>
                  <DeleteProjectActionButton className={classNames(css.Button, css.Delete)} icon={iconsTypes.delete} grow="1" type="secondary" uid={item.uid} />
                </Row>
              </Row>
            </Col>)
          }
        </Row>
      </Col>

      {/* Edit Project Modal */}
      <EditProjectModal projectId={activeEditProjectId} />
    </>
  );
};

export { ProjectsList };
