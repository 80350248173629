import React from 'react';
import { createRoot } from "react-dom/client";
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { AppContext } from './Context/App.context';
import { ModalsContext } from './Context/Modals.context';
import { Provider } from 'react-redux';
import App from './App';
import store from './Redux/Slices/Store';
import reportWebVitals from './reportWebVitals';
import {history} from './Constants/History';
import './index.css';

const rootElement = document.getElementById("root") as HTMLElement;
const root = createRoot(rootElement);
root.render(
  // <React.StrictMode>
    <HistoryRouter history={history}>
      <AppContext>
        <ModalsContext>
          <Provider store={ store }>
            <App />
          </Provider>
        </ModalsContext>
      </AppContext>
    </HistoryRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
