import { useDispatch, useSelector } from 'react-redux';
import { getNotificationsAction, INotificationState, resetNotificationsAction } from '../Redux/Slices/Notifications/Notifications.slice';

export const useNotifications = (): any => {

    const dispatch = useDispatch();

   // Selectors
    const notifications: INotificationState[] = useSelector((state: any) => state.notifications);

    // Fetch Notifications
    const fetchNotifications = () => {
        dispatch(getNotificationsAction([]));
    };

    // Reset Notifications
    const resetNotifications = () => {
        dispatch(resetNotificationsAction());
    };

    return {
        fetchNotifications, // Fetch
        notifications, // Select
        resetNotifications
    };
}