import React from 'react';
import { Route } from 'react-router-dom';
import { Login } from '../Pages/Login/Login.component';
import { Registration } from '../Pages/Registration/Registration.component';

// Auth Routes
const AuthRoute = [
    <Route key="/login" path="/login" element={ <Login /> } />,
    <Route key="/registration" path="/registration" element={ <Registration /> } />
];

export { AuthRoute };