import { createSlice } from '@reduxjs/toolkit';
import { emptyRule, IRuleType, rulesAccessTypes } from "./Rules.data";

interface IRulesTypeState {
    templates: {
        open: any[],
        company: any[],
        my: any[],
    },
    nodes: {
        [key: string]: IRuleType
    }
}

const initialState: IRulesTypeState = {
    templates: {
        open: [],
        company: [],
        my: [],
    },
    nodes: {},
};

export const rulesSlice = createSlice({
    name: 'Rules',
    initialState,
    reducers: {

        // Get Rules By Parent
        getRulesByParentAction: (state, action) => {},

        // Receive Rules By Parent
        receiveRulesByParentAction: (state, action) => {
            if(action.payload.resultCode !== "OK"){
                return;
            }

            const rules = action.payload.data;
            if(!Array.isArray(rules)){
                return state;
            }

            rules.forEach((rule: any) => {
                rule['jsonLogic'] = (rule['jsonLogic'] && rule['jsonLogic'].length > 0) ? JSON.parse(rule['jsonLogic']): "";
                state.nodes[rule.uid] = Object.assign({}, emptyRule, rule);
            });
        },

        // Clone Rule
        cloneRuleAction: (state, action) => {},

        // Receive Rule Clone
        receiveRuleCloneAction: (state, action) => {
            const rule = action.payload.data;
            rule['jsonLogic'] = (rule['jsonLogic'] && rule['jsonLogic'].length > 0) ? JSON.parse(rule['jsonLogic']): "";
            state.nodes[rule.uid] = Object.assign({}, emptyRule, rule);
        },

        // Get Rules
        getRulesAction: (state, action) => {},

        // Receive Rules
        receiveRulesAction: (state, action) => {
            const { type, results } = action.payload.data;

            switch (type) {
                case rulesAccessTypes.open:
                    state.templates.open = results;
                    break;
                case rulesAccessTypes.company:
                    state.templates.company = results;
                    break;
                case rulesAccessTypes.my:
                    state.templates.my = results;
                    break;
            }
        },

        // Get Rule
        getRuleAction: (state, action) => {},

        // Receive Rule
        receiveRuleAction: (state, action) => {},

        // Add Rule
        addRuleAction: (state, action) => {
            const rule = action.payload[0];
            rule['jsonLogic'] = (rule['jsonLogic'] && rule['jsonLogic'].length > 0) ? JSON.parse(rule['jsonLogic']): "";
            state.nodes[rule.uid] = Object.assign({}, emptyRule, rule);
        },

        // Receive Add Rule
        receiveAddRuleAction: (state, action) => {},

        // Update Rule
        updateRuleAction: (state, action) => {
            const rule = action.payload[0];
            rule['jsonLogic'] = (rule['jsonLogic'] && rule['jsonLogic'].length > 0) ? JSON.parse(rule['jsonLogic']): "";
            state.nodes[rule.uid] = Object.assign({}, emptyRule, rule);
        },

        // Receive Update Rule
        receiveUpdateRuleAction: (state, action) => {},

        // Delete Rule
        deleteRuleAction: (state, action) => {},

        // Receive Delete Rule
        receiveDeleteRuleAction: (state, action) => {},

        // Add Rule
        addRuleToNodesAction: (state, action) => {
            const rule = action.payload[0];
            state.nodes[rule.uid] = Object.assign({}, emptyRule, rule);
        },

        // Reset Rules
		resetRulesAction: (state) => {
			return {...initialState};
		}
    }
});

export const {
    getRulesByParentAction,
    cloneRuleAction,
    getRulesAction,
    getRuleAction,
    addRuleAction,
    updateRuleAction,
    addRuleToNodesAction,
    deleteRuleAction,
    receiveRulesByParentAction,
    receiveRulesAction,
    receiveRuleAction,
    receiveAddRuleAction,
    receiveUpdateRuleAction,
    receiveDeleteRuleAction,
    resetRulesAction
} = rulesSlice.actions;
export default rulesSlice.reducer;
