import { getAccountAction } from './Account.slice';
import { setLocalStorage } from 'lavaa';

const receiveLoginCallback = ( store: any, payload: any) => {
    payload = payload[0];
    if(payload.errorMessage.length > 0) return;

    store.dispatch(getAccountAction([payload.data.access_token]));
}

const receiveUserInfoCallback = ( store: any, payload: any) => {
    payload = payload[0];
    if(payload.errorMessage.length > 0){
        setLocalStorage("refresh_token", "");
        // call logout
    }
}

const receiveRefreshTokenCallback = ( store: any, payload: any) => {
    payload = payload[0];
    if(payload.errorMessage.length > 0) {
        setLocalStorage("refresh_token", "");
        return;
    }

    if(!store.getState().account.isAuth){
        store.dispatch(getAccountAction([payload.data.access_token]));
    }
}

export { receiveLoginCallback, receiveUserInfoCallback, receiveRefreshTokenCallback }
