import { useDispatch, useSelector } from 'react-redux';
import {
    addProjectDataOutputAction,
    addProjectRunStatusAction,
    resetProjectDataAction,
    stopProjectRunAction
} from "../Redux/Slices/Projects/ProjectTemporary.slice";
import { useParams } from 'react-router-dom';
import { useEffect, useRef } from "react";

export const useProjectTemporary = ( needControl: boolean = false): any => {

    const { activeProjectId } = useParams();
    const dispatch = useDispatch();
    const timerId = useRef<any>();

    // Selectors
    const projectDataOutput = useSelector((state: any) => state.projectTemporary.output);
    const projectReportLink = useSelector((state: any) => state.projectTemporary.reportLink);
    const projectReportFlowUID = useSelector((state: any) => state.projectTemporary.flowUID);
    const nodesState = useSelector((state: any) => state.projectTemporary.nodesState);
    const runState = useSelector((state: any) => state.projectTemporary.runState);

    // Add Project Data Output
    const addProjectDataOutput = (output: string) => {
        dispatch(addProjectDataOutputAction([output]));
    };

    // Add Project Run Status
    const addProjectRunStatus = () => {
        dispatch(addProjectRunStatusAction([]));
    };

		// Reset Project Data
		const resetProjectTemporaryData = () => {
			dispatch(resetProjectDataAction([]));
		};

    // Reset Project Data
    const ResponseTimeoutReached = () => {
        const message = 'Response timeout is reached.';
        dispatch(addProjectDataOutputAction([message, 'error']));
        dispatch(stopProjectRunAction([]));
    };

    useEffect(() => {
        if(needControl && activeProjectId === undefined) resetProjectTemporaryData();
    }, [activeProjectId]);

    useEffect(() => {
        if(!needControl) return;

        if(timerId.current) clearTimeout(timerId.current);

        if(runState.isRunning){
            timerId.current = setTimeout(ResponseTimeoutReached, 30000);
        }
    }, [runState.isRunning, runState.lastUpdate]);

    return {
        addProjectDataOutput, addProjectRunStatus, // Add
        projectDataOutput, projectReportLink, projectReportFlowUID, nodesState, runState, // Select
        resetProjectTemporaryData,
        // resetProjectReportLink // Reset
    };
}
