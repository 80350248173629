import { FC } from 'react';
import { NodeProps } from 'reactflow';
import { Node } from '../Node.component';
import { useNavigate } from "react-router-dom";

export const MyNode: FC<NodeProps> = ( props) => {
  let navigate = useNavigate();
  let { id, data } = props;
  const { moduleTypeID } = data;

  const onDoubleClick = () => {
    navigate( id );
  }

  const getModuleType = (moduleTypeID: number): 'health' | 'clinicalResearch' => {
    
    if (moduleTypeID === 6) {
      return 'clinicalResearch';
    }

    return 'health';
  };

  return <Node {...props} moduleType={getModuleType(moduleTypeID)} type="my" label="My module" inputs={ 1 } outputs={ 1 } onDoubleClick={onDoubleClick} />
};