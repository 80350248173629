import { FC, useContext, useEffect } from "react";
import { AppCtx } from '../../Context/App.context';
import { useParams } from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';
import { Col, Loading, Grid, Splitter, PanelMenu, Panel, iconsTypes, addSnackbar } from "lavaa";
import { RulesetFlow } from '../RulesetFlow/RulesetFlow.component';
import { useModules } from "../../Hooks/UseModules";
import { prepareRulesetsAsMenuItems, useMenuRulesets } from "../../Hooks/Menu/UseMenuRulesets";
import { UnavailableModule } from "../Fallback/Module/Unavailable.component";
import { useMenuHierarchy } from "../../Hooks/Menu/UseMenuHierarchy";
import { useProjects } from "../../Hooks/UseProjects";
import { CreateNewRulesetModal } from '../Custom/Modals/CreateNewRulesetModal/CreateNewRulesetModal.component';
import { MenuEntityInfo } from "../MenuEntityInfo/MenuEntityInfo.component";
import { ModalsCtx } from "../../Context/Modals.context";
import { ModuleSettingsModal } from "../Custom/Modals/ModuleSettingsModal/ModuleSettingsModal.component";
import { useRulesets } from "../../Hooks/UseRulesets";
import { EditModuleModal } from "../Custom/Modals/Modules/EditModuleModal/EditModuleModal.component";
import { ProjectOutput } from "../ProjectLayout/ProjectOutput.component";
import { EditRulesetModal } from "../Custom/Modals/EditRulesetModal/EditRulesetModal.component";
import { FilterNodeModal } from "../Custom/Modals/FilterNodeModal/FilterNodeModal.component";
import { TreeMenu } from "../Custom/TreeMenu/TreeMenu.component";
import { AddNodesToFlowModalNew } from "../Common/AddNodesToFlowModalNew/AddNodesToFlowModalNew.component";
import { DeleteRulesetTemplateConfirm } from "../Custom/Modals/DeleteRulesetTemplateConfirm/DeleteRulesetTemplateConfirm.component";
import { useTypes } from "../../Hooks/UseTypes";

// Module Layout
const ModuleLayout: FC<any> = () => {

  // Contexts
  const { performanceNow } = useContext(AppCtx);

  const { setEditModuleModalActive, setEditModuleUID, isAddOpenRulesetsModalActive, setAddOpenRulesetsModalActive, isAddMyRulesetsModalActive, setAddMyRulesetsModalActive } = useContext(ModalsCtx);

  const { openRulesets, myRulesets, getRulesetTemplate } = useRulesets();
  const openRulesetsMenuData = prepareRulesetsAsMenuItems(openRulesets, 'openRuleset');

  const myRulesetsMenuData = prepareRulesetsAsMenuItems(myRulesets, 'myRuleset');

  const menuRulesets = useMenuRulesets();
  const menuHierarchy = useMenuHierarchy();

  const { activeProjectId, activeModuleId } = useParams();
  const { fetchModule, updateModule, getActiveModuleData, modulesOnLoading, getModuleTemplate, fetchModuleTemplate } = useModules();
  const { getActiveProjectData, getProjectLink } = useProjects();

  const activeProjectData = getActiveProjectData();
  const activeModuleData = getActiveModuleData();
  const moduleTemplateData = (activeModuleData) ? getModuleTemplate(activeModuleData.primaryId) ?? null : null;
  const backUrl = getProjectLink(activeProjectData);
  const { disorderTypes } = useTypes();

  // Fetch Module Data
  useEffect(() => {
    fetchModule(activeProjectId, activeModuleId);
  }, [activeModuleId]);

  // Fetch Module Template Data
  useEffect(() => {
    if (activeModuleData) {
      fetchModuleTemplate(activeModuleData.primaryId);
    }
  }, [activeModuleData]);

  // Handle Edit
  const handleEdit = () => {
    if(activeModuleData) {
      setEditModuleModalActive( true );
      setEditModuleUID(activeModuleData.primaryId);
    }
  };

  const onTemplateUpdated = (data: any) => {
    updateModule(data);
    addSnackbar('success', 'Success', 'Module has been updated');
  };

  if(modulesOnLoading.includes(activeModuleId)) {
    return (
      <Col grow="1" alignitems="center" justifycontent="center">
        <Loading/>
      </Col>
    );
  }

  if(activeModuleData === null || moduleTemplateData === null) {
    return (
      <UnavailableModule/>
    )
  }

  return <>
    <ReactFlowProvider>

      <Splitter resetLayoutKey={performanceNow} direction="vertical" collapsible={[0, 2]} paneSizes={['15%', '70%', '15%']} minSizes={[200, undefined, 250]} splitterSize="10px" collapsedSplitterSize="20px" name="ModuleLayout">

        {/* Rulesets Panel */}
        <Grid scroll="true">
          <Panel title="Rulesets" backUrl={ backUrl } filterPlaceholder="Search by Ruleset name">
            {
              ({filterValue}) => <PanelMenu filterValue={ filterValue } data={ menuRulesets } TreeMenu={TreeMenu}/>
            }
          </Panel>
        </Grid>

        {/* Center Pane */}
        <Splitter resetLayoutKey={performanceNow} direction="horizontal" collapsible={[1]} paneSizes={['70%', '30%']} splitterSize="10px" name="ModuleLayoutCenterPane">

          {/* Top Pane */}
          <Grid>
            <RulesetFlow />
          </Grid>

          {/* Output Area */}
          <Grid>
            <ProjectOutput />
          </Grid>
        </Splitter>

        {/* Right Pane */}
        <Splitter resetLayoutKey={performanceNow} direction="horizontal" paneSizes={['70%', '30%']} splitterSize="10px" name="ModuleLayoutRightPane">

          {/* Project Hierarchy Panel */}
          <Grid scroll="true">
            <Panel title="Project Hierarchy" filterPlaceholder="Search">
              {
                ( { filterValue } ) => <PanelMenu filterValue={filterValue} data={menuHierarchy} TreeMenu={TreeMenu}/>
              }
            </Panel>
          </Grid>

          {/* Project Info */}
          <Grid>
            <Panel
				title="Current Template Module"
				hasFilter={false}
				onActionClick={moduleTemplateData?.isEditEnabled == true ? handleEdit : undefined}
			>
              {
                () => <MenuEntityInfo data={moduleTemplateData} />
              }
            </Panel>
          </Grid>
        </Splitter>
      </Splitter>
    </ReactFlowProvider>

    {/* Add Open Rulesets Popup */}
    <AddNodesToFlowModalNew tplType="ruleset" nodesType="openRuleset" title="Add Open Rulesets"
      parentData={ activeModuleData }
      data={ openRulesetsMenuData }
      getNodeTemplate={ getRulesetTemplate }
      // deleteNodeByPrimaryId={ deleteRuleset }
      updateParent={ onTemplateUpdated }
      filters={[
        { title: 'Disorder Type', data: disorderTypes, displayField: 'name', fieldOfFilterData: 'disorderTypeID', fieldOfListData: 'disorderTypeID' }
      ]}
      active={ isAddOpenRulesetsModalActive }
      setActive={ setAddOpenRulesetsModalActive }
      searchPlaceholder="Search ruleset by name"
      closeButtonText="Back to Module"
      closeButtonIcon={iconsTypes.exit}
    />

    {/* Add My Rulesets Popup */}
    <AddNodesToFlowModalNew tplType="ruleset" nodesType="myRuleset" title="Add My Rulesets"
      parentData={ activeModuleData }
      data={ myRulesetsMenuData }
      getNodeTemplate={ getRulesetTemplate }
      // deleteNodeByPrimaryId={ deleteRuleset }
      updateParent={ onTemplateUpdated }
      filters={[
        { title: 'Disorder Type', data: disorderTypes, displayField: 'name', fieldOfFilterData: 'disorderTypeID', fieldOfListData: 'disorderTypeID' }
      ]}
      active={ isAddMyRulesetsModalActive }
      setActive={ setAddMyRulesetsModalActive }
      searchPlaceholder="Search ruleset by name"
      closeButtonText="Back to Module"
      closeButtonIcon={iconsTypes.exit}
    />

    {/* Edit Ruleset Modal */}
    <EditRulesetModal/>

    {/* Create New Ruleset Modal */}
    <CreateNewRulesetModal/>

    {/* Module Settings Modal */}
    <ModuleSettingsModal/>

    {/* Edit Module Modal */}
    <EditModuleModal />

    {/* Filter Node Modal */}
    <FilterNodeModal />

    {/* Delete Ruleset Template Confirm */}
    <DeleteRulesetTemplateConfirm />
  </>
};

export { ModuleLayout };
