import { FC } from "react";
import { Button } from "lavaa";
import { Cell, Col, Row } from "lavaa";
import { iconsTypes } from "lavaa";
import { Title } from "lavaa";
import { CreateNewRulesetModalActionButton } from "../../Custom/Modals/CreateNewRulesetModal/CreateNewRulesetModalActionButton.component";
import { CreateNewModuleModalActionButton } from "../../Custom/Modals/Modules/CreateNewModuleModal/CreateNewModuleModalActionButton.component";
import css from "./FlowTopBar.module.scss";

interface IProps {
	type: 'module' | 'ruleset',
	flowData: any,
	onSave: () => void,
	onPublish: () => void,
	onRun?: () => void,
	toggleFullScreen: () => void,
    fullScreen: boolean,
	panToCenter: () => void,
	zoomIn: (options: any) => void,
	zoomOut: (options: any) => void,
	title?: string,
	description?: string,
	isDisabled?: boolean
}

// Flow Top Bar
export const FlowTopBar: FC<IProps> = (props) => {

    const { type, flowData, onSave, onPublish, onRun, toggleFullScreen, panToCenter, zoomIn, zoomOut, title, description, isDisabled = false, fullScreen = false } = props;

    return <>
        <Row className={css.FlowTopBar} paddingTop="1rem" paddingBottom="1rem" paddingLeft={fullScreen ? '1rem' : '0'} paddingRight={fullScreen ? '1rem' : '0'}>

            {/* Add New Module/Ruleset Action Button */}
            <Row grow="1" marginLeft="0">

                {/* Module type */}
                {
                    type === 'module' &&
                    <CreateNewModuleModalActionButton icon={ iconsTypes.playCircle } text="Add New Module" type="secondary"/>
                }

                {/* Ruleset type */}
                {
                    type === 'ruleset' &&
                    <CreateNewRulesetModalActionButton icon={ iconsTypes.playCircle } text="Add New Ruleset" type="secondary"/>
                }
            </Row>

            <Row marginRight="0">

                {/* Save */}
                <Row>
                    <Button text="Save" type={!isDisabled ? "secondary" : "disable"} onClick={ onSave }/>
                </Row>

                {/* Publish */}
                <Row paddingLeft="0.5rem">
                    <Button text="Publish" type={!isDisabled ? "secondary" : "disable"} onClick={ onPublish }/>
                </Row>

                {/* Run */}
                {
                    onRun && (
                        <Row paddingLeft="0.5rem">
                            <Button text="Run" type={!isDisabled ? "primary" : "disable"} onClick={ onRun }/>
                        </Row>
                    )
                }
            </Row>
        </Row>

        {/* Flow Area Header */}
        {
            flowData &&
            <Col className={ css.FlowHeader } paddingLeft="1rem" paddingBottom="1rem" paddingTop="0.75rem" paddingRight="1rem">

                <div className={ css.TitleSide } />
                <Row alignitems="center" justifycontent="space-between">

                    {/* Title */}
                    <Title className={css.Title} size="x6" ellipsis={true}>{ title || flowData.name || flowData.Name }</Title>

                    {/* Controlls */}
                    <Row>

                        {/* Set Center */}
                        <Cell paddingRight="0.5rem">
                            <Button type="secondary" icon={ iconsTypes.flowNavigation } variant="controls" onClick={ panToCenter } />
                        </Cell>

                        {/* Fullscreen */}
                        <Cell paddingRight="0.5rem">
                            <Button onClick={ toggleFullScreen } type="secondary" icon={ iconsTypes.flowFullScreen } variant="controls" />
                        </Cell>

                        {/* Zoom Out */}
                        <Cell paddingRight="0.5rem">
                            <Button type="secondary" icon={ iconsTypes.flowMinus } variant="controls" onClick={ () => zoomOut({duration: 300}) } />
                        </Cell>

                        {/* Zoom In */}
                        <Cell>
                            <Button type="secondary" icon={ iconsTypes.flowPlus } variant="controls" onClick={ () => zoomIn({duration: 300}) } />
                        </Cell>
                    </Row>
                </Row>

                {/* Description */}
                {/* <Text className={css.Text} size="x2" ellipsis={true} maxLines={2}>{ description || flowData.description }</Text> */}
            </Col>
        }
    </>
};
