import { FC } from 'react';
import { useTypes } from '../../../../Hooks/UseTypes';
import { Dropdown } from 'lavaa';

interface IProps {
    onSelect: (data: any) => void,
    selected?: any
}

// Disorder Types Selector
const DisorderTypesSelector: FC<IProps> = (props) => {

    const { onSelect, selected } = props;

    const { disorderTypes } = useTypes();

    return <Dropdown data={ disorderTypes } displayField="name" selectField="disorderTypeID" selected={ selected } placeholder="Disorder:" label="*Type of disorder" wrap="col" onSelect={ onSelect } />
};

export { DisorderTypesSelector };