import { Route } from 'react-router-dom';
import { ProjectLayout } from '../ProjectLayout/ProjectLayout.component';
import { OpenProjects } from '../Pages/OpenProjects/OpenProjects.component';
import { ModuleLayout } from '../ModuleLayout/ModuleLayout.component';
import { RulesetLayout } from '../RulesetLayout/RulesetLayout.component';

// Open Projects Route
const OpenProjectsRoute = [
    <Route key="/openprojects" path="/openprojects" element={<OpenProjects />} />,
    <Route key="/openprojects/:activeProjectId" path="/openprojects/:activeProjectId" element={ <ProjectLayout /> } />,
    <Route key="/openprojects/:activeProjectId/:activeModuleId" path="/openprojects/:activeProjectId/:activeModuleId" element={ <ModuleLayout /> } />,
    <Route key="/openprojects/:activeProjectId/:activeModuleId/:activeRulesetId" path="/openprojects/:activeProjectId/:activeModuleId/:activeRulesetId" element={ <RulesetLayout /> } />,
];

export { OpenProjectsRoute };