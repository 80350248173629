import { useEffect, useRef } from 'react';

export const usePrev = (value: any): any => {

    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
}