import { FC, useEffect, useState } from 'react';
import { Col, IValidationResult, Input, Row, Text, Icon, iconsTypes, Tooltip } from 'lavaa';
import { GuidelinePrioritySelector } from '../GuidelinePrioritySelector/GuidelinePrioritySelector.component';
import { InputNum } from '../../../Common/InputNum/InputNum.component';

interface IProps {
    onChange: (name: string, value: string | any) => void,
    initialData: any,
    mode: '' | 'create' | 'edit',
    onValidate: (value: boolean) => void
}

// CreateEditGuidelineDataTab
const CreateEditGuidelineDataTab: FC<IProps> = (props) => {
    
    // Props
    const { onChange, initialData = {}, mode = '' } = props;
    
    // States
    const [guidelineName, setGuidelineName] = useState<string>('');
    const [priority, setPriority] = useState<any>();
    const [description, setDescription] = useState<string>('');
    const [minValue, setMinValue] = useState<string>('');
    const [maxValue, setMaxValue] = useState<string>('');

    const [isNameValid, setNameValid] = useState(false);
    const [isPriorityValid, setPriorityValid] = useState(initialData?.priority ? true : false);

    const [isFormValid, setIsFormValid] = useState(false);

    // Name
    const onNameChange = (value: string) => {
        setGuidelineName(value);
        onChange('guidelineName', value);
    };

    // Priority
    const onPriorityChange = (value: any) => {
        setPriority(value);
        onValidate({error: '', valid: true, name: 'priority'});
        onChange('priority', value);
    };

    // Description
    const onDescriptionChange = (value: string) => {
        setDescription(value);
        onChange('description', value);
    };

    // Min
    const onMinChange = (value: number | string) => {

        // Empty
        if (value === '') {
            setMinValue('');
            onChange('minValue', '');
        }

        // Init Min
        else if (value !== '' && maxValue !== '' && minValue === '' && Number(maxValue) < Number(value)) {
            setMinValue(maxValue.toString());
            onChange('minValue', maxValue.toString());
        }

        // Increase Min+Max
        else if (maxValue !== '' && minValue !== '' && Number(value) > Number(maxValue)) {
            setMaxValue(value.toString());
            setMinValue(value.toString());
            onChange('maxValue', value.toString());
            onChange('minValue', value.toString());
        }

        // Increase/Decrease Min
        else {
            setMinValue(value.toString());
            onChange('minValue', value.toString());
        }
    };

    // Max
    const onMaxChange = (value: number | string) => {

        // Empty
        if (value === '') {
            setMaxValue('');
            onChange('maxValue', '');
        }

        // Init Max
        else if (value !== '' && minValue !== '' && maxValue === '' && Number(minValue) > Number(value)) {
            setMaxValue(minValue.toString());
            onChange('maxValue', minValue.toString());
        }

        // Decrease Max+Min
        else if (minValue !== '' && maxValue !== '' && Number(value) < Number(minValue)) {
            setMinValue(value.toString());
            setMaxValue(value.toString());
            onChange('minValue', value.toString());
            onChange('maxValue', value.toString());
        }

        // Increase/Decrease Max
        else {
            setMaxValue(value.toString());
            onChange('maxValue', value.toString());
        }
    };

    // On Validate
    const onValidate = (data: IValidationResult) => {
        const { name, error, valid } = data;

        // Guideline Name
        if (name === 'guidelineName' && isNameValid !== valid) {
            setNameValid(valid);
        }

        // Priority
        if (name === 'priority' && isPriorityValid !== valid) {
            setPriorityValid(valid);
        }
    }

    // Watch form valid
    useEffect(() => {

        // Set Form as Valid
        if (isNameValid && isPriorityValid && !isFormValid) {
            setIsFormValid(true);
            props.onValidate && props.onValidate(true);
        }

        // Set Form as Not Valid
        if ((!isNameValid || !isPriorityValid) && isFormValid) {
            setIsFormValid(false);
            props.onValidate && props.onValidate(false);
        }
    }, [isNameValid, isPriorityValid]);

    useEffect(() => {
        if (initialData) {
            initialData.text !== '' && guidelineName === '' && setGuidelineName(initialData.text);
            initialData.description !== '' && description === '' && setDescription(initialData.description);
            if (initialData.priority && !priority) {
                setPriority(initialData.priority);
                setPriorityValid(true);
            }
            setMinValue(initialData.minValue);
            setMaxValue(initialData.maxValue);
        }
    }, [initialData]);
    
    return (
        <Col paddingTop="1.75rem">
            <Row paddingBottom="1rem">

                {/* Name */}
                <Row grow="1" paddingRight="0.5rem">
                    <Input disabled={mode === 'edit' ? true : false} type="text" name="guidelineName" wrap="col" label="*Guideline Name" placeholder="Guideline Name" onChange={ onNameChange } value={guidelineName} validation="required" onValidate={onValidate} />
                </Row>

                {/* Priority Type */}
                <Row grow="1" paddingLeft="0.5rem">
                    <GuidelinePrioritySelector onSelect={ onPriorityChange } initialData={priority} />
                </Row>
            </Row>

            {/* Description */}
            <Row grow="1" paddingBottom="1rem">
                <Input disabled={mode === 'edit' ? true : false} style={{ height: 153, resize: 'none' }} type="textarea" name="description" wrap="col" label="*Description" placeholder="Description, max length is 200 characters" onChange={onDescriptionChange} value={description || ''} maxlength={200} />
            </Row>
            
            {/* Range */}
            <Row paddingRight="1rem" paddingTop="1rem">
                <Col>

                    {/* Title */}
                    <Row paddingBottom="0.5rem" alignitems="center" style={{color: 'var(--secondary-button-text-color)'}}>
                        <Row>
                            <Text size="x4" bold="true" text="Module conditions" style={{borderLeft: '4px solid var(--alternative-primary-color)', paddingLeft: '.5rem'}}/>
                        </Row>
                        <Row paddingLeft="0.5rem">
                            {/* <Icon name={iconsTypes.infoFilled} size="18" style={{display: 'flex', cursor: 'pointer'}} /> */}
                            <Tooltip useHover={true} positionH="left" positionV="top" tooltipText="You should assign Module scores in order to determine the guideline's visibility on the patients' pages." />
                        </Row>
                    </Row>

                    <Row paddingTop="1rem">
                        <Text size="x2" style={{color: 'var(--alternative-primary-color)'}}>Module Score</Text>
                    </Row>

                    {/* Inputs */}
                    <Row alignitems="center" paddingTop="1rem">

                        {/* Min */}
                        <Row alignitems="center">
                            <Row paddingRight="0.5rem">
                                <Text size="x2" style={{color: 'var(--alternative-primary-color)'}}>Min</Text>
                            </Row>

                            <Row>
                                <InputNum placeholder="Empty" value={minValue} onChange={onMinChange} />
                                {/* <InputNumber min={0} max={100} value={ Number(minValue || 0) } placeholder="0" onChange={ onMinChange } style={{width: '100px'}} /> */}
                            </Row>
                        </Row>

                        {/* Max */}
                        <Row paddingLeft="1rem" alignitems="center">
                            <Row paddingRight="0.5rem">
                                <Text size="x2" style={{color: 'var(--alternative-primary-color)'}}>Max</Text>
                            </Row>

                            <Row>
                                <InputNum placeholder="Empty" value={maxValue} onChange={onMaxChange} />
                                {/* <InputNumber min={Number(minValue || 0)} max={100} value={ Number(maxValue || 0) } placeholder="100" onChange={ onMaxChange } style={{width: '100px'}} /> */}
                            </Row>
                        </Row>
                    </Row>
                </Col>
            </Row>
        </Col>
    )
};

export { CreateEditGuidelineDataTab };
