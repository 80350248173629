import { FC, useState } from 'react';
import { Dropdown } from 'lavaa';
import { Row } from 'lavaa';
import { iconsTypes } from 'lavaa';

interface IProps {
    sortData: Array<any>,
    onSort: (option: any) => void
}

// ProjectsSort
const ProjectsSort: FC<IProps> = (props) => {

    let { sortData, onSort } = props;

    // States
    const [selected, setSelected] = useState(null as any);

    const onSelect = (data: any) => {
        setSelected(data);
        onSort(data);
    };

    return <Row alignitems="center" grow="0" paddingRight="1rem">

        {/* Drop Down */}
        <Dropdown icon={iconsTypes.sort} placeholder="Sort by:" data={ sortData } selected={selected} onSelect={onSelect}></Dropdown>
        
    </Row>;
};

export { ProjectsSort };