import css from './Notifications.module.scss';
import { Row, Cell, withoutPaddings } from 'lavaa';
import { Icon, iconsTypes } from 'lavaa';
import { NotificationsDropdown } from './NotificationsDropdown.component';
import { useState } from 'react';
import { classNames } from '../../Tools';
import { useNotifications } from '../../Hooks/UseNotifications';
import { INotificationState } from '../../Redux/Slices/Notifications/Notifications.slice';

// Notifications
const Notifications = () => {

    //States
    const [dropdownActive, setDropdownActive] = useState(false);

    // Hooks
    const { notifications } = useNotifications();
    const hasNewNotify = notifications.some((notify: INotificationState) => notify.isNew);

    function clickHandler(){
        setDropdownActive((prev) => !prev);
    }

    return (
        <Row justifycontent="end" paddingRight='2rem'>
            <Row paddingRight='2rem'>
                <Row className={css.NotificationsWrapper}>
                    <Row alignitems="center" className={css.Notifications} onClick={clickHandler}>
                        <Cell alignitems='center' justifycontent='center' className={ classNames(css.Icon, hasNewNotify ? css.HasNewNotify : '', dropdownActive ? css.Active : '') } {...withoutPaddings}>
                            <Icon name={dropdownActive ? iconsTypes.bellFilled : iconsTypes.bell}/>
                        </Cell>
                    </Row>

                    {
                        /* Dropdown */
                        dropdownActive && <NotificationsDropdown closeHandler={() => setDropdownActive(false)}/>
                    }
                </Row>
            </Row>
        </Row>
    );
};

export { Notifications };