import { FC, useEffect, useState } from 'react';
import { Col, Cell } from 'lavaa';
import { Text } from 'lavaa';
import parse from 'html-react-parser';
import css from './MenuEntityInfo.module.scss';

interface IProps {
  data: {
    isEditEnabled: boolean,
    name: string,
    description: string,
    additionalInfo: string
  }
}

// Menu Entity Info
const MenuEntityInfo: FC<IProps> = ( props) => {

  const { data } = props;
  const [additionalInfo, setAdditionalInfo] = useState<any>();

  useEffect(() => {
    setAdditionalInfo(parse(data.additionalInfo));
  }, [data.additionalInfo]);

  return (
      <Col className={css.InfoPanel} paddingLeft="1rem" paddingRight="1rem" grow="1">

        {/* Name */}
        <Col paddingTop="0.5rem" paddingBottom="0.5rem">

          {/* Title */}
          <Cell paddingBottom="0.25rem">
            <Text text="Name" size="x2" className={css.Title} bold="true"/>
          </Cell>

          {/* Text */}
          <Text size='x1' text={ data.name } className={css.Text} ellipsis={true} maxLines={2} />
        </Col>

        {/* Description */}
        <Col paddingTop="0.5rem" paddingBottom="0.5rem">

          {/* Title */}
          <Cell paddingBottom="0.25rem">
            <Text text="Description" size="x2" className={css.Title} bold="true"/>
          </Cell>

          {/* Text */}
          <Text size='x1' text={ data.description } className={css.Text} ellipsis={true} maxLines={4} />
        </Col>

        {/* Additional Info */}
        {
          !data.additionalInfo ? null :
          <Col paddingTop="0.5rem" paddingBottom="0.5rem" grow="1">

            {/* Title */}
            <Cell paddingBottom="0.25rem">
              <Text text="Additional Info" size="x2" className={css.Title} bold="true"/>
            </Cell>

            {/* Text */}
            <Col grow="1" scroll="true">
              <Text size='x1' className={css.Text}>{additionalInfo}</Text>
            </Col>
          </Col>
        }
      </Col>
  );
};

export { MenuEntityInfo };
