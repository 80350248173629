import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProjectsTopBar } from './ProjectsTopBar/ProjectsTopBar.component';
import { ProjectsList } from './ProjectsList/ProjectsList.component';
import { Col, Grid, Paginator, Loading, Row } from 'lavaa';
import { EmptyProjectsView } from './EmptyProjectsView/EmptyProjectsView.component';
import { useProjects } from "../../../Hooks/UseProjects";
import { projectAccessType } from "../../../Redux/Slices/Projects/Projects.data";
import { IProjectTypeOptions } from '../../../Types/Project.types';
import { usePrev } from '../../../Hooks/UsePrev';
import css from './Projects.module.scss';

interface IProps {
    projectsType: projectAccessType,
    pageTitle: string,
    locationPath: string,
    filterData: Array<any>,
    options: IProjectTypeOptions
}

// Projects Page
const ProjectsPage: FC<IProps> = (props) => {

    const { projectsType, pageTitle, locationPath, filterData: filterOptions, options } = props;
    const { loading, data = [], page } = options;
    const pageSize = 12;

    // Hooks
    const { fetchProjects } = useProjects();

    const sortOptions = [
        {id: '008', value: 'Last Update', key: 'Last Update', sortBy: 'name', sortType: 'asc'},
        {id: '009', value: 'A-Z', key: 'A-Z', sortBy: 'lastUpdate', sortType: 'asc'},
        {id: '010', value: 'Z-A', key: 'Z-A', sortBy: 'lastUpdate', sortType: 'desc'}
    ];

    // States
    const [searchValue, setSearchValue] = useState<string>('');
    // const [filterData, setFilterData] = useState<any>();
    const [sortData, setSortData] = useState<any>();
    const navigate = useNavigate();
    const { runProject } = useProjects();
    const prevSearchValue = usePrev(searchValue);

    // Handle Search
    const onSearch = (value: string) => {
        setSearchValue(value.toLowerCase());
    };

    // Handle Filter - @DEPRECATED
    const onFilter = (option: any) => {
        // setFilterData(option);
    };

    // Handle Sorting
    const onSort = (option: any) => {
        setSortData(option);
    };

    // On Project View - Navigate to project page
    const onProjectView = (item: any) => {
        navigate(`/${locationPath}/` + item.uid);
    };

    // On Project Play - Navigate to project page and play
    const onProjectPlay = (item: any) => {
        navigate(`/${locationPath}/` + item.uid);

        setTimeout(() => {
            runProject(item.uid);
        }, 1000);
    };

    // On View All
    const onViewAll = () => {
        setSearchValue('');
    };

    // Fetch projects
    const getProjects = (page: number = 1) => {
        fetchProjects({accessType: projectsType, pageNumber: page, searchTerm: searchValue});
    };

    // Watch search value
    useEffect(() => {
        if (searchValue.length >= 2 || prevSearchValue !== '' && searchValue === '') {
            getProjects();
        }
    }, [searchValue]);

    // Apply Filter
    // let filteredData: Array<any> | null = filterData ? projects.filter(item => !filterData.filterBy ? item : item[filterData.filterBy] === filterData.key) : null;

    // Apply Search Value
    // let projectsList = (filteredData || projects).filter(item => item.name.toLowerCase().includes(searchValue)).slice((page - 1) * perPage, page * perPage);
    // const currentItems = projectsList.length;

    // Apply Sorting
    // sortData && projectsList.sort((a, b) => sortData.sortType === 'asc' ? a[sortData.sortBy] - b[sortData.sortBy] : b[sortData.sortBy] - a[sortData.sortBy]);

    return <Grid className={ css.Projects}>

        {/* Projects Top Bar */}
        <ProjectsTopBar onSearch={ onSearch } searchValue={ searchValue } onFilter={ onFilter } onSort={ onSort } onViewAll={ onViewAll } pageTitle={ pageTitle } filterData={ filterOptions } sortData={ sortOptions } isCreateNewProjectActive={!!data.length} />

        {/* Loading */}
        {
            loading &&
            <Col grow="1" alignitems="center" justifycontent="center">
                <Loading />
            </Col>
        }

        {/* Projects List */}
        {
            !loading &&
            <Grid style={{overflow: 'auto'}}>
                {
                    data.length ?
                    <ProjectsList data={ data } onProjectView={ onProjectView } onProjectPlay={ onProjectPlay } />
                    :
                    // Empty Content
                    <EmptyProjectsView />
                }
            </Grid>
        }

        {/* Paginator */}
        <Row paddingTop="1rem" paddingBottom="1rem" alignitems="center" justifycontent="center">
            <Paginator page={page} changePage={getProjects} perPage={pageSize} currentItems={data.length}/>
        </Row>
    </Grid>
};

export { ProjectsPage };
