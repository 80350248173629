export type FilterNodeModalType = {
	isActive: boolean,
	uid: string,
	type: 'module' | 'ruleset'
}

export const filterNodeModalDefault: FilterNodeModalType = {
	isActive: false,
	uid: '',
	type: 'module'
}
