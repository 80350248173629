import { FC, useEffect, useState } from 'react';
import { IValidationResult, Title } from 'lavaa';
import { Row, Col, Cell } from 'lavaa';
import { Input } from 'lavaa';
import { Button } from 'lavaa';
import { Text } from 'lavaa';
import { useAccount } from '../../../../Hooks/UseAccount';
import css from '../Auth.module.scss';

// Login Page
const AuthLogin: FC<any> = () => {

    // Hooks
    const { loginAccount } = useAccount();

    const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

    // States
    const [username, setUsername] = useState(isDev ? 'lavaa_dev_user' : '');
    const [password, setPassword] = useState(isDev ? 'Lavaa!22!Dev' : '');
    const [isUsernameValid, setIsUsernameValid] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);

    // On Login
    const loginHandler = () => {
        loginAccount(username, password);
    }

    // On Validate
    const onValidate = (data: IValidationResult) => {
        const { name, error, valid } = data;

        // Username
        if (name === 'username' && isUsernameValid !== valid) {
            setIsUsernameValid(valid);
        }

        // Password
        if (name === 'password' && isPasswordValid !== valid) {
            setIsPasswordValid(valid);
        }
    };

    // Watch form valid
    useEffect(() => {

        // Set Form as Valid
        if (isUsernameValid && isPasswordValid && !isFormValid) {
            setIsFormValid(true);
        }

        // Set Form as Not Valid
        if ((!isUsernameValid || !isPasswordValid) && isFormValid) {
            setIsFormValid(false);
        }
    }, [isUsernameValid, isPasswordValid]);

    return (
        <Col className={css.AuthPage} alignitems="center" justifycontent="center">

            {/* Form */}
            <Col className={css.AuthForm} alignitems="center">
                <Title textAlign="center" grow="1" size="x8">Welcome and Login</Title>

                <Col className={css.AuthInputs}>

                    {/* Username */}
                    <Row grow="1" paddingBottom="1.5rem">
                        <Input
                            type="text"
                            wrap="col"
                            label="*Username"
                            placeholder=".........."
                            onChange={setUsername}
                            value={username}
                            className={css.AuthInput}
                            labelClassName={css.AuthInputLabel}
                            validation="required"
                            onValidate={onValidate}
                            name="username"
                        />
                    </Row>

                    {/* Password */}
                    <Row grow="1" paddingBottom="1.5rem">
                        <Input
                            type="password"
                            wrap="col"
                            label="*Password"
                            placeholder="........................................"
                            onChange={setPassword}
                            value={password}
                            className={css.AuthInput}
                            labelClassName={css.AuthInputLabel}
                            validation="required"
                            name="password"
                            onValidate={onValidate}
                        />
                    </Row>

                    {/* Sublit Button */}
                    <Cell grow="1" paddingBottom="1.5rem">
                        <Button text="Login" size="large" grow="1" onClick={loginHandler} disabled={!isFormValid} />
                    </Cell>

                    {/* Login & Registration links */}
                    <Cell paddingBottom="2rem" justifycontent="center">
                        <Text size="x3" textAlign="center" link="/login" className={css.AuthLinkForgot}>Forgot Password?</Text>
                    </Cell>
                    <Cell paddingBottom="2rem" justifycontent="center">
                        <Text size="x3" textAlign="center">Don't have an account?</Text>
                        <Text size="x3" textAlign="center" link="/registration" bold={true} className={css.AuthLinkSignUp}>Sign Up</Text>
                    </Cell>
                </Col>
            </Col>
        </Col>
    );
};

export { AuthLogin };