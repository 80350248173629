import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    addProjectAction,
    getProjectsAction,
    updateProjectAction,
    deleteProjectAction,
    getProjectAction,
    publishProjectAction,
    runProjectAction,
    resetProjectsAction
} from "../Redux/Slices/Projects/Projects.slice";
import { projectAccessType } from '../Redux/Slices/Projects/Projects.data';
import { useEffect } from 'react';
import { useProjectTemporary } from "./UseProjectTemporary";

interface IProjects {
    accessType: 1 | 2 | 3,
    pageNumber?: number,
    pageSize?: number,
    sortedBy?: string,
    isSortingDesc?: boolean,
    searchTerm?: string,
    filterByCompany?: string
}

export const useProjects = (needUpdate: boolean = false): any => {

    const { activeProjectId } = useParams();
    const { addProjectRunStatus } = useProjectTemporary();
    const dispatch = useDispatch();

    // Selectors
    const projects = useSelector((state: any) => state.projects);


    const openProjects = {
        ...useSelector((state: any) => state.projects.open),
        data: Object.values(useSelector((state: any) => state.projects.open.data))
    };
    const myCompanyProjects = {
        ...useSelector((state: any) => state.projects.myCompany),
        data: Object.values(useSelector((state: any) => state.projects.myCompany.data))
    };
    const myProjects = {
        ...useSelector((state: any) => state.projects.my),
        data: Object.values(useSelector((state: any) => state.projects.my.data))
    };
    const projectsOnLoading = useSelector((state: any) => state.projects.onLoading);

    // Fetch Projects
    const fetchProjects = (data: IProjects) => {
        const { accessType, pageNumber = 1, pageSize = 12, sortedBy = 'name', isSortingDesc = true, searchTerm = '', filterByCompany = '' } = data;
        
        dispatch(getProjectsAction([
            accessType,
            pageNumber-1,
            pageSize,
            sortedBy,
            isSortingDesc,
            searchTerm,
            filterByCompany
        ]));
    };

    // Fetch Project
    const fetchProject = (uid: any) => {
        dispatch(getProjectAction([uid]));
    };

    // Watch activeProjectId
    useEffect(() => {
        if(activeProjectId && needUpdate) fetchProject(activeProjectId);
    }, [activeProjectId]);

    // Add Open Project
    const addOpenProject = (data: any) => {
        const { name, description, additionalInfo, company} = data;

        // Dispatch Add Project
        dispatch(addProjectAction([{
            name,
            description,
            additionalInfo,
            accessType: projectAccessType.open,
            createdByCompany: company.id
        }]));
    };

    // Add My Company Project
    const addMyCompanyProject = (data: any) => {
        const { name, description, additionalInfo, company} = data;

        // Dispatch Add Project
        dispatch(addProjectAction([{
            name,
            description,
            additionalInfo,
            accessType: projectAccessType.myCompany,
            createdByCompany: company.id
        }]));
    };

    // Add My Project
    const addMyProject = (data: any) => {
        const { name, description, additionalInfo, company} = data;

        // Dispatch Add Project
        dispatch(addProjectAction([{
            name,
            description,
            additionalInfo,
            accessType: projectAccessType.my,
            createdByCompany: company.id
        }]));
    };

    // Get Project
    const getProject = (uid: string) => {
        return projects.open.data[uid] || projects.myCompany.data[uid] || projects.my.data[uid] || null;
    };

    // Get Active Project Data
    const getActiveProjectData = () => {
        return activeProjectId ? getProject(activeProjectId) : null;
    };

    // Delete Project by uid
    const deleteProject = (uid: string) => {
        dispatch(deleteProjectAction([uid]));
    };

    // Update Project
    const updateProject = (data: any) => {
        dispatch(updateProjectAction([data]));
    };

    // Publish Project
    const publishProject = (uid: any) => {
        dispatch(publishProjectAction(uid));
    };

    // Run Project
    const runProject = (uid: any) => {
        addProjectRunStatus();
        dispatch(runProjectAction(uid));
    };

    // Get Project Link
    const getProjectLink = (data: any) => {
        if(data){
            let link = "/";
            switch (data.accessType) {
                case projectAccessType.open:
                    link += "openprojects/";
                    break;
                case projectAccessType.my:
                    link += "myprojects/";
                    break;
                case projectAccessType.myCompany:
                    link += "mycompanyprojects/";
                    break;
            }
            link += `${data.uid}`;
            return link;
        }

        return "/";
    };

    // Reset Projects
    const resetProjects = () => {
        dispatch(resetProjectsAction());
    };

    return {
        activeProjectId,
        fetchProjects, fetchProject, // Fetch
        addOpenProject, addMyCompanyProject, addMyProject, // Add
        updateProject, deleteProject, publishProject, runProject,
        projects, openProjects, myCompanyProjects, myProjects, projectsOnLoading, // Select,
        getProject, getProjectLink,
        getActiveProjectData,
        resetProjects
    };
}
