import { useDispatch, useSelector } from 'react-redux';
import { refreshTokenAction } from '../Redux/Slices/Account/Account.slice';
import { useLocalStorage } from 'lavaa'
import { useEffect, useRef } from 'react';

export const useAuth = (): any => {
    const account = useSelector((state: any) => state.account);
    const dispatch = useDispatch();
    const [, setRefreshToken, refreshTokenActual] = useLocalStorage("refresh_token", '');
    const timerId = useRef<any>(null);

    const isAuth = account.isAuth;

    useEffect(() => {
        if(account.access_token === "") return;
        setRefreshToken(account.refresh_token);
    }, [account.refresh_token]);

    useEffect(() => {
        if(account.access_token === "") {
            clearTimeout(timerId.current);
            return;
        }
        timerId.current = setTimeout(refreshTokenAccount, 90 * 1000);
    }, [account.access_token]);

    // Load Account
    const loadAccount = () => {
        refreshTokenAccount();
    };

    // Refresh Token
    const refreshTokenAccount = () => {
        clearTimeout(timerId.current);
        const token = refreshTokenActual();
        dispatch(refreshTokenAction([token]));
    };

    return { loadAccount, isAuth };
}
