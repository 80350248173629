import { parseJsonLogicFlow } from "../../../Tools/parseJsonLogic";
import { addRulesetDataOutputAction } from "../RulesetData/RulesetData.slice";
import { v4 as uuid } from 'uuid';
import { getRandom } from "../../../Tools";
import { AbstractionType } from "../../../Constants/AbstractionType";
import { updateModuleAction } from "../Modules/Modules.slice";

const receivePublishRulesetCallback = ( store: any, payload: any) => {
    if(payload.resultCode === 'AUTH_ERROR'){
        store.dispatch(addRulesetDataOutputAction(["You do not have access to publish this ruleset!"]))
    }
}

const receiveAddRulesetCallback = ( store: any, payload: any) => {
    const state = store.getState();
    const { data: rulesetTemplate } = payload;
    const { projectUID, moduleUID } = rulesetTemplate;
    const getModule = (uid: string) => {
        return state.modules.nodes[uid] || null;
    };
    const moduleData = getModule(moduleUID);
    const jsonLogic = parseJsonLogicFlow(moduleData);
    const { initialNodes, initialEdges } = jsonLogic;
    const nodeId = uuid();
    const nodeType = 'myRuleset';

    const newNode = {
        id: nodeId,
        nodeId: nodeId,
        primaryId: rulesetTemplate.primaryId,
        type: nodeType,
        position: {
            x: getRandom(250, 350),
            y: getRandom(0, 150)
        },
        name: rulesetTemplate.name,
        data: {
            label: rulesetTemplate.name
        },
        description: rulesetTemplate.description,
        abstractionType: AbstractionType[nodeType],
        accessType: 1,
        additionalInfo: rulesetTemplate.additionalInfo,
        createdByCompany: "ec3ca9d3-f9cd-4707-9520-0e0c7d9aceb2",
        disorderTypeID: 1,
        jsonLogic:[],
        lastUpdateDate: (new Date()).toISOString(),
        parent: null,
        nodeType: "node",
        moduleTypeID: 1
    };

    const updateData: any = {
        ...moduleData,
        jsonLogic: [
            {name: 'initialEdges', data: [...initialEdges]},
            {name: 'initialNodes', data: [...initialNodes, newNode]}
        ]
    };

    store.dispatch(updateModuleAction([updateData]));
}

export { receivePublishRulesetCallback, receiveAddRulesetCallback }