import * as signalR from "@microsoft/signalr";
import { Messages } from './Messages';

const hubUrl = "https://api.lavaa.health/fuji-hub";
const connection = new signalR.HubConnectionBuilder()
    .withUrl(hubUrl, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
        })
    .withAutomaticReconnect([0, 0, 10000])
    .configureLogging(signalR.LogLevel.Information)
    .build();

connection.serverTimeoutInMilliseconds = 120000;

let useLog = false;

// On onreconnecting
connection.onreconnecting((error?: Error) => {
    console.assert(connection.state === signalR.HubConnectionState.Reconnecting);
    console.warn(`Connection lost due to error "${error}". Reconnecting.`);

    if (error) {
        setTimeout(() => connect(), 1000);
    }
});

// Preprocess Response
const preprocessRes = (store: any, payload: any, preprocessData: any) => {

    let res = JSON.parse(payload);

    if(preprocessData && preprocessData.hasOwnProperty('oldVersion')) {
        res = Array.isArray(res) ? res : [res];

        const actions = Object.keys(preprocessData);
        actions.forEach((action: string) => {

            // jsonParse
            if (action === 'jsonParse') {
                const fields = preprocessData[action];

                res = res.map((item: any) => {
                    fields.forEach((field: string) => {
                        item[field] = (item[field] && item[field].length > 0) ? JSON.parse(item[field]): "";
                    });

                    return item;
                });
            }

            if(action === "callback") {
                const callbacks = preprocessData[action];
                if(!callbacks) return;

                callbacks.forEach(
                    (method: (store: any, payload: any) => void) => method.call(null, store, res)
                );
            }
        });
        return res;
    }

    const isJsonParseFalse = preprocessData && preprocessData.hasOwnProperty('jsonParse') && preprocessData.jsonParse === false;
    if(!isJsonParseFalse && res.data !== '') {
        try {
            res.data = JSON.parse(res.data);
        }
        catch (e: any){
            console.error("Data parse error", res.data);
        }

    }

    if (preprocessData) {
        const actions = Object.keys(preprocessData);
        actions.forEach((action: string) => {

            if(action === "callback") {
                const callbacks = preprocessData[action];
                if(!callbacks) return;

                callbacks.forEach(
                    (method: (store: any, payload: any) => void) => method.call(null, store, res)
                );
            }

        });
    }

    return res;
};

// Preprocess Request
const preprocessReq = (payload: any, preprocessData: any) => {
    let res = [...payload];

    if (preprocessData) {
        const actions = Object.keys(preprocessData);

        actions.forEach((action: string) => {

            // stringify
            if (action === 'stringify') {
                const fields = preprocessData[action];
                res = res.map((item: any) => {
                    fields.forEach((field: string) => {
                        item[field] && (item[field] = JSON.stringify(item[field]));
                    });

                    return item;
                });
            }
        });

    }
    return payload;
};

// Client Messages
export function signalRInvokeMiddleware(store: any) {
    signalRRegisterCommands(store, () => { });

    return (next: any) => async (action: any) => {
        useLog && console.log('SEND MSG', action);
        let message: any = (<any>Messages.CLIENT)[action.type];
        message && send(message.func, Array.isArray(action.payload) ? preprocessReq(action.payload, (<any>Messages.CLIENT)[action.type].preprocess) : preprocessReq([action.payload], (<any>Messages.CLIENT)[action.type].preprocess)); //connection.invoke(message.func);
        return next(action);
    };
}

// Receive Server Messages
export function signalRRegisterCommands(store: any, callback: Function) {

    Object.keys(Messages.SERVER).forEach((key: any) => {
        connection.on(key, (data: any) => {
            const preprocessData = (<any>Messages.SERVER)[key].preprocess;
            const payload = preprocessRes(store, data, preprocessData);

            store.dispatch({
                type: (<any>Messages.SERVER)[key].action,
                payload: payload
            })
        })
    });

    connection.start()
    .then(() => { setTimeout(() => connect(), 1000); })
    .catch(() => { setTimeout(() => connect(), 1000); });
}

// Send Message
export function send(message: any, data: any) {
    if (connection.state == signalR.HubConnectionState.Connected) {
        if (data) {
            // if (message === 'Clients/GetClientsForRTReport') {
            //     debugger;
            // }
            connection.send(message, ...data).then(() => useLog && console.log("From client: ", message, data));
        } else {
            connection.send(message).then(() => useLog && console.log("From client: ", message, "no data"));
        }

    } else {
        window.setTimeout(() => {
            send(message, data)
        }, 100);
    }
}

// Establish Connection
function connect() {
    if (connection.state == signalR.HubConnectionState.Disconnected) {
        connection.start().then(() => {
            var search = window.location.search.substring(1);
            var body = search ? JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}') : {};
            if (window.parent) {
                try {
                    body.hostname = window.parent.location.host;
                } catch (e) {
                    body.hostname = window.location.host
                }
            } else {
                body.hostname = window.location.host;
            }
            if ((<any>window).xiAppType) {
                body.appType = (<any>window).xiAppType;
            }
            // send(Messages.CLIENT.CONNECT.func, body);
        }).catch((err: any) => {
            console.error("Connection error: ", err);
            setTimeout(() => connect(), 1000);
        });
    }
}
