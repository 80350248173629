import { FC, useContext, useEffect, useState } from "react";
import { ModalsCtx } from "../../../../Context/Modals.context";
import { Modal, LogicEditor } from 'lavaa';
import { parseJsonLogicFlow } from "../../../../Tools/parseJsonLogic";
import { useClients } from "../../../../Hooks/UseClients";
import { emptyRule, IRuleType } from "../../../../Redux/Slices/Rules/Rules.data";
import { useModules } from "../../../../Hooks/UseModules";
import { filterNodeModalDefault, FilterNodeModalType } from "../../../../Context/Modals.types";
import { useProjects } from "../../../../Hooks/UseProjects";

// Filter Node Modal
const FilterNodeModal: FC<any> = () => {

    const context = useContext(ModalsCtx);
    let filterNodeModal: FilterNodeModalType = context.filterNodeModal;
    let setFilterNodeModal: ((value: FilterNodeModalType) => void) = context.setFilterNodeModal;

    const { getClientScheme, clientScheme} = useClients();
    const { getActiveModuleData, updateModule } = useModules();
    const { getActiveProjectData, updateProject } = useProjects();

    const [ruleData, setRuleData] = useState<IRuleType | null>(null);

    // Close Modal
    const closeModal = () => {
        setFilterNodeModal(filterNodeModalDefault);
        setRuleData(null);
    };

	// Handle Ok
	const handleOk = () => {
		saveUpdates();
		closeModal();
	};

    // On Json Logic Changed
    const onJsonLogicChanged = (val: any) => {
        ruleData !== null && setRuleData({...ruleData, jsonLogic: val});
    };

    // Save Updates
    const saveUpdates = () => {
        if (ruleData !== null) {
            const data = (filterNodeModal.type === "module") ? getActiveProjectData() : getActiveModuleData();
            const jsonLogic = parseJsonLogicFlow(data);
            const { initialNodes, initialEdges } = jsonLogic;
            const updatedNodes = initialNodes.map((node: any) => node.id !== filterNodeModal.uid ? node : {...node, jsonLogic: ruleData.jsonLogic});
            const updatedData = {...data, jsonLogic: [
                {name: 'initialEdges', data: [...initialEdges]},
                {name: 'initialNodes', data: updatedNodes}
            ]};

            if(filterNodeModal.type === "module"){
                updateProject(updatedData);
            }
            else{
                updateModule(updatedData);
            }
        }
    };

    // Watch filterNodeModal
    useEffect(() => {
        if (filterNodeModal.uid) {
            const data = (filterNodeModal.type === "module") ? getActiveProjectData() : getActiveModuleData();
            const jsonLogic = parseJsonLogicFlow(data);
            const { initialNodes } = jsonLogic;
            const conditionFilterData = initialNodes.filter((node: any) => node.id === filterNodeModal.uid);
            if (conditionFilterData.length) {
                if(conditionFilterData[0].jsonLogic){
                    setRuleData(conditionFilterData[0]);
                }
                else {
                    setRuleData( {...emptyRule, ...conditionFilterData[0]});
                }
            }
        }
    }, [filterNodeModal]);

    useEffect(() => {
        getClientScheme();
    }, []);

    return ruleData === null ? null : <Modal
        active={ filterNodeModal.isActive }
        onClose={ closeModal }
        onCancel={ closeModal }
        onOk={ handleOk }
        title="Filter Condition"
        okText="Save"
        cancelText="Cancel"
        type="large"
        height="90vh"
    >
        <LogicEditor jsonLogic={ruleData.jsonLogic} onChange={onJsonLogicChanged} showError={false} scheme={clientScheme}/>
    </Modal>
};

export { FilterNodeModal };
