import { FC } from 'react';
import {AuthLogin} from "../../Common/Auth/Login/Login.component";

// Login Page
const Login: FC<any> = () => {
  return (
      <AuthLogin/>
  );
};

export { Login };