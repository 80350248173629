export const AbstractionType: any = {
    0: 'source',
    1: 'destination',
    2: 'function',
    3: 'my',
    4: 'ruleset',
    5: 'logic',
    6: 'health',
    7: 'clinicalResearch',

    source: 0,
    destination: 1,
    function: 2,
    my: 3,
    ruleset: 4,
    logic: 5,
    health: 6,
    clinicalResearch: 7
};