import { FC, useContext, useEffect, useState } from 'react';
import { AppCtx } from '../../Context/App.context';
import { ReactFlowProvider } from 'reactflow';
import { Container, Col, Grid, Splitter, PanelMenu, Panel, iconsTypes, addSnackbar } from "lavaa";
import { useParams } from 'react-router-dom';
import { useRulesets } from '../../Hooks/UseRulesets';
import { RulesetHeader } from "./RulesetHeader.component";
import { RulesList } from '../Common/Rules/RulesList/RulesList.component';
import { UnavailableRuleset } from "../Fallback/Ruleset/Unavailable.component";
import { useMenuHierarchy } from "../../Hooks/Menu/UseMenuHierarchy";
import { useProjects } from "../../Hooks/UseProjects";
import { RulesetOutput } from "./RulesetOutput.component";
import { useRules } from "../../Hooks/UseRules";
import { compareFields } from "../../Tools/compareTypes";
import { useTypes } from "../../Hooks/UseTypes";
import { ModalsCtx } from '../../Context/Modals.context';
import { Loading } from "lavaa";
import { prepareRulesAsMenuItems, useMenuRules } from "../../Hooks/Menu/UseMenuRules";
import { EditRulesetModal } from "../Custom/Modals/EditRulesetModal/EditRulesetModal.component";
import { MenuEntityInfo } from "../MenuEntityInfo/MenuEntityInfo.component";
import { TreeMenu } from "../Custom/TreeMenu/TreeMenu.component";
import { AddNodesToFlowModalNew } from '../Common/AddNodesToFlowModalNew/AddNodesToFlowModalNew.component';

// Ruleset Layout
const RulesetLayout: FC<any> = () => {

  // Contexts
  const { performanceNow } = useContext(AppCtx);

  const {
    isAddCompanyRulesModalActive, setAddCompanyRulesModalActive,
    isAddCommonRulesModalActive, setAddCommonRulesModalActive,
    isAddMyRulesModalActive, setAddMyRulesModalActive,
		setEditRulesetUID, setEditRulesetModalActive
  } = useContext(ModalsCtx);

  const sortOptions = [
    {id: '1', value: 'Score', sortBy: 'score', sortType: 'desc', fieldType: 'number'},
    {id: '2', value: 'A-Z', sortBy: 'name', sortType: 'asc', fieldType: 'string'},
    {id: '3', value: 'Z-A', sortBy: 'name', sortType: 'desc', fieldType: 'string'}
  ];

  const { types } = useTypes();
  const { rules, fetchRulesTemplates, addRuleToNodes, myRules, openRules, companyRules } = useRules();
  const myRulesMenuData = prepareRulesAsMenuItems(myRules, 'rule');
  const openRulesMenuData = prepareRulesAsMenuItems(openRules, 'rule');
  const companyRulesMenuData = prepareRulesAsMenuItems(companyRules, 'rule');
  const { activeProjectId, activeModuleId, activeRulesetId } = useParams();
  const { fetchRuleset, getActiveRulesetData, addRuleToRuleset, rulesetsOnLoading, getRulesetTemplate } = useRulesets();
  const { getActiveProjectData, getProjectLink } = useProjects();
  const activeRulesetData = getActiveRulesetData();
  const rulesetTemplateData = (activeRulesetData) ? getRulesetTemplate(activeRulesetData.primaryId) ?? null : null;

  const menuHierarchy = useMenuHierarchy();
  const menuRules = useMenuRules();

  const [ sortData, setSortData ] = useState<any>();
  const [ filterData, setFilterData ] = useState<any>();

  const RuleCategory = types['Rules.RuleCategory'] || [];
  const filterOptions = RuleCategory.map((cat: any) => {
    return {id: cat.typeID, value: cat.name, filterBy: "ruleCategoryID"}
  });
  const templateFilter = [...filterOptions];
  filterOptions.push({id: 'all', value: 'Show All', filterBy: ''});

  const activeProjectData = getActiveProjectData();
  const backUrl = getProjectLink(activeProjectData) + `/${activeModuleId}`;

  // Did Mount
  useEffect(() => {
    fetchRulesTemplates();
  }, []);

  useEffect(() => {
    fetchRuleset(activeProjectId, activeModuleId, activeRulesetId);
  }, [activeRulesetId]);

  if(rulesetsOnLoading.includes(activeRulesetId)) {
    return (
      <Col grow="1" alignitems="center" justifycontent="center">
        <Loading/>
      </Col>
    );
  }

  if(activeRulesetData === null || rulesetTemplateData === null) {
    return (
      <UnavailableRuleset/>
    )
  }

  // Filter
  let filteredData: Array<any> = filterData
    ? activeRulesetData.jsonLogic.filter((item: any) =>
      !filterData.filterBy
        ? item
        : rules[item.ruleUID] ? rules[item.ruleUID][filterData.filterBy] === filterData.id : false
    )
    : [...activeRulesetData.jsonLogic];

  // Sort
  if(sortData){
    filteredData.sort((a: any, b: any) => {
      if(sortData.sortBy === 'score'){
        return compareFields(sortData.sortType, sortData.fieldType, a[sortData.sortBy], b[sortData.sortBy]);
      }

      const aData = rules[a.ruleUID];
      const bData = rules[b.ruleUID];

      if(!aData || !bData) return 0;
      return compareFields(sortData.sortType, sortData.fieldType, aData[sortData.sortBy], bData[sortData.sortBy]);
    });
  }


	// Handle Edit
	const handleEdit = () => {
		if(activeRulesetData) {
			setEditRulesetModalActive( true );
			setEditRulesetUID(activeRulesetData.primaryId);
		}
	};

  // Handle Filter
  const onFilter = (option: any) => {
    setFilterData(option);
  };

  // Handle Sorting
  const onSort = (option: any) => {
    setSortData(option);
  };

  // On Item Select - Add Rule to Ruleset
  const onItemSelect = (rule: any) => {
    console.log('On Item Select', rule)
    addRuleToNodes(rule);
    addRuleToRuleset(rule.uid, null);
    addSnackbar('success', 'Success', 'Ruleset has been updated');
  };

  return <>
    <ReactFlowProvider>
      <Splitter resetLayoutKey={performanceNow} direction="vertical" collapsible={[0, 2]} paneSizes={['15%', '70%', '15%']} minSizes={[200, undefined, 250]} splitterSize="10px" collapsedSplitterSize="20px" name="RulesetLayout">

        {/* Left Pane */}
        <Grid>

          {/* Rules Panel */}
          <Panel title="Rules" backUrl={ backUrl } filterPlaceholder="Search by Rule name" >
            {
              ({filterValue}) => <PanelMenu filterValue={ filterValue } data={menuRules} TreeMenu={TreeMenu}/>
            }
          </Panel>
        </Grid>

        {/* Center Pane */}
        <Splitter resetLayoutKey={performanceNow} direction="horizontal" collapsible={[1]} paneSizes={['70%', '30%']} splitterSize="10px" name="RulesetLayoutCenterPane">

          {/* Top Pane */}
          <Grid>
            <Container>
              <RulesetHeader
                sortData={ sortOptions }
                onSort={ onSort }
                filterData={ filterOptions }
                onFilter={ onFilter }
              />

              {/* Rules List */}
              <RulesList data={filteredData}/>
            </Container>
          </Grid>

          {/* Output Area */}
          <Grid>
            <RulesetOutput/>
          </Grid>
        </Splitter>

        {/* Right Pane */}
        <Splitter resetLayoutKey={performanceNow} direction="horizontal" paneSizes={['70%', '30%']} splitterSize="10px" name="RulesetLayoutRightPane">

          {/* Project Hierarchy */}
          <Grid scroll="true">
            <Panel title="Project Hierarchy" filterPlaceholder="Search">
              {
                ( { filterValue } ) => <PanelMenu filterValue={filterValue} data={menuHierarchy} TreeMenu={TreeMenu}/>
              }
            </Panel>
          </Grid>

          {/* Ruleset Info */}
          <Grid>
            <Panel
				title="Current Template Ruleset"
				hasFilter={false}
				onActionClick={rulesetTemplateData?.isEditEnabled == true ? handleEdit : undefined}
			>
              {
                () => <MenuEntityInfo data={rulesetTemplateData} />
              }
            </Panel>
          </Grid>
        </Splitter>
      </Splitter>
    </ReactFlowProvider>

    {/* Edit Ruleset Modal */}
 		<EditRulesetModal/>

    {/* Add Common Rules Popup */}
    <AddNodesToFlowModalNew tplType="rule" nodesType="ruleset" title="Add Open Rules"
      parentData={ activeRulesetData }
      data={ openRulesMenuData.filter((n: any) => !filteredData.find((m: any) => m.ruleUID === n.uid)) }
      getNodeTemplate={ () => {} }
      // deleteNodeByPrimaryId={ () => {} }
      updateParent={ () => {} }
      filters={
        [
          {title: 'Category', data: templateFilter, displayField: 'value', fieldOfFilterData: 'id', fieldOfListData: 'ruleCategoryID'}
        ]
      }
      // filter={ [
      //     {title: 'Companies', data: companiesMock, displayField: 'Name', filterField: 'Name', filterBy: 'createdByCompany'},
      //     {title: 'Disorder Types', data: disorderTypes, displayField: 'Name', filterField: 'disorderTypeID', filterBy: 'disorderTypeID'}
      // ] }
      itemType="rule"
      onItemSelect={ onItemSelect }
      active={ isAddCommonRulesModalActive }
      setActive={ setAddCommonRulesModalActive }
      searchPlaceholder="Search rule by name"
      closeButtonText="Back to Ruleset"
      closeButtonIcon={iconsTypes.exit}
      useCounter={false}
    />

    {/* Add Company Rules Popup */}
    <AddNodesToFlowModalNew tplType="rule" nodesType="ruleset" title="Add Company Rules"
      parentData={ activeRulesetData }
      data={ companyRulesMenuData.filter((n: any) => !filteredData.find((m: any) => m.ruleUID === n.uid)) }
      getNodeTemplate={ () => {} }
      // deleteNodeByPrimaryId={ () => {} }
      updateParent={ () => {} }
      filters={
        [
          {title: 'Category', data: templateFilter, displayField: 'value', fieldOfFilterData: 'id', fieldOfListData: 'ruleCategoryID'}
        ]
      }
      // filter={ [
      //     {title: 'Companies', data: companiesMock, displayField: 'Name', filterField: 'Name', filterBy: 'createdByCompany'},
      //     {title: 'Disorder Types', data: disorderTypes, displayField: 'Name', filterField: 'disorderTypeID', filterBy: 'disorderTypeID'}
      // ] }
      itemType="rule"
      onItemSelect={ onItemSelect }
      active={ isAddCompanyRulesModalActive }
      setActive={ setAddCompanyRulesModalActive }
      searchPlaceholder="Search rule by name"
      closeButtonText="Back to Ruleset"
      closeButtonIcon={iconsTypes.exit}
      useCounter={false}
    />

    {/* Add Personal Info Rules Popup */}
    {/* <AddNodesToFlowModal nodesType="ruleset" title="Add Personal Info Rules"
      parentData={ activeRulesetData }
      data={ [] }
      getNodeTemplate={ () => {} }
      deleteNodeByPrimaryId={ () => {} }
      updateParent={ () => {} }
      // filter={ [
      //     {title: 'Companies', data: companiesMock, displayField: 'Name', filterField: 'Name', filterBy: 'createdByCompany'},
      //     {title: 'Disorder Types', data: disorderTypes, displayField: 'Name', filterField: 'disorderTypeID', filterBy: 'disorderTypeID'}
      // ] }
      active={ isAddPersonalInfoRulesModalActive }
      setActive={ setAddPersonalInfoRulesModalActive }
      searchPlaceholder="Search rule by name"
    /> */}

    {/* Add Medical History Rules Popup */}
    {/* <AddNodesToFlowModal nodesType="ruleset" title="Add Medical History Rules"
      parentData={ activeRulesetData }
      data={ [] }
      getNodeTemplate={ () => {} }
      deleteNodeByPrimaryId={ () => {} }
      updateParent={ () => {} }
      // filter={ [
      //     {title: 'Companies', data: companiesMock, displayField: 'Name', filterField: 'Name', filterBy: 'createdByCompany'},
      //     {title: 'Disorder Types', data: disorderTypes, displayField: 'Name', filterField: 'disorderTypeID', filterBy: 'disorderTypeID'}
      // ] }
      active={ isAddMedicalHistoryRulesModalActive }
      setActive={ setAddMedicalHistoryRulesModalActive }
      searchPlaceholder="Search rule by name"
    /> */}

    {/* Add Lab Results Rules Popup */}
    {/* <AddNodesToFlowModal nodesType="ruleset" title="Add Open Rulesets"
      parentData={ activeRulesetData }
      data={ [] }
      getNodeTemplate={ () => {} }
      deleteNodeByPrimaryId={ () => {} }
      updateParent={ () => {} }
      // filter={ [
      //     {title: 'Companies', data: companiesMock, displayField: 'Name', filterField: 'Name', filterBy: 'createdByCompany'},
      //     {title: 'Disorder Types', data: disorderTypes, displayField: 'Name', filterField: 'disorderTypeID', filterBy: 'disorderTypeID'}
      // ] }
      active={ isAddLabResultsRulesModalActive }
      setActive={ setAddLabResultsRulesModalActive }
      searchPlaceholder="Search rule by name"
    /> */}

    {/* Add My Rules Popup */}
    <AddNodesToFlowModalNew tplType="rule" nodesType="ruleset" title="Add My Rules"
      // editNodes={true}
      parentData={ activeRulesetData }
      data={ myRulesMenuData.filter((n: any) => !filteredData.find((m: any) => m.ruleUID === n.uid)) }
      getNodeTemplate={ () => {} }
      // deleteNodeByPrimaryId={ () => {} }
      updateParent={ () => {} }
      filters={
        [
          {title: 'Category', data: templateFilter, displayField: 'value', fieldOfFilterData: 'id', fieldOfListData: 'ruleCategoryID'}
        ]
      }
      // filter={ [
      //     {title: 'Companies', data: companiesMock, displayField: 'Name', filterField: 'Name', filterBy: 'createdByCompany'},
      //     {title: 'Disorder Types', data: disorderTypes, displayField: 'Name', filterField: 'disorderTypeID', filterBy: 'disorderTypeID'}
      // ] }
      itemType="rule"
      onItemSelect={ onItemSelect }
      active={ isAddMyRulesModalActive }
      setActive={ setAddMyRulesModalActive }
      searchPlaceholder="Search rule by name"
      closeButtonText="Back to Ruleset"
      closeButtonIcon={iconsTypes.exit}
      useCounter={false}
    />
  </>
};

export { RulesetLayout };
