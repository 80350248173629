import { FC, useContext, useEffect, useState } from "react";
import { ModalsCtx } from "../../../../Context/Modals.context";
import { useModules } from '../../../../Hooks/UseModules';
import { useRulesets } from '../../../../Hooks/UseRulesets';
import { Modal } from 'lavaa';
import { IFields } from './Interface';
import { RulesetParameters } from './Parameters/RulesetParameters.component';
import { useTypes } from "../../../../Hooks/UseTypes";

interface IProps {}

const defaultParams: IFields = {name: '', disorder: '', description: '', additionalInfo: ''};

// Edit Ruleset Modal
const EditRulesetModal: FC<IProps> = () => {

    const [rulesetParameters, setRulesetParameters] = useState<IFields>(defaultParams);
    const { getRulesetTemplate, updateRuleset } = useRulesets();
		const { getDisorder } = useTypes();

    // Context
    let { isEditRulesetModalActive, setEditRulesetModalActive, editRulesetUID, setEditRulesetUID } = useContext(ModalsCtx);

	// States
    const [isRulesetParametersValid, setRulesetParametersValid] = useState<boolean>(false);

    // On Ruleset Parameters Validate
    const onRulesetParametersValidate = (isValid: boolean) => {
        setRulesetParametersValid(isValid);
    };

	const rulesetTemplateData = getRulesetTemplate(editRulesetUID);

	// Set States On Module Data Received
	useEffect(() => {
		console.log(rulesetTemplateData);
		if (rulesetTemplateData) {
			const disorder = getDisorder(rulesetTemplateData.disorderTypeID);
			setRulesetParameters({
				name: rulesetTemplateData.name,
				disorder: disorder,
				description: rulesetTemplateData.description,
				additionalInfo: rulesetTemplateData.additionalInfo
			});
		}
	}, [rulesetTemplateData]);

    // Handle Close
    const handleClose = () => {
			setRulesetParameters(defaultParams);
			setEditRulesetUID(undefined);
			setEditRulesetModalActive(false);
    };

    // Handle Save
    const handleSave = (e: Event) => {
		e.stopPropagation();
		const { name, description, additionalInfo } = rulesetParameters;
		const disorder: any = rulesetParameters.disorder || null;
		const disorderTypeID = disorder ? disorder.disorderTypeID : null;
		const updateData = { ...rulesetTemplateData, name, disorderTypeID, description, additionalInfo };

		updateRuleset(updateData);
		setRulesetParameters(defaultParams);
		setEditRulesetUID(undefined);
		setEditRulesetModalActive(false);
    };

    // On Ruleset Parameters Change
    const onRulesetParametersChange = (data: IFields) => {
        setRulesetParameters(data);
    };

    return <Modal
        active={ isEditRulesetModalActive }
        onClose={ handleClose }
        onCancel={ handleClose }
        onOk={ handleSave }
        title="Edit ruleset"
        okText="Save"
        cancelText="Cancel"
        type="large"
		isFormValid={isRulesetParametersValid}
    >
        <RulesetParameters data={ rulesetParameters } onChange={ onRulesetParametersChange } onValidate={onRulesetParametersValidate} />
    </Modal>
};

export { EditRulesetModal };
