import { FC, useContext } from 'react';
import { ModalsCtx } from "../../../../Context/Modals.context";
import { Modal } from 'lavaa';
import { Row, Col } from 'lavaa';
import { Input } from 'lavaa';

// Source Mapping Modal
const SourceMappingModal: FC<any> = () => {

    let { isSourceMappingModalActive, setSourceMappingModalActive } = useContext(ModalsCtx);

    // Handle Close
    const handleClose = () => {
        setSourceMappingModalActive(false);
    };

    // Handle Cancel
    const handleCancel = () => {
        setSourceMappingModalActive(false);
    };

    // Handle Ok
    const handleOk = () => {
        setSourceMappingModalActive(false);
    };

    return <Modal 
        active={ isSourceMappingModalActive }
        onClose={ handleClose } 
        onCancel={ handleCancel } 
        onOk={ handleOk }
        title='Mapping name: Local file'
        okText='Save'
        cancelText='Cancel'
        type='middle'
    >
        <Col grow="1" paddingBottom="1rem">
            
            {/* Name */}
            <Row grow='1' paddingBottom='1rem'>
                <Input type='text' wrap='col' label='Name' placeholder='Name' onChange={ () => {} } />
            </Row>
        </Col>
    </Modal>
};

export { SourceMappingModal };