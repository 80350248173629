import { createSlice } from '@reduxjs/toolkit';

const initialState = {} as any;

export const typesSlice = createSlice({
    name: 'Types',
    initialState,
    reducers: {

        // Get Types
        getTypesAction: (state, action) => state,

        // Receive Types
        receiveTypesAction: (state, action) => {
            return action.payload.data;
        },

        // Get Type
        getTypeAction: (state, action) => {
            console.log('Get Type Action --- ');
        },

        // Receive Type
        receiveTypeAction: (state, action) => {
            console.log('Receive Type Action --- ');
        },

        // Reset Types
		resetTypesAction: (state) => {
			return {...initialState};
		}
    }
});

export const {
    getTypesAction,
    getTypeAction,
    receiveTypesAction,
    receiveTypeAction,
    resetTypesAction
} = typesSlice.actions;
export default typesSlice.reducer;
