import { FC, useContext, useEffect, useState } from 'react';
import { ModalsCtx } from '../../../../Context/Modals.context';
import { Modal, Row, Dropdown, Input, IValidationResult } from 'lavaa';
import './CreateProjectModal.css';
import { useAccount } from '../../../../Hooks/UseAccount';

interface IProps {
    onCreate: (data: any) => void
}

// Create Project Modal
const CreateProjectModal: FC<IProps> = (props) => {

    let { onCreate } = props;
    let { isNewProjectModalActive, setNewProjectModalActive } = useContext(ModalsCtx);
    let { account } = useAccount();
    let { companies = [] } = account;
    
    // States
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [additionalInfo, setAdditionalInfo] = useState('');
    const [company, setCompany] = useState<any>(companies.length === 1 ? companies[0] : null);

    const [isNameValid, setIsNameValid] = useState(false);
    const [isDescriptionValid, setIsDescriptionValid] = useState(false);
    const [isCompanyValid, setIsCompanyValid] = useState(companies.length === 1 ? true : false);
    const [isFormValid, setIsFormValid] = useState(false);

    // On Validate
    const onValidate = (data: IValidationResult) => {
        const { name, error, valid } = data;

        // Project Name
        if (name === 'projectName' && isNameValid !== valid) {
            setIsNameValid(valid);
        }

        // Project Description
        if (name === 'projectDescription' && isDescriptionValid !== valid) {
            setIsDescriptionValid(valid);
        }

        // Company
        if (name === 'company' && isCompanyValid !== valid) {
            setIsCompanyValid(valid);
        }
    };

    // Handle Close
    const handleClose = () => {
        setNewProjectModalActive(false);
    };

    // Handle Cancel
    const handleCancel = () => {
        setNewProjectModalActive(false);
    };

    // Handle Ok
    const handleOk = () => {
        onCreate({name, description, additionalInfo, company});
        setNewProjectModalActive(false);
    };

    // Handle Project Name Input
    const handleProjectName = (value: any) => {
        setName(value);
    };

    // Handle Description Input
    const handleDescription = (value: any) => {
        setDescription(value);
    };

    // Handle AdditionalInfo
    const handleAdditionalInfo = (value: any) => {
        setAdditionalInfo(value);
    };

    // Handle Company Selected
    const handleCompany = (data: any) => {
        setCompany(data);
        onValidate({name: 'company', valid: true, error: ''});
    };

    // Watch form valid
    useEffect(() => {

        // Set Form as Valid
        if (isNameValid && isDescriptionValid && isCompanyValid && !isFormValid) {
            setIsFormValid(true);
        }

        // Set Form as Not Valid
        if ((!isNameValid || !isDescriptionValid || isCompanyValid) && isFormValid) {
            setIsFormValid(false);
        }
    }, [isNameValid, isDescriptionValid, isCompanyValid]);

    return <Modal
        active={ isNewProjectModalActive }
        onClose={ handleClose }
        onCancel={ handleCancel }
        onOk={ handleOk }
        title="Create New Project"
        okText="Create"
        cancelText="Cancel"
        type="small"
        scroll={true}
        isFormValid={isFormValid}
    >
        {/* Name */}
        <Row grow="1" paddingBottom="1rem">
            <Input type="text" name="projectName" wrap="col" label="*Project name" placeholder="Project Name, max length is 50 characters" onChange={ handleProjectName } maxlength={50} validation="required" onValidate={onValidate} />
        </Row>

        {/* Description */}
        <Row grow="1" paddingBottom="1rem">
            <Input style={{height: 75}} type="textarea" name="projectDescription" wrap="col" label="*Project description" placeholder="Project description, max length is 200 characters" onChange={ handleDescription } maxlength={200} validation="required" onValidate={onValidate} />
        </Row>

        {/* Additional Info */}
        <Row grow="1" paddingBottom="1rem">
            <Input type="editor" wrap="col" label="Additional Info" placeholder="Additional Info" onChange={ handleAdditionalInfo } style={{/*minHeight: '50px',*/ width: '100%', height: '125px'}} fullScreen={true} />
        </Row>

        {/* Company */}
        <Row grow="1" paddingBottom="1rem" style={{paddingTop: '3.5rem'}}>
            <Dropdown disabled={companies.length === 1 ? true : false} wrap="col" label="Company" placeholder="Company" hidePlaceholder={ true } data={ companies } selected={ company } onSelect={ handleCompany } selectField="name" displayField="name" />
        </Row>
    </Modal>
};

export { CreateProjectModal };
