import { Route } from 'react-router-dom';
import { MyProjects } from '../Pages/MyProjects/MyProjects.component';
import { ProjectLayout } from '../ProjectLayout/ProjectLayout.component';
import { ModuleLayout } from "../ModuleLayout/ModuleLayout.component";
import { RulesetLayout } from "../RulesetLayout/RulesetLayout.component";

// My Projects Route
const MyProjectsRoute = [
    <Route key="/myprojects" path="/myprojects" element={ <MyProjects /> } />,
    <Route key="/myprojects/:activeProjectId" path="/myprojects/:activeProjectId" element={ <ProjectLayout /> } />,
    <Route key="/myprojects/:activeProjectId/:activeModuleId" path="/myprojects/:activeProjectId/:activeModuleId" element={ <ModuleLayout /> } />,
    <Route key="/myprojects/:activeProjectId/:activeModuleId/:activeRulesetId" path="/myprojects/:activeProjectId/:activeModuleId/:activeRulesetId" element={ <RulesetLayout /> } />,
];

export { MyProjectsRoute };