import { createSlice } from '@reduxjs/toolkit';
import { rulesetAccessType, rulesetDataType } from "./Rulesets.data";

interface IRulesetTypeState {
    templates: {
        open: any[],
        company: any[],
        my: any[],
        functions: any[]
    },
    nodes: {
        [key: string]: any
    },
    onLoading: string[]
}

const initialState:IRulesetTypeState = {
    templates: {
        open: [],
        company: [],
        my: [],
        functions: []
    },
    nodes: {},
    onLoading: []
};

export const rulesetsSlice = createSlice({
    name: 'Rulesets',
    initialState,
    reducers: {

        // Get Rulesets
        getRulesetsAction: (state, action) => {},

        // Receive Rulesets
        receiveRulesetsAction: (state, action) => {
            const { type, results } = action.payload.data;

            switch (type) {
                case rulesetAccessType.open:
                    state.templates.open = results.filter((item: any) => item.ruleSetTypeID === rulesetDataType.any);
                    break;
                case rulesetAccessType.company:
                    state.templates.company = results.filter((item: any) => item.ruleSetTypeID === rulesetDataType.any);
                    break;
                case rulesetAccessType.my:
                    state.templates.my = results.filter((item: any) => item.ruleSetTypeID === rulesetDataType.any);
                    break;
            }

						Object.assign(state.templates.functions, results.filter((item: any) => item.ruleSetTypeID === rulesetDataType.condition));
        },

        // Get Ruleset
        getRulesetAction: (state, action) => {
            state.onLoading.push(action.payload[2]);
        },

        // Receive Ruleset
        receiveRulesetAction: (state, action) => {
            if(action.payload.resultCode !== "OK"){
                const requestIds = action.payload.data;
                state.onLoading = state.onLoading.filter((id) => id !== requestIds[2]);
                return;
            }

            const ruleset = action.payload.data;
            if(typeof ruleset !== 'object') {
                return state;
            }

            ruleset['jsonLogic'] = (ruleset['jsonLogic'] && ruleset['jsonLogic'].length > 0) ? JSON.parse(ruleset['jsonLogic']): "";

            if (Array.isArray(ruleset['jsonLogic']) === false) {
                ruleset['jsonLogic'] = [];
            }
            state.nodes[ruleset.nodeId] = ruleset;
            state.onLoading = state.onLoading.filter((id) => id !== ruleset.nodeId);
        },

        // Add Ruleset
        addRulesetAction: (state, action) => {},

        // Receive Add Ruleset
        receiveAddRulesetAction: (state, action) => {
            const ruleset = action.payload.data;
            state.templates.my = [...state.templates.my, ruleset];
        },

        // Update Ruleset
        updateRulesetAction: (state, action) => {
            const ruleset = action.payload[0];
            if (ruleset.nodeId !== null) {
                ruleset['jsonLogic'] = (ruleset['jsonLogic'] && ruleset['jsonLogic'].length > 0) ? JSON.parse( ruleset['jsonLogic'] ) : "";

                if ( Array.isArray( ruleset['jsonLogic'] ) === false ) {
                    ruleset['jsonLogic'] = [];
                }
                state.nodes[ruleset.nodeId] = ruleset;
            }
        },

        // Receive Update Ruleset
        receiveUpdateRulesetAction: (state, action) => {
            const ruleset = action.payload.data;

            if(typeof ruleset !== 'object') {
                return state;
            }

            ruleset['jsonLogic'] = (ruleset['jsonLogic'] && ruleset['jsonLogic'].length > 0) ? JSON.parse(ruleset['jsonLogic']): "";

            // Update Template
            if (ruleset.nodeId === null) {
                state.templates.open = state.templates.open.map((item: any) => (item.primaryId === ruleset.primaryId ? ruleset : item));
                state.templates.my = state.templates.my.map((item: any) => (item.primaryId === ruleset.primaryId ? ruleset : item));
                state.templates.company = state.templates.company.map((item: any) => (item.primaryId === ruleset.primaryId ? ruleset : item));
                state.templates.functions = state.templates.functions.map((item: any) => (item.primaryId === ruleset.primaryId ? ruleset : item));
            }

            return state;
        },

        // Publish Ruleset
        publishRulesetAction: (state, action) => {},

        // Receive Publish Ruleset
        receivePublishRulesetAction: (state, action) => {},

        // Delete Ruleset
        deleteRulesetAction: (state, action) => {
            const [ uid ] = action.payload;

            if (uid) {
                state.templates.open = state.templates.open.filter((item: any) => (item.primaryId !== uid));
                state.templates.my = state.templates.my.filter((item: any) => (item.primaryId !== uid));
                state.templates.company = state.templates.company.filter((item: any) => (item.primaryId !== uid));
                state.templates.functions = state.templates.functions.filter((item: any) => (item.primaryId !== uid));
            }

            return state;
        },

        // Receive Delete Ruleset
        receiveDeleteRulesetAction: (state, action) => {},

        // Reset Rulesets
		resetRulesetsAction: (state) => {
			return {...initialState};
		}
    }
});

export const {
    getRulesetsAction,
    getRulesetAction,
    addRulesetAction,
    updateRulesetAction,
    publishRulesetAction,
    deleteRulesetAction,
    receiveRulesetsAction,
    receiveRulesetAction,
    receiveAddRulesetAction,
    receiveUpdateRulesetAction,
    receivePublishRulesetAction,
    receiveDeleteRulesetAction,
    resetRulesetsAction
} = rulesetsSlice.actions;
export default rulesetsSlice.reducer;
