import { FC, useEffect, useState } from 'react';
import { Dropdown } from 'lavaa';
import { guidelinePriorities } from '../GuidelinePriorities';

interface IProps {
    initialData?: any,
    onSelect: (data: any) => void
}

// GuidelinePrioritySelector
const GuidelinePrioritySelector: FC<IProps> = (props) => {

    // Props
    const { initialData } = props;

    // States
    const [priority, setPriority] = useState<any>();

    // Handle Priority
    const handlePriority = (data: any) => {
        setPriority(data);
        props.onSelect(data);
    };

    useEffect(() => {
        if (initialData) {
            initialData.priority && !priority && setPriority(initialData);
        }
    }, [initialData]);

    return (
        <Dropdown wrap="col" label="*Priority of the guideline" placeholder="Priority of the guideline" hidePlaceholder={ true } data={ guidelinePriorities } selected={ priority } onSelect={ handlePriority } displayInnerIcon={true}></Dropdown>
    )
};

export { GuidelinePrioritySelector };
