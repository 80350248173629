import { FC } from "react";
import { Icon, iconsTypes, Row, Col, Text, Button, Input, Dropdown } from "lavaa";
import { classNames } from '../../../Tools';
import css from "./MappingSelector.module.scss";

export type MappingRowType = {
  input: string,
  output?: {
    type: number
    field: string
  },
}

interface IProps {
  mapping: MappingRowType[],
  fields: any[],
  onChange: ( mapping: MappingRowType[] ) => void,
}

// Mapping Selector
const MappingSelector: FC<IProps> = ( props ) => {
  const { mapping, fields, onChange } = props;

  function editInputField(data: any, index: number){
    let update = [...mapping];
		update[index] = Object.assign({}, update[index], {
			input: data
		});
    onChange(update);
  }

  function editOutputField(data: any, index: number){
    const update = [...mapping];
		update[index] = Object.assign({}, update[index], {
			output: {
				type: data.type,
				field: data.field
			}
		});
    onChange(update);
  }

  function deleteRow(index: number){
    const update = [...mapping];
    update.splice(index, 1);
    onChange(update);
  }

  function addNewRow(){
    const update = [...mapping];
    update.unshift({
      input: "",
      output: undefined
    });
    onChange(update);
  }

  if(!mapping) return null;

  return (
    <Col grow="1">
      <Row paddingBottom="1.25rem">
        <Text text="Specify the join columns and use of the reference columns" size="x2"/>
      </Row>
      <Row justifycontent="space-between" grow="1">

        <Col className={css.Box}>
          <Row marginBottom="0.5rem" paddingLeft="0.75rem" paddingRight="0.75rem" className={css.BoxHeader} alignitems="center" justifycontent="space-between">
            <Text text="Input columns" size="x2" bold="true"/>
            <Button text="Add option" icon={iconsTypes.plusCircle} type="secondary" variant="text" onClick={ addNewRow } />
          </Row>
          {
            mapping.map((row, index) => {
              return (
                <Row key={index} className={css.Row} alignitems="center" justifycontent="space-between" grow="1">
                  <Input type="text" wrap="row" placeholder="Input Field" onChange={ (data) => editInputField(data, index) } value={row.input}/>
                  <Row shrink="0" className={css.DeleteBtn} justifycontent="end">
                    <Button icon={iconsTypes.minus} type="secondary" variant="text" onClick={ () => deleteRow(index) } />
                  </Row>
                </Row>
              )
            })
          }
        </Col>
        <Col className={css.ConnectionBox} shrink="0">
          {
            mapping.map((row, index) => {
              const connected = row.output && row.input.length > 0;
              const icon = connected ? iconsTypes.connected : iconsTypes.disconnected;
              return (
                <Row key={index} className={classNames( css.ConnectionRow, connected && css.Active )} alignitems="center" justifycontent="center">
                  <Icon name={icon}/>
                </Row>
              )
            })
          }
        </Col>
        <Col className={css.Box}>
          <Row marginBottom="0.5rem" paddingLeft="0.75rem" paddingRight="0.75rem" className={css.BoxHeader} alignitems="center" justifycontent="space-between">
            <Text text="Input columns" size="x2" bold="true"/>
          </Row>
          {
            mapping.map((row, index) => {
              const selected = fields.find(field => field.id === `${row.output?.type}.${row.output?.field}`) || null;
              return (
                <Row key={index} className={css.Row} alignitems="center" grow="1">
                  <Dropdown placeholder="Output field" data={ fields } selected={ selected } onSelect={ (data) => editOutputField(data, index) } wrap="col" hidePlaceholder={true} hasSearch={true}/>
                </Row>
              )
            })
          }
        </Col>
      </Row>
    </Col>
  );
};

export { MappingSelector };
