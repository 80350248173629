import { iconsTypes } from 'lavaa';
import { useRulesets } from "../UseRulesets";

export function prepareRulesetsAsMenuItems(rulesets: any[], nodesType: string) {
    return rulesets.map((ruleset) => {
        return {
            id: ruleset.uid,
            uid: ruleset.uid,
            label: ruleset.name,
            name: ruleset.name,
            isEditEnabled: ruleset.isEditEnabled,
            description: ruleset.description,
            disorderTypeID: ruleset.disorderTypeID,
            node: {
                primaryId: ruleset.primaryId || ruleset.uid,
                type: nodesType,
                disorderTypeID: ruleset.disorderTypeID,
                createdByCompany: ruleset.createdByCompany,
				description: ruleset.description
            }
        }
    })
}
export const prepareRulesetsAsSearchItems = (rulesets: any[]) => rulesets.map((ruleset) => (ruleset.name));

const useMenuRulesets = (): any[] => {

    const { companyRulesets, functionsRulesets, openRulesets, myRulesets } = useRulesets();
    const menuCompanyRulesets = prepareRulesetsAsMenuItems(companyRulesets, 'companyRuleset');
    const menuFunctionRulesets = prepareRulesetsAsMenuItems(functionsRulesets, 'functionRuleset');
    const menuOpenRulesets = prepareRulesetsAsSearchItems(openRulesets);
    const menuMyRulesets = prepareRulesetsAsSearchItems(myRulesets);

    return [
        {
            id: 'tree-node-open',
            label: 'Open Rulesets',
            icon: iconsTypes.openRuleset,
            endIcon: iconsTypes.flowPlus,
            action: 'activateAddOpenRulesetsModal',
			searchBy: menuOpenRulesets
        },
        {
            id: 'tree-node-company',
            label: 'Company Rulesets',
            icon: iconsTypes.companyModule,
            children: menuCompanyRulesets
        },
        {
            id: 'tree-node-my',
            label: 'My Rulesets',
            icon: iconsTypes.downloadData,
            endIcon: iconsTypes.flowPlus,
            action: 'activateAddMyRulesetsModal',
			searchBy: menuMyRulesets
        },
        {
            id: 'tree-node-functions',
            label: 'Functions',
            icon: iconsTypes.functions,
            children: menuFunctionRulesets
        },
    ];
};

export { useMenuRulesets };
