import { FC } from 'react';
import { Col, Cell } from 'lavaa';
import { Text } from 'lavaa';
import css from './Unavailable.module.scss';
import { Button } from 'lavaa';
import { useNavigate } from "react-router-dom";

const UnavailableModule: FC = () => {
    const navigate = useNavigate();

    function back(){
        navigate('/openprojects');
    }

    return <Col className={ css.UnavailableModule } grow="1" alignitems="center" justifycontent="center">

        <Cell /*paddingBottom="1rem"*/>
            {/* <Text size="x7" textAlign="center">Ruleset is unavailable</Text> */}
            <Text size="x5" textAlign="center" style={{lineHeight: '2rem'}}>
                <strong>The module is deleted or is forbidden from opening by the creator.</strong>
                <br />
                Contact our <a href="mailto:support@lavaa.health">support team</a> for more details.
            </Text>
        </Cell>

        {/* <Cell>
            <Button text={"Go to projects"} onClick={ back }/>
        </Cell> */}
    </Col>;
};

export { UnavailableModule };