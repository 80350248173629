import { createSlice } from '@reduxjs/toolkit';

interface IPatientResult {
    bgColor: string,
    moduleUID: string,
    reasons: string[],
    resultGroupName: string,
    resultWeight: number,
    textColor: string
}

interface IPatient {
    id: string,
    name: string,
    results: IPatientResult[]
}

export interface IProjectReportData {
    flowUID: string,
    projectUID: string,
    nodeUID: string,
    link: string,
    expireDate: number,
    initialDate: number,
    page: number,
    sortBy: string,
    sortType: null | 'asc' | 'desc',
    errorMessage: string,
    patients: IPatient[]
}

interface IProjectReport {
    loading: boolean,
    data: IProjectReportData[]
}

const initialState: IProjectReport = {
    loading: false,
    data: []
}

export const projectReportSlice = createSlice({
    name: 'ProjectReport',
    initialState,
    reducers: {

        // Get Clients For RT Report
        getClientsForRTReportAction: (state, action) => {
            state.loading = true;
        },

        // Loading
        setProjectReportLoadingAction: (state) => {
            state.loading = true;
        },

        // Receive Clients For RT Report
        receiveClientsForRTReportAction: (state, action) => {
            const { data, errorMessage, resultCode } = action.payload;

            if (!errorMessage && resultCode === 'OK') {
                const { flowUID, clients, nodeUID } = data;

                state.data = state.data.map((item: IProjectReportData) => {
                    if (item.flowUID === flowUID && item.nodeUID === nodeUID) {
                        item.patients = clients;
                    }

                    return item;
                });
            }

            state.loading = false;
        },

        // Get Client For RT Report
        getClientForRTReportAction: (state, action) => {},

        // Receive Client For RT Report
        receiveClientForRTReportAction: (state, action) => {},

        // Receive Initial Project Report Data
        receiveInitialProjectReportDataAction: (state, action) => {
            const { link, flowUID, projectUID, nodeUID } = action.payload;
            const isReportExists = state.data.find((item: IProjectReportData) => item.flowUID === flowUID && item.nodeUID === nodeUID);
            !isReportExists && state.data.push(
                {
                    link,
                    flowUID,
                    projectUID: projectUID,
										nodeUID: nodeUID,
                    initialDate: Date.now(),
                    expireDate: Date.now() + 1000*60*60,
                    errorMessage: '',
                    patients: [],
                    page: 0,
                    sortBy: '',
                    sortType: null
                }
            );
        },

				// Set Page Action
				setPageAction: (state, action) => {
					const { flowUID, nodeUID, page } = action.payload;

					if (flowUID && nodeUID) {
						state.data = state.data.map((item: any) => {
							if (item.flowUID === flowUID && item.nodeUID === nodeUID) {
								return {...item, page: page};
							}
							return item;
						});
					}
				},

        // Set Sorting Action
        setSortingAction: (state, action) => {
            const { flowUID, nodeUID, sortBy, sortType } = action.payload;

            if (flowUID && nodeUID) {
                state.data = state.data.map((item: any) => {
										if (item.flowUID === flowUID && item.nodeUID === nodeUID) {
                        return {...item, sortBy, sortType};
                    }
                    return item;
                });
            }
        },

        // Reset Project Reports Action
        deleteProjectReportAction: ( state, action) => {
            const { flowUID, nodeUID } = action.payload;
            state.data = state.data.filter((item: any) => !(item.flowUID === flowUID && item.nodeUID === nodeUID));
        },

        // Reset Project Reports Action
        resetProjectReportsAction: ( state) => {
            state.data = [];
        },

        // Reset Project Reports Clients Action
        resetProjectReportsClientsAction: ( state) => {
            state.data = state.data.map((item: any) => Object.assign(item, { patients: [], page: 0 }));
        }
    }
});

export const {
    getClientsForRTReportAction,
    setProjectReportLoadingAction,
    receiveClientsForRTReportAction,
    getClientForRTReportAction,
    receiveClientForRTReportAction,
    receiveInitialProjectReportDataAction,
    setPageAction,
    setSortingAction,
    deleteProjectReportAction,
    resetProjectReportsAction,
    resetProjectReportsClientsAction
} = projectReportSlice.actions;
export default projectReportSlice.reducer;
