import { useDispatch, useSelector } from 'react-redux';
import { addRuleAction, cloneRuleAction, updateRuleAction, getRulesAction, addRuleToNodesAction, resetRulesAction } from "../Redux/Slices/Rules/Rules.slice";
import { useParams } from "react-router-dom";
import { useRulesetData } from "./UseRulesetData";
import { rulesAccessTypes } from "../Redux/Slices/Rules/Rules.data";

export const useRules = (): any => {

    const { activeRulesetId } = useParams();
    const { addRulesetDataOutput } = useRulesetData();

    const rules = useSelector((state: any) => state.rules.nodes);
    const rulesTemplates = useSelector((state: any) => state.rules.templates);
    const openRules = useSelector((state: any) => state.rules.templates.open);
    const companyRules = useSelector((state: any) => state.rules.templates.company);
    const myRules = useSelector((state: any) => state.rules.templates.my);
    const dispatch = useDispatch();

    // Fetch Rules Templates
    const fetchRulesTemplates = () => {
        dispatch(getRulesAction([rulesAccessTypes.my, 0, 0, 500, 'name', false]));
        dispatch(getRulesAction([rulesAccessTypes.company, 0, 0, 500, 'name', false]));
        dispatch(getRulesAction([rulesAccessTypes.open, 0, 0, 500, 'name', false]));
    };

    // Get All Rules Templates
    const getRulesTemplates = () => {
        // console.log('Get Rules Templates', [...openRules, ...myRules, ...companyRules].length);
        return [...openRules, ...myRules, ...companyRules];
    };

    // Clone Rule
    const cloneRule = (ruleId: string) => {
        dispatch(cloneRuleAction([activeRulesetId, ruleId]));
        addRulesetDataOutput(`Clonning rule...`);
    };

    // Update Rule
    const updateRule = (rule: any) => {
        dispatch(updateRuleAction([rule]));
        addRulesetDataOutput(`Saving rule "${rule.name}"`);
    };

    // Create Rule
    const createRule = (rule: any) => {
        dispatch(addRuleAction([rule]));
        addRulesetDataOutput(`Creating rule "${rule.name}"`);
    };

    // Add Rule To Nodes
    const addRuleToNodes = (rule: any) => {
        dispatch(addRuleToNodesAction([rule]));
    };

    // Reset Rules
    const resetRules = () => {
        dispatch(resetRulesAction());
    };

    return {
        rulesTemplates, getRulesTemplates,
        fetchRulesTemplates,
        createRule, cloneRule, updateRule, addRuleToNodes,
        rules, openRules, companyRules, myRules,
        resetRules
    };
}