import { FC, useEffect, useState } from 'react';
import { Modal, Tabs, ITabsContent } from 'lavaa';
import { CreateEditGuidelineDataTab } from '../CreateEditGuidelineDataTab/CreateEditGuidelineDataTab.component';
import { CreateEditGuidelineFilterTab } from '../CreateEditGuidelineFilterTab/CreateEditGuidelineFilterTab.component';

interface IProps {
    active: boolean,
    onClose: () => void,
    onOk: (data: any) => void,
    initialData: any,
    mode: '' | 'create' | 'edit'
}

// CreateEditGuidelineModal
const CreateEditGuidelineModal: FC<IProps> = (props) => {

    // Props
    const { active, onClose, onOk, initialData, mode = '' } = props;

    // States
    const [guidelineName, setGuidelineName] = useState<string>('');
    const [priority, setPriority] = useState<any>();
    const [description, setDescription] = useState<string>('');
    const [minValue, setMinValue] = useState<string>('');
    const [maxValue, setMaxValue] = useState<string>('');
    const [clientCondition, setClientCondition] = useState<any>();
    const [isFormValid, setIsFormValid] = useState(false);
    
    // Handle Change
    const onChange = (name: string, value: string) => {
        
        // Name
        if (name === 'guidelineName') {
            setGuidelineName(value);
        }

        // Priority
        if (name === 'priority') {
            setPriority(value);
        }

        // Description
        if (name === 'description') {
            setDescription(value);
        }

        // Min
        if (name === 'minValue') {
            setMinValue(value);
        }

        // Max
        if (name === 'maxValue') {
            setMaxValue(value);
        }

        // Client Condition
        if (name === 'clientCondition') {
            setClientCondition(value);
        }
    };

    // Reset States
    const resetStates = () => {
        setGuidelineName('');
        setPriority(undefined);
        setDescription('');
        setMinValue('');
        setMaxValue('');
        setClientCondition(undefined);
        setIsFormValid(false);
    };

    // Handle Cancel/Close
    const handleClose = () => {
        resetStates();
        onClose();
    };

    // Handle Ok
    const handleOk = () => {
        resetStates();
        onOk({
            priority,
            maxValue: Number(maxValue),
            minValue: Number(minValue),
            clientCondition,
            guidelineName,
            description,
        });
    };

    const tabs: ITabsContent[] = [
        {
            title: 'Info',
            content: <CreateEditGuidelineDataTab 
                        onChange={onChange} 
                        initialData={
                            {text: guidelineName, priority, description, minValue, maxValue }
                        } 
                        mode={mode} 
                        onValidate={ setIsFormValid } />
        },
        {
            title: 'Terms',
            content: <CreateEditGuidelineFilterTab onChange={onChange} initialData={clientCondition} />
        }
    ];

    useEffect(() => {
        if (initialData) {
            initialData.text !== '' && guidelineName === '' && setGuidelineName(initialData.text);
            initialData.description !== '' && description === '' && setDescription(initialData.description);
            initialData.priority && !priority && setPriority(initialData.priority);
            initialData.minValue !== minValue && setMinValue(initialData.minValue);
            initialData.maxValue !== maxValue && setMaxValue(initialData.maxValue);
            initialData.clientCondition && !clientCondition && setClientCondition(initialData.clientCondition);
        }
    }, [initialData]);

    return (
        <Modal active={active} onClose={handleClose} onCancel={handleClose} onOk={handleOk} hasTab={true}
            title="Guideline editor" type="large" height="600px" footerBtnStretch={false} isFormValid={isFormValid}>
            
            <Tabs content={tabs} />
        </Modal>
    )
};

export { CreateEditGuidelineModal };
