import { FC, useEffect, useState } from "react";
import { useModules } from "../../Hooks/UseModules";
import { RulesetNode } from "../Custom/Flow/Node/NodeTypes/RulesetNode.component";
import { useParams } from "react-router-dom";
import { FlowV11 } from "../FlowV11/FlowV11.component";
import { parseJsonLogicFlow } from "../../Tools/parseJsonLogic";
import { useProjects } from "../../Hooks/UseProjects";
import { useProjectTemporary } from "../../Hooks/UseProjectTemporary";
import { OpenRulesetNode } from "../Custom/Flow/Node/NodeTypes/OpenRulesetNode.component";
import { CompanyRulesetNode } from "../Custom/Flow/Node/NodeTypes/CompanyRulesetNode.component";
import { MyRulesetNode } from "../Custom/Flow/Node/NodeTypes/MyRulesetNode.component";
import { FunctionRulesetNode } from "../Custom/Flow/Node/NodeTypes/FunctionRulesetNode.component";

const nodeTypes = {
  ruleset: RulesetNode,
  openRuleset: OpenRulesetNode,
  companyRuleset: CompanyRulesetNode,
  myRuleset: MyRulesetNode,
  functionRuleset: FunctionRulesetNode,
};

interface IProps {
}

// RulesFlow
const RulesetFlow: FC<IProps> = () => {

  const { updateModule, publishModule, getActiveModuleData, getModuleTemplate } = useModules();
  const activeModuleData = getActiveModuleData();
  const { getActiveProjectData, projects } = useProjects();
  const { addProjectDataOutput } = useProjectTemporary();
  const { activeProjectId } = useParams();
  const [moduleName, setModuleName] = useState<string>('');
  const moduleTemplateData = (activeModuleData) ? getModuleTemplate(activeModuleData.primaryId) : null;
  const moduleDescription = (moduleTemplateData) ? moduleTemplateData.description : '';

  useEffect(() => {
    const projectData = getActiveProjectData();
    const jsonLogic = parseJsonLogicFlow(projectData);
    const { initialNodes } = jsonLogic;
    const module = initialNodes.find((item: any) => item.nodeId === activeModuleData.nodeId);

    if(module) {
      setModuleName(module.name);
    }
  }, [activeModuleData, projects])

  function saveHandler(data: any, autosave: boolean){

    data = {
      ...data,
      projectUID: activeProjectId,
      reportConfigs: null
    }

    updateModule({...data});

    if(autosave){
			addProjectDataOutput(`Auto-saving module "${moduleName}"`);
    }
    else{
			addProjectDataOutput(`Save module "${moduleName}"`);
    }
  }

  function publishHandler() {
    publishModule(activeProjectId, activeModuleData.nodeId);
		addProjectDataOutput(`Publishing a module "${moduleName}"`);
  }

  return (
    <FlowV11 type="ruleset" flowData={activeModuleData} nodeTypes={nodeTypes} onSave={saveHandler} onPublish={publishHandler} saveOnInit={true} title={moduleName} description={moduleDescription}/>
  )
};

export { RulesetFlow };
