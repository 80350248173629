import { FC, useContext } from 'react';
import { ModalsCtx } from "../../../../Context/Modals.context";
import { Modal } from 'lavaa';
import { Row } from 'lavaa';
import { Text } from 'lavaa';
import { useProjects } from "../../../../Hooks/UseProjects";

interface IProps {
    
}

// Delete Project Confirm
const DeleteProjectConfirm: FC<IProps> = (props) => {

    let { isDeleteProjectConfirmActive, setDeleteProjectConfirmActive, deleteProjectUID } = useContext(ModalsCtx);
    let { deleteProject } = useProjects();

    // Handle Close
    const handleClose = () => {
        setDeleteProjectConfirmActive(false);
    };

    // Handle Cancel
    const handleCancel = () => {
        setDeleteProjectConfirmActive(false);
    };

    // Handle Ok
    const handleOk = () => {
        deleteProject(deleteProjectUID);
        setDeleteProjectConfirmActive(false);
    };

    return <Modal 
        active={ isDeleteProjectConfirmActive } 
        onClose={ handleClose } 
        onCancel={ handleCancel } 
        onOk={ handleOk }
        title="Are you sure want to delete this project?"
        okText="Delete"
        cancelText="Cancel"
        type="alert"
        scroll={false}
        okButtonType="danger"
    >
        <Row grow="1" paddingBottom="1rem">
            <Text size="x2">If you delete this project you will not be able to restore it.</Text>
        </Row>

    </Modal>
};

export { DeleteProjectConfirm };