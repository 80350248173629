import { createSlice } from '@reduxjs/toolkit';
import { addSnackbar } from 'lavaa';

const initialState: any = {
   autocompleteData: [],
   moduleGuidelines: [],
   deleteUID: null
};

export const guidelinesSlice = createSlice({
    name: 'Guidelines',
    initialState,
    reducers: {

        // Get Guideline
        getGuidelineAction: (state, action) => state,

        // Receive Guideline
        receiveGuidelineAction: (state, action) => {
            return state;
        },

        // Get Guidelines
        getGuidelinesAction: (state, action) => state,

        // Receive Guidelines
        receiveGuidelinesAction: (state, action) => {
            const { data } = action.payload;
            state.autocompleteData = [...data];
        },

        // Reset Guidelines Autocomplete
        resetGuidelinesAutocompleteAction: (state) => {
            state.autocompleteData = [];
        },

        // Get Module Guidelines
        getModuleGuidelinesAction: (state, action) => state,

        // Receive Module Guidelines
        receiveModuleGuidelinesAction: (state, action) => {
            const { data } = action.payload;
            state.moduleGuidelines = [...data];
        },

        // Add Guideline Module
        addGuidelineModuleAction: (state, action) => state,

        // Add Guideline Module Answer Action
        addGuidelineModuleAnswerAction: (state, action) => {
            const { data } = action.payload;
            const hasId = state.moduleGuidelines.some((item: any) => item.GuidelineModuleUID === data.GuidelineModuleUID);

            if (hasId === false) {
                state.moduleGuidelines.push(data);
                addSnackbar('success', 'Success', 'Guideline was added successfully');
            } else {
                addSnackbar('warning', 'Warning', 'Guideline already exists');
            }
        },

        // Update Guideline Module
        updateGuidelineModuleAction: (state, action) => state,

        // Update Guideline Module Answer Action
        updateGuidelineModuleAnswerAction: (state, action) => {
            const { data } = action.payload;
            
            state.moduleGuidelines = state.moduleGuidelines.map((item: any) => {
                if (item.GuidelineModuleUID === data.GuidelineModuleUID) {
                    return data;
                }
                return item;
            });
            addSnackbar('success', 'Success', 'Guideline has been updated');
        },

        // Delete Module Guideline Action
        deleteModuleGuidelineAction: (state, action) => {
            const [ uid ] = action.payload;
            state.deleteUID = uid;
        },

        // Delete Module Guideline Answer Action
        deleteModuleGuidelineAnswerAction: (state, action) => {
            const { data } = action.payload;
            
            if (data === true) {
                state.moduleGuidelines = state.moduleGuidelines.filter((item: any) => {
                    if (item.GuidelineModuleUID === state.deleteUID) {
                        return false;
                    }

                    return true;
                });

                state.deleteUID = null;

                addSnackbar('success', 'Success', 'Guideline was deleted');
            }
            return state;
        },

        // Reset Module Guidelines Action
        resetModuleGuidelinesAction: (state) => {
            state.moduleGuidelines = [];
        }
    }
});

export const {
    getGuidelineAction,
    receiveGuidelineAction,
    getGuidelinesAction,
    receiveGuidelinesAction,
    resetGuidelinesAutocompleteAction,
    getModuleGuidelinesAction,
    receiveModuleGuidelinesAction,
    addGuidelineModuleAction,
    addGuidelineModuleAnswerAction,
    updateGuidelineModuleAction,
    deleteModuleGuidelineAction,
    resetModuleGuidelinesAction
} = guidelinesSlice.actions;
export default guidelinesSlice.reducer;
