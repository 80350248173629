import { createSlice } from '@reduxjs/toolkit';
import { IProjectsState } from '../../../Types/Project.types';
import { projectAccessType } from './Projects.data';

const initialState: IProjectsState = {
    open: {
        data: {},
        loading: false,
        page: 1
    },
    myCompany: {
        data: {},
        loading: false,
        page: 1
    },
    my: {
        data: {},
        loading: false,
        page: 1
    },
    onLoading: []
}

export const projectsSlice = createSlice({
    name: 'Projects',
    initialState,
    reducers: {

        // Get Projects
        getProjectsAction: (state, action) => {

            const type = action.payload[0];
            const page = action.payload[1] + 1;
            const searchTerm = action.payload[5];

            // Set Loading & Page
            switch (type) {
                case projectAccessType.open:
                    searchTerm === '' && (state.open.loading = true);
                    state.open.page = page;
                    break;
                case projectAccessType.my:
                    searchTerm === '' && (state.my.loading = true);
                    state.my.page = page;
                    break;
                case projectAccessType.myCompany:
                    searchTerm === '' && (state.myCompany.loading = true);
                    state.myCompany.page = page;
                    break;
            }

            return state;
        },

        // Receive Projects
        receiveProjectsAction: (state, action) => {
            const { type, results } = action.payload.data;
            let projectType: string = '';

            // Open
            if (type === projectAccessType.open) {
                projectType = 'open';
            }

            // My
            if (type === projectAccessType.my) {
                projectType = 'my';
            }

            // My Company
            if (type === projectAccessType.myCompany) {
                projectType = 'myCompany';
            }

            if (projectType === 'open' || projectType === 'my' || projectType === 'myCompany') {

                const data: any = {};
                state[projectType].loading = false;
                
                results.forEach((project: any) => {
                    project['jsonLogic'] = (project['jsonLogic'] && project['jsonLogic'].length > 0) ? JSON.parse(project['jsonLogic']): {};
                    data[project.uid] = project;
                });

                state[projectType].data = data;
            }
            
            return state;
        },

        // Get Project
        getProjectAction: (state, action) => {
            state.onLoading.push(action.payload[0]);
        },

        // Receive Project
        receiveProjectAction: (state, action) => {
            if(action.payload.resultCode !== "OK"){
                const requestIds = action.payload.data;
                state.onLoading = state.onLoading.filter((id) => id !== requestIds[0]);
                return;
            }

            const project = action.payload.data;

            if(typeof project !== 'object') {
                return state;
            }

            project['jsonLogic'] = (project['jsonLogic'] && project['jsonLogic'].length > 0) ? JSON.parse(project['jsonLogic']): {};

            switch (project.accessType) {
                case projectAccessType.open:
                    state.open.data[project.uid] = project;
                    break;
                case projectAccessType.my:
                    state.my.data[project.uid] = project;
                    break;
                case projectAccessType.myCompany:
                    state.myCompany.data[project.uid] = project;
                    break;
            }
            state.onLoading = state.onLoading.filter((id) => id !== project.uid);
        },

        // Add Project
        addProjectAction: (state, action) => {},

        // Update Project
        updateProjectAction: (state, action) => {},

        // Receive Update Project
        receiveUpdateProjectAction: (state, action) => {
            const project = action.payload.data;

            if(typeof project !== 'object') {
                return state;
            }

            project['jsonLogic'] = (project['jsonLogic'] && project['jsonLogic'].length > 0) ? JSON.parse(project['jsonLogic']): {};
            switch (project.accessType) {
                case projectAccessType.open:
                    state.open.data[project.uid] = project;
                    delete state.my.data[project.uid];
                    delete state.myCompany.data[project.uid];
                    break;
                case projectAccessType.my:
                    state.my.data[project.uid] = project;
                    delete state.open.data[project.uid];
                    delete state.myCompany.data[project.uid];
                    break;
                case projectAccessType.myCompany:
                    state.myCompany.data[project.uid] = project;
                    delete state.open.data[project.uid];
                    delete state.my.data[project.uid];
                    break;
            }

            return state;
        },

        // Publish Project
        publishProjectAction: (state, action) => {},

        // Run Project
        runProjectAction: (state, action) => {},

        receiveProjectRunAnswer: (state, action) => {},

        // Delete Project
        deleteProjectAction: (state, action) => {},

        // Receive Project Add
        receiveProjectAddAction: (state, action) => {
            const project = action.payload.data;

            if(typeof project !== 'object') {
                return state;
            }

            project['jsonLogic'] = (project['jsonLogic'] && project['jsonLogic'].length > 0) ? JSON.parse(project['jsonLogic']): {};

            switch (project.accessType) {
                case projectAccessType.open:
                    state.open.data[project.uid] = project;
                    break;
                case projectAccessType.my:
                    state.my.data[project.uid] = project;
                    break;
                case projectAccessType.myCompany:
                    state.myCompany.data[project.uid] = project;
                    break;
            }

            return state;
        },

        // Receive Publish Project
        receivePublishProjectAction: () => {},

        // Receive Delete Project
        receiveDeleteProjectAction: (state, action) => {
            const uid = action.payload.data;

            delete state.open.data[uid];
            delete state.my.data[uid];
            delete state.myCompany.data[uid];

            return state;
        },

        // Reset Projects
		resetProjectsAction: (state) => {
			return {...initialState};
		}
    }
});

export const {
    getProjectsAction,
    getProjectAction,
    addProjectAction,
    receiveProjectAddAction,
    updateProjectAction,
    publishProjectAction,
    runProjectAction,
    deleteProjectAction,
    receiveProjectsAction,
    receiveProjectAction,
    receiveUpdateProjectAction,
    receivePublishProjectAction,
    receiveDeleteProjectAction,
    resetProjectsAction
} = projectsSlice.actions;
export default projectsSlice.reducer;
