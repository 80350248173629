import { Row, Cell, Col } from 'lavaa';
import { Text } from 'lavaa';
import { FC, useState } from 'react';
import { classNames } from '../../Tools';
import { useNotifications } from '../../Hooks/UseNotifications';
import { INotificationState } from '../../Redux/Slices/Notifications/Notifications.slice';
import { Icon, iconsTypes } from 'lavaa';
import css from './Notifications.module.scss';

interface IProps {
    closeHandler: () => void
}

// Notifications Dropdown
const NotificationsDropdown: FC<IProps> = (props) => {
    
    const { closeHandler } = props;
    const { notifications } = useNotifications();

    // States
    const [data, setData] = useState<Array<any>>(notifications);

    const expandHandler = (id: number) => {
        setData(
            data.map((item: INotificationState) => item.id === id ? ({...item, expanded: !item.expanded}) : item)
        );
    };

    console.log('Render notifications', data);

    return (
        <>
            <div className={css.DropdownLayer} onClick={closeHandler}/>
            <Col paddingBottom='0.5rem' className={css.Dropdown}>
                <Row paddingLeft='1.5rem' paddingTop='1rem' paddingRight='1.5rem' paddingBottom='1rem'>
                    <Text text='Notification' size='x2' bold='true' className={css.DropdownTitle}/>
                </Row>
                <Col className={css.NotifyList}>
                    {
                        data.map((item: INotificationState, index: any) => {
                            return(
                                <Row className={ classNames(css.Notify, /*item.isNew ? css.New : '',*/ item.expanded ? css.Expanded : '')} alignitems='start' paddingLeft='1rem' paddingTop='0.5rem' paddingBottom='0.5rem' key={index} onClick={ () => expandHandler(item.id) }>
                                    
                                    {/* Text */}
                                    <Col grow='1' className={css.NotifyData}>
                                        <Text text={item.label} size='x2' className={css.NotifyLabel}/>
                                        <Text text={item.text} size='x2' className={css.NotifyText}/>

                                        {/* Mark as Read */}
                                        {
                                            item.expanded && 
                                            <Row paddingTop='0.5rem'>
                                                <Text text='Mark as Read' size='x2' bold='true' className={css.Mark}/>
                                            </Row>
                                        }
                                        
                                    </Col>

                                    {/* Time */}
                                    <Cell shrink='0'>
                                        <Text text='8h' size='x2' bold='true' className={css.NotifyTime}/>
                                    </Cell>

                                    {/* Expand / Collapse */}
                                    <Cell shrink='0' className={css.Icon}>
                                        <Icon name={item.expanded ? iconsTypes.arrowUp : iconsTypes.arrowDown} />
                                    </Cell>
                                </Row>
                            );
                        })
                    }
                </Col>
            </Col>
        </>
    );
};

export { NotificationsDropdown };