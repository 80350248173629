import { FC, useContext, useEffect, useState } from "react";
import { ModalsCtx } from "../../../../Context/Modals.context";
import { Modal, RuleEditor } from 'lavaa';
import { useRules } from "../../../../Hooks/UseRules";
import { useClients } from "../../../../Hooks/UseClients";
import { emptyRule, IRuleType } from "../../../../Redux/Slices/Rules/Rules.data";
import { CreateNewRuleDescription } from "./Description/CreateNewRuleDescription.component";
import { ITabsContent, Tabs } from "lavaa";
import { v4 as uuid } from 'uuid';
import { useRulesets } from "../../../../Hooks/UseRulesets";
import { useRulesetData } from "../../../../Hooks/UseRulesetData";

interface IProps {
}

// Create New Rule Modal
const CreateNewRuleModal: FC<IProps> = () => {

    const { createRule } = useRules();
    const { addRuleToRuleset, getActiveRulesetData } = useRulesets();
    const { addActiveRule } = useRulesetData();
    const { getClientScheme, clientScheme } = useClients();

    // Context
    const { isNewRuleModalActive, setNewRuleModalActive } = useContext(ModalsCtx);

    // States
    const [ruleData, setRuleData] = useState<IRuleType>(emptyRule);
    const [isRuleParametersValid, setRuleParametersValid] = useState<boolean>(false);

    useEffect(() => {
        getClientScheme();
    }, []);

    function changeHandler(newRuleData: any){
        setRuleData(newRuleData);
    }

    // On Rule Parameters Validate
    const onRuleParametersValidate = (isValid: boolean) => {
        setRuleParametersValid(isValid);
    };

    // Handle Close
    const handleClose = () => {
        setNewRuleModalActive(false);
    };

    // Handle Cancel
    const handleCancel = () => {
        setNewRuleModalActive(false);
    };

    // Handle Ok
    const handleOk = () => {

        if(ruleData.score === null || !ruleData.name){
            alert("Please fill in name and score fields");
            return;
        }

        setNewRuleModalActive(false);

        const ruleUID = uuid();
        const ruleset = getActiveRulesetData();

        const data = Object.assign({}, ruleData, {
            uid: ruleUID,
            primaryId: ruleUID,
            rulesetUID: ruleset.nodeId,
            moduleUID: ruleset.moduleUID,
            projectUID: ruleset.projectUID,
            score: ruleData.score || 0,
            accessType: 3
        });

        createRule(data);
        addRuleToRuleset(ruleUID, ruleData.score);
        addActiveRule(ruleUID);

        setRuleData(emptyRule);
    };

    let tabs: ITabsContent[] = [
        {
            title: 'Create new rule',
            content: <CreateNewRuleDescription data={ruleData} onChange={changeHandler} onValidate={onRuleParametersValidate} />
        },
        {
            title: 'Rule Editor',
            content: <RuleEditor rule={ruleData} onChange={changeHandler} scheme={clientScheme}/>,
            disabled: !isRuleParametersValid
        }
    ];

    return <Modal
        active={ isNewRuleModalActive }
        onClose={ handleClose }
        onCancel={ handleCancel }
        onOk={ handleOk }
        title="Add new rule"
        okText="Create"
        cancelText="Cancel"
        type='large'
        hasTab={ true }
        height="550px"
        isFormValid={isRuleParametersValid}
    >
        <Tabs content={tabs} />
    </Modal>
};

export { CreateNewRuleModal };
