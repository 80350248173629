import { FC, useEffect, useState } from 'react';
import { Button, Col, Grid, Row, Title, iconsTypes } from 'lavaa';
import { classNames } from '../../Tools';
import css from './Drawer.module.scss';

interface IProps {
    active: boolean;
    title: string;
    anchor?: 'left' | 'right' | 'top' | 'bottom';
    top?: string;
    zIndex?: number;
    onClose: () => void;
    children?: any;
    className?: string;
    overlay?: boolean;
    closeButtonText?: string;
    closeButtonIcon?: number;
}

// Drawer
const Drawer: FC<IProps> = (props) => {

    const { active = false, anchor = 'top', top, zIndex, title, onClose, children, className, overlay = false, closeButtonText, closeButtonIcon = iconsTypes.x } = props;

    // States
    const [ visible, setVisible ] = useState<boolean>(false);

    // On Close
    const handleClose = (e: Event) => {
        e.stopPropagation();
        setVisible(false);
    };

    // On Transition End
    const handleTransitionEnd = (e: any) => {
        if (visible === false && e.propertyName === 'transform') {
            onClose();
        }
    };

    // Effects
    useEffect(() => {

        // Open drawer
        if (active === true && visible === false) {
            setVisible(true);
        }
    }, [active]);

    // console.log('Render Drawer', active, visible);

    return active === false ? null : (
        <>
            {
                overlay === false ? null : <div className={ css.DrawerOverlay } data-open={visible}></div>
            }

            <Grid className={ classNames(css.Drawer, className) } data-anchor={anchor} data-open={visible} onTransitionEnd={handleTransitionEnd} style={{top, zIndex}}>

                {/* Header */}
                <Row className={ css.Header } paddingLeft="1rem" paddingRight="1rem">

                    {/* Title */}
                    <Row grow="1" alignitems="center" justifycontent="center">
                        <Title size='x4'>{ title }</Title>
                    </Row>

                    {/* Close Button */}
                    <Row alignitems="center">
                        {
                            closeButtonText && <Button style={{paddingRight: '1rem'}} text={closeButtonText} className={css.CloseIcon} type="secondary" variant="text" onClick={ handleClose } grow='0' />
                        }

                        {
                            closeButtonIcon && <Button className={css.CloseIcon} icon={closeButtonIcon} type="secondary" variant="text" onClick={ handleClose } grow='0' />
                        }
                    </Row>
                </Row>

                {/* Content */}
                <Col className={ css.Content } scroll="true">
                    { children }
                </Col>

                {/* Footer */}
                {/* <Row className={ css.Footer }>
                    
                </Row> */}
            </Grid>
        </>
    )
};

export { Drawer };
