import { FC, useContext } from 'react';
import { ModalsCtx } from '../../../Context/Modals.context';
import { useModules } from "../../../Hooks/UseModules";
import { useNavigate } from "react-router-dom";
import { TreeView } from "lavaa";
import { FilterNodeModalType } from "../../../Context/Modals.types";

interface IProps {
    data: Array<any>,
    filter?: boolean
}

// TreeMenu
const TreeMenu: FC<IProps> = (props) => {

    let { data } = props;
    const { setAddHealthModulesModalActive, setAddMyModulesModalActive, setAddCommonRulesModalActive, setAddPersonalInfoRulesModalActive, setAddMedicalHistoryRulesModalActive, setAddLabResultsRulesModalActive, setAddMyRulesModalActive, setAddCompanyRulesModalActive, setEditRuleModalActive, setSourceOpenDataModalActive, setSourceLocalFileModalActive, setAddClinicalResearchModulesModalActive } = useContext(ModalsCtx);
    const { setRealReportModalActive, setSourceReportEmailModalActive, setModuleSettingsModalActive, setAddOpenRulesetsModalActive, setAddMyRulesetsModalActive, setModuleSettingsUID } = useContext(ModalsCtx);

		const setFilterNodeModal: ((value: FilterNodeModalType) => void) = useContext(ModalsCtx).setFilterNodeModal;
    const { setMappingModalActive, setMappingModalUID } = useContext(ModalsCtx);

    const navigate = useNavigate();
    const { fetchModuleAndSave } = useModules();

    // On Drag Start
    const onDragStart = (event: any, props: any) => {
        if (event.target === event.currentTarget) {
            const { primaryId, node, label } = props;
			const description = node.description;
            event.dataTransfer.setData('lavaa/flow', JSON.stringify({primaryId, node, label, description}));
            event.dataTransfer.effectAllowed = 'move';
        }
    };

    const onAction = (props: any) => {

        const { action, actionData } = props;

        switch (action) {
            case 'activateAddOpenRulesetsModal':
                setAddOpenRulesetsModalActive(true);
                console.log('onAction', props.action);
                break;
            case 'activateAddMyRulesetsModal':
                setAddMyRulesetsModalActive(true);
                console.log('onAction', props.action);
                break;
            case 'activateAddHealthModulesModal':
                setAddHealthModulesModalActive(true);
                console.log('onAction', props.action);
                break;
            case 'activateAddClinicalResearchModulesModal':
                setAddClinicalResearchModulesModalActive(true);
                console.log('onAction', props.action);
                break;
            case 'activateAddMyModulesModal':
                setAddMyModulesModalActive(true);
                console.log('onAction', props.action);
                break;
            case 'activateCommonRulesModal':
                setAddCommonRulesModalActive(true);
                console.log('onAction', props.action);
                break;
            case 'activatePersonalInfoRulesModal':
                setAddPersonalInfoRulesModalActive(true);
                console.log('onAction', props.action);
                break;
            case 'activateMedicalHistoryRulesModal':
                setAddMedicalHistoryRulesModalActive(true);
                console.log('onAction', props.action);
                break;
            case 'activateLabResultsRulesModal':
                setAddLabResultsRulesModalActive(true);
                console.log('onAction', props.action);
                break;
            case 'activateCompanyRulesModal':
                setAddCompanyRulesModalActive(true);
                console.log('onAction', props.action);
                break;
            case 'activateMyRulesModal':
                setAddMyRulesModalActive(true);
                console.log('onAction', props.action);
                break;
            case 'activateEditRuleModal': // ???
                setEditRuleModalActive(true);
                console.log('onAction', props.action);
                break;
                // ###
            case 'activateSourceOpenDataModal':
                setSourceOpenDataModalActive(true);
                console.log('onAction', props.action);
                break;
            case 'activateSourceLocalFileModal':
                setSourceLocalFileModalActive(true);
                console.log('onAction', props.action);
                break;
            case 'activateRealReportModal':
                setRealReportModalActive(true);
                console.log('onAction', props.action);
                break;
            case 'activateSourceReportEmailModal':
                setSourceReportEmailModalActive(true);
                console.log('onAction', props.action);
                break;
            case 'loadModuleData':
                const { moduleId, projectId } = actionData;
                fetchModuleAndSave(projectId, moduleId);
                console.log('onAction', props.action);
                break;
            case 'editMapping':
                const { mappingUID } = actionData;
                setMappingModalActive(true);
                setMappingModalUID(mappingUID);
                console.log('onAction', props.action);
                break;
            case 'navigate':
                const { link } = actionData;
                if ( link ) {
                    navigate( link );
                }
                console.log( 'onAction', props.action );
                break;
        }
    };

    function onContentAction(e: any, props: any){
        const { link, contentAction, contentActionData } = props;

        if(link){
            e.preventDefault();
            navigate(link);
        }

        if(contentAction){
            switch (contentAction) {
								case 'showModuleSettings':
									setModuleSettingsUID( contentActionData.moduleId );
									setModuleSettingsModalActive( true );
									break;
								case 'showFilterRulesetSettings':
									navigate( contentActionData.link );
									setFilterNodeModal({ isActive: true, uid: contentActionData.uid, type: "ruleset" } );
									break;
            }
        }
    }

	return <TreeView data={data} onAction={onAction} onDragStart={onDragStart} onContentAction={onContentAction} />;
};

export { TreeMenu };
