import { FC, useContext, useEffect, useRef, useState } from "react";
import { ModalsCtx } from "../../../../Context/Modals.context";
import { Modal } from "lavaa";
import { SqlSourceModule } from "../../../ModuleSettings/SourceModule/SqlSourceModule.com";
import { OpenDataModule } from "../../../ModuleSettings/SourceModule/OpenData.component";
import { SingleClientModule } from "../../../ModuleSettings/SourceModule/SingleClient.component";
import { useProjects } from "../../../../Hooks/UseProjects";
import { parseJsonLogicFlow } from "../../../../Tools/parseJsonLogic";
import { BasicEditModule } from "../../../ModuleSettings/BasicEditModule/BasicEditModule.component";
import { LocalFileModule } from "../../../ModuleSettings/SourceModule/LocalFile.component";
import { useClients } from "../../../../Hooks/UseClients";
import { RealTimeReportModule } from "../../../ModuleSettings/DestinationModule/RealTimeReport/RealTimeReport.component";

// Module Settings Modal
const ModuleSettingsModal: FC<any> = () => {

	const {
		isModuleSettingsModalActive,
		setModuleSettingsModalActive,
		moduleSettingsUID,
		setModuleSettingsUID,
		setLocalFileModuleFile
	} = useContext(ModalsCtx);
	const { getActiveProjectData, updateProject } = useProjects();
	const { uploadLocalFile, lastUploadedFile } = useClients();

	// States
	const [localModule, setLocalModule] = useState<any>(null);
	const [projectNodes, setProjectNodes] = useState<any>([]);
	const [isLoading, setLoading] = useState<boolean>(false);
	const [isParametersValid, setParametersValid] = useState<boolean>(false);

	// Refs
	const isUploadingFileRef = useRef<boolean>(false);

	// On Parameters Validate
	const onParametersValidate = (isValid: boolean) => {
		setParametersValid(isValid);
	};

	useEffect(() => {
		if (moduleSettingsUID == "") {
			setLocalModule(null);
			return;
		}
		const projectData = getActiveProjectData();
		const jsonLogic = parseJsonLogicFlow(projectData);
		const { initialNodes } = jsonLogic;
		setProjectNodes(initialNodes);
		const module = initialNodes.find((item: any) => item.nodeId === moduleSettingsUID);
		if (module) setLocalModule(module);
	}, [moduleSettingsUID]);

	useEffect(() => {
		if (!isUploadingFileRef.current) return;
		isUploadingFileRef.current = false;
		fileUploadedHandler(lastUploadedFile);
	}, [lastUploadedFile])

	function changeHandler(newModuleData: any, immediatelySave: boolean = false) {
		if (isLoading) return;
		setLocalModule(newModuleData);
		if (immediatelySave) saveChanges(newModuleData, false);
	}

	function fileUploadedHandler(fileName: string) {
		const update = Object.assign({}, localModule, {
			data: {
				...localModule.data,
				file: fileName
			}
		});
		delete update.data.fileData;
		setLocalModule(update);
		setLoading(false);
		saveChanges(update)
	}

	function saveChanges(localData: any = null, closeAfterSave: boolean = true) {
		if (uploadFile(localData) || (isLoading && localData === null)) return;
		localData = localData || localModule;

		const projectData = getActiveProjectData();
		const jsonLogic = parseJsonLogicFlow(projectData);
		const { initialNodes, initialEdges } = jsonLogic;

		const nodes = initialNodes.map((item: any) => {
			if (item.nodeId === moduleSettingsUID) {
				return localData;
			}
			return item;
		});

		const data = Object.assign({}, projectData, {
			jsonLogic: [
				{ name: "initialEdges", data: [...initialEdges] },
				{ name: "initialNodes", data: nodes }
			]
		});

		updateProject(data);

		if (closeAfterSave) {
			setModuleSettingsModalActive(false);
			setModuleSettingsUID("");
		}
	}

	function uploadFile(localData: any = null) {
		localData = localData || localModule;
		if (!localData) return false;
		if (localData.primaryId === "1b04171d-d9db-45fd-8895-c825343a9294") {
			if (localData.data.hasOwnProperty("fileData") && localData.data.fileData) {
				isUploadingFileRef.current = true;
				setLoading(true);

				uploadLocalFile(localData.data.fileData);
				return true;
			}
		}
		return false;
	}

	// Handle Close
	const handleClose = () => {
		setModuleSettingsModalActive(false);
		setModuleSettingsUID("");
		setLocalFileModuleFile(null);
	};

	// Handle Ok
	const handleOk = () => {
		saveChanges();
	};

	let content = null,
		height = undefined,
		hasTab = false,
		modalSize: "small" | "middle" | "large" = "small";

	if (localModule !== null) {
		switch (localModule.primaryId) {
			case "4aa6d5c1-560b-4d8d-b45e-b25acf0f1f7e": // SQL Source
				content = <SqlSourceModule module={localModule} onChange={changeHandler} onValidate={onParametersValidate} />;
				break;
			case "30cf22cf-c225-4e53-bd32-04ae487b642f": // Open Data Module
				content = <OpenDataModule module={localModule} onChange={changeHandler} onValidate={onParametersValidate} />;
				break;
			case "b3c901a5-559e-4455-bbc1-44156069b3ad": // Single Client Module
				content = <SingleClientModule module={localModule} onChange={changeHandler} onValidate={onParametersValidate} />;
				modalSize = "large";
				height = "90vh";
				break;
			case "1b04171d-d9db-45fd-8895-c825343a9294": // Local File Module
				content = <LocalFileModule module={localModule} onChange={changeHandler} onValidate={onParametersValidate} />;
				modalSize = "large";
				break;
			case "648ec5bd-834e-4a7b-b294-d5bcaf3a5f97": // Real Time Report Module
				content = <RealTimeReportModule type="realTime" module={localModule} projectNodes={projectNodes} onChange={changeHandler} onValidate={onParametersValidate} />;
				modalSize = "large";
				hasTab = true;
				break;
			case "06c8f1cc-2335-4e4c-ab58-71bbebd9abe6": // Real Time Report Module - email
				content = <RealTimeReportModule type="email" module={localModule} projectNodes={projectNodes} onChange={changeHandler} onValidate={onParametersValidate} />;
				modalSize = "large";
				hasTab = true;
				break;
			case "972855fc-005e-43c6-b7dc-ad6c9b640940": // Real Time Report Module - slack
				content = <RealTimeReportModule type="slack" module={localModule} projectNodes={projectNodes} onChange={changeHandler} onValidate={onParametersValidate} />;
				modalSize = "large";
				hasTab = true;
				break;
			default:
				content = <BasicEditModule module={localModule} onChange={changeHandler} onValidate={onParametersValidate} />
		}
	}

	return <Modal
		active={isModuleSettingsModalActive}
		onClose={handleClose}
		onCancel={handleClose}
		onOk={handleOk}
		title="Module settings"
		okText={"Save"}
		okLoading={isLoading}
		cancelText="Cancel"
		type={modalSize}
		height={height}
		hasTab={hasTab}
		isFormValid={isParametersValid}
	>
		{content}
	</Modal>
};

export { ModuleSettingsModal };
