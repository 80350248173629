import { FC, useEffect, useState } from 'react';
import { ChromePicker, ColorResult } from 'react-color';
import { Button, Row, Col, ScrollContainer, iconsTypes, Input, Text, Title } from 'lavaa';
import css from './ModuleReports.module.scss';
import { classNames } from '../../../../../Tools';

interface IProps {
    onReportUpdate: (data: Array<any>) => void,
    initialData: Array<any>
}

// Module Reports
const ModuleReports: FC<IProps> = (props) => {

    const { onReportUpdate, initialData } = props;
    const initData = initialData.map((item: any) => ({...item, minValue: item.minValue !== null ? item.minValue.toString() : '', maxValue: item.maxValue !== null ? item.maxValue.toString() : ''}));

    // States
    const [data, setData] = useState<Array<any>>([...initData]);
    const [colorPickerActive, setColorPickerActive] = useState<any>({index: -1, field: ''});

    // Add Row
    const addRow = () => {
        const newRow = {minValue: '', maxValue: '', groupName: '', textColor: '', bgColor: '', condition: '{}'};
        setData([...data, newRow]);
        onReportUpdate([...data, newRow]);
    };

    // Update Row
    const updateRow = (field: string, value: string, index: number) => {
        const updatedData: any = [...data];
        updatedData[index][field] = value;
        setData([...updatedData]);
        onReportUpdate([...updatedData]);
    };

    // Delete Row
    const deleteRow = (index: number) => {
        const updatedData = data.filter((item: any, i: number) => i !== index);
        setData([...updatedData]);
        onReportUpdate([...updatedData]);
    };

    // On Color Select
    const onColorSelect = (color: ColorResult, event: React.ChangeEvent<HTMLInputElement>, index: number, field: string) => {
        event.stopPropagation();
        const updatedData: any = [...data];
        updatedData[index][field] = color.hex;
        setData([...updatedData]);
        onReportUpdate([...updatedData]);
    };

    // Activate Color Picker
    const activateColorPicker = (index: number, field: string) => {
        setColorPickerActive({index, field});
    };

    // Close Picker
    const closePicker = () => {
        setColorPickerActive({index: -1, field: ''});
    };

    // Did Mount
    useEffect(() => {
        if (data.length === 0) {
            addRow();
        }
    }, []);


    return <>
        <Col grow="1" paddingTop="0.25rem" paddingBottom="0.5rem" className={ css.ModuleReports }>

            {/* Head */}
            <Row alignitems="center" paddingTop="0.25rem" paddingBottom="1rem">

                {/* Title */}
                <Row grow="1">
                    <Title size="x3">All Reports</Title>
                </Row>

                {/* Add Button */}
                <Button text="Add Row" icon={ iconsTypes.playCircle} type="secondary" variant="text" onClick={ addRow } />
            </Row>

            {/* Report Data */}
            <ScrollContainer>
                {
                    data.map((item: any, index: number) => {
                      const hasTextColor = item.textColor.length > 0;
                      const hasBgColor = item.bgColor.length > 0;

                      return(
                          <Row className={ css.ReportRow } paddingTop="1rem" paddingLeft="1rem" paddingRight="1rem" paddingBottom="1rem" key={index} marginBottom="1rem">
                            
                            {/* Range */}
                            <Row paddingRight="1rem">
                                <Col>

                                    {/* Title */}
                                    <Row paddingBottom="0.5rem">
                                        <Text className={ css.Label } size="x2" bold="true" text="Range"/>
                                    </Row>

                                    {/* Inputs */}
                                    <Row alignitems="center">

                                        {/* Min */}
                                        <Row>
                                            <Input className={ css.RangeInput } type="text" value={ item.minValue } label="Min" placeholder="1" labelClassName={ css.Label } onChange={ (value: string) => updateRow('minValue', value, index) } />
                                        </Row>

                                        {/* Max */}
                                        <Row paddingLeft="1rem">
                                            <Input className={ css.RangeInput } type="text" value={ item.maxValue } label="Max" placeholder="100" labelClassName={ css.Label } onChange={ (value: string) => updateRow('maxValue', value, index) } />
                                        </Row>
                                    </Row>
                                </Col>
                            </Row>

                            {/* Group Name */}
                            <Row paddingRight="1rem" grow="1">
                                <Col grow="1">

                                    {/* Title */}
                                    <Row paddingBottom="0.5rem">
                                        <Text className={ css.Label } size="x2" bold="true" text="Report Group Name"/>
                                    </Row>

                                    {/* Inputs */}
                                    <Row alignitems="center">

                                        {/* Group */}
                                        <Input type="text" value={ item.groupName } placeholder="Type name" labelClassName={ css.Label } onChange={ (value: string) => updateRow('groupName', value, index) } style={{color: item.textColor, background: item.bgColor}}/>
                                    </Row>
                                </Col>
                            </Row>

                            {/* Text Color */}
                            <Row paddingRight="1rem" grow="1" style={{position: 'relative'}}>
                                <Col grow="1">

                                    {/* Title */}
                                    <Row paddingBottom="0.5rem">
                                        <Text className={ css.Label } size="x2" bold="true" text="Text Color"/>
                                    </Row>

                                    {/* Inputs */}
                                    <Row alignitems="center" justifycontent="start">

                                        {/* Color Selector */}
                                        <Row className={ css.ColorSelector } alignitems="center" justifycontent="start" paddingLeft="0.75rem" paddingRight="0.75rem" onClick={ () => activateColorPicker(index, 'textColor') } >
                                            <Text className={ classNames(css.ColorSelectorText, !hasTextColor && css.ColorSelectorTextInactive) } size="x2" text={ hasTextColor ? item.textColor.toUpperCase() : "#XXXXXX" } />
                                        </Row>

                                        {/* Color Picker */}
                                        {
                                            index === colorPickerActive.index && colorPickerActive.field === 'textColor' &&
                                            <Col style={{background: 'var(--secondary-gradient-color)', position: 'absolute', top: -15, left: -430, border: '1px solid var(--sidebar-text-color)', zIndex: '1'}}>
                                                <Row justifycontent="end" paddingRight="0.5rem">
                                                    <Button icon={iconsTypes.x} type="secondary" variant="text" onClick={ closePicker } grow='0' className={css.CloseIcon} />
                                                </Row>
                                                <ChromePicker color={ hasTextColor ? item.textColor : "#0C1E2A" } onChange={ (color: ColorResult, event: React.ChangeEvent<HTMLInputElement>) => onColorSelect(color, event, index, 'textColor') }/>
                                            </Col>
                                        }
                                    </Row>
                                </Col>
                            </Row>

                            {/* Bg Color */}
                            <Row paddingRight="1rem" grow="1" style={{position: 'relative'}}>
                                <Col grow="1">

                                    {/* Title */}
                                    <Row paddingBottom="0.5rem">
                                        <Text className={ css.Label } size="x2" bold="true" text="Bg Color"/>
                                    </Row>

                                    {/* Inputs */}
                                    <Row alignitems="center" justifycontent="start">

                                        {/* Color Selector */}
                                        <Row className={ css.ColorSelector } alignitems="center" justifycontent="start" paddingLeft="0.75rem" paddingRight="0.75rem" onClick={ () => activateColorPicker(index, 'bgColor') } >
                                          <Text className={ classNames(css.ColorSelectorText, !hasBgColor && css.ColorSelectorTextInactive) } size="x2" text={ hasBgColor ? item.bgColor.toUpperCase() : "#XXXXXX" } />
                                        </Row>

                                        {/* Color Picker */}
                                        {
                                            index === colorPickerActive.index && colorPickerActive.field === 'bgColor' &&
                                            <Col style={{background: 'var(--secondary-gradient-color)', position: 'absolute', top: -15, left: -527, border: '1px solid var(--sidebar-text-color)', zIndex: '1'}}>
                                                <Row justifycontent="end" paddingRight="0.5rem">
                                                    <Button icon={iconsTypes.x} type="secondary" variant="text" onClick={ closePicker } grow='0' className={css.CloseIcon} />
                                                </Row>
                                                <ChromePicker color={ hasBgColor ? item.bgColor : "#FFFFFF" } onChange={ (color: ColorResult, event: React.ChangeEvent<HTMLInputElement>) => onColorSelect(color, event, index, 'bgColor') }/>
                                            </Col>
                                        }
                                    </Row>
                                </Col>
                            </Row>

                            {/* Delete */}
                            <Row alignitems="end">
                                <Button icon={ iconsTypes.delete } type="secondary" variant="text" onClick={ () => deleteRow(index)} />
                            </Row>
                        </Row>
                      );
                    })
                }
            </ScrollContainer>
        </Col>
    </>
};

export { ModuleReports };
