import { FC, useState } from 'react';
import { Row, Cell } from 'lavaa';
import { Button } from 'lavaa';
import { Dropdown } from "lavaa";
import { iconsTypes } from 'lavaa';
import { useRulesets } from '../../Hooks/UseRulesets';
import { CreateNewRuleModalActionButton } from "../Custom/Modals/CreateNewRuleModal/CreateNewRuleModalActionButton.component";
import { useRulesetData } from "../../Hooks/UseRulesetData";
import css from "./Ruleset.module.scss";

interface IProps {
    sortData: Array<any>
    onSort: (option: any) => void,
    filterData: Array<any>,
    onFilter: (option: any) => void,
}

// Ruleset Header
const RulesetHeader: FC<IProps> = (props) => {

    const { sortData, onSort, filterData, onFilter} = props;

    // States
    const [sortSelected, setSortSelected] = useState(null);
    const [filterSelected, setFilterSelected] = useState(null);

    const { getActiveRulesetData, publishRuleset } = useRulesets();
    const { addRulesetDataOutput } = useRulesetData();
    const activeRulesetData = getActiveRulesetData();

    const onSelectSort = (data: any) => {
        setSortSelected(data);
        onSort(data);
    };

    const onSelectFilter = (data: any) => {
        setFilterSelected(data);
        onFilter(data);
    };

    function publishHandler(){
        publishRuleset(activeRulesetData.projectUID, activeRulesetData.moduleUID, activeRulesetData.nodeId);
        addRulesetDataOutput(`Publishing a ruleset "${activeRulesetData.name}"`);
    }

    return (
        <Row className={ css.RulesetLayoutHeader } paddingBottom="1rem" paddingTop="1rem">

            {/* Add new Rule Button */}
            <Row>
                <CreateNewRuleModalActionButton icon={iconsTypes.playCircle} text="Add New Rule" type="secondary"/>
            </Row>

            <Row grow={"1"} justifycontent={"center"}>

                {/* Rule Category Dropdown */}
                <Cell paddingRight="0.5rem">
                    {/* Rule Type Dropdown */}
                    <Dropdown icon={iconsTypes.filter} placeholder="Rule category:" data={ filterData } selected={filterSelected} onSelect={onSelectFilter}></Dropdown>
                </Cell>

                {/* Sort Dropdown */}
                <Cell>
                    <Dropdown icon={iconsTypes.sort} placeholder="Sort by:" data={ sortData } selected={sortSelected} onSelect={onSelectSort}></Dropdown>
                </Cell>
            </Row>

            {/* Right Side */}
            <Row>

                {/* Publish */}
                <Row>
                    <Button text="Publish" type="secondary" onClick={publishHandler} />
                </Row>
            </Row>
        </Row>
    );
};

export { RulesetHeader };