import { FC, useContext, useState } from 'react';
import { ModalsCtx } from "../../../../../Context/Modals.context";
import { useModules } from '../../../../../Hooks/UseModules';
import { Modal } from 'lavaa';
import { ITabsContent, Tabs } from 'lavaa';
import { IFields } from './Interface';
import { ModuleParameters } from './Parameters/ModuleParameters.component';
import { ModuleReports } from '../ModuleReports/ModuleReports.component';
import { useProjects } from '../../../../../Hooks/UseProjects';

// Create New Module Modal
const CreateNewModuleModal: FC = () => {

    const initialParameters: any = { name: '', icon: '', disorder: '', moduleType: '', description: '', additionalInfo: '' };
    const { saveNewModule } = useModules();
    const { getActiveProjectData } = useProjects();
    const activeProjectData = getActiveProjectData();

    // Context
    let { isNewModuleModalActive, setNewModuleModalActive } = useContext(ModalsCtx);

    // States
    const [moduleParameters, setModuleParameters] = useState<IFields>(initialParameters);
    const [moduleReport, setModuleReport] = useState<Array<any>>([]);
    const [isModuleParametersValid, setModuleParametersValid] = useState<boolean>(false);

    // On Module Parameters Validate
    const onModuleParametersValidate = (isValid: boolean) => {
        setModuleParametersValid(isValid);
    };

    // On Report Update
    const onReportUpdate = (data: Array<any>) => {
        setModuleReport([...data]);
    }

    // On Modules Parameters Change
    const onModuleParametersChange = (data: IFields) => {
        setModuleParameters(data);
    };

    // Handle Close
    const handleClose = () => {
        setModuleParameters(initialParameters);
        setModuleReport([]);
        setNewModuleModalActive(false);
    };

    // Handle Create
    const handleCreate = () => {
        saveNewModule({...moduleParameters, projectUID: activeProjectData.primaryId}, moduleReport);
        setModuleParameters(initialParameters);
        setModuleReport([]);
        setNewModuleModalActive(false);
    };

    // Tabs
    const tabs: ITabsContent[] = [
        {
            title: 'Create new module',
            content: <ModuleParameters data={moduleParameters} onChange={onModuleParametersChange} onValidate={onModuleParametersValidate} />
        },
        {
            title: 'Report',
            content: <ModuleReports onReportUpdate={onReportUpdate} initialData={moduleReport} />,
            disabled: !isModuleParametersValid
        },
    ];

    return <Modal
        active={isNewModuleModalActive}
        onClose={handleClose}
        onCancel={handleClose}
        onOk={handleCreate}
        title="Add new module"
        okText="Create"
        cancelText="Cancel"
        type='large'
        hasTab={true}
        height="550px"
        isFormValid={isModuleParametersValid}
    >
        <Tabs content={tabs} />
    </Modal>
};

export { CreateNewModuleModal };
