import { FC } from 'react';
import { Col, Cell } from 'lavaa';
import { Text } from 'lavaa';
import { CreateProjectActionButton } from '../../../Custom/Modals/CreateProjectModal/CreateProjectActionButton.component';
import css from './EmptyProjectsView.module.scss';
import { iconsTypes } from 'lavaa';

interface IProps {}

// EmptyProjectsView
const EmptyProjectsView: FC<IProps> = () => {

    return <Col className={ css.EmptyProjectsView } grow="1" alignitems="center" justifycontent="start">

        {/* Text */}
        <Cell paddingBottom="1rem">
            <Text size="x7" textAlign="center">You don't have any projects yet.</Text>
        </Cell>

        {/* Text */}
        <Cell paddingBottom="1rem">
            <Text size="x7" textAlign="center">Let’s create it from the scratch!</Text>
        </Cell>

        {/* Create New Project */}
        <Cell>
            <CreateProjectActionButton text="Add New Project" icon={iconsTypes.playCircle} />
        </Cell>
    </Col>;
};

export { EmptyProjectsView };