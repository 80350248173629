import { FC, useEffect, useState } from 'react';
import { Row, Col, Text, Icon, iconsTypes } from 'lavaa';
import css from './InputNum.module.scss';

interface IProps {
    min?: number;
    max?: number;
    wrap?: 'col' | 'row';
    label?: string;
    placeholder?: string;
    value: number | string;
    step?: number;
    onChange: (value: number | string) => void;
}

// Input Number
const InputNum: FC<IProps> = (props) => {

    // Props
    const { min = Number.MIN_SAFE_INTEGER, max = Number.MAX_SAFE_INTEGER, wrap = 'row', label, placeholder, value, step = 1, onChange } = props;

    // States
    const [inputValue, setInputValue] = useState<number | string>(value);

    // Vars
    const Wrap: any = wrap === 'row' ? Row : Col;

    // Increment
    const onIncrement = () => {
        const newValue = Number(inputValue) + step <= max ? Number(inputValue) + step : max;
        setInputValue(newValue);
        onChange(newValue);
    };

    // Decrement
    const onDecrement = () => {
        const newValue = Number(inputValue) - step >= min ? Number(inputValue) - step : min;
        setInputValue(newValue);
        onChange(newValue);
    };

    // Handle Input Change
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!/[eE]/.test(e.target.value)) {
            const newValue = Number(e.target.value);
            setInputValue(newValue);
            onChange(newValue);
        }
    };

    // Handle Clear
    const onClear = () => {
        if (inputValue === '') return;
        setInputValue('');
        onChange('');
    };

    // Watch outside changes
    useEffect(() => {
        if (value !== undefined && value !== null && value !== inputValue) {
            setInputValue(value || '');
        }
    }, [value]);

    return <Wrap className={ css.InputBlock } grow="1" data-wraptype={wrap}>

        {/* Label */}
        {
            !label ? null :
            <Row alignitems="center" className={css.Label}>
                <Text className={css.LabelText} size={"x2"}>{label}</Text>
            </Row>
        }

        {/* Input */}
        <Row className={css.InputWrap} grow="1" alignitems="center">
            
            <input 
                type="number" 
                className={css.Input} 
                min={min} 
                max={max} 
                placeholder={placeholder} 
                value={inputValue}
                step={step}
                onChange={handleInputChange}
            />

            {/* Clear Button */}
            <Col className={css.ClearButton} onClick={onClear} data-active={inputValue !== ''}>
                <Icon name={iconsTypes.cancel} size="16" style={{display: 'flex'}} />
            </Col>
            

            {/* Arrows */}
            <Col className={css.NumberControls}>
                
                {/* Up */}
                <Row className={css.Arrow} onClick={onIncrement}>
                    <Icon name={iconsTypes.numberInc} size="16" style={{display: 'flex'}} />
                </Row>

                {/* Down */}
                <Row className={css.Arrow} onClick={onDecrement}>
                    <Icon name={iconsTypes.numberDec} size="16" style={{display: 'flex'}} />
                </Row>
            </Col>
        </Row>
    </Wrap>;
};

export { InputNum };