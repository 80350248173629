import { useEffect, useState } from 'react';
import css from './ThemeSwitcher.module.scss';
import { useTheme } from '../../../Hooks/UseTheme';
import { Row, Cell } from 'lavaa';
import { Icon, iconsTypes } from 'lavaa';
import { Text } from 'lavaa';

// ThemeSwitcher
const ThemeSwitcher = () => {

    const { getTheme, changeTheme } = useTheme();
    const [theme, setTheme] = useState<any>('');

    // Handle Toggle
    const handleToggle = (value: 'light' | 'dark') => {
        console.log(value);
        changeTheme(value)
        setTheme(value);
    };


    useEffect(() => {
        getTheme().then( (value: any) => setTheme(value) );
    }, []);

    return (
        <Row paddingRight='1.5rem' paddingLeft='1.5rem' paddingTop='1rem' justifycontent='space-between' className={css.ThemeSwitcher} data-theme={theme}>
            <Row grow='1' className={css.Panel} paddingTop='0.5rem' paddingBottom='0.5rem' paddingLeft='1.25rem' paddingRight='1.25rem' onClick={() => handleToggle('light')}>
                <Cell paddingTop='0' paddingBottom='0' paddingLeft='0' paddingRight='0.75rem' className={css.Icon}>
                    <Icon name={iconsTypes.sun}/>
                </Cell>
                <Text text='Light' size='x2' bold='true' className={css.Label}/>
            </Row>
            <Row grow='1' className={css.Panel} paddingTop='0.5rem' paddingBottom='0.5rem' paddingLeft='1.25rem' paddingRight='1.25rem' onClick={() => handleToggle('dark')}>
                <Cell paddingTop='0' paddingBottom='0' paddingLeft='0' paddingRight='0.75rem' className={css.Icon}>
                    <Icon name={iconsTypes.moon}/>
                </Cell>
                <Text text='Dark' size='x2' bold='true' className={css.Label}/>
            </Row>
        </Row>
    );
};

export { ThemeSwitcher };