import { FC, useEffect } from "react";
import { ProjectsPage } from "../../Common/Projects/Projects.component";
import { CreateProjectModal } from '../../Custom/Modals/CreateProjectModal/CreateProjectModal.component';
import { DeleteProjectConfirm } from "../../Custom/Modals/DeleteProjectConfirm/DeleteProjectConfirm.component";
import { useProjects } from "../../../Hooks/UseProjects";
import { projectAccessType } from "../../../Redux/Slices/Projects/Projects.data";

// MyProjects
const MyProjects: FC<any> = () => {

  const { fetchProjects, addMyProject, myProjects } = useProjects();

  // Did Mount
  useEffect(() => {
    fetchProjects({ accessType: projectAccessType.my });
  }, []);

  const filterData = [
    { id: '001', value: 'Diabetes Type 2', key: 'Diabetes Type 2', filterBy: 'projectType' },
    { id: '002', value: 'LAVAA ADA+', key: 'LAVAA ADA+', filterBy: 'projectType' },
    { id: '003', value: 'Hypertension', key: 'Hypertension', filterBy: 'projectType' },
    { id: '004', value: 'Diabetes Type 1', key: 'Diabetes Type 1', filterBy: 'projectType' },
    { id: '005', value: 'Thyroid disorders', key: 'Thyroid disorders', filterBy: 'projectType' },
    { id: '006', value: 'Intervertebral hernia', key: 'Intervertebral hernia', filterBy: 'projectType' },
    { id: '007', value: 'Show All', key: 'Show All', filterBy: '' }
  ];

  // Handle Project Create
  const handleProjectCreate = (data: any) => {
    addMyProject(data);
  };

  return <>

    {/* Projects Page */}
    <ProjectsPage projectsType={projectAccessType.my} pageTitle="All my projects" locationPath="myprojects" filterData={filterData} options={myProjects} />

    {/* Create New Project Modal */}
    <CreateProjectModal onCreate={handleProjectCreate} />

    {/* Delete Project Confirm */}
    <DeleteProjectConfirm />
  </>
};

export { MyProjects };
