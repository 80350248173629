import { FC } from 'react';
import { Row, Cell } from 'lavaa';
import { Title } from 'lavaa';
import { ProjectsFilter } from '../ProjectsFilter/ProjectsFilter.component';
import { ProjectsSort } from '../ProjectsSort/ProjectsSort.component';
import { CreateProjectActionButton } from '../../../Custom/Modals/CreateProjectModal/CreateProjectActionButton.component';
import { iconsTypes } from 'lavaa';
import css from './ProjectsTopBar.module.scss';

interface IProps {
    onSearch: (value: string) => void,
    searchValue?: string,
    onFilter: (option: any) => void,
    onSort: (option: any) => void,
    onViewAll: () => void,
    pageTitle: string,
    filterData: Array<any>,
    sortData: Array<any>,
    isCreateNewProjectActive: boolean
}

// ProjectsTopBar
const ProjectsTopBar: FC<IProps> = (props) => {

    let { pageTitle, onSearch, searchValue, onFilter, onSort, onViewAll, filterData, sortData, isCreateNewProjectActive = false } = props;

    return <Row className={ css.ProjectsTopBar } alignitems="center">

        {/* Title */}
        <Row grow="1" style={{whiteSpace: 'nowrap'}}>
            <Title className={ css.Title } size='x6'>{ pageTitle }</Title>
        </Row>

        {/* Projects Filter */}
        <ProjectsFilter onSearch={ onSearch } searchValue={ searchValue } onFilter={ onFilter } filterData={ filterData } onViewAll={ onViewAll } />

        {/* Projects Sort */}
        <ProjectsSort onSort={ onSort } sortData={ sortData } />

        {/* Create New Project */}
        {
            // isCreateNewProjectActive &&
            <Cell shrink="0">
                <CreateProjectActionButton text="Create New Project" icon={iconsTypes.plus} />
            </Cell>
        }
    </Row>;
};

export { ProjectsTopBar };