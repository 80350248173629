import { iconsTypes } from 'lavaa';
import { useRules } from "../UseRules";

export function prepareRulesAsMenuItems(rules: any[], nodesType: string) {
    return rules.map((rule) => {
        return {
            id: rule.uid,
            uid: rule.uid,
            label: rule.name,
            name: rule.name,
			isEditEnabled: rule.isEditEnabled,
			description: rule.description,
			ruleCategoryID: rule.ruleCategoryID,
            node: {
                primaryId: rule.primaryId || rule.uid,
                type: nodesType,
                disorderTypeID: rule.disorderTypeID,
                createdByCompany: rule.createdByCompany
            }
        }
    })
}

export const prepareRulesAsSearchItems = (rules: any[]) => rules.map((rule) => (rule.name));

const useMenuRules = (): any[] => {
	const { myRules, getRulesTemplates } = useRules();
	const rulesTemplates = getRulesTemplates();

	const menuMyRules = prepareRulesAsSearchItems(myRules);
	const menuCommonRules = prepareRulesAsSearchItems(rulesTemplates);

    return [
		{
			id: 'tree-node-1',
			label: 'Open Rules',
			icon: iconsTypes.commonRules,
			endIcon: iconsTypes.flowPlus,
			action: 'activateCommonRulesModal',
			searchBy: menuCommonRules
		},
		{
			id: 'tree-node-2',
			label: 'Company Rules',
			icon: iconsTypes.companyModule,
			endIcon: iconsTypes.flowPlus,
			action: 'activateCompanyRulesModal',
			searchBy: menuCommonRules
		},
		{
			id: 'tree-node-5',
			label: 'My Rules',
			icon: iconsTypes.myRules,
			endIcon: iconsTypes.flowPlus,
			action: 'activateMyRulesModal',
			searchBy: menuMyRules
		}
    ];
};

export { useMenuRules };
