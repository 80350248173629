import { FC, useContext, useState } from 'react';
import { ModalsCtx } from "../../../../Context/Modals.context";
import { useModules } from '../../../../Hooks/UseModules';
import { useRulesets } from '../../../../Hooks/UseRulesets';
import { Modal } from 'lavaa';
import { IFields } from './Interface';
import { RulesetParameters } from './Parameters/RulesetParameters.component';

interface IProps {}

// Create New Ruleset Modal
const CreateNewRulesetModal: FC<IProps> = () => {

    const [rulesetParameters, setRulesetParameters] = useState<IFields>({name: '', disorder: '', description: '', additionalInfo: ''});
    const { addNewModule, getActiveModuleData } = useModules();
    const { addNewRuleset } = useRulesets();

    // Context
    let { isNewRulesetModalActive, setNewRulesetModalActive } = useContext(ModalsCtx);

    // States
    const [isRulesetParametersValid, setRulesetParametersValid] = useState<boolean>(false);

    // On Ruleset Parameters Validate
    const onRulesetParametersValidate = (isValid: boolean) => {
        setRulesetParametersValid(isValid);
    };

    // Handle Close
    const handleClose = () => {
        setNewRulesetModalActive(false);
    };

    // Handle Create
    const handleCreate = () => {
        const { projectUID, nodeId: moduleUID } = getActiveModuleData();
        // console.log('Handle Create', rulesetParameters);

        addNewRuleset({...rulesetParameters, projectUID, moduleUID});
        setNewRulesetModalActive(false);
    };

    // On Ruleset Parameters Change
    const onRulesetParametersChange = (data: IFields) => {
        setRulesetParameters(data);
    };

    // const tabs: ITabsContent[] = [
    //     {
    //         title: 'Create new ruleset',
    //         content: <RulesetParameters data={ rulesetParameters } onChange={ onRulesetParametersChange } />
    //     },
    //     // {
    //     //     title: 'Report',
    //     //     content: <RulesetReport />
    //     // },
    // ];

    return <Modal 
        active={ isNewRulesetModalActive }
        onClose={ handleClose } 
        onCancel={ handleClose }
        onOk={ handleCreate }
        title="Add new ruleset"
        okText="Create"
        cancelText="Cancel"
        type='large'
        isFormValid={isRulesetParametersValid}
        // hasTab={true}
    >
        {/* <Tabs content={tabs} /> */}
        <RulesetParameters data={ rulesetParameters } onChange={ onRulesetParametersChange } onValidate={onRulesetParametersValidate} />
    </Modal>
};

export { CreateNewRulesetModal };