import { FC, useEffect, useRef, useState } from "react";
import { useProjectTemporary } from "../../Hooks/UseProjectTemporary";
import { FlowOutput, IMultiTabsContent, MultiTabs } from 'lavaa';
import { useProjectReport } from "../../Hooks/UseProjectReport";
import { useProjects } from "../../Hooks/UseProjects";
import { IProjectReportData } from "../../Redux/Slices/ProjectReport/ProjectReport.slice";
import { ProjectReport } from "../Custom/ProjectReport/ProjectReport.component";

// Project Output
const ProjectOutput: FC<any> = () => {

	const { projectDataOutput } = useProjectTemporary();
	const { reportsData, deleteProjectReport } = useProjectReport();

	const { getActiveProjectData } = useProjects();
	const projectData = getActiveProjectData();
	const uid = (projectData) ? projectData.uid ?? null : null;

	const reportsDataFiltered = getFilteredReportsData();
	const reportsLengthRef = useRef<number>(getFilteredReportsData().length);
	const [activeTab, setActiveTab] = useState({ tab: 0, subTab: 0 });

	useEffect(() => {
		if (reportsDataFiltered.length > reportsLengthRef.current) {
			setActiveTab({ tab: 1, subTab: reportsDataFiltered.length - 1 });
		}
		reportsLengthRef.current = reportsDataFiltered.length;
	}, [reportsDataFiltered]);

	function getFilteredReportsData() {
		return reportsData.filter((report: IProjectReportData) => report.projectUID === uid);
	}

	function closeSubTab(flowUID: string, nodeUID: string) {
		deleteProjectReport(flowUID, nodeUID);
	}

	const tabs: IMultiTabsContent[] = [
		{
			title: 'Output',
			content: <FlowOutput logs={projectDataOutput} />
		},
	];

	if (reportsDataFiltered.length > 0) {
		const subtabs = reportsDataFiltered.map((report: IProjectReportData) => {
			const date = new Date(report.initialDate);

			const strDate = date.toLocaleDateString("en-US")

			let hours = date.getHours();
			const minutes = date.getMinutes();
			const ampm = hours >= 12 ? 'pm' : 'am';
			hours = hours % 12;
			hours = hours ? hours : 12;
			const minutesStr = (minutes < 10) ? `0${minutes.toString()}` : minutes.toString();
			const strTime = hours + ':' + minutesStr + ' ' + ampm;

			return (
				{
					dateTitle: {
						date: strDate,
						time: strTime
					},
					content: <ProjectReport reportData={report} />,
					scroll: false,
					onClose: () => closeSubTab(report.flowUID, report.nodeUID)
				}
			);
		});

		tabs.push(
			{
				title: 'Real Time Report',
				children: subtabs
			}
		);
	}

	return (
		<MultiTabs content={tabs} hasFullScreen={true} activeTab={activeTab} />
	);
};

export { ProjectOutput };
