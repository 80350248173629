import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getRulesetsAction, getRulesetAction, updateRulesetAction, publishRulesetAction, addRulesetAction, resetRulesetsAction, deleteRulesetAction } from "../Redux/Slices/Rulesets/Rulesets.slice";
import { rulesetAccessType } from "../Redux/Slices/Rulesets/Rulesets.data";
import { getRulesByParentAction } from "../Redux/Slices/Rules/Rules.slice";
import { useRulesetData } from "./UseRulesetData";

export const useRulesets = (): any => {
    const { activeRulesetId } = useParams();
    const { addRulesetDataOutput } = useRulesetData();

    const openRulesets = useSelector((state: any) => state.rulesets.templates.open);
    const companyRulesets = useSelector((state: any) => state.rulesets.templates.company);
    const myRulesets = useSelector((state: any) => state.rulesets.templates.my);
    const functionsRulesets = useSelector((state: any) => state.rulesets.templates.functions);
    const nodes = useSelector((state: any) => state.rulesets.nodes);
    const rulesetsOnLoading = useSelector((state: any) => state.rulesets.onLoading);

    const dispatch = useDispatch();

    // Get Ruleset Template
    const getRulesetTemplate = (uid: string) => {
        return [...openRulesets, ...companyRulesets, ...myRulesets].find((item: any) => item.primaryId === uid);
    };

    // Get Ruleset Node
    const getRulesetNode = (uid: string = '') => {
        return uid ? (nodes[uid] || null) : null;
    };

    // Get Active Ruleset
    const getActiveRulesetData = () => {
        return getRulesetNode(activeRulesetId);
    };

    // Fetch Rulesets
    const fetchRulesets = () => {
        dispatch(getRulesetsAction([null, rulesetAccessType.open, 0, 0, 200, 'name', true]));
        dispatch(getRulesetsAction([null, rulesetAccessType.company, 0, 0, 200, 'name', true]));
        dispatch(getRulesetsAction([null, rulesetAccessType.my, 0, 0, 200, 'name', true]));
        dispatch(getRulesetsAction([null, rulesetAccessType.functions, 0, 0, 200, 'name', true]));
    };

    // Fetch Ruleset by uid
    const fetchRuleset = (projectId: string, moduleId: string, rulesetId: string) => {
        dispatch(getRulesetAction([projectId, moduleId, rulesetId]));
        dispatch(getRulesByParentAction([projectId, moduleId, rulesetId]));
    };

    // Add New Ruleset
    const addNewRuleset = (data: any) => {
        const { name, disorder, moduleTypeID = 1, description, additionalInfo, projectUID, moduleUID, jsonLogic = {}, createdByCompany = '0086ee63-e65f-4f7b-a243-155667de29d7'} = data;

        // Dispatch Add Ruleset
        dispatch(addRulesetAction([{
            name,
            description,
            additionalInfo,
            disorderTypeID: disorder.disorderTypeID,
            moduleTypeID,

            accessType: 3, // 1 - open, 2 - company, 3 - my
            projectUID,
            jsonLogic,
            createdByCompany,
            moduleUID
        }]));
    };

    // Update Ruleset
    const updateRuleset = (data: any) => {
        dispatch(updateRulesetAction([data]));
    };

    // Update Rule Score in Ruleset
    const updateRuleScore = (ruleId: string, score: number) => {
        const ruleset = getActiveRulesetData();
        const jsonLogic = ruleset.jsonLogic.map(
          (rule: any) =>
            (rule.ruleUID !== ruleId)
              ? rule
              : Object.assign({}, rule, {score: score})
        );
        const newRuleset = Object.assign({}, ruleset, {jsonLogic: jsonLogic});
        dispatch(updateRulesetAction([newRuleset]));
    };

    // Update Rule isEnabled in Ruleset
    const updateRuleIsEnabled = (ruleId: string, isEnabled: boolean) => {
        const ruleset = getActiveRulesetData();
        const jsonLogic = ruleset.jsonLogic.map(
          (rule: any) =>
            (rule.ruleUID !== ruleId)
              ? rule
              : Object.assign({}, rule, {isEnabled: isEnabled})
        );
        const newRuleset = Object.assign({}, ruleset, {jsonLogic: jsonLogic});
        dispatch(updateRulesetAction([newRuleset]));
        addRulesetDataOutput(`Saving rule state`);
    };

    // Add Rule in Ruleset
    const addRuleToRuleset = (ruleId: string, score: number | null) => {
        const ruleset = getActiveRulesetData();
        const jsonLogic = [...ruleset.jsonLogic];
        jsonLogic.push({
            score: score,
            ruleUID: ruleId,
            isEnabled: true
        });
        const newRuleset = Object.assign({}, ruleset, {jsonLogic: jsonLogic});
        dispatch(updateRulesetAction([newRuleset]));
    };

    // Publish Ruleset
    const publishRuleset = (projectId: string, moduleId: string, rulesetId: string) => {
        dispatch(publishRulesetAction([projectId, moduleId, rulesetId]));
    };

    // Delete Ruleset
    const deleteRuleset = (uid: string) => {
        dispatch(deleteRulesetAction([uid]));
    };

    // Delete Rule from Ruleset
    const deleteRuleFromRuleset = (ruleId: string) => {
        const ruleset = getActiveRulesetData();
        const jsonLogic = ruleset.jsonLogic.filter((rule: any) => rule.ruleUID !== ruleId);
        const newRuleset = Object.assign({}, ruleset, {jsonLogic: jsonLogic});
        dispatch(updateRulesetAction([newRuleset]));
        addRulesetDataOutput(`Deleting rule`);
    };

    // Reset Rulesets
    const resetRulesets = () => {
        dispatch(resetRulesetsAction());
    };

    return {
        getRulesetTemplate, getActiveRulesetData, deleteRuleset, updateRuleset,
        fetchRulesets, fetchRuleset,
        openRulesets, companyRulesets, myRulesets, functionsRulesets, rulesetsOnLoading,
        addNewRuleset, updateRuleScore, publishRuleset, addRuleToRuleset, deleteRuleFromRuleset, updateRuleIsEnabled,
        resetRulesets
    };
}
