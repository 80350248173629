import { FC } from 'react';
import css from '../EditRuleModal.module.scss';
import { Row, Cell, withoutPaddings } from 'lavaa';
import { Text } from 'lavaa';
import { IRuleType } from "../../../../../Redux/Slices/Rules/Rules.data";

interface IProps {
  rule: IRuleType,
  isTemplate: boolean
}

// Header Content
const HeaderContent: FC<IProps> = ( props ) => {

  const { rule, isTemplate } = props;

	const score = (rule.score !== undefined && rule.score !== null)
		? rule.score >= 1000 ? '999+' : (rule.score ?? "").toString()
		: "";

  return(
      <Row className={css.Header} alignitems="center" grow="1">
        <Text bold='true' text={rule.name} size='x6' className={css.Title} />
        {
          !isTemplate && (
            <Row alignitems='center' paddingLeft='1.75rem'>
              <Text bold='true' text='Score:' size='x2' />
              <Cell paddingLeft='0.25rem' paddingBottom='0' paddingTop='0' paddingRight='0'>
                <Cell className={css.Score} alignitems='center' justifycontent='center' {...withoutPaddings}>
                  <Text bold='true' text={ score } size='x1' textAlign='center' className={css.ScoreText}/>
                </Cell>
              </Cell>
            </Row>
          )
        }
      </Row>
  )
};

export { HeaderContent };
