import { FC, useEffect, useState } from "react";
import { SourceNode } from '../Custom/Flow/Node/NodeTypes/SourceNode.component';
import { DestinationNode } from '../Custom/Flow/Node/NodeTypes/DestinationNode.component';
import { FunctionNode } from '../Custom/Flow/Node/NodeTypes/FunctionNode.component';
import { MyNode } from '../Custom/Flow/Node/NodeTypes/MyNode.component';
import { HealthNode } from '../Custom/Flow/Node/NodeTypes/HealthNode.component';
import { ClinicalResearchNode } from '../Custom/Flow/Node/NodeTypes/ClinicalResearchNode.component';
import { useProjects } from '../../Hooks/UseProjects';
import { useProjectTemporary } from "../../Hooks/UseProjectTemporary";
import { FlowV11 } from "../FlowV11/FlowV11.component";
import * as React from "react";

const nodeTypes = {
  source: SourceNode,
  destination: DestinationNode,
  function: FunctionNode,
  my: MyNode,
  health: HealthNode,
  clinicalResearch: ClinicalResearchNode
};

interface IProps {
}

// ModulesFlow
const ModulesFlow: FC<IProps> = () => {

  const { getActiveProjectData, updateProject, publishProject, runProject } = useProjects();
  const { addProjectDataOutput, runState } = useProjectTemporary();
  const activeProjectData = getActiveProjectData();
	const [isRunning, setIsRunning] = useState(false);
	const isRunningRef = React.useRef(runState.isRunning);

	useEffect(() => {
		setIsRunning(runState.isRunning);
		isRunningRef.current = runState.isRunning;
	}, [runState]);

  function saveHandler(data: any, autosave: boolean) {
		// Disable action when project is running
		if(isRunningRef.current ) return;

    updateProject({...data});

    if(autosave){
      addProjectDataOutput(`Auto-saving project "${activeProjectData.name}"`);
    }
    else{
      addProjectDataOutput(`Save project "${activeProjectData.name}"`);
    }
  }

  function publishHandler() {
		// Disable action when project is running
		if(isRunningRef.current ) return;

    publishProject(activeProjectData.uid);
  }

  function runHandler() {
		// Disable action when project is running
		if(isRunningRef.current ) return;

    runProject(activeProjectData.uid);
  }

  return(
    <FlowV11 type="module" flowData={activeProjectData} nodeTypes={nodeTypes} onSave={saveHandler} onPublish={publishHandler} onRun={runHandler} isDisabled={isRunning}/>
  )
};

export { ModulesFlow };
