import { NavLink } from "react-router-dom";
import { Row } from 'lavaa';
import { classNames } from "../../Tools";
import css from './MainMenu.module.scss';

// MainMenu
const MainMenu = () => {

    // Is  Active
    const isActive = ({isActive}: any) => {
        return classNames(css.MenuItem, isActive ? css.Active : '')
    };

    return <Row className={ css.MainMenu } alignitems="end">

        {/* Site */}
        <a href="//www.lavaa.health" className={css.MenuItem} target="_blank">Site</a>

        {/* My Projects */}
        <NavLink to="myprojects" className={isActive}>My Projects</NavLink>
        
        {/* My Company Projects */}
        <NavLink to="mycompanyprojects" className={isActive}>My Company Projects</NavLink>
        
        {/* Open Projects */}
        <NavLink to="openprojects" className={isActive}>Open Projects</NavLink>
    </Row>;
};

export { MainMenu };