import { FC } from 'react';
import { ITabsContent, Tabs } from 'lavaa';
import { FlowOutput } from "lavaa";
import { useRulesetData } from "../../Hooks/UseRulesetData";

// Ruleset Output
const RulesetOutput: FC<any> = () => {

  const { rulesetOutput } = useRulesetData(true);

  const tabs: ITabsContent[] = [
    {
      title: 'Output',
      content: <FlowOutput logs={rulesetOutput} />
    },
  ];

  return (
      <Tabs content={tabs} />
  );
};

export { RulesetOutput };