import { FC, useContext } from "react";
import { NodeProps } from 'reactflow';
import { Node } from '../Node.component';
import { ModalsCtx } from "../../../../../Context/Modals.context";
import { FilterNodeModalType } from "../../../../../Context/Modals.types";

export const FunctionRulesetNode: FC<NodeProps> = ( props) => {

	let setFilterNodeModal: ((value: FilterNodeModalType) => void) = useContext(ModalsCtx).setFilterNodeModal;
  	let { id } = props;

  	const onDoubleClick = () => {
		setFilterNodeModal({
				isActive: true,
				uid: id,
				type: "ruleset"
			}
		);
  	}

  	return <Node {...props} type="functionRuleset" label="Function" inputs={ 1 } outputs={ 1 } onDoubleClick={onDoubleClick} />
};
