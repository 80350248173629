import { FC, useEffect, useState } from 'react';
import { Row, Modal } from 'lavaa';
import { GuidelinePrioritySelector } from '../GuidelinePrioritySelector/GuidelinePrioritySelector.component';
import { GuidelineSearch } from '../GuidelineSearch/GuidelineSearch.component';

interface IProps {
    active: boolean,
    onClose: () => void,
    onOk: (guideline: any, priority: any) => void
}

// AddCurrentGuidelineModal
const AddCurrentGuidelineModal: FC<IProps> = (props) => {

    const { active, onClose, onOk } = props;

    // States
    const [guideline, setGuideline] = useState<any>('');
    const [priority, setPriority] = useState<any>(undefined);
    const [isFormValid, setFormValid] = useState(false);

    // Handle Cancel/Close
    const handleClose = () => {
        setGuideline('');
        setPriority(undefined);
        onClose();
    };

    // Handle Ok
    const handleOk = () => {
        setGuideline('');
        setPriority(undefined);
        onOk(guideline, priority);
    };

    // On Select
    const onSelect = (type: string, data: any) => {

        // Guideline
        if (type === 'guideline') {
            setGuideline(data);
        }

        // Priority
        if (type === 'priority') {
            setPriority(data);
        }
    };

    useEffect(() => {

        // Set Valid
        if (guideline && priority && isFormValid === false) {
            setFormValid(true);
        }
    }, [guideline, priority]);

    return (
        <Modal active={active} onClose={handleClose} onCancel={handleClose} onOk={handleOk} isFormValid={isFormValid}
            title="Add Current Guideline" width="660px" height="260px" footerBtnStretch={false}>
            
            {/* Current guideline */}
            <Row paddingBottom="1rem">
                <GuidelineSearch onSelect={ (data: any) => onSelect('guideline', data) } />
            </Row>

            {/* Priority of the guideline */}
            <Row>
                <GuidelinePrioritySelector onSelect={ (data: any) => onSelect('priority', data) } />
            </Row>
        </Modal>
    )
};

export { AddCurrentGuidelineModal };
