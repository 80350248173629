import { createContext, FC, useState } from 'react';
import { filterNodeModalDefault, FilterNodeModalType } from "./Modals.types";

export const ModalsCtx = createContext<{
	[key: string]: any;
}>({});

export const ModalsContext: FC<any> = (props) => {

    const { children } = props;

    // States
    const [ isPatientLabResultModalActive, setPatientLabResultModalActive ] = useState(false);
    const [ patientLabResultModalData, setPatientLabResultModalData ] = useState({});
    const [ isAddCommonRulesModalActive, setAddCommonRulesModalActive ] = useState(false);
    const [ isAddPersonalInfoRulesModalActive, setAddPersonalInfoRulesModalActive ] = useState(false);
    const [ isAddMedicalHistoryRulesModalActive, setAddMedicalHistoryRulesModalActive ] = useState(false);
    const [ isAddLabResultsRulesModalActive, setAddLabResultsRulesModalActive ] = useState(false);
    const [ isAddMyRulesModalActive, setAddMyRulesModalActive ] = useState(false);
    const [ isAddCompanyRulesModalActive, setAddCompanyRulesModalActive ] = useState(false);
    const [ isNewProjectModalActive, setNewProjectModalActive ] = useState(false);
    const [ isEditProjectModalActive, setEditProjectModalActive ] = useState(false);
    const [ isDeleteProjectConfirmActive, setDeleteProjectConfirmActive ] = useState(false);
    const [ deleteProjectUID, setDeleteProjectUID ] = useState('');
    const [ isAddHealthModulesModalActive, setAddHealthModulesModalActive ] = useState(false);
    const [ isAddClinicalResearchModulesModalActive, setAddClinicalResearchModulesModalActive ] = useState(false);
    const [ isAddMyModulesModalActive, setAddMyModulesModalActive ] = useState(false);
    const [ isNewModuleModalActive, setNewModuleModalActive ] = useState(false);
    const [ isEditModuleModalActive, setEditModuleModalActive ] = useState(false);
    const [ editModuleUID, setEditModuleUID ] = useState();
    const [ isNewRulesetModalActive, setNewRulesetModalActive ] = useState(false);
		const [ isEditRulesetModalActive, setEditRulesetModalActive ] = useState(false);
		const [ editRulesetUID, setEditRulesetUID ] = useState();
    const [ isResultsModalActive, setResultsModalActive ] = useState(true);
    const [ isModuleSettingsModalActive, setModuleSettingsModalActive ] = useState(false);
    const [ moduleSettingsUID, setModuleSettingsUID ] = useState('');
    const [ isModuleFilterConditionModalActive, setModuleFilterConditionModalActive ] = useState(false);
    const [ moduleFilterConditionUID, setModuleFilterConditionUID ] = useState('');
    const [ isNewRuleModalActive, setNewRuleModalActive ] = useState(false);
    const [ isEditRuleModalActive, setEditRuleModalActive ] = useState(false);
    const [ isSourceOpenDataModalActive, setSourceOpenDataModalActive ] = useState(false);
    const [ isSourceLocalFileModalActive, setSourceLocalFileModalActive ] = useState(false);
    const [ isRealReportModalActive, setRealReportModalActive ] = useState(false);
    const [ isSourceReportEmailModalActive, setSourceReportEmailModalActive ] = useState(false);
    const [ isSourceMappingModalActive, setSourceMappingModalActive ] = useState(false);
    const [ isDeleteRuleConfirmActive, setDeleteRuleConfirmActive ] = useState(false);
    const [ deleteRuleUID, setDeleteRuleUID ] = useState('');
    const [ isAddOpenRulesetsModalActive, setAddOpenRulesetsModalActive ] = useState(false);
    const [ isAddMyRulesetsModalActive, setAddMyRulesetsModalActive ] = useState(false);
    const [ isMappingModalActive, setMappingModalActive ] = useState(false);
    const [ mappingModalUID, setMappingModalUID ] = useState('');
    const [ mappingModalHeaders, setMappingModalHeaders ] = useState<string[] | null>(null);
    const [ localFileModuleFile, setLocalFileModuleFile ] = useState<any>(null);
		const [ filterNodeModal, setFilterNodeModal ]: [FilterNodeModalType, (( value: ((( prevState: FilterNodeModalType ) => FilterNodeModalType) | FilterNodeModalType) ) => void)] = useState<FilterNodeModalType>(filterNodeModalDefault);
    const [ isDeleteModuleTemplateConfirmActive, setDeleteModuleTemplateConfirmActive ] = useState(false);
    const [ deleteModuleTemplateUID, setDeleteModuleTemplateUID ] = useState('');
    const [ isDeleteRulesetTemplateConfirmActive, setDeleteRulesetTemplateConfirmActive ] = useState(false);
    const [ deleteRulesetTemplateUID, setDeleteRulesetTemplateUID ] = useState('');
    const [ isDeleteRuleTemplateConfirmActive, setDeleteRuleTemplateConfirmActive ] = useState(false);
    const [ deleteRuleTemplateUID, setDeleteRuleTemplateUID ] = useState('');

    // Banch of states
    const context = {

        // Patient Lab Result Modal
        isPatientLabResultModalActive,
        setPatientLabResultModalActive,
        patientLabResultModalData,
        setPatientLabResultModalData,

        // Add Common Rules Modal
        isAddCommonRulesModalActive,
        setAddCommonRulesModalActive,

        // Add Personal Info Rules Modal
        isAddPersonalInfoRulesModalActive,
        setAddPersonalInfoRulesModalActive,

        // Add Medical History Rules Modal
        isAddMedicalHistoryRulesModalActive,
        setAddMedicalHistoryRulesModalActive,

        // Add Lab Results Rules Modal
        isAddLabResultsRulesModalActive,
        setAddLabResultsRulesModalActive,

        // Add My Rules Modal
        isAddMyRulesModalActive,
        setAddMyRulesModalActive,

        // Add Company Rules Modal
        isAddCompanyRulesModalActive, 
        setAddCompanyRulesModalActive,

        // Add Open Rulesets Modal
        isAddOpenRulesetsModalActive,
        setAddOpenRulesetsModalActive,

        // Add My Rulesets Modal
        isAddMyRulesetsModalActive,
        setAddMyRulesetsModalActive,

        // Add Project Modal
        isNewProjectModalActive,
        setNewProjectModalActive,

        // Edit Project Modal
        isEditProjectModalActive,
        setEditProjectModalActive,

        // Delete Project Confirm
        isDeleteProjectConfirmActive,
        setDeleteProjectConfirmActive,
        deleteProjectUID,
        setDeleteProjectUID,

        // Add Health Modules Modal
        isAddHealthModulesModalActive,
        setAddHealthModulesModalActive,

        // Add Clinical Research Modules Modal
        isAddClinicalResearchModulesModalActive, 
        setAddClinicalResearchModulesModalActive,

        // Add My Modules Modal
        isAddMyModulesModalActive,
        setAddMyModulesModalActive,

        // Create New Module Modal
        isNewModuleModalActive,
        setNewModuleModalActive,

        // Edit Module Modal
        isEditModuleModalActive,
        setEditModuleModalActive,
        editModuleUID,
        setEditModuleUID,

        // Create New Ruleset Modal
        isNewRulesetModalActive,
        setNewRulesetModalActive,

				// Edit Ruleset Modal
				isEditRulesetModalActive,
				setEditRulesetModalActive,
				editRulesetUID,
				setEditRulesetUID,

        // Test Result
        isResultsModalActive,
        setResultsModalActive,

        // Module Settings
        isModuleSettingsModalActive,
        setModuleSettingsModalActive,
        moduleSettingsUID,
        setModuleSettingsUID,

        // Module Filter Conditions
        isModuleFilterConditionModalActive,
        setModuleFilterConditionModalActive,
        moduleFilterConditionUID,
        setModuleFilterConditionUID,

        // Add New Rule
        isNewRuleModalActive,
        setNewRuleModalActive,

        // Edit Rule
        isEditRuleModalActive,
        setEditRuleModalActive,

        // Source Open Data Modal
        isSourceOpenDataModalActive,
        setSourceOpenDataModalActive,

        // Source Local File Modal
        isSourceLocalFileModalActive,
        setSourceLocalFileModalActive,

        // Real Report Modal
        isRealReportModalActive,
        setRealReportModalActive,

        // Source Report Email Modal
        isSourceReportEmailModalActive,
        setSourceReportEmailModalActive,

        // Local File Mapping Modal
        isSourceMappingModalActive,
        setSourceMappingModalActive,

        // Delete Rule Confirm
        isDeleteRuleConfirmActive,
        setDeleteRuleConfirmActive,
        deleteRuleUID,
        setDeleteRuleUID,

        // Mapping Modal
        isMappingModalActive,
        setMappingModalActive,
        mappingModalUID,
        setMappingModalUID,
        mappingModalHeaders,
        setMappingModalHeaders,

        // Local File Module
        localFileModuleFile,
        setLocalFileModuleFile,

				// Filter Node Modal
				filterNodeModal,
				setFilterNodeModal,

        // Delete Module Template Confirm
        isDeleteModuleTemplateConfirmActive,
        setDeleteModuleTemplateConfirmActive,
        deleteModuleTemplateUID,
        setDeleteModuleTemplateUID,

        // Delete Ruleset Template Confirm
        isDeleteRulesetTemplateConfirmActive,
        setDeleteRulesetTemplateConfirmActive,
        deleteRulesetTemplateUID,
        setDeleteRulesetTemplateUID,

        // Delete Rule Template Confirm
        isDeleteRuleTemplateConfirmActive,
        setDeleteRuleTemplateConfirmActive,
        deleteRuleTemplateUID,
        setDeleteRuleTemplateUID,
    };

		return <ModalsCtx.Provider value={ {...context} }>
        { children }
    </ModalsCtx.Provider>
};
