import { FC, useRef, useState } from 'react';
import { AutocompleteItemType, /*Dropdown,*/ iconsTypes } from 'lavaa';
import { useGuidelines } from '../../../../Hooks/UseGuidelines';
import { Dropdown } from '../../../Sandbox/Dropdown';

interface IProps {
    onSelect: (data: any) => void
}

// GuidelineSearch
const GuidelineSearch: FC<IProps> = (props) => {

    // States
    const searchRef = useRef<string>("");
    const [selected, setSelected] = useState<AutocompleteItemType>();

    // Hooks
    const { fetchGuidelines, autocompleteData, resetGuidelinesAutocomplete } = useGuidelines();

    // Handle Change
    const onChange = (value: string) => {
        searchRef.current = value;

        if (searchRef.current) {
            fetchGuidelines(searchRef.current);
        } else {
            resetGuidelinesAutocomplete();
        }
    };

    // Handle Select
    const onSelect = (item: any) => {
        const result = autocompleteData.find((i: any) => i.GuidelineUID === item.id);
        
        resetGuidelinesAutocomplete();
        setSelected(item);
        props.onSelect(result);
    };

    const autocompleteList: AutocompleteItemType[] = autocompleteData.map((item: any) => ({
        id: item.GuidelineUID,
        value: item.Text
    }));

    return <Dropdown 
        data={autocompleteList} 
        label="*Current guideline" 
        wrap="col" 
        onSelect={onSelect}
        selected={selected}
        autocomplete={true}
        placeholder="Current guideline"
        onType={onChange}
    />
};

export { GuidelineSearch };
