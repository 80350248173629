import { useDispatch, useSelector } from 'react-redux';
import {
	deleteProjectReportAction,
	getClientsForRTReportAction,
	setProjectReportLoadingAction,
	setPageAction,
	setSortingAction,
	resetProjectReportsAction, resetProjectReportsClientsAction
} from "../Redux/Slices/ProjectReport/ProjectReport.slice";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export const useProjectReport = (needControl: boolean = false): any => {
    const dispatch = useDispatch();

		const { activeProjectId } = useParams();

		useEffect(() => {
			if(needControl && activeProjectId === undefined) resetProjectReportsClients();
		}, [activeProjectId]);

    // Selectors
    const reportsData = useSelector((state: any) => state.projectReport.data);
    const lastReportData = useSelector((state: any) => state.projectReport.data.length ? state.projectReport.data[state.projectReport.data.length-1] : {});
    const projectReportLoading = useSelector((state: any) => state.projectReport.loading);

    // Fetch Project
    const fetchProjectReport = (projectUID: string, flowUID: string, nodeUID: string, page: number = 0, sortBy: string = '', sortType: null | 'asc' | 'desc' = null) => {
			dispatch(getClientsForRTReportAction([projectUID, flowUID, nodeUID, page || 0, 25, sortBy, sortType === 'desc', '']));
    };

		// Go To Page
		const goToPage = (projectUID: string, flowUID: string, nodeUID: string, page: number) => {
			if (flowUID) {
				dispatch(setProjectReportLoadingAction());
				dispatch(setPageAction({flowUID, nodeUID, page}));

				// Fetch
				fetchProjectReport(projectUID, flowUID, nodeUID, page);
			}
		};

    // Set Sorting
    const setSorting = (projectUID: string, flowUID: string, nodeUID: string, page: number, sortBy: string, sortType: null | 'asc' | 'desc') => {
        dispatch(setProjectReportLoadingAction());
        dispatch(setSortingAction({flowUID, nodeUID, sortBy, sortType}));

        // Fetch
        fetchProjectReport(projectUID, flowUID, nodeUID, page, sortBy, sortType);
    };

    // Reset Project Report
    const deleteProjectReport = (flowUID: string, nodeUID: string) => {
        dispatch(deleteProjectReportAction({flowUID, nodeUID}));
    };

    // Reset Project Report
    const resetProjectReports = () => {
        dispatch(resetProjectReportsAction());
    };

		// Reset Clients data
		const resetProjectReportsClients = () => {
			dispatch(resetProjectReportsClientsAction());
		};

    return {
        fetchProjectReport,
        projectReportLoading,
        lastReportData,
        reportsData,
				goToPage,
        setSorting,
        deleteProjectReport,
        resetProjectReports
    };
}
