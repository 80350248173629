import { useDispatch, useSelector } from "react-redux";
import {
	deleteLocalFileAction,
	getClientSchemeAction,
	getClientMappingSchemeAction,
	uploadLocalFileAction,
	resetClientsAction,
	getClientResultsSchemeAction
} from "../Redux/Slices/Clients/Clients.slice";

export const useClients = (): any => {

	const clientsData = useSelector( ( state: any ) => state.clients );
	const clientScheme = useSelector( ( state: any ) => state.clients ).scheme;
	const clientResultsScheme = useSelector( ( state: any ) => state.clients ).resultsScheme;
	const clientMappingScheme = useSelector( ( state: any ) => state.clients ).mappingScheme;
	const lastUploadedFile = useSelector( ( state: any ) => state.clients ).lastUploadedFile;

	const dispatch = useDispatch();

	// Get Client Scheme
	const getClientScheme = () => {
		dispatch( getClientSchemeAction( [] ) );
	};

	// Get Client Mapping Scheme
	const getClientMappingScheme = () => {
		dispatch( getClientMappingSchemeAction( [] ) );
	};

	// Get Client Results Scheme
	const getClientResultsScheme = () => {
		dispatch( getClientResultsSchemeAction( [] ) );
	};

	// Upload Local File
	const uploadLocalFile = (fileBase64: string) => {
		dispatch(uploadLocalFileAction(fileBase64));
	};

	// Upload Local File
	const deleteLocalFile = (fileName: string) => {
		dispatch(deleteLocalFileAction(fileName));
	};

	// Reset Clients
	const resetClients = () => {
		dispatch(resetClientsAction());
	}

	return {
		getClientScheme, getClientMappingScheme, getClientResultsScheme,
		uploadLocalFile, deleteLocalFile,
		clientsData, clientScheme, clientMappingScheme, clientResultsScheme, lastUploadedFile,
		resetClients
	};
}
