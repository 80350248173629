import { FC, useEffect, useState } from "react";
import { Col, IValidationResult, Row } from "lavaa";
import { Input } from "lavaa";
import { CSVInlineLoader } from "lavaa";
import { MappingRowType, MappingSelector } from "../../../Common/MappingSelector/MappingSelector.component";
import { useClients } from "../../../../Hooks/UseClients";
import { prepareHeadersAsMapping, prepareSchemeToSelect } from "../../../../Tools/schemeData";

export type MappingFormType = {
  name: string,
  description: string,
  mappingJSON: MappingRowType[]
}

interface IProps {
  initValues: MappingFormType,
  fileNameInit?: string,
  onChange: (newModuleData: any) => void,
  onFileLoad: (file: any) => void,
  onValidate: (value: boolean) => void
}

// Mapping Form
const MappingForm: FC<IProps> = (props) => {

  const { onChange, fileNameInit, initValues, onFileLoad } = props;
  const { clientMappingScheme } = useClients();

  // States
  const [inputs, setInputs] = useState<MappingFormType>(initValues);
  const [fields, setFields] = useState<any[]>([]);
  const [fileName, setFileName] = useState<string | undefined>(fileNameInit);
  const [isNameValid, setIsNameValid] = useState(false);
  const [isDescriptionValid, setIsDescriptionValid] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  // On Validate
  const onValidate = (data: IValidationResult) => {
    const { name, error, valid } = data;

    // Module Name
    if (name === 'mapName' && isNameValid !== valid) {
      setIsNameValid(valid);
    }

    // Module Description
    if (name === 'mapDescription' && isDescriptionValid !== valid) {
      setIsDescriptionValid(valid);
    }
  };

  // Watch form valid
  useEffect(() => {

    // Set Form as Valid
    if (isNameValid && isDescriptionValid && !isFormValid) {
        setIsFormValid(true);
        props.onValidate && props.onValidate(true);
    }

    // Set Form as Not Valid
    if ((!isNameValid || !isDescriptionValid) && isFormValid) {
        setIsFormValid(false);
        props.onValidate && props.onValidate(false);
    }
}, [isNameValid, isDescriptionValid]);

  useEffect(() => {
    setFields(prepareSchemeToSelect(clientMappingScheme));
  }, [clientMappingScheme]);

  // Handle Name Input
  const handleName = (value: any) => {
    const newInputs = { ...inputs, name: value };
    setInputs(newInputs);
    onChange(newInputs);
  };

  // Handle Description
  const handleDescription = (value: any) => {
    const newInputs = { ...inputs, description: value };
    setInputs(newInputs);
    onChange(newInputs);
  };

  // Handle Mapping Change
  const handleModuleMapping = (mapping: MappingRowType[]) => {
    const newInputs = { ...inputs, mappingJSON: mapping };
    setInputs(newInputs);
    onChange(newInputs);
  };

  function uploadHandler(data: any, file: File) {
    setFileName(file.name);
    let header = data[0] as Array<string>;

    const mapping = prepareHeadersAsMapping(header, fields);
    const newInputs = { ...inputs, mappingJSON: mapping };
    setInputs(newInputs);
    onChange(newInputs);
    onFileLoad(file);
  }

  function clearFileInputHandler() {
    setFileName(undefined);
    onFileLoad(null);
  }

  return (
    <Col paddingTop="1rem" paddingBottom="1rem" grow="1">

      {/* Name */}
      <Row grow="1" paddingBottom="1rem">
        <Input type="text" name="mapName" wrap="col" label="*Mapping name" placeholder="Mapping Name, max length is 50 characters" onChange={handleName} value={inputs.name} maxlength={50} validation="required" onValidate={onValidate} />
      </Row>

      {/* Description */}
      <Row grow="1" paddingBottom="1rem">
        <Input type="textarea" name="mapDescription" wrap="col" label="*Mapping description" placeholder="Mapping description, max length is 200 characters" onChange={handleDescription} value={inputs.description} maxlength={200} validation="required" onValidate={onValidate} />
      </Row>

      <Row grow="1" paddingBottom="1.75rem">
        <CSVInlineLoader onUpload={uploadHandler} onClear={clearFileInputHandler} fileName={fileName} label="Add file" />
      </Row>
      <Row grow="1" paddingBottom="1rem">
        <MappingSelector mapping={inputs.mappingJSON} fields={fields} onChange={handleModuleMapping} />
      </Row>
    </Col>
  )
};

export { MappingForm };
