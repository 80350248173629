import { receiveProjectAddCallback, receiveProjectRunCallback } from "../Slices/Projects/Projects.callbacks";
import { receiveLoginCallback, receiveRefreshTokenCallback, receiveUserInfoCallback } from '../Slices/Account/Account.callbacks';
import { receiveRuleCloneCallback } from "../Slices/Rules/Rules.callbacks";
import { receivePublishRulesetCallback, receiveAddRulesetCallback } from "../Slices/Rulesets/Rulesets.callbacks";
import { receiveAddModuleCallback, receivePublishModuleCallback } from "../Slices/Modules/Modules.callbacks";

export const Messages = {
    CLIENT: { // Requests

        // Projects
        'Projects/getProjectsAction': { func: 'Rules/GetProjects', preprocess: { stringify: ['jsonLogic'] } },
        'Projects/getProjectAction': { func: 'Rules/GetProject' },
        'Projects/addProjectAction': { func: 'Rules/AddProject' },
        'Projects/updateProjectAction': { func: 'Rules/UpdateProject', preprocess: { stringify: ['jsonLogic'] } },
        'Projects/publishProjectAction': { func: 'Rules/PublishProject' },
        'Projects/runProjectAction': { func: 'Execution/RunProject' },
        'Projects/deleteProjectAction': { func: 'Rules/DeleteProject' },

        // Project Report
        'ProjectReport/getClientsForRTReportAction': { func: 'Clients/GetClientsForRTReport'},
        'ProjectReport/getClientForRTReportAction': { func: 'Clients/GetClientForRTReport'},

        // Modules
        'Modules/getModulesAction': { func: 'Rules/GetModules' },
        'Modules/getModuleTemplateAction': { func: 'Rules/GetModuleTemplate' },
        'Modules/getModuleAction': { func: 'Rules/GetModule' },
        'Modules/getModuleAndUpdateAction': { func: 'Rules/GetModuleAndUpdate' },
        'Modules/addModuleAction': { func: 'Rules/AddModule', preprocess: { stringify: ['jsonLogic'] } },
        'Modules/updateModuleAction': { func: 'Rules/UpdateModule', preprocess: { stringify: ['jsonLogic'] } },
        'Modules/publishModuleAction': { func: 'Rules/PublishModule' },
        'Modules/deleteModuleAction': { func: 'Rules/DeleteModule' },
        'Modules/saveModuleReportAction': { func: 'Rules/ModuleConfigurationUpdate' },
        'Modules/fetchModuleReportAction': { func: 'Rules/ModuleConfigurations' },

        // Rulesets
        'Rulesets/getRulesetsAction': { func: 'Rules/GetRulesets' },
        'Rulesets/getRulesetAction': { func: 'Rules/GetRuleset' },
        'Rulesets/addRulesetAction': { func: 'Rules/AddRuleset', preprocess: { stringify: ['jsonLogic'] } },
        'Rulesets/updateRulesetAction': { func: 'Rules/UpdateRuleset', preprocess: { stringify: ['jsonLogic'] } },
        'Rulesets/publishRulesetAction': { func: 'Rules/PublishRuleset' },
        'Rulesets/deleteRulesetAction': { func: 'Rules/DeleteRuleset' },

        // Rules
        'Rules/getRulesByParentAction': { func: 'Rules/GetRulesByParent' },
        'Rules/cloneRuleAction': { func: 'Rules/CloneRule' },
        'Rules/getRulesAction': { func: 'Rules/GetRules' },
        'Rules/getRuleAction': { func: 'Rules/GetRule' },
        'Rules/addRuleAction': { func: 'Rules/AddRule', preprocess: { stringify: ['jsonLogic'] } },
        'Rules/updateRuleAction': { func: 'Rules/UpdateRule', preprocess: { stringify: ['jsonLogic'] } },
        'Rules/deleteRuleAction': { func: 'DeleteRule' },

        // Types
        'Types/getTypesAction': { func: 'Rules/GetTypes' },
        'Types/getTypeAction': { func: 'Rules/GetType' },

        // Clients
        'Clients/getClientSchemeAction': { func: 'Clients/GetClientScheme' },
        'Clients/getClientResultsSchemeAction': { func: 'Clients/GetClientResultsScheme' },
        'Clients/getClientMappingSchemeAction': { func: 'Clients/GetClientMappingScheme' },
        'Clients/uploadLocalFileAction': { func: 'Clients/UploadLocalFile' },
        'Clients/deleteLocalFileAction': { func: 'Clients/DeleteLocalFile' },

        // Account
        'Account/loginAccountAction': { func: 'Auth/Login' },
        'Account/getAccountAction': { func: 'Auth/UserInfo' },
        'Account/refreshTokenAction': { func: 'Auth/RefreshToken' },
        'Account/registrationAccountAction': { func: 'RegistrationAccount' }, // Temporary

        // Mapping
        'Mapping/addMappingAction': { func: 'Mapping/AddMapping', preprocess: { stringify: ['mappingJSON'] } },
        'Mapping/updateMappingAction': { func: 'Mapping/UpdateMapping', preprocess: { stringify: ['mappingJSON'] } },
        'Mapping/deleteMappingAction': { func: 'Mapping/DeleteMapping' },
        'Mapping/getMappingsAction': { func: 'Mapping/GetMappings' },

        // Guidelines
        'Guidelines/getGuidelineAction': {},
        'Guidelines/getGuidelinesAction': { func: 'Guidelines/GetGuidelines'},
        'Guidelines/getModuleGuidelinesAction': { func: 'Guidelines/GetModuleGuidelines'},
        'Guidelines/addGuidelineModuleAction': { func: 'Guidelines/AddGuidelineModule'},
        'Guidelines/updateGuidelineModuleAction': { func: 'Guidelines/UpdateGuidelineModule'},
        'Guidelines/deleteModuleGuidelineAction': { func: 'Guidelines/DeleteModuleGuideline'},
    },

    SERVER: { // Responses

        // Projects
        ProjectsAnswer: { action: 'Projects/receiveProjectsAction' },
        ProjectAnswer: { action: 'Projects/receiveProjectAction' },
        ProjectAddAnswer: { action: 'Projects/receiveProjectAddAction', preprocess: { callback: [receiveProjectAddCallback] } },
        ProjectUpdateAnswer: { action: 'Projects/receiveUpdateProjectAction' },
        ProjectPublishAnswer: { action: 'Projects/receivePublishProjectAction', preprocess: { jsonParse: false } },
        ProjectDeleteAnswer: { action: 'Projects/receiveDeleteProjectAction', preprocess: { jsonParse: false } },
        ProjectExecutionStatusAnswer: { action: 'Projects/receiveProjectRunAnswer', preprocess: { callback: [receiveProjectRunCallback] } },

        // Project Report
        GetClientsForRTReportAnswer: { action: 'ProjectReport/receiveClientsForRTReportAction'},
        // 'ProjectReport/getClientForRTReportAction': { func: 'Clients/GetClientForRTReport'},

        // Modules
        ModulesAnswer: { action: 'Modules/receiveModulesAction' },
        ModuleTemplateAnswer: { action: 'Modules/receiveModuleTemplateAction' },
        ModuleAnswer: { action: 'Modules/receiveModuleAction' },
        ModuleAddAnswer: { action: 'Modules/receiveAddModuleAction', preprocess: { callback: [receiveAddModuleCallback] } },
        ModuleUpdateAnswer: { action: 'Modules/receiveUpdateModuleAction' },
        ModulePublishAnswer: { action: 'Modules/receivePublishModuleAction', preprocess: { jsonParse: false, callback: [receivePublishModuleCallback] } },
        ModuleDeleteAnswer: { action: 'Modules/receiveDeleteModuleAction', preprocess: { jsonParse: false } },
        ModuleConfigurationUpdateAnswer: { action: 'Modules/receiveSaveModuleReportAction', preprocess: { jsonParse: false }},
        ModuleConfigurationsAnswer: { action: 'Modules/receiveModuleReportAction', preprocess: { jsonParse: true }},

        // Rulesets
        RulesetsAnswer: { action: 'Rulesets/receiveRulesetsAction' },
        RulesetAnswer: { action: 'Rulesets/receiveRulesetAction' },
        RulesetAddAnswer: { action: 'Rulesets/receiveAddRulesetAction', preprocess: { callback: [receiveAddRulesetCallback] } },
        RulesetUpdateAnswer: { action: 'Rulesets/receiveUpdateRulesetAction'},
        RulesetPublishAnswer: { action: 'Rulesets/receivePublishRulesetAction', preprocess: { jsonParse: false, callback: [receivePublishRulesetCallback] } },
        RulesetDeleteAnswer: { action: 'Rulesets/receiveDeleteRulesetAction', preprocess: { jsonParse: false } },

        // Rules
        RulesByParentAnswer: { action: 'Rules/receiveRulesByParentAction' },
        RuleCloneAnswer: { action: 'Rules/receiveRuleCloneAction', preprocess: { callback: [receiveRuleCloneCallback] } },
        RulesAnswer: { action: 'Rules/receiveRulesAction' },
        RuleAnswer: { action: 'Rules/receiveRuleAction' },
        RuleAddAnswer: { action: 'Rules/receiveAddRuleAction' },
        RuleUpdateAnswer: { action: 'Rules/receiveUpdateRuleAction', preprocess: { jsonParse: false }  },
        RuleDeleteAnswer: { action: 'Rules/receiveDeleteRuleAction' },

        // Types
        TypesAnswer: { action: 'Types/receiveTypesAction' },
        TypeAnswer: { action: 'Types/receiveTypeAction' },

        // Clients
        ClientSchemeAnswer: { action: 'Clients/receiveClientSchemeAction', preprocess: { jsonParse: false } },
        ClientResultsSchemeAnswer: { action: 'Clients/receiveClientResultsSchemeAction', preprocess: { jsonParse: false } },
        ClientMappingSchemeAnswer: { action: 'Clients/receiveClientMappingSchemeAction', preprocess: { jsonParse: false } },
        UploadLocalFileAnswer: { action: 'Clients/receiveUploadLocalFileAction', preprocess: { jsonParse: false } },
        DeleteLocalFileAnswer: { action: 'Clients/receiveDeleteLocalFileAction', preprocess: { jsonParse: false } },

        // Rules
        AccountAnswer: { action: 'Account/receiveAccountAction' },  // Temporary

        // Account
        LoginAnswer: { action: 'Account/receiveLoginAction', preprocess: { oldVersion: true, jsonParse: ["data"], callback: [receiveLoginCallback] } },
        UserInfoAnswer: { action: 'Account/receiveUserInfoAction', preprocess: { oldVersion: true, jsonParse: ["data"], callback: [receiveUserInfoCallback] } },
        RefreshTokenAnswer: { action: 'Account/receiveRefreshTokenAction', preprocess: { oldVersion: true, jsonParse: ["data"], callback: [receiveRefreshTokenCallback] } },

        // Mapping
        MappingAddAnswer: { action: 'Mapping/receiveAddMapping' },
        MappingUpdateAnswer: { action: 'Mapping/receiveUpdateMapping' },
        MappingDeleteAnswer: { action: 'Mapping/receiveDeleteMapping', preprocess: { jsonParse: false } },
        MappingsAnswer: { action: 'Mapping/receiveMappings' },

        // Guidelines
        GuidelinesAnswer: { action: 'Guidelines/receiveGuidelinesAction'},
        ModuleGuidelinesAnswer: { action: 'Guidelines/receiveModuleGuidelinesAction' },
        AddGuidelineModuleAnswer: { action: 'Guidelines/addGuidelineModuleAnswerAction' },
        UpdateGuidelineModuleAnswer: { action: 'Guidelines/updateGuidelineModuleAnswerAction' },
        DeleteModuleGuidelineAnswer: { action: 'Guidelines/deleteModuleGuidelineAnswerAction' },
    }
}
