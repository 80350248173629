import { FC, useContext } from 'react';
import { ModalsCtx } from "../../../../Context/Modals.context";
import { Modal } from 'lavaa';
import { Row, Col } from 'lavaa';
import { Input, Dropdown } from 'lavaa';
import { Button } from 'lavaa';
import { iconsTypes } from 'lavaa';

// Real Report Modal
const RealReportModal: FC<any> = () => {

    let { isRealReportModalActive, setRealReportModalActive, setSourceMappingModalActive } = useContext(ModalsCtx);

    const mappingData = [
        {id: '1', value: 'My mapping 1', key: 'm1'},
        {id: '2', value: 'My mapping 2', key: 'm2'},
        {id: '3', value: 'My mapping 3', key: 'm3'}
    ];

    // Handle Close
    const handleClose = () => {
        setRealReportModalActive(false);
    };

    // Handle Cancel
    const handleCancel = () => {
        setRealReportModalActive(false);
    };

    // Handle Ok
    const handleOk = () => {
        setRealReportModalActive(false);
    };

    // Handle Create Mapping
    function handleCreateMapping(){
        setRealReportModalActive(false);
        setSourceMappingModalActive(true)
    }

    return <Modal 
        active={ isRealReportModalActive }
        onClose={ handleClose } 
        onCancel={ handleCancel } 
        onOk={ handleOk }
        title='Real report options'
        okText='Save'
        cancelText='Cancel'
        type='middle'
    >
        <Col grow="1" paddingBottom="1rem">

            {/* Name */}
            <Row grow='1' paddingBottom='1rem'>
                <Input type='text' wrap='col' label='Name' placeholder='Name' onChange={ () => {} } />
            </Row>

            {/* FTP Server Address */}
            <Row grow='1' paddingBottom='1rem'>
                <Input type='text' wrap='col' label='FTP Server Address' placeholder='FTP Server Address' onChange={ () => {} } />
            </Row>

            <Row grow='1' paddingBottom='1rem'>

                {/* Name */}
                <Row grow='1' paddingBottom='1rem' paddingRight='1rem'>
                    <Input type='text' wrap='col' label='User Name' placeholder='Name' onChange={ () => {} } />
                </Row>

                {/* Category */}
                <Row grow='1' paddingBottom='1rem'>
                    <Input type='password' wrap='col' label='Password' placeholder='******' onChange={ () => {} } />
                </Row>
            </Row>

            {/* File Pattern */}
            <Row grow='1' paddingBottom='1rem'>
                <Input type='text' wrap='col' label='File Pattern' placeholder='*.csv' onChange={ () => {} } />
            </Row>

            <Row grow='1' paddingBottom='1rem'>

                {/* Mapping */}
                <Row grow='1' paddingBottom='1rem' paddingRight='1rem'>
                    <Dropdown wrap="col" label="Mapping" placeholder="Mapping" hidePlaceholder={ true } data={ mappingData } selected={ null } onSelect={ () => {} }/>
                </Row>

                {/* Category */}
                <Row grow='1' paddingBottom='1rem'  alignitems='end'>
                    <Button variant='text' type='secondary' text="Add New Mapping" icon={iconsTypes.playCircle} onClick={ handleCreateMapping } />
                </Row>
            </Row>
        </Col>
    </Modal>
};

export { RealReportModal };