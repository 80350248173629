import { FC, useContext, useEffect, useState } from 'react';
import { ModalsCtx } from "../../../../../Context/Modals.context";
import { useModules } from '../../../../../Hooks/UseModules';
import { usePrev } from '../../../../../Hooks/UsePrev';
import { useTypes } from '../../../../../Hooks/UseTypes';
import { Modal } from 'lavaa';
import { ITabsContent, Tabs } from 'lavaa';
import { ModuleReports } from '../ModuleReports/ModuleReports.component';
import { IFields } from './Interface';
import { ModuleParameters } from './Parameters/ModuleParameters.component';
import { GuidelinesTab } from '../../../Guidelines/GuidelinesTab/GuidelinesTab.component';
import { useGuidelines } from '../../../../../Hooks/UseGuidelines';

interface IProps { }

// Edit Module Modal
const EditModuleModal: FC<IProps> = () => {

    const initialParameters: IFields = { name: '', disorder: {}, moduleType: {}, icon: '', description: '', additionalInfo: '' };

    // Context
    let { isEditModuleModalActive, setEditModuleModalActive, editModuleUID, setEditModuleUID } = useContext(ModalsCtx);

    // Hooks
    const { resetModuleGuidelines } = useGuidelines();

    // States
    const [moduleParameters, setModuleParameters] = useState<IFields>(initialParameters);
    const [moduleReport, setModuleReport] = useState<Array<any>>([]);
    const [isModuleParametersValid, setModuleParametersValid] = useState<boolean>(false);

    const { updateModule, getModuleTemplate, fetchModuleTemplate } = useModules();
    const { getDisorder, getModuleType } = useTypes();
    const prevEdModuleUID = usePrev(editModuleUID);

    const moduleTemplateData = getModuleTemplate(editModuleUID);

    // On Module Parameters Validate
    const onModuleParametersValidate = (isValid: boolean) => {
        setModuleParametersValid(isValid);
    };

    // On Report Update
    const onReportUpdate = (data: Array<any>) => {
        setModuleReport([...data]);
    }

    // Handle Close
    const handleClose = () => {
        resetModuleGuidelines();
        setModuleReport([]);
        setEditModuleUID(undefined);
        setEditModuleModalActive(false);
    };

    // Handle Save
    const handleSave = (e: Event) => {
        e.stopPropagation();
        const { name, description, icon, additionalInfo } = moduleParameters;
        const disorder: any = moduleParameters.disorder || null;
        const disorderTypeID = disorder ? disorder.disorderTypeID : null;
        const reportConfigs = [...moduleReport];
        const updateData = { ...moduleTemplateData, name, disorderTypeID, icon, description, additionalInfo, reportConfigs };

        updateModule(updateData);
        setModuleParameters(initialParameters);
        setModuleReport([]);
        setEditModuleUID(undefined);
        setEditModuleModalActive(false);
    };

    // On Modules Parameters Change
    const onModulesParametersChange = (data: IFields) => {
        setModuleParameters(data);
    };

    const tabs: ITabsContent[] = [
        {
            title: 'Module Data',
            content: <ModuleParameters data={moduleParameters} onChange={onModulesParametersChange} onValidate={onModuleParametersValidate} />
        },
        {
            title: 'Report Data',
            content: <ModuleReports onReportUpdate={onReportUpdate} initialData={moduleReport} />,
            disabled: !isModuleParametersValid
        },
        {
            title: 'Guidelines',
            content: <GuidelinesTab moduleData={moduleTemplateData} />,
            disabled: !isModuleParametersValid
        }
    ];

    // Fetch Module Data
    useEffect(() => {
        if (isEditModuleModalActive && editModuleUID && editModuleUID !== prevEdModuleUID) {
            fetchModuleTemplate(editModuleUID);
        }
    }, [isEditModuleModalActive, editModuleUID, prevEdModuleUID]);

    // Set States On Module Data Received
    useEffect(() => {
        if (moduleTemplateData) {
            const disorder = getDisorder(moduleTemplateData.disorderTypeID);
            const moduleType = getModuleType(moduleTemplateData.moduleTypeID);

            setModuleParameters({
                name: moduleTemplateData.name,
                moduleType: moduleType,
				icon: moduleTemplateData.icon,
                disorder: disorder,
                description: moduleTemplateData.description,
                additionalInfo: moduleTemplateData.additionalInfo
            });
            setModuleReport(moduleTemplateData.reportConfigs || []);
        }
    }, [moduleTemplateData]);

    return <Modal
        active={isEditModuleModalActive}
        onClose={handleClose}
        onCancel={handleClose}
        onOk={handleSave}
        title="Edit template module"
        okText="Save"
        cancelText="Cancel"
        type="large"
        hasTab={true}
        height="550px"
        isFormValid={isModuleParametersValid}
    >
        <Tabs content={tabs} />
    </Modal>
};

export { EditModuleModal };
