import React, {FC, useEffect, useState} from 'react';
// import { Row, Col } from '../../../Layout';
// import { Icon, iconsTypes } from '../../../Icon';
// import { Text } from '../../../Text';
import { classNames } from '../../../../Tools';
import { Select } from './Select.component';
// import { iconSizeType } from '../../../Icon/Icon.config';
import {
	Row, Col,
	Icon, iconsTypes,
	Text,
	Input,
} from 'lavaa';
import css from '../Dropdown.module.scss';

interface IProps {
	data: Array<any>,
	label?: string,
	placeholder?: string,
	selected?: any,
	selectField?: string,
	onSelect?: (data: any) => void,
	wrap?: 'col' | 'row',
	icon?: number,
	hidePlaceholder?: boolean,
	displayField?: string,
	labelClassName?: string,
	hasSearch?: boolean,
	type?: 'inline' | 'simple' | 'button' | 'button-simplified',
	hasMaxWidth?: boolean,
	disabled?: boolean,
	displayInnerIcon?: boolean,
	innerIconSize?: any//iconSizeType
	autocomplete?: boolean,
	onType?: (val: string) => void,
	size?: 'small' | 'middle' | 'large'
}

// Dropdown
const Dropdown: FC<IProps> = React.memo((props) => {

	// Props
	let { data, label, placeholder, selected, selectField = 'id', wrap = 'row', icon, type = 'inline', hasMaxWidth = false } = props;
	let { labelClassName, hidePlaceholder = false, displayField = 'value', onSelect, hasSearch = false, disabled = false, displayInnerIcon = false, innerIconSize = '18' } = props;
	const { autocomplete = false, onType = () => {}, size: inputSize = 'large' } = props;
	let Wrap: any = wrap === 'row' ? Row : Col; // Define column or row wrapper

	const [isActive, setIsActive] = useState<boolean>(false);
	const [bounds, setBounds] = useState( { x: 0, y: 0, width: 0, height: 0 });
	const isActiveRef = React.useRef(false);
	const dropdownRef = React.useRef<HTMLDivElement>(null);

	useEffect(() => {
		const getBounds = () => {
			// If dropdown isn't active - skip
			if(!isActiveRef.current) return;

			// Get dropdown bounds
			calculateBounds();
		};

		// Resize Listener
		window.addEventListener('resize', getBounds);

		return () => {
			window.removeEventListener('resize', getBounds);
		}

	}, []);

	const calculateBounds = () => {
		// Get dropdown bounds
		const selectBounds = dropdownRef.current?.getBoundingClientRect() || { x: 0, y: 0, width: 0, height: 0 };
		
		// Set bounds
		setBounds(selectBounds);
	}

	const handleChange = (value: any) => {
		if (value !== null) {
			const newVal = data.find((row) => row[selectField] === value) ?? null;
			if (onSelect) onSelect(newVal);
		}

		deactivate();
	};

	// Activate
	const activate = (e: Event) => {
		const target: any = e.target;
		if (disabled === true || (autocomplete === true && target.tagName === 'INPUT')) return;

		isActiveRef.current = true;
		calculateBounds();
		setIsActive(true);
	};

	// Deactivate
	const deactivate = () => {
		isActiveRef.current = false;
		setIsActive(false);
	};

	// Handle User Typing
	const handleType = (val: string) => {

		// Activate
		if (val !== '') {
			isActiveRef.current = true;
			calculateBounds();
			setIsActive(true);
		}

		// Deactivate
		else {
			console.log('Deactivate')
			isActiveRef.current = false;
			setIsActive(false);
		}
		
		onType(val);
	};

	const onFocusInput = (e: Event) => {
		const input: any = e.target;

		if (input && input.value) {
			handleType(input.value);
		}
	};

	const isButton = type === 'button' || type === 'button-simplified';
	const isSimple = type === 'simple';

	const isClickable = isButton || isSimple;

	let textSize: 'x2' | 'x3' = 'x2';
	let textBold: boolean = false;

	if (type === 'button' || type === 'button-simplified') {
		textSize = 'x3';
		textBold = true;
	}

	if(type === 'simple') {
		textSize = 'x3';
		textBold = true;
	}

	return <Wrap
		className={classNames(css.Dropdown, hasMaxWidth ? css.hasMaxWidth : '')}
		data-type={type}
		shrink='0' grow='1' onClick={isClickable ? activate : undefined}
	>

		{/* Inner Label */}
		{
			!label ? null :
				<Row alignitems='center' className={css.Label}>
					<Text className={classNames(css.LabelText, labelClassName)} size='x2'>{label}</Text>
				</Row>
		}

		{/* Icon */}
		{
			!icon ? null :
				<Row alignitems='center' className={css.Icon}>
					<Icon name={icon} size="x2" />
				</Row>
		}

		{/* Inner */}
		<Col className={css.Inner} data-disabled={disabled}>
			<Row className={css.SelectRoot} onClick={activate} innerRef={dropdownRef} justifycontent='space-between' alignitems='center' data-size={inputSize}>
				<Row className={css.SelectText} grow="1" alignitems="center">

					{/* Placeholder */}
					{
						autocomplete || !placeholder || isSimple ? null :
							(selected && hidePlaceholder) ? null :
								<Text size={textSize} ellipsis={true} className={css.SelectPlaceholder} bold={textBold} style={{marginRight: '1rem'}}>{placeholder}</Text>
					}

					{/* Selected Item Value */}
					{
						!autocomplete && (!selected || isButton) ? null :
							<Row grow="1" paddingLeft={hidePlaceholder || isSimple ? '0' : '0'} alignitems="center">

								{/* Icon */}
								{
									displayInnerIcon === false || (displayInnerIcon === true && !selected.icon) ? null :
									<Row marginRight="0.5rem">
										<Icon name={selected.icon} size={innerIconSize} />
									</Row>
								}

								{/* Text */}
								{
									autocomplete === true ? 
										<Input autocomplete={autocomplete} type="text" value={selected ? selected[displayField] : ''} onChange={handleType} onFocus={onFocusInput} placeholder={placeholder} style={{border: 'none', minHeight: '30px', padding: '0 2.5rem 0 0'}} />
									:	
										<Text size={textSize} className={css.SelectValue} bold={textBold} ellipsis={true}>{selected ? selected[displayField] : ''}</Text>
								}
								
							</Row>
					}
				</Row>

				{/* Arrow */}
				{
					!isButton && autocomplete === false ? (
						<div className={classNames(css.ArrowIcon, isActive && css.ArrowIconActive)}>
							<Icon style={{display: 'flex'}} name={iconsTypes.arrowDown} />
						</div>
					) : null
				}
			</Row>

			{/* Portal data / Data List */}
			<Select
				active={isActive}
				selectBounds={bounds}
				data={data}
				onChange={handleChange}
				selectField={selectField}
				displayField={displayField}
				hasSearch={hasSearch}
				selected={selected}
				displayIcon={displayInnerIcon}
				iconSize={innerIconSize}
			/>
		</Col>
	</Wrap>;
});

export { Dropdown };
