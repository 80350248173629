import { FC } from 'react';
import DataGrid from 'react-data-grid';
import 'react-data-grid/lib/styles.css';
import css from './Table.module.scss';

interface IProps {
    columns: any[],
    rows: any
}

// Table
const Table: FC<IProps> = (props) => {

    // Props
    const { columns = [], rows = [] } = props;

    return <DataGrid className={css.Table} columns={columns} rows={rows} rowHeight={48} />
};

export { Table };
