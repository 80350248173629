import { createSlice } from '@reduxjs/toolkit';

interface AccountStateType {
    isAuth: boolean | null,
    id: any,
    name: string,
    position: string,
    access_token: string,
    refresh_token: string
}

const initialState: AccountStateType = {
    isAuth: null,
    id: null,
    name: '',
    position: '',
    access_token: '',
    refresh_token: ''
};

export const accountSlice = createSlice({
    name: 'Account',
    initialState,
    reducers: {

        // Login Account
        loginAccountAction: (state, action) => {},

        // Receive Login
        receiveLoginAction: (state, action) => {

            const payload = action.payload[0];
            if(payload.errorMessage.length > 0) return state;

            return {...state, ...{
                access_token: payload.data.access_token,
                refresh_token: payload.data.refresh_token
            }};
        },

        // Get User Info
        getAccountAction: (state, action) => {},

        // Receive User Info
        receiveUserInfoAction: (state, action) => {
            const payload = action.payload[0];
            if(payload.errorMessage.length > 0) return state;

            return {...state, ...payload.data, ...{isAuth: true}};
        },

        // Refresh Token
        refreshTokenAction: (state, action) => {},

        // Receive Refresh Token
        receiveRefreshTokenAction: (state, action) => {

            const payload = action.payload[0];
            if(payload.errorMessage.length > 0){
                return {...initialState, ...{isAuth: false}};
            }

            return {...state, ...{
                access_token: payload.data.access_token,
                refresh_token: payload.data.refresh_token
            }};
        },

        // Registration Account
        registrationAccountAction: (state, action) => {},

        // Logout Account
        logoutAccountAction: (state, action) => {
            return {...initialState, ...{isAuth: false}};
        }
    }
});

export const {
    getAccountAction,
    receiveUserInfoAction,
    loginAccountAction,
    refreshTokenAction,
    registrationAccountAction,
    logoutAccountAction
} = accountSlice.actions;
export default accountSlice.reducer;
