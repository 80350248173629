import { FC, useContext } from 'react';
import { Button } from 'lavaa';
import { ModalsCtx } from '../../../../Context/Modals.context';

interface IProps {
    text?: string,
    icon?: number,
    className?: string,
    grow?: '0' | '1',
    type?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'disable',
    uid: string
}

// Delete Rule Action Button
const DeleteRuleActionButton: FC<IProps> = (props) => {
 
    let { setDeleteRuleConfirmActive, setDeleteRuleUID } = useContext(ModalsCtx);

    const handleClick = (e: any) => {
        e.stopPropagation();
        setDeleteRuleConfirmActive(true);
        setDeleteRuleUID(props.uid);
    };

    return <Button {...props} onClick={ (e) => handleClick(e) } />
};

export { DeleteRuleActionButton };