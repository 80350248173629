import { FC, useContext } from 'react';
import { Button } from 'lavaa';
import { ModalsCtx } from '../../../../Context/Modals.context';

interface IProps {
    text?: string,
    icon?: number,
    iconSize?: 'x1',
    className?: string,
    grow?: '0' | '1',
    type?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'disable',
    variant?: 'text',
    uid: string
}

// Delete Module Template Action Button
const DeleteModuleTemplateActionButton: FC<IProps> = (props) => {
 
    let { setDeleteModuleTemplateConfirmActive, setDeleteModuleTemplateUID } = useContext(ModalsCtx);

    const handleClick = (e: any) => {
        // e.stopPropagation();
        setDeleteModuleTemplateUID(props.uid);
        setDeleteModuleTemplateConfirmActive(true);
    };

    return <Button {...props} iconSize="18" onClick={ handleClick } />
};

export { DeleteModuleTemplateActionButton };