import { FC, useState } from 'react';
import { Col, LogicEditor } from 'lavaa';
import { useClients } from '../../../../Hooks/UseClients';

interface IProps {
    initialData: any,
    onChange: (name: string, value: string) => void
}

// CreateEditGuidelineFilterTab
const CreateEditGuidelineFilterTab: FC<IProps> = (props) => {

    // Props
    const { initialData } = props;

    // States
    const [value, setValue] = useState<any>(initialData);

    // Hooks
    const { clientScheme } = useClients();

    // On Change
    const onChange = (value: any) => {
        setValue(value);
        props.onChange('clientCondition', value);
    };
    
    return (
        <Col paddingTop="1.75rem" scroll='true' grow="1" paddingRight="0.5rem">
            <LogicEditor jsonLogic={value} onChange={onChange} scheme={clientScheme} />
        </Col>
    )
};

export { CreateEditGuidelineFilterTab };
