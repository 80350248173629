import { FC, useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Row, Cell, Col, Icon, iconsTypes, Text, getLocalStorageKeys, clearLocalStorage } from 'lavaa';
import { useAccount } from '../../Hooks/UseAccount';
import { useClients } from '../../Hooks/UseClients';
import { useMapping } from '../../Hooks/UseMapping';
import { useModules } from '../../Hooks/UseModules';
import { useNotifications } from '../../Hooks/UseNotifications';
import { useProjectReport } from '../../Hooks/UseProjectReport';
import { useProjects } from '../../Hooks/UseProjects';
import { useRules } from '../../Hooks/UseRules';
import { useRulesetData } from '../../Hooks/UseRulesetData';
import { useRulesets } from '../../Hooks/UseRulesets';
import { useTypes } from '../../Hooks/UseTypes';
import { ThemeSwitcher } from '../Custom/ThemeSwitcher/ThemeSwitcher.component';
import { AppCtx } from '../../Context/App.context';
import css from './MenuProfile.module.scss';

interface IProps {
    closeHandler: () => void
}

// Menu Profile Dropdown
const MenuProfileDropdown: FC<IProps> = (props) => {

    const { closeHandler } = props;

    // Hooks
    const { resetClients } = useClients();
    const { resetMapping } = useMapping();
    const { resetModules } = useModules();
    const { resetNotifications } = useNotifications();
    const { resetProjectReports } = useProjectReport();
    const { resetProjects } = useProjects();
    const { resetRules } = useRules();
    const { resetRulesetsData } = useRulesetData();
    const { resetRulesets } = useRulesets();
    const { resetTypes } = useTypes();
    const { logoutAccount } = useAccount();
    const navigate = useNavigate();

    // Contexts
    let { setPerformanceNow } = useContext(AppCtx);

    // Logout
    const logoutHandler = () => {
        const storageKeys = getLocalStorageKeys();
        clearLocalStorage(storageKeys);

        // Reset Redux
        resetClients();
        resetMapping();
        resetModules();
        resetNotifications();
        resetProjectReports();
        resetProjects();
        resetRules();
        resetRulesetsData();
        resetRulesets();
        resetTypes();

        navigate('/openprojects');

        logoutAccount();
    }

    // Reset Layout
    const resetLayout = () => {
        const projectLayout: string[] = ['ProjectLayout', 'ProjectLayoutLeftPane', 'ProjectLayoutCenterPane', 'ProjectLayoutRightPane'];
        const moduleLayout: string[] = ['ModuleLayout', 'ModuleLayoutCenterPane', 'ModuleLayoutRightPane'];
        const rulesetLayout: string[] = ['RulesetLayout', 'RulesetLayoutCenterPane', 'RulesetLayoutRightPane'];

        clearLocalStorage([...projectLayout, ...moduleLayout, ...rulesetLayout]);
        setPerformanceNow(performance.now());
    };

    return (
        <>
            <div className={css.DropdownLayer} onClick={closeHandler}/>
            <Col paddingBottom='1.75rem' className={css.Dropdown}>

                {/* Settings */}
                <NavLink to="settings" className={css.DropdownLink}>
                    <Row className={css.DropdownItem} alignitems='center' paddingLeft='1.5rem' paddingTop='1rem' paddingRight='1.5rem' paddingBottom='1rem'>
                        <Cell paddingTop='0' paddingBottom='0' paddingLeft='0' paddingRight='0.75rem' className={css.DropdownIcon}>
                            <Icon name={iconsTypes.gear}/>
                        </Cell>
                        <Text text='Settings' size='x2' bold='true' className={css.DropdownLabel}/>
                    </Row>
                </NavLink>

                {/* Reset Layout */}
                <Row className={css.DropdownItem} alignitems='center' paddingLeft='1.5rem' paddingTop='1rem' paddingRight='1.5rem' paddingBottom='1rem' onClick={resetLayout}>
                    <Cell paddingTop='0' paddingBottom='0' paddingLeft='0' paddingRight='0.75rem' className={css.DropdownIcon}>
                        <Icon name={iconsTypes.reset}/>
                    </Cell>
                    <Text text='Reset layout' size='x2' bold='true' className={css.DropdownLabel}/>
                </Row>

                {/* Logout */}
                <Row className={css.DropdownItem} alignitems='center' paddingLeft='1.5rem' paddingTop='1rem' paddingRight='1.5rem' paddingBottom='1rem' onClick={logoutHandler}>
                    <Cell paddingTop='0' paddingBottom='0' paddingLeft='0' paddingRight='0.75rem' className={css.DropdownIcon}>
                        <Icon name={iconsTypes.logout}/>
                    </Cell>
                    <Text text='Logout' size='x2' bold='true' className={css.DropdownLabel}/>
                </Row>

                {/* Theme Switcher */}
                <ThemeSwitcher/>
            </Col>
        </>
    );
};

export { MenuProfileDropdown };
