import { Route } from 'react-router-dom';
import { ProjectLayout } from '../ProjectLayout/ProjectLayout.component';
import { MyCompanyProjects } from '../Pages/MyCompanyProjects/MyCompanyProjects.component';
import { ModuleLayout } from "../ModuleLayout/ModuleLayout.component";
import { RulesetLayout } from "../RulesetLayout/RulesetLayout.component";

// My Company Projects Route
const MyCompanyProjectsRoute = [
    <Route key="/mycompanyprojects" path="/mycompanyprojects" element={<MyCompanyProjects />} />,
    <Route key="/mycompanyprojects/:activeProjectId" path="/mycompanyprojects/:activeProjectId" element={ <ProjectLayout /> } />,
    <Route key="/mycompanyprojects/:activeProjectId/:activeModuleId" path="/mycompanyprojects/:activeProjectId/:activeModuleId" element={ <ModuleLayout /> } />,
    <Route key="/mycompanyprojects/:activeProjectId/:activeModuleId/:activeRulesetId" path="/mycompanyprojects/:activeProjectId/:activeModuleId/:activeRulesetId" element={ <RulesetLayout /> } />,
];

export { MyCompanyProjectsRoute };