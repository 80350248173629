import { FC, useEffect, useState } from 'react';
import { Button, Row, Col, iconsTypes, Text, Title, Icon, Input } from 'lavaa';
import { MoreMenu } from '../../../MoreMenu/MoreMenu.component';
import { AddCurrentGuidelineModal } from '../AddCurrentGuidelineModal/AddCurrentGuidelineModal.component';
import { CreateEditGuidelineModal } from '../CreateEditGuidelineModal/CreateEditGuidelineModal.component';
import { useGuidelines } from '../../../../Hooks/UseGuidelines';
import { guidelinePriorities } from '../GuidelinePriorities';
import { DeleteGuidelineModal } from '../DeleteGuidelineModal/DeleteGuidelineModal.component';
import css from './GuidelinesTab.module.scss';
import { Dropdown } from '../../../Sandbox/Dropdown';

interface IProps {
    moduleData: any
}

const sortConditionsData = [
    { id: 'lowToHigh', value: 'Low to High', icon: iconsTypes.lowToHigh, sortBy: 'asc'},
    { id: 'highToLow', value: 'High to Low', icon: iconsTypes.highToLow, sortBy: 'desc'}
];

// Guidelines Tab
const GuidelinesTab: FC<IProps> = (props) => {

    // Props
    const { moduleData } = props;

    // States
    const [addCurrentGuidelineModalActive, setAddCurrentGuidelineModalActive] = useState<boolean>(false);
    const [createEditGuidelineModalActive, setCreateEditGuidelineModalActive] = useState<boolean>(false);
    const [deleteGuidelineModalActive, setDeleteGuidelineModalActive] = useState<boolean>(false);
    const [deleteUID, setDeleteUID] = useState<string | null>(null);
    const [guidelineData, setGuidelineData] = useState<any>();
    const [mode, setMode] = useState<'' | 'create' | 'edit'>('');
    const [modeAction, setModeAction] = useState<'' | 'create' | 'edit'>('');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [sortBy, setSortBy] = useState<'asc' | 'desc'>('asc');
    const selectedSortCond = sortConditionsData.find((item: any, index: number) => {
        return item.sortBy === sortBy;
    });

    // Hooks
    const { moduleGuidelines = [], addGuidelineModule, updateGuidelineModule, deleteModuleGuideline, fetchModuleGuidelines } = useGuidelines();

    // Priorities
    const priorityIcon: any = {
        1: iconsTypes.highRisk,
        2: iconsTypes.mediumRisk,
        3:iconsTypes.lowRisk
    };

    // Reset Current Guideline Data
    const resetCurrentGuidelineData = () => {
        setGuidelineData(undefined);
    };

    // Handle Add Current Guideline
    const handleAddCurrentGuideline = (guideline: any, priority: any) => {
        setGuidelineData({
            guidelineUID: guideline.GuidelineUID,
            text: guideline.Text,
            description: guideline.Description,
            priority: priority
        });

        // Switch modal window
        setMode('edit');
        setModeAction('create');
        setAddCurrentGuidelineModalActive(false);
        setCreateEditGuidelineModalActive(true);
    };

    // Handle Create New Guideline
    const handleCreateNewGuideline = (data: any) => {
        addGuidelineModule({
            moduleId: moduleData.primaryId,
            ...data,
            priority: data.priority.priority
        });

        setCreateEditGuidelineModalActive(false);
        resetCurrentGuidelineData();
    };

    // Handle Update Guideline
    const handleUpdateGuideline = (data: any) => {
        updateGuidelineModule({
            guidelineModuleUID: guidelineData.guidelineModuleUID,
            ...data,
            priority: data.priority.priority
        });

        setCreateEditGuidelineModalActive(false);
        resetCurrentGuidelineData();
    };

    // Edit Guideline
    const onEditGuideline = (data: any) => {
        const priority: any = guidelinePriorities.find((item: any) => item.priority === data.Priority);

        setGuidelineData({
            guidelineModuleUID: data.GuidelineModuleUID,
            guidelineUID: data.GuidelineUID,
            text: data.Guideline.Text,
            description: data.Guideline.Description,
            priority: priority,
            minValue: data.ModuleMinScore,
            maxValue: data.ModuleMaxScore,
            clientCondition: JSON.parse(data.ClientCondition)
        });

        setMode('edit');
        setModeAction('edit');
        setCreateEditGuidelineModalActive(true);
    };

    // Handle Ok Create Edit Modal
    const handleOkCreateEditModal = (data: any) => {
        // Create
        if (modeAction === 'create') {
            handleCreateNewGuideline(data);
        }

        // Edit
        if (modeAction === 'edit') {
            handleUpdateGuideline(data);
        }

        resetCurrentGuidelineData();
    };

    // Close Create Edit Modal
    const closeCreateEditModal = () => {
        setMode('');
        setModeAction('');
        setCreateEditGuidelineModalActive(false);
        resetCurrentGuidelineData();
    };

    // Delete Guideline
    const onDeleteGuideline = (data: any) => {
        setDeleteUID(data.GuidelineModuleUID);
        setDeleteGuidelineModalActive(true);
    };

    // Handle Ok Delete Guideline
    const handleOkDeleteGuideline = () => {
        deleteModuleGuideline(deleteUID);
        setDeleteUID(null);
        setDeleteGuidelineModalActive(false);
    };

    // Handle Cancel Delete Guideline
    const handleCancelDeleteGuideline = () => {
        setDeleteUID(null);
        setDeleteGuidelineModalActive(false);
    };

    // Filter Search Term
    const filterSearchTerm = (item: any) => {
        return item.Guideline.Text.toLowerCase().includes(searchTerm.toLowerCase());
    };

    // Handle Sort
    const sort = (a: any, b: any) => {
        return sortBy === 'asc' ? b.Priority - a.Priority : a.Priority - b.Priority;
    };

    // Handle Sort
    const handleSort = (item: any) => {
        setSortBy(item.sortBy);
    };

    // Did Mount
    useEffect(() => {
        resetCurrentGuidelineData();
        fetchModuleGuidelines(moduleData.primaryId);
    }, []);

    return <>
        <Col grow="1" paddingTop="1.75rem" paddingBottom="0.5rem" className={ css.GuidelinesTab }>

            {/* Head */}
            <Row alignitems="center" paddingBottom="1rem">

                {/* Title */}
                <Row grow="1">
                    <Title size="x4">Guidelines Names</Title>
                </Row>

                {/* Sort */}
                <Row>
                    <Dropdown /*icon={iconsTypes.sort}*/ size="middle" placeholder="Sort by:" hidePlaceholder={ false } data={ sortConditionsData } selected={ selectedSortCond } onSelect={ handleSort } displayInnerIcon={true}></Dropdown>
                </Row>

                {/* Search */}
                <Row paddingLeft="1rem" paddingRight="1rem">
                    <Input type="text" placeholder="Search..." size="middle" onChange={setSearchTerm} icon={iconsTypes.search} />
                </Row>

                {/* Add Button */}
                <Row>
                    <MoreMenu icon={iconsTypes.plus} text="Add Guidelines">
                        <Row><Button style={{paddingLeft: '1rem', paddingRight: '1rem'}} type="secondary" variant="text" onClick={() => setAddCurrentGuidelineModalActive(true)}>Add Current Guideline</Button></Row>
                        <Row><Button style={{paddingLeft: '1rem', paddingRight: '1rem'}} type="secondary" variant="text" onClick={() => {setCreateEditGuidelineModalActive(true); setMode('create'); setModeAction('create');}}>Create New Guideline</Button></Row>
                    </MoreMenu>
                </Row>
            </Row>

            <Col scroll="true" grow="1" paddingTop="1rem" paddingRight="1rem">
                {
                    moduleGuidelines.filter(filterSearchTerm).sort(sort).map((item: any, index: any) => {
                        return <Row key={item.GuidelineModuleUID} alignitems="center" paddingBottom="1rem">
                            
                            {/* Priority Icon */}
                            <Row paddingRight="1rem">
                                <Icon name={priorityIcon[item.Priority]} size="18" />
                            </Row>

                            {/* Text */}
                            <Row grow="1" paddingRight="1rem">
                                <Text ellipsis={true}>{item.Guideline.Text}</Text>
                            </Row>

                            {/* Edit/Delete */}
                            <Row>
                                <Row paddingRight="1rem">
                                    <Button onClick={() => onEditGuideline(item)} icon={iconsTypes.pen} type="secondary" variant="text" iconSize="18" />
                                </Row>
                                <Row>
                                    <Button onClick={() => onDeleteGuideline(item)} icon={iconsTypes.delete} type="secondary" variant="text" iconSize="18" />
                                </Row>
                            </Row>
                        </Row>        
                    })
                }
                
            </Col>

            {/* Add Current Guideline Modal */}
            <AddCurrentGuidelineModal 
                active={ addCurrentGuidelineModalActive } 
                onClose={ () => setAddCurrentGuidelineModalActive(false) }
                onOk={ handleAddCurrentGuideline } 
            />

            {/* Create Edit Guideline Modal */}
            <CreateEditGuidelineModal 
                initialData={ guidelineData }
                active={ createEditGuidelineModalActive } 
                onClose={ closeCreateEditModal }
                onOk={ handleOkCreateEditModal } 
                mode={mode}
            />

            {/* Delete Guideline Modal */}
            <DeleteGuidelineModal
                active={ deleteGuidelineModalActive }
                onClose={ handleCancelDeleteGuideline }
                onOk={ handleOkDeleteGuideline }
            />
        </Col>
    </>
};

export { GuidelinesTab };
