import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import { AuthRoute } from './Auth.route';
import { AuthProvider } from './AuthProvider.component';
import { MyCompanyProjectsRoute } from './MyCompanyProjects.route';
import { MyProjectsRoute } from './MyProjects.route';
import { OpenProjectsRoute } from './OpenProjects.route';
import { TestRoute } from './Test.route';


// Routes Provider
const RoutesProvider: FC = () => {

    return (
        <Routes>
            <Route path="/" element={<AuthProvider/>}>
                { AuthRoute }
                { MyProjectsRoute }
                { MyCompanyProjectsRoute }
                { OpenProjectsRoute }
                {/* { TestRoute } */}
            </Route>
        </Routes>
    )
};

export { RoutesProvider };