import { iconsTypes } from 'lavaa';
import { useModules } from '../UseModules';
import { useProjects } from "../UseProjects";
import { parseJsonLogicFlow } from "../../Tools/parseJsonLogic";
import { v4 as uuid } from 'uuid';
import { nodeTypesHasDrillDown } from "../../Components/Custom/Flow/Node/Config/Nodes";

export const prepareModuleRulesetsAsMenuItems = (moduleData: any, hasDrillDown: boolean, moduleLink: string | null) => {

    if(!hasDrillDown){
        return [];
    }

    // Check empty data
    if(moduleData === null){
        return [
            {
                id: uuid(),
                label: "Loading...",
            }
        ];
    }

    // Prepare rulesets data
    const jsonLogic = parseJsonLogicFlow( moduleData );
    const { initialNodes } = jsonLogic;
    return initialNodes.map((item: any) => {
				const isFunctionRuleset = item.type === "functionRuleset";
				const action = (!isFunctionRuleset) ? "navigate" : null;
				const contentAction = isFunctionRuleset ? "showFilterRulesetSettings" : null;

        return {
            id: item.nodeId,
            label: item.name,
            action: action,
            actionData: {
                link: `${moduleLink}/${item.nodeId}`
            },
						contentAction: contentAction,
						contentActionData: {
							link: `${moduleLink}`,
							uid: item.nodeId,
						},
        }
    });
}

const useMenuHierarchy = (): any[] => {
    const { getActiveProjectData, getProjectLink } = useProjects();
    const { moduleNodes } = useModules();
    const activeProjectData = getActiveProjectData();
    const jsonLogic = parseJsonLogicFlow(activeProjectData);
    const { initialNodes } = jsonLogic;
    const projectLink = getProjectLink(activeProjectData);

     return initialNodes.map((item: any) => {

        // Check node type
        const hasDrillDown = nodeTypesHasDrillDown.includes(item.type);
        const module = (moduleNodes) ?  (moduleNodes[item.nodeId] || null) : null;
        const action = (module === null && hasDrillDown) ? "loadModuleData" : null;
        const moduleLink = hasDrillDown ? `${projectLink}/${item.nodeId}` : projectLink;
        const contentAction = hasDrillDown ? null : "showModuleSettings";
        const rulesets = prepareModuleRulesetsAsMenuItems(module, hasDrillDown, moduleLink);

        return {
            id: item.nodeId,
            label: item.name,
            icon: iconsTypes.item,
            action: action,
            actionData: {
                moduleId: item.nodeId,
                projectId: activeProjectData.uid
            },
            link: moduleLink,
            contentAction: contentAction,
            contentActionData: {
                moduleId: item.nodeId,
                projectId: activeProjectData.uid
            },
            children: rulesets
        }
    });
};

export { useMenuHierarchy };
