import React, { FC, RefObject, useContext, useEffect, useRef, useState } from 'react';
import {Row, Col, Button, Icon, iconsTypes, Input, Text, useDetectOutsideClick, Grid, DropdownMulti} from 'lavaa';
import { v4 as uuid } from 'uuid';
import { AbstractionType } from '../../../Constants/AbstractionType';
import { classNames, getRandom } from '../../../Tools';
import { EditModuleModalActionButton } from '../../Custom/Modals/Modules/EditModuleModal/EditModuleModalActionButton.component';
import { parseJsonLogicFlow } from '../../../Tools/parseJsonLogic';
import css from './AddNodesToFlowModalNew.module.scss';
import {
	EditRulesetModalActionButton
} from "../../Custom/Modals/EditRulesetModal/EditRulesetModalActionButton.component";
import { Drawer } from '../../Drawer/Drawer.component';
import { MoreMenu } from '../../MoreMenu/MoreMenu.component';
import { Counter } from '../../Counter/Counter.component';
import { DeleteModuleTemplateActionButton } from '../../Custom/Modals/DeleteModuleTemplateConfirm/DeleteModuleTemplateActionButton.component';
import { AppCtx } from '../../../Context/App.context';
import { DeleteRulesetTemplateActionButton } from '../../Custom/Modals/DeleteRulesetTemplateConfirm/DeleteRulesetTemplateActionButton.component';

interface IFilter {
    title: string;
    data: any[];
    displayField: string;
    fieldOfFilterData: string | number;
    fieldOfListData: string | number;
}

interface IProps {
    title: string;
    active: boolean;
    setActive: (state: boolean) => void;
    filters?: IFilter[];
    data: Array<any>;
    nodesType: string;
    parentData: any | null;
    getNodeTemplate: (id: string) => any;
    updateParent?: (id: string) => any;
    onItemSelect?: (item: any) => void;
    itemType?: string;
    isFlowDisabled?: boolean;
    searchPlaceholder: string;
    tplType: 'module' | 'ruleset' | 'rule';
    closeButtonText?: string;
    closeButtonIcon?: number;
    useCounter?: boolean;
    useOptimize?: boolean;
    onOptimize?: (id: string) => void,
    displayModuleType?: boolean
}

// Add Nodes To Flow Modal New
const AddNodesToFlowModalNew: FC<IProps> = (props) => {

    const { setDeleteNodeId } = useContext(AppCtx);
    const modalRef: RefObject<any> = useRef(null);

    const { active, setActive, title, filters = [], nodesType, getNodeTemplate, updateParent = () => {}, parentData, onItemSelect = () => {}, itemType, isFlowDisabled = false, searchPlaceholder = 'Search' } = props;
    let { data, closeButtonText, closeButtonIcon, useCounter = true, useOptimize = false, onOptimize = (id: string) => {}, displayModuleType } = props;
    const [ isClickOutSideActive, setClickOutSide ] = useDetectOutsideClick(modalRef, active);

    const jsonLogic = parseJsonLogicFlow(parentData);
    const { initialNodes, initialEdges } = jsonLogic;
    const [ searchValue, setSearchValue ] = useState<string>('');
    const [ selectedFilters, setSelectedFilters ] = useState<any>({});
    const [ resetId, setResetId ] = useState<number>(0);

    // Map Selected Nodes Amount
    data = data.map((item) => {
        const { id } = item;
        const selectedItems = initialNodes.filter((node: any) => node.primaryId === id);
        return {...item, selectedAmount: selectedItems.length};
    });

    // On Filter Select
    const onFilterSelect = (data: any, filterIndex: number) => {
        setSelectedFilters({...selectedFilters, [filterIndex]: data});
    };


    // On Node Click
    const onNodeClick = (item: any) => {

        // Prevent action when flow is disabled
        if(isFlowDisabled) return;

        if (itemType === 'rule') {
            onItemSelect(item);
        } else {
            const nodeTemplate = getNodeTemplate(item.node.primaryId);

            const nodeId = uuid();
            const label = nodeTemplate.name;

            const newNode = {
                id: nodeId,
                nodeId: nodeId,
                primaryId: nodeTemplate.primaryId,
                type: nodesType,
                position: {
                    x: getRandom(250, 350),
                    y: getRandom(0, 150)
                },
                name: nodeTemplate.name,
                data: {
                    label
                },
                description: nodeTemplate.description,
                abstractionType: AbstractionType[nodesType],
                accessType: nodeTemplate.accessType || 1,
                additionalInfo: nodeTemplate.additionalInfo,
                createdByCompany: "ec3ca9d3-f9cd-4707-9520-0e0c7d9aceb2",
                disorderTypeID: nodeTemplate.disorderTypeID || 1,
                jsonLogic:[],
                lastUpdateDate: (new Date()).toISOString(),
                parent: null,
                nodeType: "node",
                moduleTypeID: nodeTemplate.moduleTypeID
            };

            const data = {
                ...parentData,
                jsonLogic: [
                    {name: 'initialEdges', data: [...initialEdges]},
                    {name: 'initialNodes', data: [...initialNodes, newNode]}
                ]
            };

            updateParent({...data});
        }
    };

    // On Node Delete
    const onNodeDelete = (item: any) => {
        const node = initialNodes.find((node: any) => node.primaryId === item.id);
        setDeleteNodeId(node.id);
    };

    // On Search
    const onSearch = (value: string) => {
        setSearchValue(value);
    };

    // Filter By Search
    const filterBySearch = (item: any) => {
        return item.name.toLowerCase().includes(searchValue.toLowerCase());
    };

    // Filter By Filters
    const filterByFilters = (module: any) => {
        const keys = Object.keys(selectedFilters);
        const flatFilterData = keys.map((filterIndex: string) => selectedFilters[filterIndex].map((item: any) => ({...item, filterIndex}))).flat();
        // // console.log('selectedFilters', flatFilterData);
        const res = flatFilterData.some((item: any) => {
            const currentFilterData = filters[item.filterIndex];
            const fieldOfFilterData = currentFilterData['fieldOfFilterData']; // string
            const fieldOfListData = currentFilterData['fieldOfListData']; // string
            return module[fieldOfListData] === item[fieldOfFilterData];
        });

        return flatFilterData.length ? res : true;
    };

    // Reset Filter
    const resetFilter = () => {
        setSearchValue('');
        setSelectedFilters({});
        setResetId(performance.now());
    };

    useEffect(() => {
        active === true && setClickOutSide(true);
    }, [active]);

    useEffect(() => {
        isClickOutSideActive === false && setActive(false);
    }, [isClickOutSideActive]);

    return (
        <Drawer overlay={true} top="60px" className={ css.AddNodesToFlowModal } active={ active } onClose={ () => setActive(false) } title={ title } closeButtonText={closeButtonText} closeButtonIcon={closeButtonIcon}>

            {/* Top Bar */}
            <Row>

                {/* Search */}
                <Row grow="1" alignitems="center" style={{flexBasis: 0}}>
                    <Input type="text" icon={iconsTypes.search} value={ searchValue } placeholder={searchPlaceholder} onChange={ onSearch } />
                </Row>

                {/* Filters */}
                <Row grow="1" alignitems="center" justifycontent="end" style={{flexBasis: 0}}>
                    {
                        filters.length === 0 ? null : <>
                            <Row className={ css.Filter }>
                                {
                                    filters.map((filter: any, index: number) => <Row key={index} grow="1" paddingRight="0.25rem">
                                        <DropdownMulti
                                            hasSearch={true}
                                            reset={resetId}
                                            initialSelected={selectedFilters[index]}
                                            showCountSelected={true}
                                            placeholder={filter.title}
                                            data={filter.data}
                                            displayField={filter.displayField}
                                            selectField={filter.fieldOfFilterData}
                                            onSelect={(data: any) => onFilterSelect(data, index)}
                                        />
                                    </Row>)
                                }
                            </Row>
                        </>
                    }
                </Row>

                {/* Reset */}
                <Row alignitems="center">
                    <Button text="Reset filter" type="secondary" variant="text" icon={iconsTypes.reset} onClick={resetFilter} />
                </Row>
            </Row>

            {/* Grid */}
			<Grid scroll="true" className={ css.Grid }>
				{
					data
						.filter(filterBySearch)
						.filter(filterByFilters)
						.map((item: any, index: number) => <Col className={ css.Cell } key={item.id}>

							{/* Name + Controlls */}
							<Row className={ css.Name }>

								{/* Name */}
								<Row grow="1" alignitems="start">
									{/* Icon */}
									{item.icon && (
										<Row className={css.Icon} marginRight="0.5rem">
											{/* @ts-ignore */}
											<Icon name={iconsTypes[item.icon]} size="18" />
										</Row>
									)}
											<Text wordBreak="break-all" text={ item.name } bold="true" size="x3" className={css.ItemName} ellipsis={true} maxLines={2} />
										</Row>

									{/* Controlls */}
										<Row>

									{/* More Menu */}
									{
										item.isEditEnabled !== true ? null :
											<Row paddingLeft="0.5rem" alignitems="start">
												<MoreMenu>
													{/* Edit */}
													{
														(nodesType === 'my' || nodesType === 'clinicalResearch') ? <EditModuleModalActionButton className={ css.MoreMenuItem } type="secondary" variant="text" text="Edit" icon={ iconsTypes.pen } uid={ item.node.primaryId } /> : null
													}
													{
														(nodesType === 'ruleset' || nodesType === 'myRuleset') ? <EditRulesetModalActionButton className={ css.MoreMenuItem } type="secondary" variant="text" text="Edit" icon={ iconsTypes.pen } uid={ item.node.primaryId } /> : null
													}

													{/* Delete */}
													{
														props.tplType === 'module' ? <DeleteModuleTemplateActionButton className={ css.MoreMenuItem } type="secondary" variant="text" text="Delete" icon={ iconsTypes.delete } uid={item.id} /> : null
													}
													{
														props.tplType === 'ruleset' ? <DeleteRulesetTemplateActionButton className={ css.MoreMenuItem } type="secondary" variant="text" text="Delete" icon={ iconsTypes.delete } uid={item.id} /> : null
													}

												</MoreMenu>
											</Row>
									}
								</Row>
							</Row>

							{/* Dynamic Module Type - Health Module Label */}
							{
								displayModuleType === true && item.moduleTypeID === 1 ? (
									<Row alignitems="center" className={css.ModuleType}>
										<Row>
											<Icon name={iconsTypes.heartRate} size="16" style={{display: 'flex'}} />
										</Row>
										<Row paddingLeft="0.5rem">
											Health
										</Row>
									</Row>
								) : null
							}

							{/* Clinical Research Label */}
							{
								displayModuleType === true && item.moduleTypeID === 6 ? (
									<Row alignitems="center" className={css.ModuleType}>
										<Row>
											<Icon name={iconsTypes.microscope} size="16" style={{display: 'flex'}} />
										</Row>
										<Row paddingLeft="0.5rem">
											Clinical Research
										</Row>
									</Row>
								) : null
							}

							{/* Description */}
							<Row className={css.Description} grow="1">
								<Text text={ item.description } size="x2" className={css.ItemName} ellipsis={true} maxLines={5} />
							</Row>

							{/* Footer */}
							<Row className={css.Footer}>

								{/* Optimize */}
								{
									useOptimize === false ? null :
										<Row>
											<Button className={ classNames(css.Optimize, item.optimized ? css.Active : '') } variant="text" type="secondary" icon={item.optimized ? iconsTypes.verify : iconsTypes.notVerified} onClick={item.optimized ? () => {} : () => onOptimize(item.id)}>{item.optimized ? 'Optimized' : 'Optimize'}</Button>
										</Row>
								}

								{/* Counter */}
								<Row alignitems="center" grow="1" justifycontent="end">
									{
										useCounter === true ?
											<Counter amount={item.selectedAmount} onIncrease={ () => onNodeClick(item)} onDecrease={ () => onNodeDelete(item)} />
											:
											<Button icon={iconsTypes.plus} type="secondary" variant="controls" style={{padding: '1rem'}} onClick={ () => onNodeClick(item)} />
									}
								</Row>
							</Row>
						</Col>)
				}
			</Grid>
        </Drawer>
    )
};

export { AddNodesToFlowModalNew };
