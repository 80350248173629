import { FC, useContext, useEffect, useRef, useState } from "react";
import { Col, IValidationResult, Row } from "lavaa";
import css from "../ModuleSettings.module.scss";
import { Dropdown, Input } from "lavaa";
import { CSVInlineLoader } from "lavaa";
import { Button } from "lavaa";
import { iconsTypes } from "lavaa";
import { ModalsCtx } from "../../../Context/Modals.context";
import { useMapping } from "../../../Hooks/UseMapping";
import { useClients } from "../../../Hooks/UseClients";
import { fileToBase64 } from "../../../Tools/files";

interface IProps {
	module: any,
	onChange: (newModuleData: any, immediatelySave?: boolean) => void,
	onValidate: (value: boolean) => void
}

// Local File Module Settings
const LocalFileModule: FC<IProps> = (props) => {

	const { setMappingModalActive, isModuleSettingsModalActive, setModuleSettingsModalActive, setMappingModalHeaders, localFileModuleFile, setLocalFileModuleFile } = useContext(ModalsCtx);
	const { module, onChange } = props;
	const { mappings, lastAddedMappingUID } = useMapping();
	const { deleteLocalFile } = useClients();

	// Refs
	const fileHeader = useRef<string[] | null>(null);
	const lastAddedMappingRef = useRef<string>(lastAddedMappingUID);

	// States
	const [inputs, setInputs] = useState({
		name: module.name,
		mapping: module.mapping
	});
	const [fileName, setFileName] = useState<string | undefined>((module.data && module.data.file) ? "File loaded" : undefined);
	const [isNameValid, setIsNameValid] = useState(false);
	const [isFormValid, setIsFormValid] = useState(false);

	const mappingsSelect = Object.values(mappings).map((map: any) => ({ id: map.mappingUID, value: map.name }));
	const mapping = mappingsSelect.find((map: any) => map.id === inputs.mapping) || null;

	// On Validate
	const onValidate = (data: IValidationResult) => {
		const { name, error, valid } = data;

		// Module Name
		if (name === 'moduleName' && isNameValid !== valid) {
			setIsNameValid(valid);
		}
	};

	// Watch form valid
	useEffect(() => {

		// Set Form as Valid
		if (isNameValid && !isFormValid) {
			setIsFormValid(true);
			props.onValidate && props.onValidate(true);
		}

		// Set Form as Not Valid
		if (!isNameValid && isFormValid) {
			setIsFormValid(false);
			props.onValidate && props.onValidate(false);
		}
	}, [isNameValid]);

	useEffect(() => {
		if (localFileModuleFile) {
			changeFileHandler(localFileModuleFile);
		}
	}, [isModuleSettingsModalActive]);

	useEffect(() => {
		if (!isModuleSettingsModalActive) return;
		if (lastAddedMappingRef.current === lastAddedMappingUID) return;

		lastAddedMappingRef.current = lastAddedMappingUID
		changeMappingHandler({ id: lastAddedMappingUID });
	}, [lastAddedMappingUID]);

	// Handle Module Name Input
	const handleModuleName = (value: any) => {
		const newInputs = { ...inputs, name: value };
		setInputs(newInputs);

		const update = Object.assign({}, module, {
			"name": value,
			data: {
				...module.data,
				label: value
			}
		});
		onChange(update);
	};

	function uploadHandler(data: any, file: File) {
		fileHeader.current = data[0] as Array<string>;
		changeFileHandler(file);
	}

	async function changeFileHandler(file: File) {
		setFileName(file.name);
		setLocalFileModuleFile(file);

		const fileBase64 = await fileToBase64(file);

		const update = Object.assign({}, module, {
			data: {
				...module.data,
				fileData: fileBase64
			}
		});
		onChange(update);
	}

	function clearFileInputHandler() {
		setLocalFileModuleFile(null);
		setFileName(undefined);

		if (module.data && module.data.file) {
			deleteLocalFile(module.data.file);
			const update = { ...module, data: { ...module.data } };
			delete update.data.file;
			onChange(update, true);
		}
	}

	function addNewMapping() {
		setModuleSettingsModalActive(false);
		setMappingModalHeaders(fileHeader.current);
		setMappingModalActive(true);
	}

	function changeMappingHandler(value: any) {
		const newInputs = { ...inputs, mapping: value.id };
		setInputs(newInputs);

		const update = Object.assign({}, module, {
			"mapping": value.id
		});
		onChange(update);
	}

	return (
		<Col paddingTop="1rem" paddingBottom="1rem" className={css.Box}>

			{/* Name */}
			<Row grow="1" paddingBottom="1rem">
				<Input type="text" name="moduleName" wrap="col" label="*Module name" placeholder="Module name, max length is 50 characters" onChange={handleModuleName} value={inputs.name} maxlength={50} validation="required" onValidate={onValidate} />
			</Row>

			{/* Add File */}
			<Row grow="1" paddingBottom="1.75rem">
				<CSVInlineLoader onUpload={uploadHandler} onClear={clearFileInputHandler} fileName={fileName} label="Add file" />
			</Row>

			{/* Mapping Form */}
			{
				mappingsSelect.length > 0 && (
					<Row grow="1" paddingBottom="1rem">
						<Dropdown
							wrap="col"
							label="Mapping"
							placeholder="Mapping"
							hidePlaceholder={true}
							data={mappingsSelect}
							selected={mapping}
							onSelect={changeMappingHandler}
						/>
					</Row>
				)
			}

			{/* Add New Mapping */}
			<Row grow="1" paddingBottom="1rem">
				<Button text="Add New Mapping" icon={iconsTypes.plusCircle} type="secondary" variant="text" onClick={addNewMapping} />
			</Row>
		</Col>
	)
};

export { LocalFileModule };
