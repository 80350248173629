import { useDispatch, useSelector } from 'react-redux';
import {
    addActiveRuleAction,
    addRulesetDataOutputAction,
    resetRulesetActiveRuleAction,
    resetRulesetDataAction,
    resetRulesetsDataAction
} from "../Redux/Slices/RulesetData/RulesetData.slice";
import { useContext, useEffect } from "react";
import { ModalsCtx } from "../Context/Modals.context";
import { useParams } from "react-router-dom";

export const useRulesetData = (needReset: boolean = false): any => {

    let { isEditRuleModalActive, setEditRuleModalActive } = useContext(ModalsCtx);

    const { activeRulesetId } = useParams();
    const dispatch = useDispatch();

   // Selectors
    const activeRule = useSelector((state: any) => state.rulesetData.activeRule);
    const rulesetOutput = useSelector((state: any) => state.rulesetData.output);

    useEffect(() => {
        if(!isEditRuleModalActive){
            resetRulesetActiveRule();
        }
    }, [isEditRuleModalActive]);

    useEffect(() => {
        if(needReset) resetRulesetData();
    }, [activeRulesetId]);

    // Add Ruleset Data Output
    const addRulesetDataOutput = (output: string) => {
        dispatch(addRulesetDataOutputAction([output]));
    };

    // Add Active Rule
    const addActiveRule = (ruleId: string) => {
        dispatch(addActiveRuleAction(ruleId));
        setEditRuleModalActive(true);
    };

    // Reset Ruleset Data Active Rule
    const resetRulesetActiveRule = () => {
        dispatch(resetRulesetActiveRuleAction([]));
    };

    // Reset Rule Data
    const resetRulesetData = () => {
        dispatch(resetRulesetDataAction([]));
    };

    // Reset Rulesets Data
    const resetRulesetsData = () => {
        dispatch(resetRulesetsDataAction());
    };

    return {
        addActiveRule, addRulesetDataOutput, // Add
        activeRule, rulesetOutput, // Select
        resetRulesetData, resetRulesetActiveRule,
        resetRulesetsData
    };
}