import { FC } from 'react';
import { Row, Button, iconsTypes } from 'lavaa';

interface IProps {
    link: string
}

// Report Link
const ReportLink: FC<IProps> = (props) => {

    // Props
    const { link } = props;

    // Open Link
    const openLink = () => {
        window.open(link);
    }

    return <Button onClick={ openLink } text="Export to Excel" icon={iconsTypes.excel} type="secondary" />
};

export { ReportLink };