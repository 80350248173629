import { FC } from "react";
import { Grid, LogicEditor } from "lavaa";
import { useClients } from "../../../../Hooks/UseClients";

interface IProps {
	jsonLogic: any,
	onChange: ( filterData: any ) => void,
	scheme: any
}

// Filter Settings
const Filter: FC<IProps> = ( props ) => {

	const { jsonLogic, onChange, scheme } = props;
	const { clientScheme } = useClients();

	// On Json Logic Changed
	const onJsonLogicChanged = ( val: any ) => {
		onChange( val );
	};

	return (
		<Grid paddingTop="1rem" paddingBottom="1rem" scroll="true">
			<LogicEditor
				jsonLogic={jsonLogic}
				onChange={onJsonLogicChanged}
				showError={false}
				scheme={scheme ? scheme : clientScheme}
			/>
		</Grid>
	)
};

export { Filter };
