import { FC, useEffect, useState } from "react";
import { Row, Col, IValidationResult } from 'lavaa';
import { Input, Dropdown } from "lavaa";
import { useTypes } from "../../../../../Hooks/UseTypes";
import { IRuleType } from "../../../../../Redux/Slices/Rules/Rules.data";
import css from "../CreateNewRuleModal.module.scss";

interface IProps {
  data: IRuleType,
  onChange: (newRuleData: any) => void,
  onValidate: (value: boolean) => void
}

// Create New Rule Description
const CreateNewRuleDescription: FC<IProps> = (props) => {

  const { data } = props;
  const { types } = useTypes();
  const RuleCategory = types['Rules.RuleCategory'] || [];
  const categories = RuleCategory.map((cat: any) => ({ id: cat.typeID, value: cat.name }));
  const category = categories.find((cat: any) => cat.id === data.ruleCategoryID) || null;

  // States
  const [isNameValid, setIsNameValid] = useState(false);
  const [isScoreValid, setIsScoreValid] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  // On Validate
  const onValidate = (data: IValidationResult) => {
    const { name, valid } = data;

    // Rule Name
    if (name === 'ruleName' && isNameValid !== valid) {
        setIsNameValid(valid);
    }

    // Rule Score
    if (name === 'ruleScore' && isScoreValid !== valid) {
        setIsScoreValid(valid);
    }
  };

  // On Change
  const onChange = (value: any, field: string) => {
    const newData = { ...data, [field]: value };
    props.onChange(newData);
  };

  // On Score Change
  const changeScoreHandler = (value: any) => {
    const newScore = parseInt(value) || 0;
    onChange(newScore, 'score');
  }

  // On Category Change
  const changeCategoryHandler = (value: any) => {
    onChange(value.id, 'ruleCategoryID');
  }

  // Watch form valid
  useEffect(() => {

    // Set Form as Valid
    if (isNameValid && isScoreValid && !isFormValid) {
        setIsFormValid(true);
        props.onValidate && props.onValidate(true);
    }

    // Set Form as Not Valid
    if ((!isNameValid || !isScoreValid) && isFormValid) {
        setIsFormValid(false);
        props.onValidate && props.onValidate(false);
    }
  }, [isNameValid, isScoreValid]);

  return (
    <Col paddingTop="1.75rem" paddingBottom="1.75rem" paddingRight="1rem" className={css.Box} scroll="true" grow="1">

      {/* Name */}
      <Row grow="1" paddingBottom="1rem">
        <Input type="text" name="ruleName" wrap="col" label="*Rule name" placeholder="Rule name, max length is 50 characters" onChange={(value: string) => onChange(value, 'name')} value={data.name} maxlength={50} validation="required" onValidate={onValidate} />
      </Row>

      <Row grow="1" paddingBottom="1rem">

        {/* Score */}
        <Row shrink="0" paddingBottom="1rem" paddingRight="1rem">
          <Input type="text" name="ruleScore" wrap="col" label="*Score" placeholder="Score" onChange={changeScoreHandler} value={((data.score ?? "").toString())}
								 validation={
										{
											type: 'required',
											pattern: /^[^\s].+[^\s]$/,
											message: 'Required'
										}
								 }
								 onValidate={onValidate}
					/>
        </Row>

        {/* Category */}
        <Row grow="1" paddingBottom="1rem">
          <Dropdown wrap="col" label="Category" placeholder="Category" hidePlaceholder={true} data={categories} selected={category} onSelect={changeCategoryHandler} />
        </Row>
      </Row>

      {/* Description */}
      <Row grow="1" paddingBottom="1rem">
        <Input style={{ height: 150 }} type="textarea" name="ruleDescription" wrap="col" label="Rule Description" placeholder="Rule description, max length is 200 characters" onChange={(val: any) => onChange(val, 'description')} value={data.description} maxlength={200} onValidate={onValidate} />
      </Row>

      {/* Additional Info */}
      <Row grow="1" paddingBottom="1rem">
        <Input type="editor" wrap="col" label="Additional Info" placeholder="Additional Info" onChange={(value: string) => onChange(value, 'additionalInfo')} value={data.additionalInfo} style={{ minHeight: '50px', width: '100%', height: '125px' }} fullScreen={true} />
      </Row>
    </Col>
  )
};

export { CreateNewRuleDescription };
