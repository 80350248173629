import { FC, useContext, useEffect, useState } from "react";
import { ModalsCtx } from "../../../../Context/Modals.context";
import { Modal, RuleEditor, ITabsContent, Tabs } from "lavaa";
import { EditRuleDescription } from "./Description/EditRuleDescription.component";
import { EditRuleTest } from "./Test/EditRuleTest.component";
import { HeaderContent } from "./HeaderContent/HeaderContent.component";
import { useRules } from "../../../../Hooks/UseRules";
import { useRulesets } from "../../../../Hooks/UseRulesets";
import { useClients } from "../../../../Hooks/UseClients";
import { IRuleType } from "../../../../Redux/Slices/Rules/Rules.data";
import JL from "json-logic-js";
import { prepareJsonLogicByDateFields, prepareTestData } from "../../../../Tools/parseJsonLogic";

interface IProps {
  rule: IRuleType | null,
}

// Edit Rule Modal
const EditRuleModal: FC<IProps> = (props) => {

  const { rule } = props;
  const { updateRuleScore } = useRulesets();
  const { cloneRule, updateRule } = useRules();
  const { getClientScheme, clientScheme } = useClients();

  // Context
  const { isEditRuleModalActive, setEditRuleModalActive } = useContext(ModalsCtx);

  // States
  const [ruleData, setRuleData] = useState<IRuleType | null>(null);
  const [testData, setTestData] = useState<{ [key: string]: any }>({});
  const [activeTab, setActiveTab] = useState(0);
  const [ruleRunStatus, setRuleRunStatus] = useState<boolean | null>(null);
  const [isRuleParametersValid, setRuleParametersValid] = useState<boolean>(false);

  useEffect(() => {
    getClientScheme();
  }, [])

  useEffect(() => {
	  if(isEditRuleModalActive){
		  setRuleData(rule);
		  setRuleRunStatus(null);
		  setTestData({});
	  }
  }, [rule?.uid, isEditRuleModalActive]);

  useEffect(() => {
    setRuleRunStatus(null);
  }, [activeTab]);

  if (!ruleData) return null;
  const isTemplate = ruleData.score === null || ruleData.score === undefined;

  function changeHandler(newRuleData: any) {
    setRuleData(newRuleData);
  }

  // On Rule Parameters Validate
  const onRuleParametersValidate = (isValid: boolean) => {
    setRuleParametersValid(isValid);
  };

  // Handle Close
  const handleClose = () => {
    setEditRuleModalActive(false);
  };

  // Handle Cancel
  const handleCancel = () => {
    setEditRuleModalActive(false);
  };

  // Handle Ok
  const handleOk = () => {
    if (!rule) {
      setEditRuleModalActive(false);
      return;
    }

    if (isTemplate) {
      cloneRule(ruleData.uid);
      return;
    }

    if (activeTab === 0 || activeTab === 1) {
      const data = Object.assign({}, ruleData, { score: rule.score });
      updateRule(data);
      updateRuleScore(ruleData.uid, ruleData.score);
    }

    if (activeTab === 2) {
		const jsonLogicPrepared = prepareJsonLogicByDateFields(ruleData.jsonLogic, clientScheme);
		const preparedTestData = prepareTestData(testData);
      	const result = JL.apply(jsonLogicPrepared, preparedTestData);
      	setRuleRunStatus(result);
    }


    if (activeTab === 0) {
      setEditRuleModalActive(false);
    }
  };

  // Default Tabs View
  let tabs: ITabsContent[] = [
    {
      title: "Edit rule",
      content: <EditRuleDescription data={ruleData} isTemplate={isTemplate} onChange={changeHandler} onValidate={onRuleParametersValidate} />
    },
  ];

  // Extended Tabs View
  if (!isTemplate) {
    tabs.push(
      {
        title: "Rule Editor",
        content: <RuleEditor rule={ruleData} onChange={changeHandler} scheme={clientScheme} />,
        disabled: !isRuleParametersValid
      },
      {
        title: "Test",
        content: <EditRuleTest rule={ruleData} onChange={setTestData} runStatus={ruleRunStatus} inputs={testData} />,
        disabled: !isRuleParametersValid
      }
    );
  }

  let okText = "Save";
  if (activeTab === 2) okText = "Run";
  if (isTemplate) okText = "Clone";

  return <Modal
    active={isEditRuleModalActive}
    onClose={handleClose}
    onCancel={handleCancel}
    onOk={handleOk}
    headerContent={<HeaderContent rule={ruleData} isTemplate={isTemplate} />}
    okText={okText}
    cancelText="Cancel"
    type="large"
    hasTab={true}
    height="90vh"
    isFormValid={isTemplate ? true : isRuleParametersValid}
  >
    <Tabs content={tabs} onChange={setActiveTab} />
  </Modal>
};

export { EditRuleModal };
