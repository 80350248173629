interface ITheme {
  [key: string]: string
}

const light: ITheme = {
  //Background Colors
  "--primary-bg-color": "#ffffff",
  "--header-bg-color": "#3B54D0",
  "--secondary-bg-color": "#F5F6F8",
  "--dander-bg-color": "#FCE6EA",
  "--success-bg-color": "#EAF6E7",
  "--warning-bg-color": "#FFF3E0",
  "--popup-screen-bg-color": "rgba(232, 232, 240, 0.5)",
  "--disable-bg-color": "#EDF0F4",

  //Text Colors
  "--primary-text-color": "#0C1E2A",
  "--nodes-text-color": "#0C1E2A",
  "--disable-text-color": "#CDD3DB",
  "--danger-text-color": "#E40931",
  "--success-text-color": "#2AA40C",
  "--warning-text-color": "#FF9800",

  //Icon Colors
  "--primary-bg-icons-color": "#3B54D0",
  "--secondary-bg-icons-color": "#A5B2C4",
  "--alternative-primary-icons-color": "#FFFFFF",
  "--success-icons-color": "#00C853",
  "--danger-icons-color": "#E40931",
  "--disable-icons-color": "#CDD3DB",

  //Alternative Colors
  "--alternative-primary-color": "#3B54D0",
  "--alternative-secondary-bg-color": "#EAF0F5",
  "--alternative-primary-text-color": "#FFFFFF",
  "--alternative-secondary-text-color": "#C6CAF8",

  //Sidebar Colors
  "--sidebar-primary-color": "#ffffff",
  "--sidebar-hover-color": "#F5F6F8",
  "--sidebar-primary-text-color": "#3B54D0",
  "--sidebar-text-color": "#A5B2C4",

  //Primary Button
  "--primary-button-bg-color": "#3B54D0",
  "--primary-button-text-color": "#FFFFFF",
  "--primary-button-hover-text-color": "#FFFFFF",
  "--primary-button-active-color": "linear-gradient(74.59deg, #2731D0 43.14%, #5C46CE 95.74%)",

  //Secondary Button
  "--secondary-button-bg-color": "#FFFFFF",
  "--secondary-button-text-color": "#3B54D0",
  "--secondary-button-border-color": "#EAF0F5",
  "--secondary-button-hover-color": "#3B54D0",
  "--secondary-button-hover-text-color": "#FFFFFF",
  "--secondary-button-active-color": "linear-gradient(74.59deg, #2731D0 43.14%, #5C46CE 95.74%)",

  //Danger Button
  "--danger-button-bg-color": "#E40931",
  "--danger-button-text-color": "#FFFFFF",
  "--danger-button-bg-hover-color": "#C8062A",

  //Success Button
  "--success-button-bg-color": "#00C853",

  //Disable Button
  "--disable-button-bg-color": "#F5F6F8",
  "--disable-button-text-color": "#CDD3DB",
  "--disable-button-border-color": "#CDD3DB",

  //Gradient Colors
  "--primary-gradient-color": "linear-gradient(74.59deg, #2731D0 43.14%, #5C46CE 95.74%)",
  "--secondary-gradient-color": "linear-gradient(180deg, #FFFFFF 0%, #F0F1F3 143.32%)",

  //Flow nodes Colors
  "--additional-blue-light": "#BBDEFB",
  "--additional-blue": "#2962FF",
  "--additional-yellow-light": "#FFCC80",
  "--additional-yellow": "#FFA726",
  "--additional-violet-light": "#D3BAFF",
  "--additional-violet": "#7C4DFF",
  "--additional-green-light": "#B9F6CA",
  "--additional-green": "#00E676",

  //Background Images
  "--auth-background-image": "url(/Images/auth_bg.jpg)",

  //Shadows
  "--node-shadow": "0 2px 10px rgba(0, 0, 0, 0.2)",
}

const dark: ITheme = {
  //Background Colors
  "--primary-bg-color": "#272B30",
  "--header-bg-color": "#272B30",
  "--secondary-bg-color": "#111315",
  "--dander-bg-color": "#FCE6EA",
  "--success-bg-color": "#EAF6E7",
  "--warning-bg-color": "#FFF3E0",
  "--popup-screen-bg-color": "rgba(26, 29, 31, 0.5)",
  "--disable-bg-color": "#272B30",

  //Text Colors
  "--primary-text-color": "#FFFFFF",
  "--nodes-text-color": "#111315",
  "--disable-text-color": "#585E64",
  "--danger-text-color": "#E40931",
  "--success-text-color": "#2AA40C",
  "--warning-text-color": "#FF9800",

  //Icon Colors
  "--primary-bg-icons-color": "#FFFFFF",
  "--secondary-bg-icons-color": "#A7AEB5",
  "--alternative-primary-icons-color": "#FFFFFF",
  "--success-icons-color": "#00C853",
  "--danger-icons-color": "#E40931",
  "--disable-icons-color": "#585E64",

  //Alternative Colors
  "--alternative-primary-color": "#4985FA",
  "--alternative-secondary-bg-color": "#3B4046",
  "--alternative-primary-text-color": "#FFFFFF",
  "--alternative-secondary-text-color": "#919497",

  //Sidebar Colors
  "--sidebar-primary-color": "#1A1D1F",
  "--sidebar-hover-color": "#272B30",
  "--sidebar-primary-text-color": "#FFFFFF",
  "--sidebar-text-color": "#6F767E",

  //Primary Button
  "--primary-button-bg-color": "#4985FA",
  "--primary-button-text-color": "#FFFFFF",
  "--primary-button-hover-text-color": "#FFFFFF",
  "--primary-button-active-color": "linear-gradient(74.59deg, #2731D0 43.14%, #5C46CE 95.74%)",

  //Secondary Button
  "--secondary-button-bg-color": "#272B30",
  "--secondary-button-text-color": "#A7AEB5",
  "--secondary-button-border-color": "#626972",
  "--secondary-button-hover-color": "#4985FA",
  "--secondary-button-hover-text-color": "#FFFFFF",
  "--secondary-button-active-color": "linear-gradient(74.59deg, #2731D0 43.14%, #5C46CE 95.74%)",

  //Danger Button
  "--danger-button-bg-color": "#E40931",
  "--danger-button-text-color": "#FFFFFF",
  "--danger-button-bg-hover-color": "#C8062A",

  //Success Button
  "--success-button-bg-color": "#00C853",

  //Disable Button
  "--disable-button-bg-color": "#393B3E",
  "--disable-button-text-color": "#585E64",
  "--disable-button-border-color": "#44474A",

  //Gradient Colors
  "--primary-gradient-color": "linear-gradient(74.59deg, #4851E3 43.14%, #775FF1 95.74%)",
  "--secondary-gradient-color": "linear-gradient(180deg, #23262F 1.84%, #413F42 153.8%)",

  //Flow nodes Colors
  "--additional-blue-light": "#BBDEFB",
  "--additional-blue": "#2962FF",
  "--additional-yellow-light": "#FFCC80",
  "--additional-yellow": "#FFA726",
  "--additional-violet-light": "#D3BAFF",
  "--additional-violet": "#7C4DFF",
  "--additional-green-light": "#B9F6CA",
  "--additional-green": "#00E676",

  //Background Images
  "--auth-background-image": "url(/Images/auth_bg_dark.jpg)",

  //Shadows
  "--node-shadow": "none",
}

const getThemeData = ( theme: string ): Promise<ITheme> => {
  let data;
  switch (theme) {
    case "light":
      data = light;
      break;
    case "dark":
      data = dark;
      break;
    default:
      data = light;
      break;
  }

  return Promise.resolve( data );
};

export { getThemeData };