import { FC } from 'react';
import { getBezierPath, getMarkerEnd } from 'reactflow';
import { Icon, iconsTypes } from 'lavaa';
import css from './Edge.module.scss';

export const Edge: FC<any> = ( props ) => {
  const { id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, arrowHeadType, markerEndId, data = {} } = props;
  const { deleteHandler } = data;
  const [edgePath, labelX, labelY] = getBezierPath( { sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition } );
  const markerEnd = getMarkerEnd( arrowHeadType, markerEndId );
  const foreignObjectSize = 18;

  // Click On Edge Delete
  const onEdgeClick = (e: any, edgeId: any) => {
    e.stopPropagation();
    deleteHandler(edgeId);
  };

  return (
    <>
      <path id={id} className={css.Edge} d={edgePath} markerEnd={markerEnd}/>
      <foreignObject
        style={{backgroundColor: 'var(--primary-bg-color)'}}
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={labelX - foreignObjectSize / 2}
        y={labelY - foreignObjectSize / 2}
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <div className={css.EdgeDelete} onClick={(e) => onEdgeClick(e, id)}>
          <Icon name={iconsTypes.edgeX} />
        </div>
      </foreignObject>
    </>
  );
};
