import { configureStore } from '@reduxjs/toolkit';
import { signalRInvokeMiddleware } from '../Middleware/Connector';
import typesReducer from './Types/Types.slice';
import modulesReducer from './Modules/Modules.slice';
import projectsReducer from './Projects/Projects.slice';
import rulesReducer from './Rules/Rules.slice';
import rulesetsReducer from './Rulesets/Rulesets.slice';
import accountReducer from './Account/Account.slice';
import notificationsReducer from './Notifications/Notifications.slice';
import projectTemporaryReducer from './Projects/ProjectTemporary.slice';
import rulesetDataReducer from './RulesetData/RulesetData.slice';
import clientsReducer from './Clients/Clients.slice';
import mappingReducer from './Mapping/Mapping.slice';
import projectReportReducer from './ProjectReport/ProjectReport.slice';
import guidelinesReducer from './Guidelines/Guidelines.slice';

export default configureStore({
    reducer: {
        types: typesReducer,
        modules: modulesReducer,
        projects: projectsReducer,
        rules: rulesReducer,
        rulesets: rulesetsReducer,
        account: accountReducer,
        notifications: notificationsReducer,
        projectTemporary: projectTemporaryReducer,
        rulesetData: rulesetDataReducer,
        clients: clientsReducer,
        mapping: mappingReducer,
        projectReport:  projectReportReducer,
        guidelines: guidelinesReducer
    },
    middleware: [signalRInvokeMiddleware]
});
