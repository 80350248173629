import { createSlice } from '@reduxjs/toolkit';

interface IMappingTypeState {
    mappings: any,
    lastAddedMappingUID: string,
}

const initialState:IMappingTypeState = {
    mappings: {},
    lastAddedMappingUID: '',
};

export const mappingSlice = createSlice({
    name: 'Mapping',
    initialState,
    reducers: {

        // Add Mapping
        addMappingAction: (state, action) => {},

        // Receive Add Mapping
        receiveAddMapping: (state, action) => {
            const mapping = action.payload.data;

            if(typeof mapping !== 'object') {
                return state;
            }

            mapping['mappingJSON'] = (mapping['mappingJSON'] && mapping['mappingJSON'].length > 0) ? JSON.parse(mapping['mappingJSON']): {};
            state.mappings[mapping.mappingUID] = mapping;
            state.lastAddedMappingUID = mapping.mappingUID;
        },

        // Update Mapping
        updateMappingAction: (state, action) => {},

        // Receive Update Mapping
        receiveUpdateMapping: (state, action) => {
            const mapping = action.payload.data;

            if(typeof mapping !== 'object') {
                return state;
            }

            mapping['mappingJSON'] = (mapping['mappingJSON'] && mapping['mappingJSON'].length > 0) ? JSON.parse(mapping['mappingJSON']): {};
            state.mappings[mapping.mappingUID] = mapping;
        },

        // Delete Mapping
        deleteMappingAction: (state, action) => {},

        // Receive Delete Mapping
        receiveDeleteMapping: (state, action) => {
            const uid = action.payload.data;
            delete state.mappings[uid];
        },

        // Fetch Mappings
        getMappingsAction: (state, action) => {},

        // Receive Mappings
        receiveMappings: (state, action) => {
            const results = action.payload.data;
            let data: any = {};

            results.forEach((mapping: any) => {
                mapping['mappingJSON'] = (mapping['mappingJSON'] && mapping['mappingJSON'].length > 0) ? JSON.parse(mapping['mappingJSON']): {};
                data[mapping.mappingUID] = mapping;
            });
            state.mappings = data;
        },

        // Reset Mapping
		resetMappingAction: (state) => {
			return {...initialState};
		}
    }
});

export const {
    addMappingAction,
    getMappingsAction,
    updateMappingAction,
    deleteMappingAction,
    receiveAddMapping,
    receiveMappings,
    receiveUpdateMapping,
    receiveDeleteMapping,
    resetMappingAction
} = mappingSlice.actions;
export default mappingSlice.reducer;