import { useDispatch, useSelector } from 'react-redux';
import { getTypesAction, resetTypesAction } from '../Redux/Slices/Types/Types.slice';

export const useTypes = (): any => {
    const types = useSelector((state: any) => state.types);
    const disorderTypes = useSelector((state: any) => state.types['Rules.RuleDisorder']);
    const moduleTypes = useSelector((state: any) => state.types['Rules.ModuleType']) || [];

    const dispatch = useDispatch();

    // Fetch Types
    const fetchTypes = () => {
        dispatch(getTypesAction([]));
    };

    // Get Disorder Type By Id
    const getDisorder = (id: number) => {
        return disorderTypes.find((item: any) => item.disorderTypeID === id);
    };

    // Get Module Type By Id
    const getModuleType = (id: number) => {
        return moduleTypes.find((item: any) => item.typeID === id);
    };

    // Reset Types
    const resetTypes = () => {
        dispatch(resetTypesAction());
    };

    return { fetchTypes, types, disorderTypes, moduleTypes, getDisorder, getModuleType, resetTypes };
}