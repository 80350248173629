import { FC, useEffect, useState } from "react";
import { Button, Col, Element, iconsTypes, IValidationResult, Row } from "lavaa";
import css from "../../ModuleSettings.module.scss";
import { Input } from "lavaa";
import { FilterType } from "./RealTimeReport.component";

interface IProps {
	module: any,
	canAddClientFilter: boolean,
	canAddModuleFilter: boolean,
	onChange: (inputs: any) => void,
	onAddFilter: (filterType: FilterType) => void,
	onValidate: (value: boolean) => void,
	isEmailType: boolean,
	isSlackType: boolean
}

// Basic Edit Real Time Report Module
const BasicEdit: FC<IProps> = (props) => {

	const { module, onChange, onAddFilter, canAddClientFilter, canAddModuleFilter, isEmailType, isSlackType } = props;

	// States
	const [inputs, setInputs] = useState({ name: module.name, email: module.jsonLogic?.Email, slack: module.jsonLogic?.SlackChannel });
	const [isNameValid, setIsNameValid] = useState(false);
	const [isEmailValid, setIsEmailValid] = useState(false);
	const [isSlackValid, setIsSlackValid] = useState(false);
	const [isFormValid, setIsFormValid] = useState(false);

	// On Validate
	const onValidate = (data: IValidationResult) => {
		const { name, error, valid } = data;

		// Module Name
		if (name === 'moduleName' && isNameValid !== valid) {
			setIsNameValid(valid);
		}

		// Module Email
		if (name === 'moduleEmail' && isEmailValid !== valid) {
			setIsEmailValid(valid);
		}

		// Module Slack
		if (name === 'moduleSlack' && isSlackValid !== valid) {
			setIsSlackValid(valid);
		}
	};

	// Watch form valid
	useEffect(() => {

		// Set Form as Valid
		if (isNameValid && ((!isEmailType && !isSlackType) || (isEmailType && isEmailValid) || (isSlackType && isSlackValid)) && !isFormValid) {
			setIsFormValid(true);
			props.onValidate && props.onValidate(true);
		}

		// Set Form as Not Valid
		if (!isNameValid || ((isEmailType && !isEmailValid) || (isSlackType && !isSlackValid)) && isFormValid) {
			setIsFormValid(false);
			props.onValidate && props.onValidate(false);
		}
	}, [isNameValid, isEmailValid, isSlackValid]);

	// On Input Change
	const onChangeInput = (name: string, value: string) => {
		const inputsData = { ...inputs, [name]: value };
		setInputs(inputsData);

		onChange(inputsData);
	};

	return (
		<Col paddingTop="1rem" paddingBottom="1rem" className={css.Box}>

			{/* Name */}
			<Row grow="1" paddingBottom="1rem">
				<Input type="text" name="moduleName" wrap="col" label="*Module name" placeholder="Module name, max length is 50 characters" onChange={(value: string) => onChangeInput('name', value)} value={inputs.name} maxlength={50} validation="required" onValidate={onValidate} />
			</Row>

			{/* Email */}
			{
				isEmailType === true &&
				<Row grow="1" paddingBottom="1rem">
					<Input type="text" name="moduleEmail" wrap="col" label="*E-mail" placeholder="Enter email address" onChange={(value: string) => onChangeInput('email', value)} value={inputs.email} maxlength={50} validation="email" onValidate={onValidate} />
				</Row>
			}

			{/* Slack */}
			{
				isSlackType === true &&
				<Row grow="1" paddingBottom="1rem">
					<Input type="text" name="moduleSlack" wrap="col" label="*Slack" placeholder="Enter slack channel name" onChange={(value: string) => onChangeInput('slack', value)} value={inputs.slack} maxlength={50} validation="required" onValidate={onValidate} />
				</Row>
			}
			
			{
				canAddClientFilter || canAddModuleFilter ?
					(
						<Row grow="1" paddingBottom="1rem">
							{
								canAddClientFilter && (
									<Element paddingRight="1rem">
										<Button onClick={() => onAddFilter("client")} text="Add Clients Filter" type="secondary"
											icon={iconsTypes.plusCircle} />
									</Element>
								)
							}
							{
								canAddModuleFilter && (
									<Element paddingRight="1rem">
										<Button onClick={() => onAddFilter("module")} text="Add Modules Filter" type="secondary"
											icon={iconsTypes.plusCircle} />
									</Element>
								)
							}
						</Row>
					) : null
			}
		</Col>
	)
};

export { BasicEdit };
