import { FC, useContext, useEffect, useRef, useState } from "react";
import { ModalsCtx } from "../../../../Context/Modals.context";
import { IValidationResult, Modal } from 'lavaa';
import { MappingForm, MappingFormType } from "./Mapping.component";
import { useMapping } from "../../../../Hooks/UseMapping";
import { prepareHeadersAsMapping, prepareSchemeToSelect } from "../../../../Tools/schemeData";
import { useClients } from "../../../../Hooks/UseClients";

const emptyMapping: MappingFormType = {
  name: "",
  description: "",
  mappingJSON: [
      {
          input: "",
      }
  ]
}

// Mapping Modal
const MappingModal: FC<any> = () => {

    const { isMappingModalActive, setMappingModalActive, mappingModalUID, setMappingModalUID, mappingModalHeaders, setMappingModalHeaders, moduleSettingsUID, setModuleSettingsModalActive, setLocalFileModuleFile } = useContext(ModalsCtx);
    const mappingData = useRef<MappingFormType>(emptyMapping);
    
    const { addMapping, updateMapping, deleteMapping, getMapping } = useMapping();
    const { getClientMappingScheme, clientMappingScheme } = useClients();
    const isEditModal = mappingModalUID.length > 0;
    const fileHasLoaded = useRef<boolean>(false);
    const loadedFile = useRef<any>(null);

    // States
    const [loaded, setLoaded] = useState<boolean>(false);
    const [isMappingParametersValid, setMappingParametersValid] = useState<boolean>(false);

    // On Mapping Parameters Validate
    const onMappingParametersValidate = (isValid: boolean) => {
        setMappingParametersValid(isValid);
    };

	useEffect(() => {
		getClientMappingScheme();
	}, []);

    useEffect(() => {
        if(!isMappingModalActive){
            setLoaded(false);
            return;
        }

        const isEditModal = mappingModalUID.length > 0;
        if(isEditModal) {
            mappingData.current = getMapping(mappingModalUID) || {...emptyMapping};
            fileHasLoaded.current = true;
        }
        else {
            const mapping = {...emptyMapping};
            if(mappingModalHeaders) {
                const fields = prepareSchemeToSelect( clientMappingScheme );
                mapping.mappingJSON = prepareHeadersAsMapping(mappingModalHeaders, fields);
                fileHasLoaded.current = true;
            }
            mappingData.current = mapping;
        }
        setLoaded(true);
    },[mappingModalUID, isMappingModalActive]);

    function saveChanges(){
        if(isEditModal) {
            updateMapping(mappingData.current);
        }
        else{
            addMapping(mappingData.current);
        }
        handleClose();
    }

    // Handle Close
    const handleClose = () => {
        setMappingModalActive(false);
        setMappingModalUID('');
        setMappingModalHeaders(null);
        fileHasLoaded.current = false;

        if(moduleSettingsUID.length > 0){ // open by local file module settings
            setModuleSettingsModalActive(true);

            if(loadedFile.current !== null){
                setLocalFileModuleFile(loadedFile.current);
            }
        }

        setLoaded(false);
    };

    // Handle Ok
    const handleOk = () => {
        if(mappingData.current.name.length === 0 || mappingData.current.description.length === 0){
            alert("Please fill in name and description fields");
            return;
        }

        saveChanges();
        handleClose();
    };

    function changeHandler(newMappingData: any){
        mappingData.current = newMappingData;
    }

    function fileLoadHandler(file: any){
        loadedFile.current = file;
    }

    function handleDelete(){
        if(!isEditModal) return;
        deleteMapping(mappingModalUID);
        handleClose();
    }

    return <Modal
        active={ isMappingModalActive }
        onClose={ handleClose }
        onCancel={ handleClose }
        onOk={ handleOk }
        onDelete={ isEditModal ? handleDelete : undefined}
        title="Mapping"
        okText="Save"
        cancelText="Cancel"
        type="large"
        isFormValid={isMappingParametersValid}
    >
        {
          loaded && <MappingForm onChange={changeHandler} onFileLoad={fileLoadHandler} initValues={mappingData.current} fileNameInit={fileHasLoaded.current ? "File loaded" : undefined} onValidate={onMappingParametersValidate} />
        }
    </Modal>
};

export { MappingModal };
