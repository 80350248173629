import { FC, useEffect, useState } from "react";
import { Col, Input, IValidationResult, Row } from "lavaa";
import css from "../ModuleSettings.module.scss";

interface IProps {
  module: any,
  onChange: (newModuleData: any) => void,
  onValidate: (value: boolean) => void
}

// SQL Source Module Settings
const SqlSourceModule: FC<IProps> = (props) => {

  const { module, onChange } = props;
  const { jsonLogic } = module;
  const spName = module.jsonLogic.SPName;
  console.log('jsonLogic', jsonLogic);
  
  // States
  const [isModuleNameValid, setModuleNameValid] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  // On Validate
  const onValidate = (data: IValidationResult) => {
    const { name, valid } = data;

    // Module Name
    if (name === 'moduleName' && isModuleNameValid !== valid) {
      setModuleNameValid(valid);
  }
  };

  // Watch form valid
  useEffect(() => {

    // Set Form as Valid
    if (isModuleNameValid && !isFormValid) {
        setIsFormValid(true);
        props.onValidate && props.onValidate(true);
    }

    // Set Form as Not Valid
    if (!isModuleNameValid && isFormValid) {
        setIsFormValid(false);
        props.onValidate && props.onValidate(false);
    }
  }, [isModuleNameValid, isFormValid]);

    // Handle Module Name Input
    const handleModuleName = (value: any) => {
      const update = Object.assign({}, module, {
        "name": value,
        data: {
          ...module.data,
          label: value
        }
      });
  
      onChange(update);
    };

  // SP Name Input Handler
  const handleSpNameChange = (value: any) => {
    const updateJsonLogic = Array.isArray(jsonLogic) && jsonLogic.length === 0 
    ? { // Init
      SPName: value,
      Parameters: []
    } 
    : { // Update
      ...jsonLogic,
      SPName: value
    };
    
    const update = Object.assign({}, module, {
      jsonLogic: updateJsonLogic
    });

    onChange(update);
  };

  return (
    <Col paddingTop="1rem" paddingBottom="1rem" className={css.Box}>

      {/* Module name */}
      <Row grow="1" paddingBottom="1rem">
        <Input type="text" name="moduleName" wrap="col" label="*Module name" placeholder="Module name, max length is 50 characters" onChange={handleModuleName} value={module.name} maxlength={50} validation="required" onValidate={onValidate} />
      </Row>

      {/* Stored Procedure Name */}
      <Row paddingBottom="1rem">
        <Input type="text" name="spName" wrap="col" label="*Stored Procedure Name" placeholder="SP name, max length is 50 characters" onChange={handleSpNameChange} value={spName} maxlength={50} />
      </Row>
    </Col>
  )
};

export { SqlSourceModule };
