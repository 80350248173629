import { FC, useContext } from 'react';
import { AppCtx } from '../../Context/App.context';
import { ReactFlowProvider } from 'reactflow';
import { addSnackbar, Col, Grid, iconsTypes, Panel, PanelMenu, Splitter, useLocalStorage } from "lavaa";
import { prepareModulesAsMenuItems, useMenuModules } from '../../Hooks/Menu/UseMenuModules';
import { ModuleSettingsModal } from '../Custom/Modals/ModuleSettingsModal/ModuleSettingsModal.component';
import { SourceOpenDataModal } from '../Custom/Modals/SourceOpenDataModal/SourceOpenDataModal.component';
import { SourceLocalFileModal } from '../Custom/Modals/SourceLocalFileModal/SourceLocalFileModal.component';
import { ProjectOutput } from './ProjectOutput.component';
import { RealReportModal } from '../Custom/Modals/RealReportModal/RealReportModal.component';
import { SourceReportEmailModal } from '../Custom/Modals/SourceReportEmailModal/SourceReportEmailModal.component';
import { SourceMappingModal } from '../Custom/Modals/SourceMappingModal/SourceMappingModal.component';
import { ModulesFlow } from '../ModulesFlow/ModulesFlow.component';
import { useProjects } from "../../Hooks/UseProjects";
import { UnavailableProject } from '../Fallback/Project/Unavailable.component';
import { useProjectTemporary } from "../../Hooks/UseProjectTemporary";
import { ModalsCtx } from '../../Context/Modals.context';
import { useModules } from '../../Hooks/UseModules';
import { useTypes } from '../../Hooks/UseTypes';
import { useMenuHierarchy } from "../../Hooks/Menu/UseMenuHierarchy";
import { CreateNewModuleModal } from '../Custom/Modals/Modules/CreateNewModuleModal/CreateNewModuleModal.component';
import { EditProjectModal } from "../Custom/Modals/EditProjectModal/EditProjectModal.component";
import { MenuEntityInfo } from "../MenuEntityInfo/MenuEntityInfo.component";
import { EditModuleModal } from '../Custom/Modals/Modules/EditModuleModal/EditModuleModal.component';
import { AddNodesToFlowModal } from '../Common/AddNodesToFlowModal/AddNodesToFlowModal.component';
import { AddNodesToFlowModalNew } from '../Common/AddNodesToFlowModalNew/AddNodesToFlowModalNew.component';
import { Loading } from "lavaa";
import { MappingModal } from "../Custom/Modals/EditMapping/MappingModal.component";
import { useMenuMappings } from '../../Hooks/Menu/UseMenuMappings';
import { PatientLabResultModal } from '../Custom/Modals/PatientLabResultModal/PatientLabResultModal.component';
import { FilterNodeModal } from "../Custom/Modals/FilterNodeModal/FilterNodeModal.component";
import { TreeMenu } from "../Custom/TreeMenu/TreeMenu.component";
import { Drawer } from '../Drawer/Drawer.component';
import { DeleteModuleTemplateConfirm } from '../Custom/Modals/DeleteModuleTemplateConfirm/DeleteModuleTemplateConfirm.component';

// Project Layout
const ProjectLayout: FC<any> = () => {

  // Contexts
  const { performanceNow } = useContext(AppCtx);
  const { setEditProjectModalActive } = useContext(ModalsCtx);
  
  // Hooks
  const { updateProject, getActiveProjectData, projectsOnLoading, activeProjectId } = useProjects();
  const activeProjectData = getActiveProjectData();
  const { runState } = useProjectTemporary();

  const menuModules = useMenuModules(activeProjectData?.createdByCompany);
  const menuHierarchy = useMenuHierarchy();
  const menuMappings = useMenuMappings();
  
  // console.log('activeProjectData -> COMPANY', activeProjectData?.createdByCompany);
  
  // console.log('menuModules', menuModules);
  // console.log('menuHierarchy', menuHierarchy)

  const { isAddMyModulesModalActive, setAddMyModulesModalActive, isAddHealthModulesModalActive, setAddHealthModulesModalActive, isAddClinicalResearchModulesModalActive, setAddClinicalResearchModulesModalActive } = useContext(ModalsCtx);
  const { healthModules, clinicalResearchModules, myModules, getModuleTemplate, deleteModule, optimizeModule } = useModules();
  const healthModulesMenuData = prepareModulesAsMenuItems(healthModules, 'health');
  const clinicalResearchModulesMenuData = prepareModulesAsMenuItems(clinicalResearchModules, 'health');
  let myModulesMenuData = prepareModulesAsMenuItems(myModules, 'my');
  const { disorderTypes, moduleTypes } = useTypes();

  // Storage
  const [ optimizedModulesStoreData = [], setOptimizedModules, getActualValue ] = useLocalStorage('optimizedModules', []);

  myModulesMenuData = myModulesMenuData.map((item: any) => ((optimizedModulesStoreData as string[]).includes(item.id)) ? {...item, optimized: true} : item);

  const flowDisabled = runState.isRunning;

  function handleEdit() {
    setEditProjectModalActive(true);
  }

  const onTemplateUpdated = (data: any) => {
    updateProject(data);
    addSnackbar('success', 'Success', 'Project has been updated');
  };

  // On Optimize
  const onOptimize = (id: string) => {
    // console.log(optimizedModulesStoreData);
    setOptimizedModules([...optimizedModulesStoreData, id] as any);
    // optimizeModule(id);
  };

  if (projectsOnLoading.includes(activeProjectId)) {
    return (
      <Col grow="1" alignitems="center" justifycontent="center">
        <Loading />
      </Col>
    );
  }

  if (activeProjectData === null) {
    return (
      <UnavailableProject />
    )
  }

  return <>
    <ReactFlowProvider>
      <Splitter resetLayoutKey={performanceNow} direction="vertical" collapsible={[0, 2]} paneSizes={['15%', '70%', '15%']} minSizes={[200, undefined, 250]} splitterSize="10px" collapsedSplitterSize="20px" name="ProjectLayout">

        {/* Left Pane */}
        <Splitter resetLayoutKey={performanceNow} direction="horizontal" paneSizes={['70%', '30%']} splitterSize="10px" name="ProjectLayoutLeftPane">

          {/* Modules Pane */}
          <Grid scroll="true">
            <Panel title="Modules Menu">
                {
                  ({ filterValue }) => <PanelMenu filterValue={filterValue} data={menuModules} TreeMenu={TreeMenu} />
                }
            </Panel>
          </Grid>

          {/* My Resources Pane */}
          <Grid>
            <Panel title="My Resources" filterPlaceholder="Search by resource name">
              {
                ({ filterValue }) => <PanelMenu filterValue={filterValue} data={menuMappings} TreeMenu={TreeMenu} />
              }
            </Panel>
          </Grid>

        </Splitter>

        {/* Center Pane */}
        <Splitter resetLayoutKey={performanceNow} direction="horizontal" collapsible={[1]} paneSizes={['70%', '30%']} splitterSize="10px" name="ProjectLayoutCenterPane">

          {/* Modules Flow Area */}
          <Grid>
            <ModulesFlow />
          </Grid>

          {/* Output Area */}
          <Grid>
            <ProjectOutput />
          </Grid>
        </Splitter>

        {/* Right Pane */}
        <Splitter resetLayoutKey={performanceNow} direction="horizontal" paneSizes={['70%', '30%']} splitterSize="10px" name="ProjectLayoutRightPane">

          {/* Project Hierarchy Pane */}
          <Grid scroll="true">
            <Panel title="Project Hierarchy" filterPlaceholder="Search">
              {
                ({ filterValue }) => <PanelMenu filterValue={filterValue} data={menuHierarchy} TreeMenu={TreeMenu}/>
              }
            </Panel>
          </Grid>

          {/* Project Info Pane */}
          <Grid>
            <Panel title="Current Project" hasFilter={false} actionButtonIcon={activeProjectData.isEditEnabled ? iconsTypes.pen : undefined} onActionClick={!flowDisabled ? handleEdit : undefined}>
              {
                () => <MenuEntityInfo data={activeProjectData} />
              }
            </Panel>
          </Grid>
        </Splitter>
      </Splitter>
    </ReactFlowProvider>

    {/* Add Health Modules Popup */}
    <AddNodesToFlowModalNew tplType="module" nodesType="health" title="Add Health Modules"
      parentData={activeProjectData}
      data={healthModulesMenuData}
      getNodeTemplate={getModuleTemplate}
      // deleteNodeByPrimaryId={deleteModule}
      updateParent={onTemplateUpdated}
      filters={[
        { title: 'Disorder Type', data: disorderTypes, displayField: 'name', fieldOfFilterData: 'disorderTypeID', fieldOfListData: 'disorderTypeID' }
      ]}
      active={isAddHealthModulesModalActive}
      setActive={setAddHealthModulesModalActive}
      isFlowDisabled={flowDisabled}
      searchPlaceholder="Search module by name"
      closeButtonText="Back to Project"
      closeButtonIcon={iconsTypes.exit}
      displayModuleType={true}
    />

    {/* Add Clinical Research Modules Popup */}
    <AddNodesToFlowModalNew tplType="module" nodesType="clinicalResearch" title="Add Clinical Research Modules"
      parentData={activeProjectData}
      data={clinicalResearchModulesMenuData}
      getNodeTemplate={getModuleTemplate}
      // deleteNodeByPrimaryId={deleteModule}
      updateParent={onTemplateUpdated}
      filters={[
        { title: 'Disorder Type', data: disorderTypes, displayField: 'name', fieldOfFilterData: 'disorderTypeID', fieldOfListData: 'disorderTypeID' }
      ]}
      active={isAddClinicalResearchModulesModalActive}
      setActive={setAddClinicalResearchModulesModalActive}
      isFlowDisabled={flowDisabled}
      searchPlaceholder="Search module by name"
      closeButtonText="Back to Project"
      closeButtonIcon={iconsTypes.exit}
      displayModuleType={true}
    />

    {/* Add My Modules Popup */}
    <AddNodesToFlowModalNew tplType="module" nodesType="my" title="Add My Modules"
      parentData={activeProjectData}
      data={myModulesMenuData}
      getNodeTemplate={getModuleTemplate}
      // deleteNodeByPrimaryId={deleteModule}
      updateParent={onTemplateUpdated}
      filters={[
        { title: 'Module Type', data: moduleTypes, displayField: 'name', fieldOfFilterData: 'typeID', fieldOfListData: 'moduleTypeID' },
        { title: 'Disorder Type', data: disorderTypes, displayField: 'name', fieldOfFilterData: 'disorderTypeID', fieldOfListData: 'disorderTypeID' }
      ]}
      active={isAddMyModulesModalActive}
      setActive={setAddMyModulesModalActive}
      isFlowDisabled={flowDisabled}
      searchPlaceholder="Search module by name"
      closeButtonText="Back to Project"
      closeButtonIcon={iconsTypes.exit}
      useOptimize={true}
      onOptimize={onOptimize}
      displayModuleType={true}
    />

    {/* Edit Project Modal */}
    <EditProjectModal projectId={activeProjectData.uid} />

    {/* Create New Module Modal */}
    <CreateNewModuleModal />

    {/* Edit Module Modal */}
    <EditModuleModal />

    {/* Test Result Modal */}
    {/*<TestResultModal />*/}

    {/* Module Settings Modal */}
    <ModuleSettingsModal />

    {/* Filter Node Modal */}
    <FilterNodeModal />

    {/* Real Report Modal */}
    <RealReportModal />

    {/* Source Open Data Modal */}
    <SourceOpenDataModal />

    {/* Source Local File Modal */}
    <SourceLocalFileModal />

    {/* Source Report Email Modal */}
    <SourceReportEmailModal />

    {/* Source Mapping Modal */}
    <SourceMappingModal />

    {/* Patient Lab Result Modal */}
    <PatientLabResultModal />

    {/* Mapping Modal */}
    <MappingModal />

    {/* Delete Module Template Confirm */}
    <DeleteModuleTemplateConfirm />
  </>
};

export { ProjectLayout };
