import { FC, useEffect, useState } from "react";
import { Col, IValidationResult, Row } from "lavaa";
import css from "../ModuleSettings.module.scss";
import { Input } from "lavaa";

interface IProps {
    module: any,
    onChange: (newModuleData: any) => void,
    onValidate: (value: boolean) => void
}

// Basic Edit Module
const BasicEditModule: FC<IProps> = (props) => {

    const { module, onChange } = props;

    // States
    const [inputs, setInputs] = useState({ name: module.name });
    const [isNameValid, setIsNameValid] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);

    // On Validate
	const onValidate = (data: IValidationResult) => {
		const { name, error, valid } = data;

		// Module Name
		if (name === 'moduleName' && isNameValid !== valid) {
			setIsNameValid(valid);
		}
	};

    // Watch form valid
	useEffect(() => {

		// Set Form as Valid
		if (isNameValid && !isFormValid) {
			setIsFormValid(true);
			props.onValidate && props.onValidate(true);
		}

		// Set Form as Not Valid
		if (!isNameValid && isFormValid) {
			setIsFormValid(false);
			props.onValidate && props.onValidate(false);
		}
	}, [isNameValid]);

    // Handle Module Name Input
    const handleModuleName = (value: any) => {
        const newInputs = { ...inputs, name: value };
        setInputs(newInputs);

        const update = Object.assign({}, module, {
            "name": value,
            data: {
                ...module.data,
                label: value
            }
        });
        onChange(update);
    };

    return (
        <Col paddingTop="1rem" paddingBottom="1rem" className={css.Box}>

            {/* Name */}
            <Row grow="1" paddingBottom="1rem">
                <Input type="text" name="moduleName" wrap="col" label="*Module name" placeholder="Module name, max length is 50 characters" onChange={handleModuleName} value={inputs.name} maxlength={50} validation="required" onValidate={onValidate} />
            </Row>
        </Col>
    )
};

export { BasicEditModule };
