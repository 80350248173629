import { createSlice } from '@reduxjs/toolkit';
import { IOutput } from 'lavaa';
import { formatDate } from "../../../Tools/date";

interface IProjectTemporaryState {
    output: IOutput[],
    // reportLink: string,
    // flowUID: string,
    nodesState: {
        inProgress: string,
        finished: string[]
    }
    lastMessage: {
        message: string,
        nodeId: string,
    },
    runState: {
       startTime: number,
       lastUpdate: number,
       isRunning: boolean,
    }
}

const initialState: IProjectTemporaryState = {
    output: [],
    // reportLink: '',
    // flowUID: '',
    nodesState: {
        inProgress: '',
        finished: []
    },
    lastMessage: {
        message: '',
        nodeId: ''
    },
    runState: {
        startTime: 0,
        lastUpdate: 0,
        isRunning: false,
    }
}

export const projectTemporarySlice = createSlice({
    name: 'ProjectData',
    initialState,
    reducers: {

        // Add Project Data Output
        addProjectDataOutputAction: (state, action) => {
            const color = action.payload[1] || 'default';
            const output: IOutput = {
                message: action.payload[0],
                date: formatDate(new Date()),
                color: color,
                link: action.payload[2]
            }
            state.output.push(output);
        },

        // Add Last Message Info
        addLastMessageInfoAction: (state, action) => {
            state.lastMessage = {
                message: action.payload[0],
                nodeId: action.payload[1]
            };
        },

        // Receive Project Report Data
        // receiveProjectReportData: (state, action) => {
        //     state.reportLink = action.payload.link;
        //     state.flowUID = action.payload.flowUID;
        // },

        // Reset Project Report Data
        // resetProjectReportData: (state) => {
        //     state.reportLink = '';
        //     state.flowUID = '';
        // },

        // Add Node State
        addNodeStateAction: (state, action) => {
            console.log('action.payload[0]', action.payload[0]);
            state.nodesState.inProgress = action.payload[0];
            state.nodesState.finished = action.payload[1];
        },

        // Add Project Run State
        addProjectRunStatusAction: (state, action) => {
            const time = performance.now();
            state.runState = {
                startTime: time,
                lastUpdate: time,
                isRunning: true
            }
        },

        // Update Project Run State
        updateProjectRunStatusAction: (state, action) => {
            state.runState.lastUpdate = performance.now();
        },

        // Update Project Run State
        stopProjectRunAction: (state, action) => {
            const time = performance.now();
            const deltaTime = (time - state.runState.startTime) / 1000;
            const message = `Project execution time ${deltaTime.toFixed(2)} seconds`;

            const output: IOutput = {
                message: message,
                date: formatDate(new Date()),
                color: 'default'
            }

            state.runState.isRunning = false;
            state.output.push(output);
        },

        // Reset Project Data
        resetProjectDataAction: (state, action) => {
            return initialState;
        },
    }
});

export const {
    addProjectDataOutputAction,
    addLastMessageInfoAction,
    // receiveProjectReportData,
    // resetProjectReportData,
    addNodeStateAction,
    addProjectRunStatusAction,
    updateProjectRunStatusAction,
    stopProjectRunAction,
    resetProjectDataAction
} = projectTemporarySlice.actions;
export default projectTemporarySlice.reducer;
