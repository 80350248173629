import { FC, useState } from 'react';
import { Row, Cell, Input, Button, iconsTypes, Dropdown } from 'lavaa';
import { classNames } from '../../../../Tools';
import css from './ProjectsFilter.module.scss';


interface IProps {
    onSearch: (value: string) => void,
    searchValue?: string,
    onFilter: (option: any) => void,
    filterData: Array<any>,
    onViewAll: () => void
}

// ProjectsFilter
const ProjectsFilter: FC<IProps> = (props) => {

    let { onSearch, searchValue, onFilter, filterData, onViewAll } = props;

    // States
    const [selected, setSelected] = useState(null as any);
    const [isSearchFocused, setIsSearchFocused] = useState(false);

    // Handle Project Search
    // const onSelect = (data: any) => {
    //     setSelected(data);
    //     onFilter(data);
    // };

    // Handle View All
    const handleViewAll = () => {
        onViewAll();
        setSelected(null);
    };

    // On Search Focus
    const onSearchFocus = () => {
        setIsSearchFocused(true);
    };

    // On Search Blur
    const onSearchBlur = () => {
        setIsSearchFocused(false);
    };

    return <Row alignitems="center" grow="0" className={ css.ProjectsFilter }>

        {/* Search */}
        <Row justifycontent="end" grow="0" paddingRight="1rem">
            <Input type="text" icon={iconsTypes.search} className={ classNames(css.SearchInput, isSearchFocused ? css.Focused : '') } onFocus={ onSearchFocus } onBlur={ onSearchBlur } value={ searchValue } placeholder="Search by Project" onChange={ onSearch } />
        </Row>

        {/* Drop Down */}
        {/* <Row paddingRight="1rem">
            <Dropdown icon={iconsTypes.filter} placeholder="Filter by:" data={ filterData } selected={ selected } onSelect={ onSelect }/>
        </Row> */}

        {/* View All */}
        <Cell style={{whiteSpace: 'nowrap'}} paddingRight="1rem">
            <Button style={{fill: 'red'}} text="View All" icon={iconsTypes.eye} type="secondary" variant="text" onClick={ handleViewAll } />
        </Cell>

    </Row>;
};

export { ProjectsFilter };
