export function compareFields(sortType: 'asc' | 'desc', fieldType: 'string' | 'number', a: any, b: any): number{
  switch (fieldType) {
    case 'number':
      return compareNumbers(sortType, a, b);
    case "string":
      return compareStrings(sortType, a, b);
  }
}

function compareNumbers(sortType: 'asc' | 'desc', a: number, b: number): number{
  if(sortType === 'asc')
    return a - b;
  else return b - a;
}

function compareStrings(sortType: 'asc' | 'desc', a: string, b: string): number{
  if(sortType === 'asc')
    return ('' + a).localeCompare(b);
  else return ('' + b).localeCompare(a);
}