import { FC, useEffect, useState } from 'react';
import { Row, Col, IValidationResult } from 'lavaa';
import { Input } from 'lavaa';
import { IFields } from '../Interface';
import { DisorderTypesSelector } from '../../../Selectors/DisorderTypesSelector/DisorderTypesSelector.component';
import css from '../EditRulesetModal.module.scss';

interface IProps {
    data: IFields
    onChange: (data: IFields) => void,
    onValidate: (value: boolean) => void
}

// Ruleset Parameters
const RulesetParameters: FC<IProps> = (props) => {

    const { data } = props;

    // States
    const [isNameValid, setIsNameValid] = useState(false);
    const [isDescriptionValid, setIsDescriptionValid] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);

    // On Validate
    const onValidate = (data: IValidationResult) => {
        const { name, error, valid } = data;

        // Ruleset Name
        if (name === 'rulesetName' && isNameValid !== valid) {
            setIsNameValid(valid);
        }

        // Ruleset Description
        if (name === 'rulesetDescription' && isDescriptionValid !== valid) {
            setIsDescriptionValid(valid);
        }
    };

    // On Change
    const onChange = (value: any, field: string) => {
        const newData = {...data, [field]: value};
        props.onChange(newData);
    };

    // Watch form valid
    useEffect(() => {

        // Set Form as Valid
        if (isNameValid && isDescriptionValid && !isFormValid) {
            setIsFormValid(true);
            props.onValidate && props.onValidate(true);
        }

        // Set Form as Not Valid
        if ((!isNameValid || !isDescriptionValid) && isFormValid) {
            setIsFormValid(false);
            props.onValidate && props.onValidate(false);
        }
    }, [isNameValid, isDescriptionValid]);

    return(
        <Col paddingTop="1.75rem" paddingBottom="1.75rem" paddingRight="1rem" className={css.Box} scroll="true" grow="1">

            {/* Name */}
            <Row grow="1" paddingBottom="1rem">
                <Input type="text" name="rulesetName" wrap="col" label="*Ruleset name" placeholder="Ruleset name, max length is 50 characters" onChange={(value: string) => onChange(value, 'name')} value={data.name} maxlength={50} validation="required" onValidate={onValidate} />
            </Row>

            {/* Disorder Types Selector */}
            <Row grow="1" paddingBottom="1rem">
                <DisorderTypesSelector onSelect={(data: any) => onChange(data, 'disorder')} selected={data.disorder} />
            </Row>

            {/* Description */}
            <Row grow="1" paddingBottom="1rem">
                <Input style={{ height: 150 }} type="textarea" name="rulesetDescription" wrap="col" label="*Ruleset Description" placeholder="Ruleset description, max length is 200 characters" onChange={(value: string) => onChange(value, 'description')} value={data.description} maxlength={200} validation="required" onValidate={onValidate} />
            </Row>

            {/* Additional Info */}
            <Row grow="1" paddingBottom="1rem">
                <Input type="editor" wrap="col" label="Additional Info" placeholder="Additional Info" onChange={(value: string) => onChange(value, 'additionalInfo')} value={data.additionalInfo} style={{ minHeight: '50px', width: '100%', height: '125px' }} fullScreen={true} />
            </Row>
        </Col>
    )
};

export { RulesetParameters };
