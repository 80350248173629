import { FC, useContext } from 'react';
import { Button } from 'lavaa';
import { ModalsCtx } from '../../../../../Context/Modals.context';

interface IProps {
    text?: string,
    icon?: number,
    type?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'disable',
    variant?: 'text' | 'controls',
    uid: string,
    style?: any,
    className?: any
}

// Edit Module Modal Action Button
const EditModuleModalActionButton: FC<IProps> = (props) => {
 
    let { setEditModuleModalActive, setEditModuleUID } = useContext(ModalsCtx);

    const handleClick = (e: Event) => {
        // e.stopPropagation();
        setEditModuleUID(props.uid);
        setEditModuleModalActive(true);
    };

    return <Button {...props} iconSize="18" onClick={ handleClick } />
};

export { EditModuleModalActionButton };