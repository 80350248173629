import { createSlice } from "@reduxjs/toolkit";
import { Fields } from "@react-awesome-query-builder/ui";

interface IClientsTypeState {
	scheme: Fields,
	mappingScheme: Fields,
	resultsScheme: Fields,
	lastUploadedFile: string
}

const initialState: IClientsTypeState = {
	scheme: {},
	mappingScheme: {},
	resultsScheme: {},
	lastUploadedFile: ''
};

export const clientsSlice = createSlice( {
	name: "Clients",
	initialState,
	reducers: {

		// Get Client Scheme
		getClientSchemeAction: ( state, action ) => {
		},

		// Receive Client Scheme
		receiveClientSchemeAction: ( state, action ) => {
			state.scheme = action.payload;
		},

		// Get Client Results Scheme
		getClientResultsSchemeAction: ( state, action ) => {
		},

		// Receive Client Results Scheme
		receiveClientResultsSchemeAction: ( state, action ) => {
			state.resultsScheme = action.payload;
		},

		// Upload Local File
		uploadLocalFileAction: ( state, action ) => {
			console.log( "Upload Local File Action --- " );
		},

		// Receive Upload Local File
		receiveUploadLocalFileAction: ( state, action ) => {
			console.log( "Receive Upload Local File --- ", action );
			state.lastUploadedFile = action.payload.data;
		},

		// Get Client Mapping Scheme
		getClientMappingSchemeAction: ( state, action ) => {
		},

		// Receive Client Mapping Scheme
		receiveClientMappingSchemeAction: ( state, action ) => {
			state.mappingScheme = action.payload;
		},

		// Delete Local File
		deleteLocalFileAction: ( state, action ) => {
			console.log( "Delete Local File Action --- " );
		},

		// Receive Delete Local File
		receiveDeleteLocalFileAction: ( state, action ) => {
			console.log( "Receive Delete Local File --- " );
		},

		// Reset Clients
		resetClientsAction: (state) => {
			return {...initialState};
		}
	}
} );

export const {
	getClientSchemeAction,
	receiveClientSchemeAction,
	getClientResultsSchemeAction,
	receiveClientResultsSchemeAction,
	getClientMappingSchemeAction,
	receiveClientMappingSchemeAction,
	uploadLocalFileAction,
	receiveUploadLocalFileAction,
	deleteLocalFileAction,
	receiveDeleteLocalFileAction,
	resetClientsAction
} = clientsSlice.actions;
export default clientsSlice.reducer;
