import { FC, useContext } from "react";
import { Button } from 'lavaa';
import { ModalsCtx } from "../../../../Context/Modals.context";

interface IProps {
    text?: string;
    icon?: number;
    type?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'disable';
}

// Create New Rule Modal Action Button
const CreateNewRuleModalActionButton: FC<IProps> = (props) => {
 
    let { setNewRuleModalActive } = useContext(ModalsCtx);

    const handleClick = () => {
        setNewRuleModalActive(true);
    };

    return <Button {...props} onClick={ () => handleClick() } />
};

export { CreateNewRuleModalActionButton };