import { Row, Cell, Col, withoutPaddings } from 'lavaa';
import { Icon, iconsTypes } from 'lavaa';
import { Text } from 'lavaa';
import { useAccount } from '../../Hooks/UseAccount';
import { MenuProfileDropdown } from './MenuProfileDropdown.component';
import { useState } from 'react';
import css from './MenuProfile.module.scss';

// Menu Profile
const MenuProfile = () => {

    //States
    const [dropdownActive, setDropdownActive] = useState(false);

    // Hooks
    const { account } = useAccount();

    function clickHandler(){
        setDropdownActive((prev) => !prev);
    }

    return (
        <Row grow="1" justifycontent="end">
            <Row paddingRight='2rem' className={css.MenuProfileWrapper}>
                <Row alignitems="center" className={css.MenuProfile} onClick={clickHandler}>
                    <Cell alignitems='center' justifycontent='center' paddingLeft='0.75rem' paddingRight='0.75rem' className={css.ProfileIcon}>
                        <Icon name={iconsTypes.profile}/>
                    </Cell>
                    <Col>
                        <Cell {...withoutPaddings}>
                            <Text text={account.name} size='x3' bold={true} className={css.ProfileName}/>
                        </Cell>
                        <Cell {...withoutPaddings}>
                            <Text text={account.position} size='x2' className={css.ProfilePosition}/>
                        </Cell>
                    </Col>
                    <Cell alignitems='center' justifycontent='center'  {...withoutPaddings} paddingLeft='0.25rem' className={css.ArrowIcon}>
                        <Icon name={iconsTypes.arrowDown}/>
                    </Cell>
                </Row>

                {
                    /* Dropdown */
                    dropdownActive && <MenuProfileDropdown closeHandler={() => setDropdownActive(false)}/>
                }
            </Row>
        </Row>
    );
};

export { MenuProfile };