import { FC, useEffect, useRef, useState, useCallback, useContext } from "react";
import ReactFlow, { Background, addEdge, MiniMap, isEdge, getConnectedEdges, isNode, applyNodeChanges, applyEdgeChanges, useReactFlow } from "reactflow";
import { Container } from "lavaa";
import { Edge } from "../Custom/Flow/Edge/Edge.component";
import { ConnectionLine } from "../Custom/Flow/ConnectionLine/ConnectionLine.component";
import { AbstractionType } from "../../Constants/AbstractionType";
import { v4 as uuid } from "uuid";
import { parseJsonLogicFlow } from "../../Tools/parseJsonLogic";
import { FlowTopBar } from "./FlowTopBar/FlowTopBar";
import { useProjectTemporary } from "../../Hooks/UseProjectTemporary";
import { classNames } from "../../Tools";
import { emptyGUID } from "../../Tools/EmptyGUID";
import css from "./FlowV11.module.scss";
import 'reactflow/dist/style.css';
import { AppCtx } from "../../Context/App.context";

const edgeTypes = {
  edge: Edge
};

interface IProps {
  nodeTypes: any,
  flowData: any,
  onSave: (data: any, autosave: boolean) => void,
  onPublish: () => void,
  onRun?: () => void,
  saveOnInit?: boolean,
  type: "module" | "ruleset",
  title?: string,
  description?: string,
  isDisabled?: boolean
}

// Flow v.11
const FlowV11: FC<IProps> = (props) => {

  const { deleteNodeId, setDeleteNodeId } = useContext(AppCtx);

  const { nodeTypes, flowData, onSave, onPublish, onRun, saveOnInit = false, type, title, description, isDisabled = false } = props;
	// console.error(isDisabled);
  const jsonLogic = parseJsonLogicFlow(flowData);
  const { initialNodes, initialEdges } = jsonLogic;

  const { nodesState } = useProjectTemporary();

  const reactFlowWrapper: any = useRef();
  const [reactFlowInstance, setReactFlowInstance] = useState<any>(null);
  const [fullScreen, setFullScreen] = useState<boolean>(false);

  const [nodes, setNodes] = useState<any>([]);
  const [edges, setEdges] = useState<any>(null);
  const [dragData, setDragData] = useState<any>(null);
  const nodesRef = useRef(nodes);
  const edgesRef = useRef(edges);
  const reactFlowInstanceRef = useRef(reactFlowInstance);
  nodesRef.current = nodes;
  edgesRef.current = edges;
  reactFlowInstanceRef.current = reactFlowInstance;

  const isUpdateScheduled = useRef(false);

  const { zoomIn, zoomOut, fitView } = useReactFlow();

  // On Node Connect
  const onConnect = useCallback((connection: any) => {
    const { source, target } = connection;
    
    // Avoid connection to itself
    if (source === target) {
      return;
    }

    isUpdateScheduled.current = true;
    setEdges((eds: any) => addEdge({
      ...connection,
      nodeType: "edge",
      type: "edge",
      animated: false,
      data: { deleteHandler }
    }, eds));
  }, [setEdges]);

  // On Drag Over
  const onDragOver = useCallback((event: any) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  // On Drop
	const onDrop = useCallback( ( event: any ) => {
		event.preventDefault();

		const n: any[] = nodesRef.current || [];
		const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
		const nodeProps = JSON.parse( event.dataTransfer.getData( "lavaa/flow" ) );

		const { node, label, description } = nodeProps;
		const { type, primaryId } = node;

		let position = reactFlowInstance.project( {
			x: event.clientX - reactFlowBounds.left,
			y: event.clientY - reactFlowBounds.top
		} );

		const nodeId = uuid();

		const newNode = {
			id: nodeId,
			nodeId: nodeId,
			primaryId: primaryId,
			type,
			position,
			name: label,
			data: {
				label,
				deleteHandler: deleteHandler
			},
			description: description,
			abstractionType: AbstractionType[type],
			jsonLogic: [],
			nodeType: "node"
		};

		isUpdateScheduled.current = true;
		setNodes( [...n, newNode] );
	}, [reactFlowInstance] );

  // Prepare Nodes
  function prepareNodes(elems: any[]) {
    return elems.filter((element: any) => isNode(element));
  }

  // Prepare Edges
  function prepareEdges(elems: any[]) {
    return elems.filter((element: any) => isEdge(element)).map((edge: any) => { return { ...edge, animated: false } });
  }

  // Save Action
  function saveAction(autosave: boolean = false) {
    const flowInstance = reactFlowInstance || (reactFlowInstanceRef && reactFlowInstanceRef.current);

    if (flowInstance) {
      const flow = flowInstance.toObject();
      const data = {
        ...flowData,
        jsonLogic: [
          { name: "initialNodes", data: [...prepareNodes(/*nodesRef.current || */flow.nodes)] },
          { name: "initialEdges", data: [...prepareEdges(/*edgesRef.current || */flow.edges)] }
        ]
      };

      onSave(data, autosave);
    }
  }

  // On Save
  const save = useCallback(() => {
    saveAction();
  }, [flowData, reactFlowInstance]);

  // On Node Delete
  const deleteHandler = useCallback((id: any) => {
    const n: any[] = nodesRef.current || [];
    const e: any[] = edgesRef.current || [];

    // Get node for delete
    const node = n.filter((item: any) => isNode(item) && item.id === id);

    // Find connected edges
    const edgesToRemove = getConnectedEdges(node, e);
    const edgesToRemoveId = edgesToRemove.map((edge) => edge.id);

    // Exclude node & connected edges
    const filteredNodes = n.filter((el: any) => el.id !== id && !(edgesToRemoveId.includes(el.id)));
    const filteredEdges = e.filter((el: any) => el.id !== id && !(edgesToRemoveId.includes(el.id)));

    // Update Nodes and Edges
    setNodes([...filteredNodes]);
    setEdges([...filteredEdges]);

    // Set flag for auto-save
    isUpdateScheduled.current = true;
  }, []);

  // Delete using context
  useEffect(() => {
    if (deleteNodeId !== '') {
      deleteHandler(deleteNodeId);
      setDeleteNodeId('');
    }
  }, [deleteNodeId]);

  // Toggle Full Screen Mode
  const toggleFullScreen = () => {
    setFullScreen(!fullScreen);
  };

  // On PanT o Center
  const panToCenter = useCallback(() => {
    fitView();
  }, [fitView]);

  // On Nodes Change
  const onNodesChange = useCallback(
    (changes: any) => {
      setNodes((nds: any) => applyNodeChanges(changes, nds));
    },
    [setNodes]
  );

  const onNodeDragStart = (event: any, node: any) => {
    setDragData({id: node.id, x: node.position.x, y: node.position.y});
  };

  const onNodeDragStop = (event: any, node: any) => {
    if (dragData.id === node.id && dragData.x !== node.position.x && dragData.y !== node.position.y) {
      saveAction();
    }

    setDragData(null);
  };

  // On Edges Change
  const onEdgesChange = useCallback((changes: any) => {
    setEdges((eds: any) => applyEdgeChanges(changes, eds))
  }, [setEdges]);

  // Watch changes to auto save
  useEffect(() => {
    if (!isUpdateScheduled.current) return;
    isUpdateScheduled.current = false;
    saveAction(true);
  }, [nodes, edges]);

  // Watch incoming flowData
  useEffect(() => {
    if (!initialNodes || !initialEdges) return;

	  console.warn(initialNodes);
    const dataNodes = initialNodes.map((item: any) => {

      return {
        ...item,
        id: item.nodeId,
        nodeId: item.nodeId,
        primaryId: item.primaryId,
        type: AbstractionType[item.abstractionType] || item.type,
        position: item.position || { x: 250, y: 5 },
        data: {
			    label: item.name,
			    description: item.description,
			    deleteHandler: deleteHandler,
          moduleTypeID: item.moduleTypeID
        },
        nodeType: "node",
      }
    });

    const dataEdges = initialEdges.map((item: any) => {
      return {
        ...item,
        data: {
          deleteHandler: deleteHandler
        }
      }
    });

    // console.log("Initial Data", dataNodes, dataEdges);

    // Set Node and Edges states
    setNodes(dataNodes);
    setEdges(dataEdges);

  }, [flowData]);

  // Save On Init
  useEffect(() => {
    if (reactFlowInstance && saveOnInit) {
      saveAction(true);
    }
  }, [reactFlowInstance]);

  // Watch Run Process
  useEffect(() => {
		if(edgesRef.current === null) return;
    const { inProgress } = nodesState;
    if (inProgress !== '' && inProgress !== emptyGUID) {
    	const e: any[] = edgesRef.current || [];
      const edgesUpdated = e.map((edge: any) => edge.source === inProgress ? { ...edge, animated: true } : { ...edge, animated: false });
			setEdges([...edgesUpdated]);
    }
  }, [nodesState]);

	if(edges === null) return null;

  return <Container className={css.FlowV11} data-fullscreen={fullScreen}>

    {/* Top Bar */}
    <FlowTopBar
      type={type}
      flowData={flowData}
      onSave={save}
      onPublish={onPublish}
      onRun={onRun}
      toggleFullScreen={toggleFullScreen}
      fullScreen={fullScreen}
      panToCenter={panToCenter}
      zoomIn={zoomIn}
      zoomOut={zoomOut}
      title={title}
      description={description}
      isDisabled={isDisabled}
    />

    {/* Flow Area */}
    <ReactFlow
      ref={reactFlowWrapper}
      nodes={nodes}
      edges={edges}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onNodeDragStart={onNodeDragStart}
      onNodeDragStop={onNodeDragStop}
      className={classNames(css.FlowArea, isDisabled && css.Disabled)}
      connectionLineComponent={ConnectionLine}
      snapToGrid={true}
      onDragOver={onDragOver}
      onDrop={!isDisabled ? onDrop : undefined}
      onInit={setReactFlowInstance}
      onConnect={onConnect}
      maxZoom={1}
      fitView
    >
      {/* Background */}
      <Background gap={16} size={.5} color="#808080" />

      {/* MiniMap */}
      <MiniMap
        nodeStrokeColor={(n: any) => {
          if (n.type === "source") return "var(--additional-blue-light)";
          if (n.type === "health") return "var(--additional-red-light)";
          if (n.type === "destination") return "var(--additional-yellow-light)";
          if (n.type === "ruleset") return "var(--additional-violet-light)";
          if (n.type === "openRuleset") return "var(--additional-blue-light)";
          if (n.type === "companyRuleset") return "var(--additional-red-light)";
          if (n.type === "myRuleset") return "var(--additional-green-light)";
          if (n.type === "functionRuleset") return "var(--additional-violet-light)";
          if (n.type === "function") return "var(--additional-violet-light)";

          // My
          return "var(--additional-green-light)";
        }}
        nodeColor={(n: any) => {
          if (n.type === "source") return "var(--additional-blue-light)";
          if (n.type === "health") return "var(--additional-red-light)";
          if (n.type === "destination") return "var(--additional-yellow-light)";
          if (n.type === "ruleset") return "var(--additional-violet-light)";
          if (n.type === "openRuleset") return "var(--additional-blue-light)";
          if (n.type === "companyRuleset") return "var(--additional-red-light)";
          if (n.type === "myRuleset") return "var(--additional-green-light)";
          if (n.type === "functionRuleset") return "var(--additional-violet-light)";
          if (n.type === "function") return "var(--additional-violet-light)";

          // My
          return "var(--additional-green-light)";
        }}
        nodeBorderRadius={10}
      />

    </ReactFlow>
  </Container>;
};

export { FlowV11 };
