import { FC, useEffect, useState } from "react";
import { Col, Input, InputDate, IValidationResult, Row, InputSlider } from "lavaa";
import css from "../ModuleSettings.module.scss";

interface IProps {
  module: any,
  onChange: (newModuleData: any) => void,
  onValidate: (value: boolean) => void
}

// Open Data Module Settings
const OpenDataModule: FC<IProps> = (props) => {

  const { module, onChange } = props;
  const { jsonLogic } = module;
  const amount = module.jsonLogic.amount;
  const lastRecordDate = module.jsonLogic.lastRecordDate;

  // States
  const [isNameValid, setIsNameValid] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  // On Validate
  const onValidate = (data: IValidationResult) => {
    const { name, valid } = data;

    // Module Name
    if (name === 'moduleName' && isNameValid !== valid) {
        setIsNameValid(valid);
    }
  };

  // Watch form valid
  useEffect(() => {

    // Set Form as Valid
    if (isNameValid && !isFormValid) {
        setIsFormValid(true);
        props.onValidate && props.onValidate(true);
    }

    // Set Form as Not Valid
    if (!isNameValid && isFormValid) {
        setIsFormValid(false);
        props.onValidate && props.onValidate(false);
    }
  }, [isNameValid]);

  // Number of Rows
  function changeHandler(value: any) {
    const update = Object.assign({}, module, {
      "jsonLogic": {
        amount: value
      }
    });
    onChange(update);
  }

  // Handle Module Name Input
  const handleModuleName = (value: any) => {
    const update = Object.assign({}, module, {
      "name": value,
      data: {
        ...module.data,
        label: value
      }
    });

    onChange(update);
  };

  // Last Record Date Handler
  const lastRecordDateHandler = (value: any) => {
    const date = value ? (new Date(value).toISOString()) : null;
    const update = {...module, jsonLogic: {...jsonLogic, lastRecordDate: date}};

    onChange(update);
  };

  if (!amount) {
    changeHandler(1000); // Set default value
    return null;
  }

  return (
    <Col paddingTop="1rem" paddingBottom="1rem" className={css.Box}>

      {/* Last record date */}
      <Row paddingBottom="1rem">
        <InputDate value={lastRecordDate} onChange={lastRecordDateHandler} label="Last record date" wrap="col" />
      </Row>

      {/* Module name */}
      <Row grow="1" paddingBottom="1rem">
        <Input type="text" name="moduleName" wrap="col" label="*Module name" placeholder="Module name, max length is 50 characters" onChange={handleModuleName} value={module.name} maxlength={50} validation="required" onValidate={onValidate} />
      </Row>

      {/* Number of Rows */}
      <InputSlider label={`Number of rows (${amount})`} onChange={changeHandler} value={amount} min={1} max={5206} step={100} />
      {/*<InputSlider2 label={`Number of rows (${amount})`} onChange={changeHandler} value={amount} min={1} max={5206} step={100} />*/}
    </Col>
  )
};

export { OpenDataModule };
