import { FC, RefObject, useEffect, useRef, useState, Children } from 'react';
import { Button, Col, Grid, Row, Title, iconsTypes, useDetectOutsideClick, Element } from 'lavaa';
import css from './MoreMenu.module.scss';

interface IProps {
    icon?: number;
    text?: string;
    type?: 'secondary';
    variant?: 'text';
    iconSize?: 'x1';
    children?: any;
}

// MoreMenu
const MoreMenu: FC<IProps> = (props) => {

    const { icon = iconsTypes.moreVertical, text, type = 'secondary', variant = 'text', iconSize = 'x1', children } = props;

    // Refs
    const menuRef: RefObject<any> = useRef(null);

    // States
    const [ active, setActive ] = useState<boolean>(false);

    // On Activate
    const onActivate = (e: any) => {
        setActive(true);
    };

    // On Deactivate
    const onDeactivate = (e: any) => {
        setActive(false);
    };

    // Filter null
    const filterNull = (item: any) => {
        return !!item;
    };
    
    return (
        <>
            <Row className={ css.MoreMenu } innerRef={menuRef} alignitems="center">
                
                {/* ActionButton */}
                <Button className={ css.ActionButton } type={type} variant={variant} text={text} icon={ icon } iconSize={iconSize} onClick={ onActivate } />

                {/* Items */}
                {
                    active === false ? null :
                    <Col className={ css.Items } onClick={ onDeactivate }>
                        {children.filter(filterNull).map((item: any, index: number) => (
                            <Row key={index} className={ css.Item }>
                                {item}
                            </Row>
                        ))}
                    </Col>
                }
            </Row>

            {/* Overlay */}
            {
                active === false ? null :
                <Element className={ css.MoreMenuOverlay } onClick={ onDeactivate } />
            }
        </>
    )
};

export { MoreMenu };
