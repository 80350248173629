import { FC, useContext } from 'react';
import { ModalsCtx } from "../../../../Context/Modals.context";
import { Modal, Row, Text } from 'lavaa';
import { useRulesets } from '../../../../Hooks/UseRulesets';

interface IProps {
    
}

// Delete Ruleset Template Confirm
const DeleteRulesetTemplateConfirm: FC<IProps> = (props) => {

    const { isDeleteRulesetTemplateConfirmActive, setDeleteRulesetTemplateConfirmActive, deleteRulesetTemplateUID } = useContext(ModalsCtx);
    const { deleteRuleset } = useRulesets();

    // Handle Close
    const handleClose = () => {
        setDeleteRulesetTemplateConfirmActive(false);
    };

    // Handle Cancel
    const handleCancel = () => {
        setDeleteRulesetTemplateConfirmActive(false);
    };

    // Handle Ok
    const handleOk = () => {
        deleteRuleset(deleteRulesetTemplateUID);
        setDeleteRulesetTemplateConfirmActive(false);
    };

    return <Modal 
        active={ isDeleteRulesetTemplateConfirmActive } 
        onClose={ handleClose } 
        onCancel={ handleCancel } 
        onOk={ handleOk }
        title="Are you sure you want to delete this ruleset?"
        okText="Delete"
        cancelText="Cancel"
        type="alert"
        scroll={false}
        okButtonType="danger"
    >
        <Row grow="1" paddingBottom="1rem">
            <Text size="x2">If you delete this ruleset you will not be able to restore it.</Text>
        </Row>

    </Modal>
};

export { DeleteRulesetTemplateConfirm };