import { FC, RefObject, useCallback, useContext, useEffect, useRef } from "react";
import { Handle, Position, NodeProps, Connection, Edge } from "reactflow";
import { classNames } from "../../../../Tools";
import { Row, Cell, withoutPaddings, Text, Icon, iconsTypes, useDetectOutsideClick, Tooltip } from "lavaa";
import { useProjectTemporary } from "../../../../Hooks/UseProjectTemporary";
import { ModalsCtx } from "../../../../Context/Modals.context";
import css from "./Node.module.scss";

interface IProps extends NodeProps {
	type: "source" | "destination" | "function" | "my" | "logic" | "health" | "clinicalResearch" |
		"ruleset" | "openRuleset" | "companyRuleset" | "myRuleset" | "functionRuleset",
	label: string,
	inputs?: number,
	outputs?: number,
	onDoubleClick?: () => void,
	className?: string,
	moduleType?: 'health' | 'clinicalResearch'
}

export const Node: FC<IProps> = (props) => {

	const nodeRef: RefObject<any> = useRef( null );
	let { setModuleSettingsModalActive, setModuleSettingsUID } = useContext( ModalsCtx );
	const [controlsActive, setControlsActive] = useDetectOutsideClick( nodeRef, false );
	const { nodesState } = useProjectTemporary();

	let { id, data = {}, isConnectable, type, label, onDoubleClick, className, moduleType } = props;
	let { deleteHandler } = data;
	const isSuccess = nodesState.finished.includes( id );
	const isReject = false; // Todo: implement reject
	const isProgress = nodesState.inProgress == id && !isSuccess && !isReject;

	const hasModuleEdit = type === "destination" || type === "source" || type === "function" || type === "health" || type === "logic" || type === "my" || type === "clinicalResearch";

	let icon = isSuccess ? iconsTypes.nodeSuccess : isProgress ? iconsTypes.nodeProgress : iconsTypes.nodeReject;
	let inputs = Array.from( Array( props.inputs || 0 ).keys() );
	let outputs = Array.from( Array( props.outputs || 0 ).keys() );

	const getModuleTypeDescription = (moduleType?: string) => {
		if (moduleType === 'health') {
			return 'Type: Health';
		}

		if (moduleType === 'clinicalResearch') {
			return 'Type: Clinical Research';
		}
	};

	// On Connect
	const onConnect = ( params: Connection | Edge ) => {
		const { source, target } = params;

		// Avoid connection to itself
		if (source === target) {
			return;
		}
		console.log( "handle onConnect", params );
	}

	// Context Menu
	const handleContextMenu = useCallback(
		( e: any ) => {
			e.preventDefault();
			setControlsActive(true);
		},
		[setControlsActive]
	);

	function editClickHandler() {
		setModuleSettingsUID( id );
		setModuleSettingsModalActive( true );
	}

	function deleteClickHandler() {
		deleteHandler(id);
		setControlsActive(false);
	}

	useEffect( () => {
		const instance = nodeRef.current;
		instance.addEventListener("contextmenu", handleContextMenu);
		return () => {
			instance.removeEventListener("contextmenu", handleContextMenu);
		}
	}, []);

	return (
		<Row
			className={classNames( css.Node, isSuccess ? css.Success : "", isReject ? css.Reject : "", isProgress ? css.Progress : "", className )}
			data-nodetype={type} data-moduletype={moduleType} onDoubleClick={onDoubleClick} innerRef={nodeRef}>

			{/* Status */}
			{
				(isSuccess || isReject || isProgress) && (
					<div className={css.Status}>
						<Icon name={icon}/>
					</div>
				)
			}

			{/* Input */}
			<Row className={css.Inputs} data-inputs={inputs.length}>
				{
					inputs.map( ( item: number ) => <Handle key={item} className={css.Input} type="target"
															position={Position.Top} id={id + "_in_" + item}
															onConnect={onConnect}/> )
				}
			</Row>


			{/* Content */}
			<Row alignitems="center" justifycontent="center" className={css.NodeBox}>
				{/* Indicator */}
				<div className={css.Indicator}/>

				{/* Label Type */}
				<Text className={css.LabelType}>{`${label}:`}</Text>

				{/* Label */}
				<Text bold={true} className={css.Label}>{data.label}</Text>

				{/* Tooltip */}
				{
					data.description && (
						<Cell className={css.Tooltip}>
							<Tooltip positionH="center" positionV="top" tooltipText={data.description} additionalText={getModuleTypeDescription(moduleType)}/>
						</Cell>
					)
				}

			</Row>

			{/* Outputs */}
			<Row className={css.Outputs} data-outputs={outputs.length}>
				{
					outputs.map( ( item: number ) => <Handle key={item} className={css.Output} type="source"
															 position={Position.Bottom} id={id + "_out_" + item}
															 isConnectable={isConnectable}/> )
				}
			</Row>

			{/* Control Panel */}
			{
				controlsActive && (
					<Row className={css.ControlPanel}>
						
						{/* Edit */}
						{
							hasModuleEdit && (
								<Cell className={css.ControlBtn} alignitems="center" onClick={editClickHandler}
									  justifycontent="center" {...withoutPaddings}>
									<Icon name={iconsTypes.pen} />
								</Cell>
							)
						}

						{/* Delete */}
						<Cell className={css.ControlBtn} alignitems="center" justifycontent="center"
							  onClick={deleteClickHandler} {...withoutPaddings}>
							<Icon name={iconsTypes.delete} />
						</Cell>
					</Row>
				)
			}
		</Row>
	);
};
