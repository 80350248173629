import { FC, useEffect, useState } from "react";
import { Grid, Col, Row } from "lavaa";
import { Text } from "lavaa";
import { ReactIcon } from "lavaa";
import css from './NoInternetConnection.module.scss';

interface IProps {
    children: any
}

const NoInternetConnection: FC<IProps> = (props) => {

    const { children } = props;
    const [ isOnline, setOnline ] = useState(true);

    useEffect(() => {
        const handleOnline = () => setOnline(true);
        const handleOffline = () => setOnline(false);

        window.addEventListener("online", handleOnline);
        window.addEventListener("offline", handleOffline);
        return () => {
            window.removeEventListener("online", handleOnline);
            window.removeEventListener("offline", handleOffline);
        }
    }, []);

    return isOnline ? children : <Grid className={css.NoInternetConnection} alignitems="center" justifycontent="center">

            {/* Icon */}
            <Col>
                <Row justifycontent="center">
                    <ReactIcon name="noInternet" size="x5" />
                </Row>

                {/* Text */}
                <Row justifycontent="center">
                    <Text>No Internet Connection. Please try again later.</Text>
                </Row>
            </Col>
    </Grid>
}

export default NoInternetConnection;
