import { FC, useContext, useEffect, useState } from 'react';
import { ModalsCtx } from "../../../../Context/Modals.context";
import { IValidationResult, Modal } from 'lavaa';
import { Row } from 'lavaa';
import { Input } from "lavaa";
import { useProjects } from '../../../../Hooks/UseProjects';
import './EditProjectModal.css';

interface IProps {
    projectId: string | null;
}

// Edit Project Modal
const EditProjectModal: FC<IProps> = (props) => {

    const { projectId } = props;
    const { updateProject, getProject } = useProjects();
    const projectData = getProject(projectId);
    const { isEditEnabled } = projectData || {};

    let { isEditProjectModalActive, setEditProjectModalActive } = useContext(ModalsCtx);

    // States
    const [nameValue, setNameValue] = useState('');
    const [descriptionValue, setDescriptionValue] = useState('');
    const [additionalInfoValue, setAdditionalInfoValue] = useState('');

    const [isNameValid, setIsNameValid] = useState(false);
    const [isDescriptionValid, setIsDescriptionValid] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);

    // On Validate
    const onValidate = (data: IValidationResult) => {
        const { name, error, valid } = data;

        // Project Name
        if (name === 'projectName' && isNameValid !== valid) {
            setIsNameValid(valid);
        }

        // Project Description
        if (name === 'projectDescription' && isDescriptionValid !== valid) {
            setIsDescriptionValid(valid);
        }
    };

    useEffect(() => {
        // debugger;
        if(!isEditProjectModalActive || !projectData) return;
        setNameValue(projectData.name);
        setDescriptionValue(projectData.description);
        setAdditionalInfoValue(projectData.additionalInfo);
    }, [projectData, isEditProjectModalActive]);

    // Watch form valid
    useEffect(() => {

        // Set Form as Valid
        if (isNameValid && isDescriptionValid && !isFormValid) {
            setIsFormValid(true);
        }

        // Set Form as Not Valid
        if ((!isNameValid || !isDescriptionValid) && isFormValid) {
            setIsFormValid(false);
        }
    }, [isNameValid, isDescriptionValid]);

    // TODO - check if this needs to be here
    // useEffect(() => {
    //     if(!isEditProjectModalActive || !projectId) return;
    //     fetchProject(projectId);
    // }, [projectId, isEditProjectModalActive]);

    // if(!projectData) return null;
    // const canEdit = projectData.isEditEnabled;

    // Handle Close
    const handleClose = () => {
        setEditProjectModalActive(false);
    };

    // Handle Cancel
    const handleCancel = () => {
        setEditProjectModalActive(false);
    };

    // Handle Ok
    const handleOk = () => {
        if(isEditEnabled) {
            const data = Object.assign( {}, projectData, {
                name: nameValue,
                description: descriptionValue,
                additionalInfo: additionalInfoValue
            } );
            updateProject( data );
        }
        setEditProjectModalActive(false);
    };

    // Handle Project Name Input
    const handleProjectName = (value: any) => {
        setNameValue(value);
    };

    // Handle Description Input
    const handleDescription = (value: any) => {
        setDescriptionValue(value);
    };

    // Handle AdditionalInfo
    const handleAdditionalInfo = (value: any) => {
        setAdditionalInfoValue(value);
    };

    // Watch mount / unmount
    useEffect(() => {
        return () => {
            setEditProjectModalActive(false);
        }
    }, []);

    return !projectData ? null : <Modal
        active={ isEditProjectModalActive }
        onClose={ handleClose }
        onCancel={ handleCancel }
        onOk={ handleOk }
        title={isEditEnabled ? "Edit Project" : "View Project"}
        okText={isEditEnabled ? "Save" : "Ok"}
        cancelText="Cancel"
        type="small"
        isFormValid={isFormValid}
    >
        {/* Name */}
        <Row grow="1" paddingBottom="1rem">
            <Input type="text" name="projectName" wrap="col" label="*Project name" placeholder="Project name, max length is 50 characters" onChange={ handleProjectName } value={nameValue} disabled={!isEditEnabled} maxlength={50} validation="required" onValidate={onValidate} />
        </Row>

        {/* Description */}
        <Row grow="1" paddingBottom="1rem">
            <Input style={{height: 150}} type="textarea" name="projectDescription" wrap="col" label="*Project description" placeholder="Project description, max length is 200 characters" onChange={ handleDescription } value={descriptionValue} disabled={!isEditEnabled} maxlength={200} validation="required" onValidate={onValidate} />
        </Row>

        {/* Additional Info */}
        <Row grow="1" paddingBottom="1rem">
            <Input type="editor" wrap="col" label="Additional Info" placeholder="Additional Info" onChange={ handleAdditionalInfo } style={{minHeight: '50px', width: '100%', height: '125px'}} value={additionalInfoValue} disabled={!isEditEnabled} fullScreen={true} />
        </Row>
    </Modal>
};

export { EditProjectModal };
