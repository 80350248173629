import { iconsTypes } from 'lavaa';
import { useModules } from '../UseModules';

export const prepareModulesAsMenuItems = (modules: any[], nodesType: string) => modules.map((module) => ({
    id: module.primaryId || module.uid,
    label: module.name,
    name: module.name,
    description: module.description,
    isEditEnabled: module.isEditEnabled,
    disorderTypeID: module.disorderTypeID,
    moduleTypeID: module.moduleTypeID,
	icon: module.icon,
    node: {
        primaryId: module.primaryId || module.uid,
        type: nodesType,
        disorderTypeID: module.disorderTypeID,
        createdByCompany: module.createdByCompany
    }
}))
export const prepareModulesAsSearchItems = (modules: any[]) => modules.map((module) => (module.name));

const useMenuModules = (companyId?: string): any[] => {
    const { sourceModules, destinationModules, functionModules, healthModules, myModules } = useModules();
    const menuSourceModules = prepareModulesAsMenuItems(sourceModules, 'source');
    const menuDestinationModules = prepareModulesAsMenuItems(destinationModules, 'destination');
    const menuFunctionModules = prepareModulesAsMenuItems(functionModules, 'function');
	const menuHealthModules = prepareModulesAsSearchItems(healthModules);
	const menuMyModules = prepareModulesAsSearchItems(myModules);
    // console.log('menuSourceModules', menuSourceModules);

    const filterSourceModules = (menuSourceModules: any[], companyId?: string) => {
        if (!companyId) return menuSourceModules;
        return menuSourceModules.filter((module: any) => (
            module.node.createdByCompany === companyId ||
            module.id === '1b04171d-d9db-45fd-8895-c825343a9294' ||
            module.id === 'b3c901a5-559e-4455-bbc1-44156069b3ad' ||
            module.id === '4aa6d5c1-560b-4d8d-b45e-b25acf0f1f7e'
        ));
    };

	return [
        {
            id: 'tree-node-source',
            label: 'Source Module',
            icon: iconsTypes.arrowData,
            children: filterSourceModules(menuSourceModules, companyId)
        },
        {
            id: 'tree-node-health',
            label: 'Health Module',
            icon: iconsTypes.heartRate,
            endIcon: iconsTypes.flowPlus,
            action: 'activateAddHealthModulesModal',
			searchBy: menuHealthModules
        },
        {
            id: 'tree-node-clinical',
            label: 'Clinical Research',
            icon: iconsTypes.microscope,
            endIcon: iconsTypes.flowPlus,
            action: 'activateAddClinicalResearchModulesModal',
			searchBy: menuHealthModules
        },
        {
            id: 'tree-node-functions',
            label: 'Functions',
            icon: iconsTypes.functions,
            children: menuFunctionModules
        },
        {
            id: 'tree-node-my+',
            label: 'My Modules',
            icon: iconsTypes.downloadData,
						endIcon: iconsTypes.flowPlus,
            action: 'activateAddMyModulesModal',
			searchBy: menuMyModules
        },
        {
            id: 'tree-node-destination',
            label: 'Destination Module',
            icon: iconsTypes.checkData,
            children: menuDestinationModules
        },
    ];
};

export { useMenuModules };
