import { FC, useEffect } from "react";
import { Text } from "lavaa";
import { IRuleType } from "../../../../../Redux/Slices/Rules/Rules.data";
import { useClients } from "../../../../../Hooks/UseClients";
import { JsonLogicFieldType, parseJsonLogicUsedFields } from "../../../../../Tools/parseJsonLogic";
import { TestFieldsGroup, Row, Col, Cell, Icon, iconsTypes } from 'lavaa';
import css from "../EditRuleModal.module.scss";

interface IProps {
  rule: IRuleType,
  onChange: (testData: any) => void,
  runStatus: boolean | null,
  inputs: any
}

// Edit Rule Test
const EditRuleTest: FC<IProps> = ( props ) => {

  const { rule, onChange, runStatus, inputs } = props;
  const { clientScheme } = useClients();

  const jsonLogicUsedFields: JsonLogicFieldType[] = parseJsonLogicUsedFields(rule.jsonLogic, clientScheme);

  useEffect(() => {
    const updateInputs: object = {};

    jsonLogicUsedFields.forEach((logicField) => {
      if(logicField.field !== null){
        Object.assign(updateInputs, {
          [logicField.key]: undefined
        });
      }
      else{
        const group:object = {}
        Object.keys(logicField.fields).forEach((key) => Object.assign(group, {
          [key]: undefined
        }));

        return Object.assign(updateInputs, {
          [logicField.key]: [group]
        });
      }
    });

    Object.assign(updateInputs, inputs);
    onChange(updateInputs);

  }, [rule.jsonLogic]);

  function changeHandler(key: string, name: string | null, value: any){
    let update, updateInputs;

    if(name){
      update = Object.assign({}, inputs[key][0]);
      Object.assign( update, {
        [name]: value
      });

      updateInputs = Object.assign({}, inputs, {
        [key]: [update]
      });
    }
    else{
      update = value;
      updateInputs = Object.assign({}, inputs, {
        [key]: update
      });
    }

    onChange(updateInputs);
  }

  return (
    <Col grow="1" paddingTop="1rem" paddingBottom="1rem" className={css.Box} style={{position: 'relative'}}>
      <Col paddingTop="1.75rem" paddingBottom="1.75rem" className={css.Box} style={{overflow: 'auto', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0}}>

        <Text bold="true" text="Test Client simulation" size="x4" />
        {
          jsonLogicUsedFields.map((logicField) => {
              return <TestFieldsGroup key={logicField.key} logicField={logicField} onChange={changeHandler} inputs={inputs}/>
            }
          )
        }
        <Row>
          {
            runStatus === true && (
              <Row className={css.RunSuccess} paddingTop="1.5rem">
                <Icon name={iconsTypes.success}/>
                <Cell paddingLeft="0.75rem">
                  <Text text={"Success. The rule is true"} className={css.RunSuccessText}/>
                </Cell>
              </Row>
            )
          }
          {
            runStatus === false && (
              <Row className={css.RunWarning} paddingTop="1.5rem">
                <Icon name={iconsTypes.warning}/>
                <Cell paddingLeft="0.75rem">
                  <Text text={"The rule is false. The patient did not fall under the rule"} className={css.RunWarningText}/>
                </Cell>
              </Row>
            )
          }
        </Row>
      </Col>
    </Col>
  )
};

export { EditRuleTest };
