import { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Grid } from 'lavaa';
import { Header } from '../Layout/Header/Header.component';
import { useAccount } from '../../Hooks/UseAccount';
import { useProjects } from "../../Hooks/UseProjects";
import css from './AuthProvider.module.scss';
import { useProjectTemporary } from "../../Hooks/UseProjectTemporary";
import { useProjectReport } from "../../Hooks/UseProjectReport";

const authPages = [
    '/login',
    '/registration'
];

// Auth Provider
const AuthProvider: FC = () => {
    const { isAuth } = useAccount();
    const location: any = useLocation();
    useProjects(true);

    useProjectTemporary(true); // Reset output data on project id change
    useProjectReport(true); // Reset report data on project id change

    const isAuthPage = authPages.includes(location.pathname);

    if(!isAuth && !isAuthPage){
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    if(isAuth && isAuthPage) {
        let from = (location.state && location.state.hasOwnProperty('from')) ? location.state.from.pathname : "/";
        return <Navigate to={from} replace />;
    }

    return(
        // App Container
        <Grid className={css.AuthProvider}>

            {
                isAuth && (
                    // Header
                    <Header />
                )
            }

            {/* Pages */}
            <Outlet />

        </Grid>
    );
};

export { AuthProvider };
