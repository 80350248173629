import { FC, useContext } from "react";
import { ModalsCtx } from "../../../../Context/Modals.context";
import { Col, Row, Title, Icon, iconsTypes, ReportLabel } from "lavaa";
import { Modal } from 'lavaa';
import css from './PatientLabResultModal.module.scss';

// Patient Lab Result Modal
const PatientLabResultModal: FC<any> = () => {

    const { isPatientLabResultModalActive, setPatientLabResultModalActive, patientLabResultModalData, setPatientLabResultModalData } = useContext(ModalsCtx);
    const { moduleName = 'Unknown', labResult = { reasons: []}, clientId } = patientLabResultModalData;
    const reasons = labResult.reasons.map((item: string) => item.split(';')).flat();

    // Handle Close
    const handleClose = () => {
        setPatientLabResultModalData({});
        setPatientLabResultModalActive(false);
    };

    // Handle Cancel
    const handleCancel = () => {
        setPatientLabResultModalData({});
        setPatientLabResultModalActive(false);
    };

    // Handle Ok
    const handleOk = () => {
        setPatientLabResultModalData({});
        setPatientLabResultModalActive(false);
    };

    // console.log('Patient Lab Result Modal', clientId);

    return !isPatientLabResultModalActive ? null : <Modal 
        active={ isPatientLabResultModalActive } 
        onClose={ handleClose }
        cancelDisabled={ true }
        onOk={ handleOk }
        title={clientId}
        okText="Close"
        type="middle"
    >
        <Col className={ css.PatientLabResultModal }>
            
            {/* Header */}
            <Row alignitems="center">
                <Row grow="1">
                    <Title>{ moduleName }</Title>
                </Row>
                
                <Row>
                    <ReportLabel textColor={ labResult.textColor } bgColor={ labResult.bgColor }>{ labResult.resultGroupName }</ReportLabel>
                </Row>
            </Row>

            {/* Reasons */}
            {
                !reasons.length ? 'No data' :
                <Col paddingTop="1rem" className={ css.Reasons }>
                    {
                        reasons.map((item: any) => item === '' ? null : (<Row key={item} className={ css.Reason } paddingTop="0.75rem" paddingLeft="0.75rem" paddingRight="0.75rem" paddingBottom="0.75rem">
                        <Row grow="1">{ item }</Row>
                        {
                            (item.toLowerCase().endsWith('male') || item.toLowerCase().endsWith('female')) &&
                            <Row><Icon name={iconsTypes.link} /></Row>
                        }
                    </Row>))
                    }
                </Col>
            }
        </Col>
    </Modal>
};

export { PatientLabResultModal };