import { FC } from 'react';
import { Modal, Row, Text } from 'lavaa';

interface IProps {
    active: boolean,
    onClose: () => void,
    onOk: () => void
}

// DeleteGuidelineModal
const DeleteGuidelineModal: FC<IProps> = (props) => {

    // Props
    const { active, onClose, onOk } = props;

    // Handle Cancel/Close
    const handleClose = () => {
        onClose();
    };

    // Handle Ok
    const handleOk = () => {
        onOk();
    };
    
    return (
        <Modal 
            active={ active } 
            onClose={ handleClose } 
            onCancel={ handleClose } 
            onOk={ handleOk }
            title="Are you sure want to delete this guideline?"
            okText="Delete"
            cancelText="Cancel"
            type="alert"
            scroll={false}
            okButtonType="danger" footerBtnStretch={false}
        >
            <Row grow="1" paddingBottom="1rem">
                <Text size="x2">If you delete this guideline you will not be able to restore it.</Text>
            </Row>

        </Modal>
    )
};

export { DeleteGuidelineModal };
