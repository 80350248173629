import { FC, useContext } from 'react';
import { ModalsCtx } from "../../../../Context/Modals.context";
import { Modal } from 'lavaa';
import { Row, Col } from 'lavaa';
import { Input } from 'lavaa';

// Source Report Email Modal
const SourceReportEmailModal: FC<any> = () => {

    let { isSourceReportEmailModalActive, setSourceReportEmailModalActive } = useContext(ModalsCtx);

    // Handle Close
    const handleClose = () => {
        setSourceReportEmailModalActive(false);
    };

    // Handle Cancel
    const handleCancel = () => {
        setSourceReportEmailModalActive(false);
    };

    // Handle Ok
    const handleOk = () => {
        setSourceReportEmailModalActive(false);
    };

    return <Modal 
        active={ isSourceReportEmailModalActive }
        onClose={ handleClose } 
        onCancel={ handleCancel } 
        onOk={ handleOk }
        title='Send report to e-mail'
        okText='Create'
        cancelText='Cancel'
        type='small'
    >
        <Col grow="1" paddingBottom="1rem">

            {/* Email */}
            <Row grow='1'>
                <Input type='text' wrap='col' label='Email' placeholder='..............................@........' onChange={ () => {} } />
            </Row>
        </Col>
    </Modal>
};

export { SourceReportEmailModal };