import { useDispatch, useSelector } from 'react-redux';
import {
    getGuidelinesAction,
    resetGuidelinesAutocompleteAction,
    addGuidelineModuleAction,
    updateGuidelineModuleAction,
    deleteModuleGuidelineAction,
    getModuleGuidelinesAction,
    resetModuleGuidelinesAction
} from '../Redux/Slices/Guidelines/Guidelines.slice';

export const useGuidelines = (): any => {

    // Get States
    const autocompleteData = useSelector((state: any) => state.guidelines.autocompleteData);
    const moduleGuidelines = useSelector((state: any) => state.guidelines.moduleGuidelines);
	const dispatch = useDispatch();

    // Fetch Guidelines
    const fetchGuidelines = (value: string) => {
        dispatch(getGuidelinesAction([value, 10]));
    };

    // Reset Guidelines Autocomplete
    const resetGuidelinesAutocomplete = (value: string) => {
        dispatch(resetGuidelinesAutocompleteAction());
    };

    // Add Guideline Module
    const addGuidelineModule = (data: any) => {
        const { 
            moduleId, priority, minValue = 0, maxValue = 100,
            clientCondition = {}, type = 0, guidelineId: guidelineUID,
            guidelineName = '', description = ''
        } = data;

        dispatch(addGuidelineModuleAction([
            moduleId,
            priority,
            maxValue,
            minValue,
            JSON.stringify(clientCondition),
            type,
            guidelineUID,
            guidelineName,
            description
        ]));
    };

    // Update Guideline Module
    const updateGuidelineModule = (data: any) => {
        const {
            guidelineModuleUID, priority, maxValue = 100, minValue = 0, clientCondition = {}
        } = data;

        dispatch(updateGuidelineModuleAction([
            guidelineModuleUID,
            priority,
            maxValue,
            minValue,
            JSON.stringify(clientCondition)
        ]));
    };

    // Delete Module Guideline
    const deleteModuleGuideline = (uid: string) => {
        if (uid) {
            dispatch(deleteModuleGuidelineAction([uid]));
        }
    };

    // Get Module Guidelines
    const fetchModuleGuidelines = (uid: string) => {
        dispatch(getModuleGuidelinesAction([uid]));
    };

    // Reset Module Guidelines
    const resetModuleGuidelines = () => {
        dispatch(resetModuleGuidelinesAction());
    };

	return {
        autocompleteData, moduleGuidelines,
		fetchGuidelines, resetGuidelinesAutocomplete,
        addGuidelineModule, updateGuidelineModule, deleteModuleGuideline,
        fetchModuleGuidelines,
        resetModuleGuidelines
	};
}
