import { FC, useEffect, useRef, useState } from "react";
import { useClients } from "../../../Hooks/UseClients";
import { JsonLogicFieldType, prepareJsonLogicInputFields } from "../../../Tools/parseJsonLogic";
import { Col, Row, Input, iconsTypes, IValidationResult, TestFieldsGroup } from "lavaa";
import css from "../ModuleSettings.module.scss";
import { classNames } from "../../../Tools";

interface IProps {
	module: any,
	onChange: (newModuleData: any) => void,
	onValidate: (value: boolean) => void
}

// Single Client Module Settings
const SingleClientModule: FC<IProps> = (props) => {

	const { module, onChange } = props;
	const { clientScheme, getClientScheme } = useClients();

	// Refs
	const jsonLogicUsedFields = useRef<JsonLogicFieldType[]>([]);

	// States
	const [inputs, setInputs] = useState({
		name: module.name,
		jsonLogic: module.jsonLogic || {},
	});
	const [searchValue, setSearchValue] = useState<string>("");
	const [isFocusSearch, setIsFocusSearch] = useState<boolean>(false);
	const [isNameValid, setIsNameValid] = useState(false);
	const [isFormValid, setIsFormValid] = useState(false);

	// On Validate
	const onValidate = (data: IValidationResult) => {
		const { name, valid } = data;

		// Module Name
		if (name === 'moduleName' && isNameValid !== valid) {
			setIsNameValid(valid);
		}
	};

	// Watch form valid
	useEffect(() => {

		// Set Form as Valid
		if (isNameValid && !isFormValid) {
			setIsFormValid(true);
			props.onValidate && props.onValidate(true);
		}

		// Set Form as Not Valid
		if (!isNameValid && isFormValid) {
			setIsFormValid(false);
			props.onValidate && props.onValidate(false);
		}
	}, [isNameValid]);

	useEffect(() => {
		getClientScheme();
	}, []);

	useEffect(() => {
		const updateInputs = {
			name: module.name,
			jsonLogic: {}
		};

		jsonLogicUsedFields.current = prepareJsonLogicInputFields(clientScheme, null, true);

		jsonLogicUsedFields.current.forEach((logicField) => {
			if (logicField.field !== null) {
				let value = undefined;
				if (module.jsonLogic[logicField.key]) {
					value = module.jsonLogic[logicField.key];
				}
				Object.assign(updateInputs.jsonLogic, {
					[logicField.key]: value
				});
			} else {
				const group: object = {}
				Object.keys(logicField.fields).forEach((key) => {
					let value = undefined;
					if (module.jsonLogic[logicField.key] && module.jsonLogic[logicField.key][0] != undefined && module.jsonLogic[logicField.key][0][key] != undefined) {
						value = module.jsonLogic[logicField.key][0][key];
					}
					Object.assign(group, {
						[key]: value
					})
				});

				Object.assign(updateInputs.jsonLogic, {
					[logicField.key]: [group]
				});
			}
		});

		setInputs(updateInputs);

	}, [clientScheme]);

	function changeSubmit(updateInputs: any) {
		const updateData = Object.assign({}, module, updateInputs,
			{
				data: {
					...module.data,
					label: updateInputs["name"]
				}
			}
		);

		console.log(updateData);
		onChange(updateData);
	}

	function changeHandler(key: string, name: string | null, value: any) {
		let update, updateInputs;

		if (name) {
			update = Object.assign({}, inputs.jsonLogic[key][0]);
			Object.assign(update, {
				[name]: value
			});

			updateInputs = {
				...inputs, jsonLogic: {
					...inputs.jsonLogic,
					[key]: [update]
				}
			};
		} else {
			updateInputs = {
				...inputs, jsonLogic: {
					...inputs.jsonLogic,
					[key]: value
				}
			};
		}

		setInputs(updateInputs);
		changeSubmit(updateInputs);
	}

	// Handle Module Name Input
	const handleModuleName = (value: any) => {
		const updateInputs = { ...inputs, name: value };
		setInputs(updateInputs);

		const updateData = Object.assign({}, module, updateInputs);
		onChange(updateData);
		changeSubmit(updateInputs);
	};

	// Handle Search
	const onSearch = (value: string) => {
		setSearchValue(value.toLowerCase());
	};

	const onFocusSearch = () => {
		setIsFocusSearch(true);
	}

	const onBlurSearch = () => {
		setIsFocusSearch(false);
	}

	const isSearchActive = searchValue.length > 0 || isFocusSearch;

	return (
		<Col paddingTop="1rem" paddingBottom="1rem" className={css.Box}>

			{/* Search */}
			<Row grow="1" paddingBottom="1rem" justifycontent="end">
				<Col className={css.Search}>
					<Input type="text" icon={iconsTypes.search} className={classNames(css.SearchInput, isSearchActive && css.SearchActive)} value={searchValue} placeholder="Search by Field Name" onChange={onSearch} onFocus={onFocusSearch} onBlur={onBlurSearch} variant={isSearchActive ? undefined : "inline"} />
				</Col>
			</Row>

			{/* Name */}
			<Row grow="1" paddingBottom="1rem">
				<Input type="text" name="moduleName" wrap="col" label="*Module name" placeholder="Module name, max length is 50 characters" onChange={handleModuleName} value={inputs.name} maxlength={50} validation="required" onValidate={onValidate} />
			</Row>

			{/* Dynamic Form */}
			{
				jsonLogicUsedFields.current.map((logicField) =>
					<TestFieldsGroup key={logicField.key} logicField={logicField} onChange={changeHandler} inputs={inputs.jsonLogic} searchValue={searchValue} autoChangeBooleanFields={false} />
				)
			}
		</Col>
	)
};

export { SingleClientModule };
