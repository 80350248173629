import { FC, useContext } from 'react';
import { ModalsCtx } from "../../../../Context/Modals.context";
import { Modal, Row, Text } from 'lavaa';
import { useModules } from '../../../../Hooks/UseModules';

interface IProps {
    
}

// Delete Module Template Confirm
const DeleteModuleTemplateConfirm: FC<IProps> = (props) => {

    const { isDeleteModuleTemplateConfirmActive, setDeleteModuleTemplateConfirmActive, deleteModuleTemplateUID } = useContext(ModalsCtx);
    const { deleteModule } = useModules();

    // Handle Close
    const handleClose = () => {
        setDeleteModuleTemplateConfirmActive(false);
    };

    // Handle Cancel
    const handleCancel = () => {
        setDeleteModuleTemplateConfirmActive(false);
    };

    // Handle Ok
    const handleOk = () => {
        deleteModule(deleteModuleTemplateUID);
        setDeleteModuleTemplateConfirmActive(false);
    };

    return <Modal 
        active={ isDeleteModuleTemplateConfirmActive } 
        onClose={ handleClose } 
        onCancel={ handleCancel } 
        onOk={ handleOk }
        title="Are you sure you want to delete this module?"
        okText="Delete"
        cancelText="Cancel"
        type="alert"
        scroll={false}
        okButtonType="danger"
    >
        <Row grow="1" paddingBottom="1rem">
            <Text size="x2">If you delete this module you will not be able to restore it.</Text>
        </Row>

    </Modal>
};

export { DeleteModuleTemplateConfirm };