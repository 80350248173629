import { history } from '../../../Constants/History';
import {
    addLastMessageInfoAction,
    addNodeStateAction,
    addProjectDataOutputAction,
    stopProjectRunAction,
    updateProjectRunStatusAction
} from "./ProjectTemporary.slice";
import { IOutputType } from "lavaa";
import { receiveInitialProjectReportDataAction } from '../ProjectReport/ProjectReport.slice';

// Receive Project Add Callback
export const receiveProjectAddCallback = ( store: any, payload: any) => {
    const uid = payload.data.uid;

    let url = history.location.pathname;
    url = (url.slice(-1) === '/') ? url.slice(0, -1) : url;
    const path = url + '/' + uid;
    history.push(path);
}

// Receive Project Run Callback
export const receiveProjectRunCallback = ( store: any, payload: any) => {
    const { message, flowStep: step, nodeInProgressUID, reportUrl: link, flowUID, projectUID, errorMessage } = payload.data;
    const nodeInProgress = nodeInProgressUID ?? '';
    const finishedNodes = payload.data.finishedNodes ?? [];

    let type: IOutputType = 'default';

    // Means that progress is alive
    store.dispatch(updateProjectRunStatusAction([]));

    // Done
    if(step === 3) {
        type = 'success';
    }

    // Fault
    if(step === 4) { // TODO - manage errors
        type = 'error';
        store.dispatch(addProjectDataOutputAction([errorMessage, type]));
    }

    // Remove repeat messages
    const lastMessage = store.getState().projectTemporary.lastMessage;
    if(lastMessage.message !== message || lastMessage.nodeId !== nodeInProgress) {
        store.dispatch(addProjectDataOutputAction([message, type]));
        store.dispatch(addNodeStateAction([nodeInProgress, finishedNodes]));
    }

    // Last Message
    store.dispatch(addLastMessageInfoAction([message, nodeInProgress]));

    // Done
    if(link && flowUID && projectUID && nodeInProgress) {
        // Save Link & Flow UID && projectUID
        store.dispatch(receiveInitialProjectReportDataAction({link, flowUID, projectUID, nodeUID: nodeInProgress}));

        const mess = link.includes('guidelines') ? 'Download guidelines report' : 'Download project report';
        store.dispatch(addProjectDataOutputAction([mess, 'default', link]));
    }

    // Stop / Done
    if(step === 3 || step === 4) {
        store.dispatch(stopProjectRunAction([]));
    }
}
