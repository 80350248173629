import { FC, useEffect, useState } from "react";
import { IMultiTabsContent, MultiTabs, ISubTabsContent} from "lavaa";
import { BasicEdit } from "./Basic.component";
import { useClients } from "../../../../Hooks/UseClients";
import { Filter } from "./Filter.component";

export type FilterType = 'client' | 'module';

interface IProps {
	type: 'realTime' | 'email' | 'slack'
	module: any,
	projectNodes: any[],
	onChange: ( newModuleData: any ) => void,
	onValidate: (value: boolean) => void
}

// Real Time Report Module Settings
const RealTimeReportModule: FC<IProps> = ( props ) => {

	const { type, module, onChange, projectNodes } = props;

	const { getClientScheme, getClientResultsScheme, clientResultsScheme } = useClients();

	// States
	const [clientFilters, setClientFilters] = useState<any>( module.jsonLogic.ClientFilters ?? [] );
	const [moduleFilters, setModuleFilters] = useState<any>( module.jsonLogic.ClientResultFilters ?? [] );
	const [moduleScheme, setModuleScheme] = useState<any>(undefined);
	const [activeTab, setActiveTab] = useState({ tab: 0, subTab: 0 });

	// Get Filter Schemas
	useEffect( () => {
		getClientScheme();
		getClientResultsScheme();
	}, [] );

	useEffect( () => {
		const moduleScheme = structuredClone(clientResultsScheme);

		// If scheme is defined
		if( moduleScheme && moduleScheme.Result && moduleScheme.Result.subfields && moduleScheme.Result.subfields.UID ) {

			// Filter & map project modules
			const modules = projectNodes
				.filter( ( node: any ) => ['my', 'health'].includes(node.type))
				.map((module: any) => {
					return {
						value: module.id,
						title: module.name
					}
				});

			Object.assign(moduleScheme.Result.subfields.UID, {
				listValues: modules
			});
		}

		setModuleScheme( moduleScheme );
	}, [clientResultsScheme])

	// Close Sub Tabs
	function closeSubTab(filterType: FilterType, index: number){
		const update = structuredClone(module);

		if(filterType === 'client'){
			const updatedFilters = [...clientFilters];
			updatedFilters.splice(index, 1);
			setClientFilters(updatedFilters);
			update.jsonLogic.ClientFilters = updatedFilters;
		}

		if(filterType === 'module'){
			const updatedFilters = [...moduleFilters];
			updatedFilters.splice(index, 1);
			setModuleFilters(updatedFilters);
			update.jsonLogic.ClientResultFilters = updatedFilters;
		}

		onChange( update );
	}

	// Method to generate sub tabs
	function generateChildrenTabs( filterType: FilterType ): ISubTabsContent[] {
		const filters = filterType === 'client' ? clientFilters : moduleFilters;
		const childrenTabs: ISubTabsContent[] = [];

		filters.forEach( ( filter: any, index: number ) => {
			childrenTabs.push( {
				title: `#${(index + 1 )}`,
				content: <Filter
					key={`${filterType}${index}`}
					jsonLogic={filter}
					onChange={(value: any) => changeFilterHandler(filterType, index, value)}
					scheme={filterType === "module" ? moduleScheme : undefined}
				/>,
				onClose: () => closeSubTab(filterType, index)
			} );
		} );

		return childrenTabs;
	}

	const tabs: IMultiTabsContent[] = [
		{
			title: 'Basic',
			content: <BasicEdit
				module={module}
				onChange={(data: any) => changeBasicHandler(data)}
				onAddFilter={addFilterHandler}
				canAddClientFilter={clientFilters.length < 3}
				canAddModuleFilter={moduleFilters.length < 3}
				onValidate={props.onValidate}
				isEmailType={type === 'email'}
				isSlackType={type === 'slack'}
			/>
		}
	];

	// Generate client filters subtabs
	const clientFiltersTabs = generateChildrenTabs('client');
	if(clientFiltersTabs.length > 0){
		tabs.push(
			{
				title: 'Clients Filter',
				children: clientFiltersTabs
			}
		);
	}

	// Generate module filters subtabs
	const moduleFiltersTab = generateChildrenTabs('module');
	if(moduleFiltersTab.length > 0){
		tabs.push(
			{
				title: 'Modules Filter',
				children: moduleFiltersTab
			}
		);
	}

	// Change Basic Handler
	function changeBasicHandler(inputs: any) {
		let data = {...module, name: inputs.name, data: {...module.data, label: inputs.name}};

		// Check if slack exists
		if (inputs.slack) {
			if (Array.isArray(data.jsonLogic)) {
				data = {...data, jsonLogic: { SlackChannel: inputs.slack } };
			} else {
				data = {...data, jsonLogic: { ...data.jsonLogic, SlackChannel: inputs.slack } };
			}
		}

		// Check if email exists
		if (inputs.email) {
			if (Array.isArray(data.jsonLogic)) {
				data = {...data, jsonLogic: { Email: inputs.email } };
			} else {
				data = {...data, jsonLogic: { ...data.jsonLogic, Email: inputs.email } };
			}
		}

		onChange(data);
	}

	// Change Filter Handler
	function changeFilterHandler( key: FilterType, index: number, value: any ) {
		const update = structuredClone(module);

		// Add fields to jsonLogic if not exists
		if(Array.isArray(update.jsonLogic)){
			Object.assign(update, {
				jsonLogic: {
					ClientFilters: [],
					ClientResultFilters: [],
				}
			})
		}

		if(key === 'client') {
			const updatedFilters = clientFilters.map( ( filter: any, i: number ) => (i === index) ? value : filter );
			setClientFilters( updatedFilters );
			update.jsonLogic.ClientFilters = updatedFilters;
		}

		if(key === 'module') {
			const updatedFilters = moduleFilters.map( ( filter: any, i: number ) => (i === index) ? value : filter );
			setModuleFilters( updatedFilters );
			update.jsonLogic.ClientResultFilters = updatedFilters;
		}

		onChange( update );
	}

	// Add Filter
	function addFilterHandler(filterType: FilterType) {
		let tabId = 0, subTabId = 0;

		if(filterType === 'client') {
			tabId = 1;
			subTabId = clientFilters.length;
			setClientFilters([...clientFilters, undefined]);
		}

		if(filterType === 'module') {
			tabId = clientFilters.length > 0 ? 2 : 1;
			subTabId = moduleFilters.length ;
			setModuleFilters([...moduleFilters, undefined]);
		}

		// Set Active Tab
		setActiveTab({ tab: tabId, subTab: subTabId });
	}

	return (
		<MultiTabs content={tabs} activeTab={activeTab} hasFullScreen={true}/>
	)
};

export { RealTimeReportModule };
