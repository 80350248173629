import { addActiveRuleAction } from "../RulesetData/RulesetData.slice";
import { updateRulesetAction } from "../Rulesets/Rulesets.slice";

export const receiveRuleCloneCallback = ( store: any, payload: any) => {
    const prevRuleId = payload.data.parent;
    const newRuleId = payload.data.uid;
    const rulesetNodeId = payload.data.rulesetUID;

    const rulesets = store.getState().rulesets.nodes;
    const ruleset = rulesets[rulesetNodeId] || null;
    if(!ruleset) return;

    const jsonLogic = ruleset.jsonLogic.map((data: any) => {
       if(data.ruleUID != prevRuleId) return data;
       return {score: 0, ruleUID: newRuleId, isEnabled: true};
    });

    const newRuleset = Object.assign({}, ruleset, {jsonLogic: jsonLogic});
    store.dispatch(updateRulesetAction([newRuleset]));

    store.dispatch(addActiveRuleAction(newRuleId));
}