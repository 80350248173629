import { FC } from 'react';
import {AuthRegistration} from "../../Common/Auth/Registration/Registration.component";

// Registration Page
const Registration: FC<any> = () => {
  return (
      <AuthRegistration/>
  );
};

export { Registration };