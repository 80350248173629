import { getThemeData } from '../DataProviders/Themes/Themes.dataProvider';
import { useLocalStorage } from 'lavaa';

export const useTheme = (): any => {
    const [localTheme, setLocalTheme] = useLocalStorage<string | null>("theme", null);

    // Change Theme
    const changeTheme = (theme: string) => {
        setLocalTheme(theme);
        setTheme(theme);
    };

    // Get Theme
    const getTheme = async () => {
        return localTheme;
    };

    // Load Theme
    const loadTheme = () => {
        setTheme(localTheme);
    };

    const setTheme = async ( theme?: any ) => {
        if ( typeof theme !== 'string' ){
            changeTheme('light');
            return;
        }

        const themeData = await getThemeData(theme);

        const root = document.documentElement;
        Object.entries(themeData).forEach(([key, value]) => {
            root.style.setProperty(key, value);
        });
    };

    return { changeTheme, loadTheme, getTheme };
}
