import { createContext, FC, useState } from 'react';

export const AppCtx = createContext<{
	[key: string]: any;
}>({});

export const AppContext: FC<any> = (props) => {

    const { children } = props;

    // States
    const [ performanceNow, setPerformanceNow ] = useState(0);
    const [ deleteNodeId, setDeleteNodeId ] = useState('');

    // Banch of states
    const context = {

        // Performance Now
        performanceNow,
        setPerformanceNow,

        deleteNodeId,
        setDeleteNodeId
    };

    return <AppCtx.Provider value={ {...context} }>
        { children }
    </AppCtx.Provider>
};
