import { useDispatch, useSelector } from 'react-redux';
import {
    getAccountAction,
    loginAccountAction,
    logoutAccountAction,
    registrationAccountAction
} from '../Redux/Slices/Account/Account.slice';
import { setLocalStorage } from 'lavaa';

export const useAccount = (): any => {
    const account = useSelector((state: any) => state.account);
    const dispatch = useDispatch();

    // Is Auth
    const isAuth = account.id !== null;

    // Fetch Account
    const fetchAccount = () => {
        dispatch(getAccountAction([account.access_token]));
    };

    // Login Account
    const loginAccount = (login: string, password: string) => {
        dispatch(loginAccountAction([login, password]));
    };

    // Registration Account
    const registrationAccount = (data: any) => {
        const { name, email, password} = data;
        dispatch(registrationAccountAction([{
            name,
            email,
            password
        }]));
    };

    // Logout Account
    const logoutAccount = () => {
        setLocalStorage("refresh_token", ""); // Temporary
        dispatch(logoutAccountAction([]));
    };

    return { fetchAccount, loginAccount, registrationAccount, logoutAccount, account, isAuth };
}
