import { FC } from 'react';
import { useTypes } from '../../../../Hooks/UseTypes';
import { Dropdown } from 'lavaa';

interface IProps {
    onSelect: (data: any) => void,
    selected?: any,
    disabled?: boolean
}

// Module Type Selector
const ModuleTypeSelector: FC<IProps> = (props) => {

    const { onSelect, selected, disabled = false } = props;

    const { moduleTypes } = useTypes();

    return <Dropdown disabled={disabled} data={ moduleTypes } displayField="name" selectField="typeID" selected={ selected } placeholder="Module:" label="*Module type" wrap="col" onSelect={ onSelect } />
};

export { ModuleTypeSelector };