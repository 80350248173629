import { AbstractionType } from "../../../Constants/AbstractionType";
import { getRandom } from "../../../Tools";
import { parseJsonLogicFlow } from "../../../Tools/parseJsonLogic";
import { addProjectDataOutputAction } from "../Projects/ProjectTemporary.slice";
import { updateProjectAction } from "../Projects/Projects.slice";
import { v4 as uuid } from 'uuid';

const receivePublishModuleCallback = ( store: any, payload: any) => {
    if(payload.resultCode === 'AUTH_ERROR'){
        store.dispatch(addProjectDataOutputAction(["You do not have access to publish this module!"]))
    }
}

const receiveAddModuleCallback = ( store: any, payload: any) => {
    const state = store.getState();
    const { data: moduleTemplate } = payload;
    const { projectUID } = moduleTemplate;
    const getProject = (uid: string) => {
        return state.projects.open.data[uid] || state.projects.myCompany.data[uid] || state.projects.my.data[uid] || null;
    };
    const projectData = getProject(projectUID);
    const jsonLogic = parseJsonLogicFlow(projectData);
    const { initialNodes, initialEdges } = jsonLogic;
    const nodeId = uuid();
    const nodeType = 'my';
    
    const newNode = {
        id: nodeId,
        nodeId: nodeId,
        primaryId: moduleTemplate.primaryId,
        type: nodeType,
        position: {
            x: getRandom(250, 350),
            y: getRandom(0, 150)
        },
        name: moduleTemplate.name,
        data: {
            label: moduleTemplate.name
        },
        description: moduleTemplate.description,
        abstractionType: AbstractionType[nodeType],
        accessType: moduleTemplate.accessType,
        additionalInfo: moduleTemplate.additionalInfo,
        createdByCompany: "ec3ca9d3-f9cd-4707-9520-0e0c7d9aceb2",
        disorderTypeID: moduleTemplate.disorderTypeID,
        jsonLogic:[],
        lastUpdateDate: (new Date()).toISOString(),
        parent: null,
        nodeType: "node",
        moduleTypeID: moduleTemplate.moduleTypeID
    };

    const updateData: any = {
        ...projectData,
        jsonLogic: [
            {name: 'initialEdges', data: [...initialEdges]},
            {name: 'initialNodes', data: [...initialNodes, newNode]}
        ]
    };

    store.dispatch(updateProjectAction([updateData]));
}

export { receivePublishModuleCallback, receiveAddModuleCallback }

