import { FC, useEffect, useState } from "react";
import { Row, Col, Cell, Container, ScrollContainer } from 'lavaa';
import { Title } from "lavaa";
import { Text } from "lavaa";
import { classNames } from "../../../../Tools";
import { iconsTypes } from 'lavaa';
import { useRules } from "../../../../Hooks/UseRules";
import { EditRuleModal } from "../../../Custom/Modals/EditRuleModal/EditRuleModal.component";
import { useRulesetData } from "../../../../Hooks/UseRulesetData";
import { DeleteRuleActionButton } from "../../../Custom/Modals/DeleteRuleConfirm/DeleteRuleActionButton.component";
import { DeleteRuleConfirm } from "../../../Custom/Modals/DeleteRuleConfirm/DeleteRuleConfirm.component";
import { IRuleType } from "../../../../Redux/Slices/Rules/Rules.data";
import { Toggle } from "lavaa";
import { useRulesets } from "../../../../Hooks/UseRulesets";
import { CreateNewRuleModal } from "../../../Custom/Modals/CreateNewRuleModal/CreateNewRuleModal.component";
import css from "./RulesList.module.scss";

interface IProps {
  data: Array<any>,
}

// Rules List
const RulesList: FC<IProps> = ( props ) => {

  const { data } = props;

  const { rules } = useRules();
  const { activeRule, addActiveRule } = useRulesetData();
  const { updateRuleIsEnabled } = useRulesets();
  const [activeRuleEdit, setActiveRuleEdit] = useState<IRuleType | null>(null);

  function viewRule( e: Event, ruleUID: string ) {
    e.stopPropagation();
    addActiveRule(ruleUID);
  }

  function handleIsEnabledClick( e: Event, ruleUID: string ) {
    e.stopPropagation();

    const RulesetRuleConfig = data.find(item => item.ruleUID === ruleUID) ?? null;
    if(RulesetRuleConfig === null) return;
    const isEnabled = !RulesetRuleConfig.isEnabled;

    updateRuleIsEnabled(ruleUID, isEnabled);
  }

  useEffect(() => {
    if(activeRule !== '') {
      const RulesetRuleConfig = data.find(item => item.ruleUID === activeRule) ?? null;
      const score = (RulesetRuleConfig) ? RulesetRuleConfig.score : null;
      const rule = rules[activeRule] || null;

      if(!rule){
        setActiveRuleEdit(null);
        return;
      }

      const ruleData:IRuleType = Object.assign({}, rule, { score: score });
      setActiveRuleEdit(ruleData);
    }
  }, [activeRule, rules, data]);

  return (
    <Container className={css.RulesList}>
        <ScrollContainer>
        <Row wrap="wrap" paddingLeft="1rem" paddingRight="1rem" paddingTop="2rem" justifycontent="center" grow="1">
        {
          data.map((item: any, index: number) => {
            const rule = rules[item.ruleUID];
            if(!rule) return null;
            const hasScore = item.score !== null && item.score !== undefined;

            return (
              <Col key={rule.uid+'_'+index} className={css.Item} shrink="1" grow="1" onClick={(e) => viewRule(e, rule.uid) }>

                {/* Top */}
                <Row alignitems="center" paddingTop="0.25rem">

                  {/* Name */}
                  <Cell className={css.Name}>
                    <Title size="x3" ellipsis={true}>{rule.name}</Title>
                  </Cell>
                </Row>

                {/* Center - Description */}
                <Row grow="1" className={css.DescriptionBlock}>
                  <Text size="x2" bold="true" className={css.Description} text={rule.description}  ellipsis={true} maxLines={4}/>
                </Row>

                {/* Filler */}
                {/* <Row grow="1" /> */}

                {/* Bottom */}
                <Row alignitems="center">

                  {/* Score */}
                  {
                    hasScore && (
                      <Row grow="1" justifycontent="center" className={css.Controls}>
                        <Cell className={css.ScoreBlock} alignitems="center">
                          Score: <div className={css.Score}><span>{item.score >= 1000 ? '999+' : item.score}</span></div>
                        </Cell>
                      </Row>
                    )
                  }

                  {/* Toggle Rule */}
                  <Row grow="1" justifycontent="center" className={css.Controls}>
                    <Cell className={classNames( css.Toggle, css.View, !hasScore ? css.LeftBtn : "" )} alignitems="center" grow="1"  justifycontent="center"  onClick={ (e) => handleIsEnabledClick(e, rule.uid) }>
                      <Toggle onToggle={() => {}} active={item.isEnabled} size="small" color="gray"/>
                    </Cell>
                  </Row>

                  {/* Delete Rule */}
                  <Row grow="1" justifycontent="center" className={css.Controls}>
                    <DeleteRuleActionButton
                      className={classNames( css.Button, css.Delete )}
                      icon={iconsTypes.delete}
                      grow="1"
                      type="secondary"
                      uid={rule.uid}
                    />
                  </Row>
                </Row>
              </Col>
            )
          })
        }
        </Row>
        </ScrollContainer>

      {/* Create New Rule Popup */}
      <CreateNewRuleModal />

      {/* Edit Rule Popup */}
      <EditRuleModal rule={activeRuleEdit}/>

      {/* Delete Rule Confirm Popup */}
      <DeleteRuleConfirm />

    </Container>
  );
};

export { RulesList };
