import { IconPickerDataType } from "lavaa";

export const moduleIcons: IconPickerDataType = [
	{
		name: "diabetes",
		additionalNames: "diabetes;blood;sugar;insulin;glucose",
		tooltip: "Diabetes"
	},
	{
		name: "cardiovascular_disease",
		additionalNames: "cardiovascular;CVD;heart;blood;stroke",
		tooltip: "Cardiovascular"
	},
	{
		name: "covid_19",
		additionalNames: "covid;virus;infection",
		tooltip: "Covid-19"
	},
	{
		name: "male",
		additionalNames: "men;man;prostate",
		tooltip: "Men Health"
	},
	{
		name: "female",
		additionalNames: "women;woman;menopause;breast;fertility; gynecology;",
		tooltip: "Women Health"
	},
	{
		name: "metabolism",
		additionalNames: "metabolic;fat;obesity",
		tooltip: "Metabolism"
	},
	{
		name: "pulmonary",
		additionalNames: "pulmonary;lung",
		tooltip: "Lungs"
	},
	{
		name: "dentistry",
		additionalNames: "teeth;mouth",
		tooltip: "Teeth"
	},
	{
		name: "rheumatology",
		additionalNames: "bone;osteo",
		tooltip: "Bones"
	},
	{
		name: "symptom_eye",
		additionalNames: "eye;astigmatism;cataract;glaucoma",
		tooltip: "Eyes"
	},
	{
		name: "oncological_diseases",
		additionalNames: "oncology;cancer",
		tooltip: "Oncology"
	},
	{
		name: "ear",
		additionalNames: "ear;deafness",
		tooltip: "Ears"
	},
	{
		name: "blood_diseases",
		additionalNames: "blood;anemia;bleeding;leukemia;lymphoma;myeloma",
		tooltip: "Blood"
	},
	{
		name: "oxygen_saturation",
		additionalNames: "nose;sinus;nasal;smell",
		tooltip: "Nose"
	},
	{
		name: "brain",
		additionalNames: "brain;epilepsy;Alzheimer;Parkinson;Dementia;headache;sclerosis",
		tooltip: "Brain"
	},
	{
		name: "falls",
		additionalNames: "falls;dizziness",
		tooltip: "Falls"
	},
	{
		name: "pregnancy",
		additionalNames: "pregnancy",
		tooltip: "Pregnancy"
	},
	{
		name: "genetics",
		additionalNames: "genetic;cell",
		tooltip: "Genetics"
	},
	{
		name: "chronic_kidney",
		additionalNames: "kidney;CKD",
		tooltip: "Kidneys"
	},
	{
		name: "alzheimer",
		additionalNames: "brain;epilepsy;Alzheimer;Parkinson;Dementia;headache;sclerosis",
		tooltip: "Brain"
	},
	{
		name: "mental_health",
		additionalNames: "mental;psycho;depression",
		tooltip: "Mental Health"
	},
	{
		name: "clinical_research",
		additionalNames: "clinical research",
		tooltip: "Clinical Research"
	},
	{
		name: "metabolic_disease",
		additionalNames: "metabolic;fat;obesity",
		tooltip: "Metabolism"
	},
	{
		name: "intestines",
		additionalNames: "stomach;gastro;pancreatitis",
		tooltip: "Stomach"
	},
	{
		name: "hypertension",
		additionalNames: "hypertension;blood pressure;BP",
		tooltip: "Hypertension"
	},
	{
		name: "foot",
		additionalNames: "foot;leg;heel;toe;knee;muscles",
		tooltip: "Foot/Leg"
	},
	{
		name: "arm",
		additionalNames: "arm;hand;finger;elbow;muscles",
		tooltip: "Arm/Hand"
	},
	{
		name: "liver",
		additionalNames: "liver;hepatitis",
		tooltip: "Liver"
	},
	{
		name: "vessels",
		additionalNames: "vessels;vein;thrombosis;artery;venous",
		tooltip: "Vessels"
	},
	{
		name: "sexually_disease",
		additionalNames: "syphilis;gonorrhea;chlamydia;trichomoniasis;sexual;hepatitis B;herpes;HSV;HIV;papillomavirus;HPV",
		tooltip: "STDs"
	},
	{
		name: "hormones",
		additionalNames: "hormone",
		tooltip: "Hormones"
	},
	{
		name: "thyroid",
		additionalNames: "thyroid",
		tooltip: "Thyroid"
	},
	{
		name: "intestines_disease",
		additionalNames: "intestines;gastro;pancreatitis;fecal;gastritis",
		tooltip: "Intestines"
	},
	{
		name: "viruses",
		additionalNames: "virus;infection",
		tooltip: "Viruses"
	},
	{
		name: "skin",
		additionalNames: "skin;acne;melanoma",
		tooltip: "Skin"
	},
	{
		name: "rare_diseases",
		additionalNames: "rare diseases;other",
		tooltip: "Rare Diseases"
	},
	{
		name: "dollar",
		additionalNames: "money;billing;missed codes;missing codes;codes optimization;value;$;cost",
		tooltip: "Costs/ Billings"
	},
	{
		name: "doc_operations",
		additionalNames: "management operation modules",
		tooltip: "Operations"
	},
]
