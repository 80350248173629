import { FC, useContext } from 'react';
import { ModalsCtx } from "../../../../Context/Modals.context";
import { Modal } from 'lavaa';
import { Row } from 'lavaa';
import { Text } from 'lavaa';
import { useRulesets } from "../../../../Hooks/UseRulesets";

interface IProps {
}

// Delete Rule Confirm
const DeleteRuleConfirm: FC<IProps> = (props) => {

    let { isDeleteRuleConfirmActive, setDeleteRuleConfirmActive, deleteRuleUID } = useContext(ModalsCtx);
    const { deleteRuleFromRuleset } = useRulesets();

    // Handle Close
    const handleClose = () => {
        setDeleteRuleConfirmActive(false);
    };

    // Handle Cancel
    const handleCancel = () => {
        setDeleteRuleConfirmActive(false);
    };

    // Handle Ok
    const handleOk = () => {
        deleteRuleFromRuleset(deleteRuleUID);
        setDeleteRuleConfirmActive(false);
    };

    return <Modal 
        active={ isDeleteRuleConfirmActive }
        onClose={ handleClose } 
        onCancel={ handleCancel } 
        onOk={ handleOk }
        title="Are you sure want to delete this rule?"
        okText="Delete"
        cancelText="Cancel"
        type="alert"
        scroll={false}
        okButtonType="danger"
    >
        <Row grow="1" paddingBottom="1rem">
            <Text>If you delete this rule you will not be able to restore it.</Text>
        </Row>

    </Modal>
};

export { DeleteRuleConfirm };