import { FC, useContext, useEffect, useState } from 'react';
import { Grid, Row, Col, DropdownMulti, ReportLabel, Cell, Sorting, Loading, Paginator, Text } from 'lavaa';
import { IProjectReportData } from '../../../Redux/Slices/ProjectReport/ProjectReport.slice';
import { ReportLink } from './ReportLink/ReportLink.component';
import { Table } from './Table/Table.component';
import { ModalsCtx } from '../../../Context/Modals.context';
import { useProjectReport } from '../../../Hooks/UseProjectReport';
import css from './ProjectReport.module.scss';

interface IProps {
    // modules: any[],
    reportData: IProjectReportData
}

// Project Report
const ProjectReport: FC<IProps> = (props) => {

    // Props
    const { /*modules, */reportData } = props;
    const { link, patients = [], flowUID, nodeUID, sortBy, sortType, projectUID } = reportData;
    let reportModules: any = reportData && reportData.patients && reportData.patients.length ? reportData.patients.map((item: any) => item.results).flat() : [];
    reportModules = Array.from(new Set(reportModules.map((item: any) => item.moduleUID))).map((uid: any) => reportModules.find((item: any) => item.moduleUID === uid));

    // States
    const [selectedModules, setSelectedModules] = useState<any[]>([]);

    // Context
    const { setPatientLabResultModalActive, setPatientLabResultModalData } = useContext(ModalsCtx);

    // Hooks
    const { fetchProjectReport, projectReportLoading, goToPage, setSorting } = useProjectReport();

    // Vars
    const { page } = reportData;

    // On Report Label Click
    const onReportLabelClick = (e: Event, moduleName: string, labResult: any, clientId: string) => {
        e.stopPropagation();

        setPatientLabResultModalData({ moduleName, labResult, clientId });
        setPatientLabResultModalActive(true);
    };

    // On Sort Column
    const sortColumn = (item: any) => {
        const { moduleUID } = item;
        const sort = {
            by: sortBy,
            type: sortType
        };

        // Change Module
        if (sortBy !== moduleUID) {
            sort.by = moduleUID;
            sort.type = 'asc';
        }

        // Change Type
        else {
            sortType === null && (sort.type = 'asc');
            sortType === 'asc' && (sort.type = 'desc');
            sortType === 'desc' && (sort.type = null);
        }

        setSorting(projectUID, flowUID, nodeUID, page, sort.by, sort.type);
    };

    // Grid Columns
    const columns = [
        { id: 'id', key: 'id', name: 'ID', frozen: true, width: 80 },
        ...selectedModules.map((item: any) => ({
            id: item.moduleUID,
            key: item.moduleUID,
            name: <Row className={css.Column} onClick={() => sortColumn(item)}>
                <Row className={css.ColumnName} grow="1">{item.moduleName}</Row>
                <Sorting active={sortBy === item.moduleUID ? sortType : null} />
            </Row>,
            width: 182
        }))
    ];

    // Grid Rows
    const rows = patients.map((patient: any) => {
        const { id: clientId, results } = patient;
        const row = {
            ...patient,
            ...results.reduce((obj: any, item: any) => {
                const { moduleUID, moduleName, textColor, bgColor, resultGroupName } = item;
                return { ...obj, [moduleUID]: <Cell marginTop="0.5rem"><ReportLabel onClick={(e: Event) => onReportLabelClick(e, moduleName, item, clientId)} textColor={textColor} bgColor={bgColor}><Text style={{color: textColor}} ellipsis={true} maxLines={1}>{resultGroupName}</Text></ReportLabel></Cell> }
            }, {})
        };

        return row;
    });
    const currentItems = patients.length;

    // On Module Select
    const onModuleSelect = (options: any[]) => {
        setSelectedModules([...options]);
    };

    function changePage(page: number) {
        goToPage(projectUID, flowUID, nodeUID, page - 1);
    }

    // Watch link
    useEffect(() => {
        if (reportData && reportData.link && reportData.flowUID) {
            fetchProjectReport(reportData.projectUID, reportData.flowUID, reportData.nodeUID, page);
        }
    }, [reportData.link]);

    // Loading
    if (projectReportLoading === true /*|| (!patients.length && page === 0)*/) {
        return <Col grow="1" alignitems="center" justifycontent="center">
            <Loading />
        </Col>
    }

    // No Data
    if (projectReportLoading === false && patients.length == 0 && page === 0) {
        return <Col grow="1" alignitems="center" justifycontent="center">
            {
                reportData && reportData.link ?
                <span>No data for grid, please check the <a href={reportData.link} target="_blank">report</a> for more information</span>
                : <span>No data</span>
            }
        </Col>
    }

    return <Grid className={css.ProjectReport} grow="1">

        {/* Top Bar */}
        <Row alignitems="center">

            {/* Modules Selector */}
            <DropdownMulti showCountSelected={true} selectAllByDefault={true} placeholder="Modules" data={reportModules} displayField="moduleName" selectField="moduleUID" onSelect={onModuleSelect} />

            <Paginator page={page + 1} changePage={changePage} currentItems={currentItems} perPage={25} />

            {/* Report Link */}
            <ReportLink link={link} />

        </Row>

        {/* Table */}
        <Table columns={columns} rows={rows} />
    </Grid>
};

export { ProjectReport };
