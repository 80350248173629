const data: Array<any> = [
    {
        id: 1,
        label: 'Admin',
        text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        isNew: true,
        expanded: false
    },
    {
        id: 2,
        label: 'Admin',
        text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        isNew: false,
        expanded: false
    },
    {
        id: 3,
        label: 'Admin',
        text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        isNew: false,
        expanded: false
    },
    {
        id: 4,
        label: 'Admin',
        text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        isNew: false,
        expanded: false
    },
    {
        id: 5,
        label: 'Admin',
        text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        isNew: false,
        expanded: false
    },
    {
        id: 6,
        label: 'Admin',
        text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        isNew: false,
        expanded: false
    },
];

export { data as NotificationData };