import { useDispatch, useSelector } from 'react-redux';
import {
    addMappingAction,
    deleteMappingAction,
    getMappingsAction,
    updateMappingAction,
    resetMappingAction
} from "../Redux/Slices/Mapping/Mapping.slice";

export const useMapping = (): any => {

    const mappingData = useSelector((state: any) => state.mapping);
    const mappings = mappingData.mappings;
    const lastAddedMappingUID = mappingData.lastAddedMappingUID;

    const dispatch = useDispatch();

    // Add Mapping
    const addMapping = (data: any) => {
        dispatch(addMappingAction(data));
    };

    // Update Mapping
    const updateMapping = (data: any) => {
        dispatch(updateMappingAction(data));
    };

    // Delete Mapping
    const deleteMapping = (mappingUID: string) => {
        dispatch(deleteMappingAction(mappingUID));
    };

    // Fetch Mappings
    const fetchMapping = () => {
        dispatch(getMappingsAction([]));
    };

    const getMapping = (mappingUID: string) => {
        return mappings[mappingUID] || null;
    }

    // Reset Mapping
    const resetMapping = () => {
        dispatch(resetMappingAction());
    }

    return {
        addMapping, updateMapping, deleteMapping,
        fetchMapping,
        getMapping,
        mappings, lastAddedMappingUID,
        resetMapping
    };
}