import { FC, useEffect, useState } from 'react';
import { Button, Col, Grid, Input, Row, Title, iconsTypes, Text } from 'lavaa';
import css from './Counter.module.scss';
import { classNames } from '../../Tools';

interface IProps {
    amount: number;
    onIncrease: () => void;
    onDecrease: () => void;
}

// Counter
const Counter: FC<IProps> = (props) => {

    const { amount = 0, onIncrease, onDecrease } = props;

    // States
    const [ value, setValue ] = useState<number>(amount);

    // On Increase
    const increaseValue = (e: any) => {
        setValue(value + 1);
        onIncrease();
    };

    // On Decrease
    const decreaseValue = (e: any) => {
        if (value <= 0) return;

        const newValue = value - 1;
        setValue(newValue < 0 ? 0 : newValue);
        onDecrease();
    };

    return (
        <Row className={ css.Counter } alignitems="center">
            
            {/* Minus */}
            <Row className={ css.Minus } paddingRight="0.5rem" alignitems="center">
                <Button className={ css.Button } type="secondary" variant="text" icon={ iconsTypes.minus } iconSize="18" onClick={ decreaseValue } />
            </Row>

            {/* Amount */}
            <Row className={ css.Amount } alignitems="center" justifycontent="center">
                <Text size="x2">{amount || ''}</Text>
            </Row>

            {/* Plus */}
            <Row className={ css.Plus } paddingLeft="0.5rem">
                <Button className={ css.Button } type="secondary" variant="text" icon={ iconsTypes.plus } iconSize="18" onClick={ increaseValue } />
            </Row>
        </Row>
    )
};

export { Counter };
