import { createSlice } from '@reduxjs/toolkit';
import { NotificationData } from '../../../DataProviders/Notifications/Notifications.data';

export interface INotificationState {
    id: number,
    label: string,
    text: string,
    isNew: boolean,
    expanded: boolean
}

const initialState: INotificationState[] = [];

export const notificationsSlice = createSlice({
    name: 'Notifications',
    initialState,
    reducers: {

        // Get Notifications
        getNotificationsAction: (state, action) => {
            return [...state, ...NotificationData];
        },

        // Reset Notifications
		resetNotificationsAction: (state) => {
			return [...initialState];
		}
    }
});

export const {
    getNotificationsAction,
    resetNotificationsAction
} = notificationsSlice.actions;
export default notificationsSlice.reducer;