import { FC, useContext } from 'react';
import { ModalsCtx } from "../../../../Context/Modals.context";
import { Modal } from 'lavaa';
import { Row, Col } from 'lavaa';
import { Input } from 'lavaa';

// Source Local File Modal
const SourceLocalFileModal: FC<any> = () => {

    let { isSourceLocalFileModalActive, setSourceLocalFileModalActive } = useContext(ModalsCtx);

    // Handle Close
    const handleClose = () => {
        setSourceLocalFileModalActive(false);
    };

    // Handle Cancel
    const handleCancel = () => {
        setSourceLocalFileModalActive(false);
    };

    // Handle Ok
    const handleOk = () => {
        setSourceLocalFileModalActive(false);
    };

    return <Modal 
        active={ isSourceLocalFileModalActive }
        onClose={ handleClose } 
        onCancel={ handleCancel } 
        onOk={ handleOk }
        title='Local file'
        okText='Save'
        cancelText='Cancel'
        type='middle'
    >
        <Col grow="1" paddingBottom="1rem">
            
            {/* Name */}
            <Row grow='1' paddingBottom='1rem'>
                <Input type='text' wrap='col' label='Name' placeholder='Name' onChange={ () => {} } />
            </Row>

            {/* Description */}
            <Row grow='1' paddingBottom='1rem'>
                <Input type='textarea' wrap='col' label='Description' placeholder='Description' onChange={ () => {} } />
            </Row>
        </Col>

    </Modal>
};

export { SourceLocalFileModal };