import JL from "json-logic-js";
import { FieldOrGroup, Fields } from "@react-awesome-query-builder/ui";

export function parseJsonLogicFlow( data: any ) {
  if ( data && data.jsonLogic && Array.isArray( data.jsonLogic ) ) {
    const initialNodes = data.jsonLogic.find( ( item: any ) => item.name === "initialNodes" ) || { data: [] };
    const initialEdges = data.jsonLogic.find( ( item: any ) => item.name === "initialEdges" ) || { data: [] };
    return { initialNodes: initialNodes.data, initialEdges: initialEdges.data };
  }

  return { initialNodes: [], initialEdges: [] };
}

export function parseJsonLogicUsedFields( jsonLogic: object, clientScheme: Fields ): JsonLogicFieldType[] {

  // Get Uses Data from JsonLogic
  const usesData = JL.uses_data( jsonLogic );

  return prepareJsonLogicInputFields( clientScheme, usesData );
}

export function prepareJsonLogicInputFields( clientScheme: Fields, usesData: string[] | null = null, keysInCamelCase = false ): JsonLogicFieldType[] {

  const types = ["text", "number", "select", "multiselect", "date", "datetime", "boolean"];
  const fields: JsonLogicFieldType[] = [];

  Object.entries( clientScheme ).forEach( ( [key, field] ) => {
    let localFields: { [key: string]: FieldOrGroup } = {}, localFieldsSorted: { [key: string]: FieldOrGroup } = {},
      count = 0;

    const localKey = (keysInCamelCase) ? toCamelCase( key ) : key;
    const state: JsonLogicFieldType = {
      key: localKey,
      parentLabel: field.label ?? "",
      fields: {},
      field: null
    }

    if ( "subfields" in field ) {
      Object.entries( field.subfields ).forEach( ( [key, childField] ) => {
        const localKey = (keysInCamelCase) ? toCamelCase( key ) : key;
        if (
          types.includes( childField.type )
          && (usesData == null || (usesData!.includes( key )))
        ) {
          count++;
          Object.assign( localFields, {
            [localKey]: childField
          } );
        }
      } );

      Object.entries( localFields )
        .sort( function ( f1, f2 ) {
          const a = f1[1].type === "boolean" ? 1 : 0;
          const b = f2[1].type === "boolean" ? 1 : 0;
          return a - b;
        } )
        .map( ( [localKey, field] ) => {
          Object.assign( localFieldsSorted, {
            [localKey]: field
          } );
        } );

      state.fields = localFieldsSorted;
    } else {
      if (
        types.includes( field.type )
        && (usesData == null || (usesData!.includes( key )))
      ) {
        state.field = field;
        count++;
      }
    }

    if ( count > 0 ) {
      fields.push( state );
    }
  } );

  return fields;
}

export function prepareJsonLogicDataFields( fields: any ): object {
  // const formatted = Object.assign( {}, fields );
  //
  // Object.keys( fields ).forEach( ( key ) => {
  //   if ( isObject( fields[key] ) ) {
  //     formatted[key] = fields[key];
  //   }
  // } );

  return fields;
}

export function clientDataToLowerCamel( client: any ): object {
  const formatted: any = {};
  Object.keys( client ).forEach( ( key ) => {
    const data: any = {};
    Object.keys( client[key] ).forEach( ( key2 ) => {
      data[toCamelCase( key2, false )] = client[key][key2];
    } );
    formatted[toCamelCase( key, false )] = data;
  } );

  return formatted;
}

type FieldsType = {
  [key: string]: FieldOrGroup
}

export interface JsonLogicFieldType {
  key: string,
  parentLabel: string,
  field: FieldOrGroup | null,
  fields: FieldsType
}

function toCamelCase( str: string, isFirstCharUpperCase = true ): string {
  return str
    .replace( /\s(.)/g, function ( a ) {
      return isFirstCharUpperCase ? a.toUpperCase() : a.toLowerCase();
    } )
    .replace( /\s/g, "" )
    .replace( /^(.)/, function ( b ) {
      return b.toLowerCase();
    } );
}

export function prepareJsonLogicByDateFields( logic: any, scheme: Fields ):  any {
	const flattenScheme = flattenClientScheme( scheme );
	const datesScheme: any = {};
	Object.entries(flattenScheme).forEach(([key, item]) => {
		if(item.type === "date" || item.type === "datetime") {
			datesScheme[key] = item;
		}
	});

	return prepareJsonLogicByDateFieldsRecursive(logic, datesScheme);
}

function prepareJsonLogicByDateFieldsRecursive( logic: any, scheme: Fields ):  any {
	if (Array.isArray(logic)) {
		return logic.map(function(l) {
			return prepareJsonLogicByDateFieldsRecursive(l, scheme);
		});
	}

	if ( ! JL.is_logic(logic) ) {
		return logic;
	}

	const newObj: any = {};
	var op = JL.get_operator(logic as any);
	var values = (logic as any)[op];
	const newScheme = changePrimitiveDateLogic(values, scheme);
	if(newScheme) {
		newObj[op] = newScheme;
		return newObj;
	}

	for (const [key, value] of Object.entries(logic)) {
		newObj[key] = prepareJsonLogicByDateFieldsRecursive(value, scheme);
	}
	return newObj;

}

function changePrimitiveDateLogic(logic: any, scheme: Fields) {
	if(!Array.isArray(logic)){
		return false;
	}

	const label = logic[0];
	if(!label
		|| typeof logic !== "object"
		|| Array.isArray(label)
		|| !label.hasOwnProperty("var")
	) {
		return false;
	}

	const v = label.var;
	if(!scheme.hasOwnProperty(v)) return false;
	const schemeField = scheme[v];
	if(schemeField.type !== "date" && schemeField.type !== "datetime") return false;

	const newLogic: any = [];

	logic.forEach((item, index) => {
		if(index === 0){
			newLogic.push(item);
		}

		if(typeof item === "string"){
			const date = new Date(item);
			newLogic.push(date.getTime());
		}

		if ( item instanceof Date ) {
			newLogic.push(item.getTime());
		}
	});

	return newLogic;
}

function flattenClientScheme(scheme: Fields): Fields {
  const fields: Fields = {};

  Object.entries(scheme).forEach(([key, field]) => {
	if ("subfields" in field) {
	  Object.assign(fields, flattenClientScheme(field.subfields));
	} else {
	  fields[key] = field;
	}
  });

  return fields;
}

export function prepareTestData(data: any):any {
	const newData: any = {};

	Object.entries(data).forEach(([key, item]) => {
		if(item instanceof Date) {
			newData[key] = item.getTime();
		} else {
			newData[key] = item;
		}
	});

	return newData;
}
