import React, { FC, useLayoutEffect, useRef, useState } from "react";
import ReactDom from "react-dom";
// import { Row, Cell, Col, ScrollContainer } from "../../../Layout";
// import { Text } from "../../../Text";
// import { Input } from "../../Text/Input.component";
// import { Icon, iconsTypes } from "../../../Icon";
import css from "../Dropdown.module.scss";
import {classNames} from "../../../../Tools";
// import { iconSizeType } from "../../../Icon/Icon.config";
import {
	Row, Cell, Col, ScrollContainer,
	Text,
	Input,
	Icon, iconsTypes
} from 'lavaa';

interface IProps {
	active: boolean;
	selectBounds: {
		x: number,
		y: number,
		width: number,
		height: number,
	},
	data: Array<any>,
	onChange: (value: any) => void,
	selectField: string,
	displayField: string,
	hasSearch: boolean,
	selected?: any,
	displayIcon?: boolean,
	iconSize?: any//iconSizeType
}

const modalRoot = document.getElementById("modal-root");

// Select
const Select: FC<IProps> = React.memo((props) => {

	const { active, selectBounds, data, onChange, selectField, displayField, hasSearch, selected, displayIcon = false, iconSize = '18' } = props;

	const [searchValue, setSearchValue] = useState<string>('');
	const [element, setElement] = useState<any>(null);
	const [listHeight, setListHeight] = useState<number>(0);
	const elementRef = useRef<any>(null);
	const selectRef = useRef<HTMLDivElement>(null);

	useLayoutEffect(() => {
		const el = document.createElement("div");
		if (modalRoot) modalRoot.appendChild(el);
		elementRef.current = el;
		setElement(el);

		return () => {
			if (elementRef.current) {
				if (modalRoot) modalRoot.removeChild(elementRef.current);
			}
		};
	}, []);

	useLayoutEffect(() => {
		if (!active) {
			setListHeight(0);
			return;
		}

		if (selectRef.current) {
			setListHeight(selectRef.current.offsetHeight);
		}
	}, [active]);

	if (!element || !active) return null;

	let top = selectBounds.y + selectBounds.height + 5;
	if (top + listHeight + 10 > window.innerHeight) {
		top = window.innerHeight - listHeight - 10;
	}

	const width = Math.max(selectBounds.width, 210);

	let left = selectBounds.x;
	if(left + width > window.innerWidth) {
		left = Math.max(window.innerWidth - width - 10, 0);
	}

	const selectStyles = {
		width: `${width}px`,
		top: `${top}px`,
		left: `${left}px`,
	}

	function handleWrapperClick(e: any) {
		e.stopPropagation();
		e.preventDefault();
		onChange(null);
		setSearchValue('');
	}

	function handleSelect(e: any, value: any) {
		e.stopPropagation();
		e.preventDefault();
		setSearchValue('');

		onChange(value);
	}

	// On Search
	const onSearch = (value: string) => {
		setSearchValue(value);
	};

	const searchValueLower = searchValue.toLowerCase();
	const items = searchValueLower.length > 0
		? data.filter((item: any) => item[displayField]?.toLowerCase().includes(searchValueLower) && !item.hasOwnProperty("subhead"))
		: data;

	const selectedValue = selected ? selected[selectField] ?? "" : selected;

	const content = (
		<div className={css.SelectWrapper}>

			{/* Overlay */}
			<div className={css.SelectBg} onClick={handleWrapperClick} />

			{/* Select Body */}
			<Col className={css.Select} style={selectStyles} innerRef={selectRef}>
				{hasSearch && (
					<Cell paddingTop="0.25rem" paddingLeft="0.5rem" paddingRight="0.5rem">
						<Input type="text" icon={iconsTypes.search} value={searchValue} placeholder="Search..." onChange={onSearch} />
					</Cell>
				)}

				{/* Scroll */}
				<ScrollContainer maxHeight={hasSearch ? 330 : 370}>
					{
						items.map((item: any, index: number) => {
							if (item.hasOwnProperty("subhead")) {
								return (
									<div className={css.SubHead} key={index}>
										<Text size="x2" className={css.SubHeadText} bold={true} ellipsis={true}>{item.subhead}</Text>
									</div>
								)
							} else {

								return (
									<Row className={classNames(css.Option, item[selectField] === selectedValue ? css.OptionActive : "")}
										 key={index} alignitems="center"
										 onClick={(e) => handleSelect(e, item[selectField])}
									>
										{/* Icon */}
										{
											displayIcon === false || (displayIcon === true && !item.icon) ? null :
											<Row marginRight="0.5rem" alignitems="center">
												<Icon name={item.icon} style={{display: 'flex'}} />
											</Row>
										}

										{/* Text */}
										<Text size="x2" className={css.OptionText} ellipsis={true}>{item[displayField]}</Text>
									</Row>
								)
							}
						})
					}
					{
						items.length === 0 && (
							<Text size="x2" className={css.NotFound} ellipsis={true} bold={true}>Not Found</Text>
						)
					}
				</ScrollContainer>
			</Col>
		</div>
	);

	return ReactDom.createPortal(
		content,
		element
	)
});

export { Select };
