import { FC, useContext } from 'react';
import { Button } from 'lavaa';
import { ModalsCtx } from '../../../../Context/Modals.context';

interface IProps {
    text?: string;
    icon?: number;
}

// Create Project Action Button
const CreateProjectActionButton: FC<IProps> = (props) => {
 
    let { setNewProjectModalActive } = useContext(ModalsCtx);

    const handleClick = () => {
        setNewProjectModalActive(true);
    };

    return <Button {...props} onClick={ () => handleClick() } />
};

export { CreateProjectActionButton };